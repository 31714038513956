import React, { useEffect, useCallback, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import Icons from 'components/icons/svgIcons';
import Button from 'components/button';
import { useDropzone } from 'react-dropzone';
import isEmpty from 'helpers/isEmpty';
import NIcons from 'components/icons'
import CropImage from './cropImage';


export const UploadDropAlt = (props) => {
  const {
    name,
    multiple,
    control,
    required,
    ...rest
  } = props;
  return (
    <Controller
      rules={{ required: required ? 'Upload field is required' : false }}
      required={required}
      render={({ onChange, value }) => {
        return(
        <Dropzone
          multiple={multiple}
          name={name}
          files={value}
          onRemove={() => onChange("")}
          onChange={file =>onChange(file)
          }
          {...rest}
        />
      )}}
      name={name}
      control={control && control}
      defaultValue=''
    />
  )
}

const Dropzone = ({
  multiple,
  onChange,
  name,
  files,
  onRemove,
  disabled,
  images,
  ...rest
}) => {
  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    multiple,
    ...rest,
  });
  const handleImageChange = (e) => {
    if(multiple){
      if(images){
        onChange([...e.target.files,...images])
        return;
      }
      onChange(e.target.files)
      // onChange(files ? [e.target.files,...files] :e.target.files)
    }else{
      onChange(e.target.files[0])
    }
    
  }
  return (
    <div className="" key='uniqueKey'>
       <div className="generic-group__drop-alt" {...getRootProps()}>
            <input disabled={disabled} name={name} {...getInputProps({ onChange: handleImageChange })} />
            <div className="generic-group__drop-alt-icon">
              <Icons name="UploadFiles" size={42} color={"#A0A4A8"} />
            </div>
            <div className="generic-group__drop-alt-title">Drag and Drop here or <span>Browse a File</span></div>
        </div>
    </div>

  )
}


export default UploadDropAlt