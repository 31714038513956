import https from "services/http";
import Action from "services/actionUtility";
import environment from "environment";
import types from "constants/types";
import RouteEnum from "constants/routesEnum";
import {
  REFRESH_TOKEN,
  IDLE_TIME_OUT,
  EXPIRES_TIME,
  EXPIRES_MINUTES,
  AUTH_TOKEN,
} from "constants/contants";
import { setAuthToken } from "helpers/axiosInstance";
import HttpErrorResponseModel from "src/services/httpErrorResponseModel";
import { clearLogoutLocalStorage } from "helpers/methods";
import queryString from "query-string";

export const registerUser = (data, history, callback) => async (dispatch) => {
  const res = https.post(environment.api.registerUser, data);
  const actionConfig = {
    dispatch,
    isLoading:true,
    type:'post',
    isUserLoading:true,
    actionType: types.REGISTER_USER,
    message: "User Successfully Register",
    effect: res,
  };
  const response = await Action.createThunkEffect(actionConfig);
  if (response && response.Status) {
    // history.push(RouteEnum.registerSuccess);
    callback()
    // history.push(RouteEnum.Login)
  }
};

const saveToken = (response: any) => async (dispatch) => {
  if (response) {
    //Save to local Storage
    const res = response.data.Data;
    if(!res){
      clearLogoutLocalStorage();
      return null;
    }
    let AuthToken = "bearer " + res.Token;
    let expires_in = res.ExpireMinutes;
    let RefreshToken = res.RefreshToken;
    let IdleTimeoutMinutes = res.IdleTimeoutMinutes;
    let Username = res.Username;
    //Set token to ls
    localStorage.setItem(AUTH_TOKEN, AuthToken);
    localStorage.setItem(REFRESH_TOKEN, RefreshToken);
    localStorage.setItem(IDLE_TIME_OUT, IdleTimeoutMinutes);
    localStorage.setItem(EXPIRES_MINUTES, expires_in);
    const timeout = expires_in * 60 * 1000 - 60 * 1000;
    setTimeout(async () => {
      // this would reset localStorage before token expiry timr
      // await dispatch(refreshToken());
    }, timeout);
    var tokenexpiration = new Date();
    let expires: number =
      tokenexpiration.setSeconds(
        new Date().getSeconds() + (parseInt(expires_in) + 5) * 60 - 10
        // new Date().getSeconds() + parseInt(expires_in) * 60 - 10
      ) / 1000;
    // parseInt(expires_in) * 60;
    // (parseInt(expires_in) / 60) * 10 -10) / 1000;

    let tokenDetails = {
      RefreshToken,
      expires,
      expires_in,
      IdleTimeoutMinutes,
      AuthToken,
      Username,
    };
    localStorage.setItem(EXPIRES_TIME, expires.toString());
    await setAuthToken(AuthToken);
    await dispatch(setCurrentToken(AuthToken, tokenDetails));
    const promiseArray = [
      dispatch(getUser()),
    ];
      await Promise.all(promiseArray);
  }
};

export const getUser = ():any =>async dispatch =>{
  const res = https.get(environment.api.getCurrentUser,);
  const actionConfig = {
    dispatch,
    actionType: types.GET_USER,
    message: "",
    isUserLoading:true,
    type:'get',
    effect: res,
  };
  const response = await Action.createThunkEffect(actionConfig);
  if (response && response.Status) {
    // history.push(RouteEnum.Login)
  }
}

export const uploadImage = (data,isUser = false) =>async dispatch =>{
  const res:any = await https.post(environment.api.uploadUserImage,data);
  const isError = res instanceof HttpErrorResponseModel;
  if (!isError) {
    dispatch(getUser())
    // history.push(RouteEnum.Login)
  }
}

export const updateName = (data,isUser = false) =>async dispatch =>{
  const res = https.put(environment.api.updateName,data);
  const actionConfig = {
    dispatch,
    actionType: types.UPDATE_USER,
    extraPayload:{isUser},
    message: "Account Info successfully updated",
    type:'put',
    isLoading:true,
    effect: res,
  };
  const response = await Action.createThunkEffect(actionConfig);
  if (response && response.Status) {
    // history.push(RouteEnum.Login)
  }
}

export const updateEmail = (data,isUser = false) =>async dispatch =>{
  const res = https.put(environment.api.updateEmail,data);
  const actionConfig = {
    dispatch,
    actionType: types.UPDATE_USER,
    extraPayload:{isUser},
    message: "Email successfully updated",
    type:'put',
    effect: res,
    isLoading:true,
  };
  const response = await Action.createThunkEffect(actionConfig);
  if (response && response.Status) {
    dispatch(logoutUser())
    return true
    // history.push(RouteEnum.Login)
  }
  return false
}

export const updateUserPassword = (data) => async dispatch => {
    const res = https.put(environment.api.changeUserPassword, data);
    const actionConfig = {
        dispatch,
        actionType: types.UPDATE_USER,
        extraPayload: { isUser: true },
        message: "Password details successfully updated",
        type: 'post',
        effect: res,
        isLoading: true,
    };
    const response = await Action.createThunkEffect(actionConfig);
    if (response && response.Status) {
        // history.push(RouteEnum.Login)
        return true
    }
    return false
}

export const updateAgencyDetails = (data) =>async dispatch =>{
  const res = https.put(environment.api.updateAgencyDetails,data);
  const actionConfig = {
    dispatch,
    actionType: types.UPDATE_USER,
    extraPayload:{isUser:true},
    message: "Agency details successfully updated",
    type:'put',
    effect: res,
    isLoading:true,
  };
  const response = await Action.createThunkEffect(actionConfig);
  if (response && response.Status) {
    // history.push(RouteEnum.Login)
    return true
  }
  return false
}

export const updateCheckDetails = (data) =>async dispatch =>{
  const res = https.put(environment.api.updateCheckDetails,data);
  const actionConfig = {
    dispatch,
    actionType: types.UPDATE_CURRENT_USER,
    extraPayload:{isUser:true},
    message: "Checkpost successfully updated",
    type:'put',
    effect: res,
    isLoading:true,
  };
  const response = await Action.createThunkEffect(actionConfig);
  if (response && response.Status) {
    // history.push(RouteEnum.Login)
    return true
  }
  return false
}

export const forgotPassword = (data,history) =>async dispatch =>{
  const res = https.post(environment.api.forgotPassword,data);
  const actionConfig = {
    dispatch,
    actionType: types.FORGOT_PASSWORD,
    message: "Link successfully sent",
    type:'post',
    isLoading:true,
    effect: res,
    showError:true
  };
  const response = await Action.createThunkEffect(actionConfig);
  if (response && response.Status) {
    history.push(RouteEnum.checkMail)
    // history.push(RouteEnum.Login)
  }
}

export const resetPassword = (data,history) =>async dispatch =>{
  const res = https.post(environment.api.resetPassword,data);
  const actionConfig = {
    dispatch,
    actionType: types.FORGOT_PASSWORD,
    message: "Password changed successfully",
    type:'post',
    isLoading:true,
    effect: res,
  };
  const response = await Action.createThunkEffect(actionConfig);
  if (response && response.Status) {
    history.push(RouteEnum.successPasswordChange)
    // history.push(RouteEnum.Login)
  }
}
export const changeUserPassword = (data, history) => async dispatch => {
    const res = https.post(environment.api.changeUserPassword, data);
    const actionConfig = {
        dispatch,
        actionType: types.FORGOT_PASSWORD,
        message: "Password changed successfully",
        type: 'post',
        isLoading: true,
        effect: res,
    };
    const response = await Action.createThunkEffect(actionConfig);
    if (response && response.Status) {
        history.push(RouteEnum.successPasswordChange)
        // history.push(RouteEnum.Login)
    }
}


export const verifyEmail = (data,history) =>async dispatch =>{
  const res = https.get(environment.api.verifyEmail,data);
  const actionConfig = {
    dispatch,
    actionType: types.FORGOT_PASSWORD,
    message: "Email Verified successfully",
    type:'post',
    isLoading:true,
    effect: res,
  };
  const response = await Action.createThunkEffect(actionConfig);
  if (response && response.Status) {
    history.push(RouteEnum.Login)
    // history.push(RouteEnum.Login)
  }
}

export const resendEmail = (data) =>async dispatch =>{
  const res = https.post(environment.api.resendEmail,data);
  const actionConfig = {
    dispatch,
    actionType: types.FORGOT_PASSWORD,
    message: "Email Resend successfully",
    type:'post',
    isLoading:true,
    effect: res,
  };
  const response = await Action.createThunkEffect(actionConfig);
  if (response && response.Status) {
    return true;
  }
  return false;
}

export const verifyChangeEmail = (data,history) =>async dispatch =>{
  const res = https.get(environment.api.verifyChangeEmail,data);
  const actionConfig = {
    dispatch,
    actionType: types.FORGOT_PASSWORD,
    message: "Email Verified successfully",
    type:'post',
    isLoading:true,
    effect: res,
  };
  const response = await Action.createThunkEffect(actionConfig);
  if (response && response.Status) {
    history.push(RouteEnum.Login)
    // history.push(RouteEnum.Login)
  }
}


export const loginUser = (data) => async (dispatch) => {
  dispatch({ type: types.LOGIN_LOADING, payload: true });
  const res: any = await https.post(environment.api.signin, data);
  dispatch({ type: types.LOGIN_LOADING, payload: false });
  const isError = res.data instanceof HttpErrorResponseModel;
  if (res && !isError) {
    if(res?.data?.Status){
      dispatch(saveToken(res));
    }else{
      dispatch({type:types.ADD_MESSAGE,payload: { type: "danger", message: res?.data?.Message }});
    }
  }
  if (isError) {
    dispatch({ type: types.GET_ERRORS, payload: res });
    
  }
  return res?.data;
};

export const refreshToken = ():any => async (dispatch: Function) => {
  const refresh_token = localStorage[REFRESH_TOKEN];
  if (refresh_token) {
    dispatch({ type: types.ADD_LOADING, payload: true });
    let request = queryString.stringify({
      token: refresh_token,
    });
    const res: any = await https.post(environment.api.refreshToken + '?' + request);
    dispatch({ type: types.ADD_LOADING, payload: false });
    const isError = res instanceof HttpErrorResponseModel;
    if (res && !isError) {
      if (res.data.Status) {
        await dispatch(saveToken(res));
      } else {
        // await dispatch(logoutUser("refresh_error"));
        dispatch({type:types.ADD_MESSAGE,payload: { type: res.data.MessageType, message: res.data.Message }});
      }
      // dispatch(saveToken(res));
    }
    if (isError) {
      dispatch({ type: types.GET_ERRORS, payload: res });
      // await dispatch(logoutUser("refresh_error"));
      return res;
    }
  }
};

export const otherMessage = (message: string) => (dispatch: Function) => {
  dispatch({
    type: types.ADD_OTHER_MESSAGES,
    payload: {
      type: "Success",
      message,
      timeout: -1,
    },
  });
};

//Log user out
export const logoutUser = (type = "none", history = null) => async (
  dispatch: any
) => {
  // Remove the token from local storage
    clearLogoutLocalStorage();
    dispatch(setCurrentUser({}));
    dispatch(setCurrentToken("", ""));
    dispatch({type: types.RESET_STATE})
  const res = https.post(environment.api.logout);
  const actionConfig = {
    dispatch,
    actionType: types.REGISTER_USER,
    message: "User Successfully Logout",
    type:'post',
    effect: res,
  };
  const response = await Action.createThunkEffect(actionConfig);
  if (response && response.Status) {
    dispatch(setCurrentUser({}));
    dispatch(setCurrentToken("", ""));
    setAuthToken(false);
  }
  if (type === "inactivity") {
    dispatch(
      otherMessage(
        " Your login session has expired. Please login again to continue."
      )
    );
    return false;
  }
  if (type === "refresh_error") {
    dispatch(
      otherMessage(
        " Your login session has expired. Please login again to continue."
      )
    );
    return false;
  }
};


export const changePassword = (data) =>async (dispatch) =>{
  const res = https.post(environment.api.changePassword,data)
  const actionConfig = {
    dispatch,
    actionType:types.CHANGE__PASSWORD,
    message:'Password Changed Successfully',
    type:'post',
    effect:res,
    isLoading:true
  }
  const response = await Action.createThunkEffect(actionConfig); 
  if(response && response.Status){
    return true
      // history.push(RouteEnum.Login)
  }
  return false
}

//Set logged in user
export const setCurrentUser = (user) => {
  return {
    type: types.SET_CURRENT_USER,
    payload: user,
  };
};

export const setCurrentToken = (token, tokenDetails) => {
  return {
    type: types.SET_CURRENT_TOKEN,
    payload: token,
    tokenDetails,
  };
};

// Clear errors
export const clearErrors = () => {
  return {
    type: types.CLEAR_ERRORS,
  };
};
export const clearRegistrationState = () => {
  return {
    type: types.CLEAR_REGIS_TREKKERS,
  };
};
