import React from 'react'

function Background({data}) {
    return (
        <div className="request-background">
            <div className="request-background__row">
                <div className="request-background__col">Organization Name</div>
                <div className="request-background__col">{data?.OrganizationName}</div>
            </div>
            <div className="request-background__row">
                <div className="request-background__col">Organization PAN Number</div>
                <div className="request-background__col">{data?.OrganizationContact}</div>
            </div>
            <div className="request-background__row">
                <div className="request-background__col">Phone Number</div>
                <div className="request-background__col">{data?.ContactNo}</div>
            </div>
            <div className="request-background__row">
                <div className="request-background__col">Email Address</div>
                <div className="request-background__col">{data?.Email}</div>
            </div>
            <div className="request-background__row">
                <div className="request-background__col">Address</div>
                <div className="request-background__col">{data?.ContactAddress}</div>
            </div>
            <div className="request-background__row">
                <div className="request-background__col">Regd Date</div>
                <div className="request-background__col">{data?.RegisterdDate}</div>
            </div>
        </div>
    )
}

export default Background
