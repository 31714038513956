import types from "constants/types";
import isEmpty from "helpers/isEmpty";
import { TrekkersInitialState } from "ts/reducer";
import {TrekkerDetailModel,UserRegistrationModel,TrekkerDetailsMOdel} from '../../models/TrekkerModel'

const initialState: TrekkersInitialState = {
  loading:false,
  trekkers: [],
  deletedTrekkers: [],
  registeredTrekker: null,
  scannedTrekkers: [],
  voucherTrekker:null,
  trekker: null,
  trekkingHistory: [],
  esewa: null,
  khalti: null,
  fone: null,
  ips: null,
  cardPay: null,
  trekLoading:false,
};

const getUpdatedTrekkers= (dt,trekkers) => {
  if(dt?.UserRegistrationModel && trekkers){
    if(!isEmpty(trekkers?.TrekkersDetailsDtos)){
      trekkers?.TrekkersDetailsDtos?.map(item=>{
        if(item?.TrekkersDetails?.ID === dt?.UserRegistrationModel?.ID){
          const final = new TrekkerDetailModel(dt?.UserRegistrationModel)
          item.TrekkersDetails = final;
          return item
        }
        return item;
      })
    }
  }
  return trekkers;
}

const getUpdatedTrekkersDetails= (dt,trekkers) => {
  if(dt?.UserRegistrationModel && trekkers?.UserRegistrationModel){
    const dts = new UserRegistrationModel(dt.UserRegistrationModel);
    trekkers.UserRegistrationModel = dts;
  }
  return trekkers;
}

const getAddUpdatedTrekkersDetails= (dt,state) => {
  let tks = [];
  if(dt?.TrekkersDetailsDtos && state.registeredTrekker){
    
    // const final = new TrekkerDetailModel(dt?.UserRegistrationModel)
    const finalDt = new TrekkerDetailsMOdel(dt?.TrekkersDetailsDtos[0])
    tks = [
      ...state.registeredTrekker?.TrekkersDetailsDtos,
      finalDt
    ]
  }
  state.registeredTrekker.TrekkersDetailsDtos = tks;
  // state.trekker.TrekkersDetailsDtos = tks;
  return {
    registeredTrekker:state.registeredTrekker,
    // trekker:state.trekker,
  };
}

export default function TrekkerReducer(state = initialState, action): TrekkersInitialState {
  switch (action.type) {
    case types.REGISTER_NEW_TREKKERS:
      return {
        ...state,
        trekkers: [...state.trekkers, action.payload],
        registeredTrekker: action.payload,
    };
    case types.CLEAR_REGIS_TREKKERS:
      return {
        ...state,
        registeredTrekker:null
      }
    case types.ADD_USER_DETAILS:
      return {
        ...state,
        ...getAddUpdatedTrekkersDetails(action.payload,state)
        // trekker: getAddUpdatedTrekkersDetails(action.payload,state.trekker),
        // registeredTrekker:getAddUpdatedTrekkersDetails(action.payload,state.registeredTrekker),
      };
    case types.UPDATE_NEW_TREKKERS:
      return {
        ...state,
        // trekker: getUpdatedTrekkers(action.payload,state.trekker),
        registeredTrekker:getUpdatedTrekkers(action.payload,state.registeredTrekker),
      };
    case types.UPDATE_NEW_TREKKERS_DETAILS:
      return {
        ...state,
        // trekker: getUpdatedTrekkersDetails(action.payload,state.trekker),
        registeredTrekker:getUpdatedTrekkersDetails(action.payload,state.registeredTrekker),
      }
    case types.REGISTER_NEW_TREKKERS_LOADING:
      return{
        ...state,
        loading:action.payload
      }
    case types.ADD_NEW_TREKKERS_LOADING:
      return{
        ...state,
        trekLoading:action.payload
      }
    case types.GET_TREKKER_VOUCHER:
      return{
        ...state,
        voucherTrekker:action.payload
      }
    case types.GET_TREKKER:
      return {
        ...state,
        trekker: action.payload,
        
      };
    case types.GET_REGISTRATION_TREKKER:
      return {
        ...state,
        registeredTrekker: action.payload,
      }
    case types.GET_SCANNED_TREKKERS_BY_USER:
      return {
        ...state,
        scannedTrekkers: action.payload,
      };
    case types.GET_TREKKING_HISTORY:
      return {
        ...state,
        trekkingHistory: action.payload,
      };
    case types.GET_TREKKERS:
      return {
        ...state,
        trekkers: action.payload,
      };
    case types.GET_DELETED_TREKKERS:
        return {
          ...state,
          deletedTrekkers: action.payload,
        };

      
    case types.GET_PAYMENT_ESEWA:
      return {
        ...state,
        esewa: action.payload,
      };

    case types.GET_PAYMENT_FONE:
      return {
        ...state,
        fone: action.payload,
      };

    case types.GET_PAYMENT_IPS:
      return {
        ...state,
        ips: action.payload,
      };

    case types.GET_PAYMENT_KHALTI:
      return {
        ...state,
        khalti: action.payload,
      };
    case types.GET_PAYMENT_CARD:
      return {
        ...state,
        cardPay: action.payload,
      };
      case types.RESET_STATE:{
        return initialState
    }
    default:
      return state;
  }
}
