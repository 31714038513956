import React from 'react'
import { useForm, Controller } from 'react-hook-form';
import 'react-quill/dist/quill.snow.css'; 
import ReactQuill from 'react-quill';

function ReactQuilled(props) {
    const {
        name,
        multiple,
        control,
        required,
        ...rest
      } = props;
    return (
        <div>
            <Controller
                rules={{ required: required ? 'Field is required' : false }}
                required={required}
                render={({ onChange, value }) => {
                    return (
                        //@ts-ignore
                    <ReactQuill
                        value={value}
                        onChange={value =>onChange(value)
                        }
                    />
                )}}
                name={name}
                control={control && control}
                defaultValue=''
            />
        </div>
    )
}

export default ReactQuilled
