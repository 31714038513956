import React, { useState } from 'react'
import Form from 'components/form'
import { useWatch } from 'react-hook-form';
import classnames from 'classnames';
import Button from 'components/button';
import Icons from 'components/icons';
import isEmpty from 'helpers/isEmpty';


function Map({methods,attachments,setAttachments,...props}) {
    const {control,register,errors,setValue}= methods;
    const [selectedImages,setSelectedImages] = useState([])
    const [isChanged, setIsChanged] = useState(false);
    const [allowSelect,setAllowSelect] = useState(false)
    const images:any = useWatch({ control, name: 'MapFiles' });
    const imagesArray = images? Array.from(images):[]
    const onImageSelect = (item)=>{
        setSelectedImages(prev=>{
            if(prev){
                return [...prev,item]
            }
            return [item]
        })
    }
    const onImageSelectRemove = (item)=>{
        setSelectedImages(prev=>prev.filter(img=>img !== item))
    }
    const onCancel = () =>{
        setAllowSelect(false)
        setSelectedImages([])
    }
    const onImageRemove = () =>{
        setAttachments(prev=>prev.filter(att=>!selectedImages.includes(att.AttachmentId)))
        setIsChanged(true)
        setAllowSelect(false)
    }
    const onRevertAllChanges = () =>{
        if(props.route){
            setAttachments(props.route?.MapAttachment)
            setIsChanged(false)
            onCancel()
        }
    }
    return (
        <div>
            <Form
                name="MapFiles"
                formName="uploadDropAlt"
                multiple={true}
                // required={true}
                control={control}
                images={images}
                register={register}
                error={errors}
            />
            <div className="routes-images">
                <div className="routes-images__group">
                    {
                        imagesArray && imagesArray.map((item:any)=>{
                            return <div key={item?.name} className="routes-images__item">
                                <img src={URL.createObjectURL(item) } alt="" />
                                <div 
                                    onClick={()=>setValue("MapFiles",imagesArray.filter((img:any)=>img.name !== item.name))}
                                    className="routes-images__remove">
                                    <Icons type="iconify" dataIcon="clarity:times-circle-line" />
                                </div>
                            </div>
                        })
                    }
                </div>
                {
                    !isEmpty(props.route?.MapAttachment) && (
                        <div className="routes-images__select">
                        <div className="routes-images__select-buttons">
                            {
                                allowSelect ? (
                                    <>
                                        <Button title="Cancel" bg="primary" onClick={()=>onCancel()} />
                                        <Button title="Delete Selected" isDisabled={!selectedImages} bg="danger" onClick={()=>onImageRemove()} />
                                    </>
                                ) : 
                                (
                                    <Button bg="primary" title="Select" onClick={()=>setAllowSelect(true)} />
                                )
                            }
                            {isChanged && <Button bg="grey" title="Revert All Changes" onClick={()=>onRevertAllChanges()} />}
                        </div>
                        <div className={classnames("routes-images__group",{"routes-images__group-selected":allowSelect})}>
                            {
                                props.isEdit && attachments  && 
                                attachments.map(item=>{
                                    const isSelected = selectedImages && Boolean(selectedImages.includes(item.AttachmentId))
                                    return <div 
                                            onClick={()=>allowSelect && (isSelected ? onImageSelectRemove(item.AttachmentId) : onImageSelect(item.AttachmentId)) }
                                            key={"Map-"+item.AttachmentId}
                                            className={classnames("routes-images__item",{"image-active":isSelected})}>
                                        <img src={'/'+item.FileLocation} alt="" />
                                        <div className="routes-images__check">
                                            
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    )
                }

            </div>
        </div>
    )
}

export default Map
