import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import Form from 'components/form';
import { RootStateTypes } from 'ts/reducer';
import {onlyText,emailValid,onlyNum} from 'constants/regex';


function AgencyForm(props) {
    const {method} = props;
    const { setValue, control,register, errors:errs } = method;
    const { agencyUser } = useSelector((state: RootStateTypes) => state.userReducer)
    useEffect(() => {
        if (agencyUser && agencyUser.AgencyUser) {
            const {OrganizationName,OrganizationContact,FullName,ContactAddress,ContactNo} = agencyUser.AgencyUser;
            if(OrganizationName)
                setValue("OrganizationName", OrganizationName)
            if(OrganizationContact)
                setValue("OrganizationPanNumber", OrganizationContact)
            
            if(FullName)
                setValue("FullName", FullName)
            if(ContactAddress)
                setValue("Address", ContactAddress)
            if(ContactNo)
                setValue("ContactNo", ContactNo)
        }
    }, [agencyUser,setValue]);
    return (
        <div className="agency-form">
            <div className="agency-form__row">
                <Form
                    name="OrganizationName"
                    label="Organization Name"
                    required={true}
                    control={control}
                    disabled
                    register={register}
                    validate={(val)=> !onlyText(val) || 'Only text allowed'}
                    error={errs}
                />
            </div>
            <div className="agency-form__row">
                <Form
                    name="OrganizationPanNumber"
                    label="Organization PAN Number"
                    required={true}
                    validate={(val)=>onlyNum(val,9) || 'Please enter 9 digit number'}
                    disabled
                    control={control}
                    register={register}
                    error={errs}
                />
            </div>
            <div className="agency-form__row">
                <Form
                    name="FullName"
                    label="Contact Person Name"
                    required={true}
                    control={control}
                    register={register}
                    validate={(val)=> !onlyText(val) || 'Only text allowed'}
                    error={errs}
                />
            </div>
            <div className="agency-form__row">
                <Form
                    name="ContactNo"
                    label="Contact Number"
                    required={true}
                    control={control}
                    register={register}
                    validate={(val)=>onlyNum(val,10) || 'Please enter 10 digit number'}
                    error={errs}
                />
            </div>
            <div className="agency-form__row">
                <Form
                    name="Address"
                    label="Contact Address"
                    required={true}
                    control={control}
                    register={register}
                    error={errs}
                />
            </div>
        </div>
    )
}

export default AgencyForm
