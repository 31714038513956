import React, { useEffect, useState } from 'react'
import FilterDrop from 'components/common/filterDrop';
import FormGroup from 'components/form/formGroup';
import Form from 'components/form';
import Button from 'components/button';
import DatePicker from 'react-datepicker';
import Icons from 'components/icons/svgIcons';
import { useHistory } from 'react-router';
import queryString from 'query-string'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { useForm, useWatch } from 'react-hook-form';
import isEmpty from 'helpers/isEmpty';

interface FilterContainerProps{
    options:Array<any>;
    width:number,
    children?:any,
    field:string,
    onFilter:Function,
    isSearch:boolean
}

export const FilterContainer = (props:FilterContainerProps)=>{
    const {options,width,isSearch,children,field,onFilter} = props;
    const [vals, setVals] = useState([]);
    const { register, control, setValue } = useForm();
    const val = props[field]
    const search: any = useWatch({ control, name: 'search' });
    useEffect(() => {
        if (val) {
            setValue(field, val.split(","))
        }
    }, [val])
    useEffect(() => {
        if (options) {
            setVals(options)
        }
    }, [options])
    useEffect(() => {
        if (search) {
            const searchs = search.toLowerCase()
            const searched = vals.filter(item => item.label.toLowerCase().includes(searchs))
            setVals(searched)
        } else {
            setVals(options)
        }
    }, [search])
    const trekVal: any = useWatch({ control, name: field });

    const onSubmit = () => {
        if (trekVal) {
            onFilter && onFilter({ [field]: trekVal.join(",") })
        }
    }
    const onFilterClear = () =>{
        onFilter && onFilter({ [field]: "" })
    }
    if(!isEmpty(options)){
        return (
            <FilterDrop width={width}>
                {
                    isSearch && (
                        <div className="filters-form__search">
                            <Form
                                name="search"
                                register={register}
                                placeholder="Search..."
                                formName="searchInput"
                                control={control}
                            />
                        </div>
                    )
                }
                <div className="filters-form">
                    <Form
                        name={field}
                        formName="checkgroup"
                        register={register}
                        labels={vals}
                        control={control}
                    />
                </div>
                <div className="filters-form__buttons">
                    <Button title="Clear" onClick={() => onFilterClear()} size="small" bg="grey" />
                    <Button title="Filter" onClick={() => onSubmit()} htmlButtonType="submit" size="small" bg="green-alt" />
                </div>
            </FilterDrop>
        )
    }
    return(
        <FilterDrop width={225}>
            <div className="filters-none">
                <span>No Filter options found</span>
            </div>
        </FilterDrop>

    )
}

FilterContainer.defaultProps={
    width:200,
    isSearch:false
}


export const TrekkingRouteFilter = props => {
    const { routes } = useSelector((state: RootStateTypes) => state.selectReducer)
    return (
        <FilterContainer 
            field={props.field ? props.field :"trekkingArea" }
            width={225} 
            // isSearch
            options={routes} 
            {...props}
        />
    )
}

export const RegTypeFilter = props => {
    const { registrationTypes } = useSelector((state: RootStateTypes) => state.selectReducer)
    return (
        <FilterContainer 
            field="userAgencyType" 
            width={200} 
            options={registrationTypes} 
            {...props}
        />
    )
}


export const AgencyTypeFilter = props => {
    const { agencyUsers } = useSelector((state: RootStateTypes) => state.selectReducer)
    return (
        <FilterContainer 
            field="agency" 
            width={200} 
            options={agencyUsers} 
            {...props}
        />
    )
}

export const CountryFilter = (props) => {
    const { countries } = useSelector((state: RootStateTypes) => state.selectReducer)
    return (
        <FilterContainer 
            field="country" 
            width={225} 
            isSearch
            options={countries} 
            {...props}
        />
    )
}


export const CardStatusFilter = props => {
    const checkLabels = [
        { label:"Active" , value: "Active", },
        { label: "Approved", value: "Approved"},
        { label: "Cancelled", value: "Cancelled"},
        { label: "Accomplished", value: "Accomplished"},
        { label:"Payment Pending" , value: "Payment Pending", },
        // { label:"Pending",  value: "Pending", },
        { label: "Rejected", value: "Rejected"},
        { label: "Returned", value: "Returned"},
        { label: "Upcoming", value: "Upcoming"},
    ]

    return (
        <FilterContainer 
            field="cardStatus" 
            width={225} 
            options={checkLabels} 
            {...props}
        />
    )
}


export const RequestStatusFilter = props => {
    const checkLabels = [
        { label:"Pending" , value: "pending", },
        { label: "Approved", value: "approved"},
        { label: "Rejected", value: "rejected"},
        { label: "Document Pending", value: "document_pending"},
    ];
    return (
        <FilterContainer 
            field="status" 
            width={225} 
            options={checkLabels} 
            {...props}
        />
    )
}


export const RegionFilter = props => {
    const { region: rgs } = useSelector((state: RootStateTypes) => state.selectReducer)
    return (
        <FilterContainer 
            field="region" 
            width={175} 
            options={rgs} 
            {...props}
        />
    )
}


export const PaymentMethodFilter = props => {
    const { paymentMethods: pms } = useSelector((state: RootStateTypes) => state.selectReducer)
    return (
        <FilterContainer 
            field="paymethod" 
            width={200} 
            options={pms} 
            {...props}
        />
    )
}



export const StatusFilter = ({ onFilter }) => {
    const checkLabels = [
        { label: <div className="flex">Active<span className="table-status__circle"></span></div>, value: 1, color: '#53D86A' },
        { label: <div className="flex">Cancelled<span className="table-status__circle table-status__circle-danger"></span></div>, value: 2, color: '#FF3434' },
        { label: <div className="flex">Missed<span className="table-status__circle table-status__circle-warning"></span></div>, value: 3, color: '#E28800' },
        { label: <div className="flex">Returned<span className="table-status__circle table-status__circle-primary"></span></div>, value: 4, color: '#004EFF' },
    ]
    // const {  register } = useForm();
    const onSubmit = (data) => {
        onFilter && onFilter({ country: data.join(",") })
    }
    return (
        <FilterDrop width={175}>
            <FormGroup onSubmit={onSubmit} defaultValues={{ trek_status: [] }}>
                <div className="filters-form">
                    <Form
                        name="trek_status"
                        formName="checkgroup"
                        labels={checkLabels}
                    />
                    <div className="filters-form__buttons">
                        <Button title="Submit" size="small" bg="primary" />
                        <Button title="Filter" htmlButtonType="submit" size="small" bg="green-alt" />
                    </div>
                </div>
            </FormGroup>
        </FilterDrop>
    )
}

export const UserStatusFilter = (props) => {
    const checkLabels = [
        { label: <div className="flex">Active<span className="table-status__circle"></span></div>, value: 1, color: '#53D86A' },
        { label: <div className="flex">Disabled<span className="table-status__circle table-status__circle-danger"></span></div>, value: 0, color: '#FF3434' },
    ];
    return (
        <FilterContainer 
            field="status" 
            width={175} 
            options={checkLabels} 
            {...props}
        />
    )
}


export const DateFilter = (props) => {
    const { onFilter, isSingle, field } = props
    const history = useHistory()
    let query = queryString.parse(history.location.search);
    const [startDate, setStartDate] = useState<any>(new Date());
    const [endDate, setEndDate] = useState(null);
    const [value, setValue] = useState(null);
    var lastday = new Date();
    var firstday = new Date(new Date().setDate(lastday.getDate() - 7));
    var firstMonthday = new Date(new Date().setDate(lastday.getDate() - 30));
    var firstThreeMonthday = new Date(new Date().setDate(lastday.getDate() - 90));
    const [stDate, setStDate] = useState(null);
    const [edDate, setEdDate] = useState(null);
    const onDateClick = (start, end) => {
        setStartDate(start)
        setEndDate(end)
    }
    const onChange = dates => {
        const [start, end] = dates;
        // dayjs(start).format('YYYY-MM-DD')
        setStartDate(start);
        setEndDate(end);
    };
    useEffect(() => {
        if (props.startDate) {
            setStartDate(new Date(props.startDate))
        }
        if (props.endDate) {
            setEndDate(new Date(props.endDate))
        }
        if(props.value){
            setValue(props.value)
        }
    }, [props.startDate, props.endDate,props.value])
    //   useEffect(()=>{
    //     console.log({query})
    //     if(startDate && endDate){
    //         history.push({
    //             pathname:history.location.pathname,
    //             search:'?' + queryString.stringify({
    //                 ...query,
    //                 startDate:dayjs(new Date(startDate)).format("YYYY-MM-DD"),
    //                 endDate:dayjs(new Date(endDate)).format("YYYY-MM-DD")
    //             }),
    //         })
    //     }
    //   },[startDate,endDate])
    const handleFilter = () => {

        if(isSingle){
            const start = value && dayjs(value).format('YYYY-MM-DD')
            field && onFilter && onFilter({ [field]:start })
        }else{
            const start = startDate && dayjs(startDate).format('YYYY-MM-DD')
            const end = endDate && dayjs(endDate).format('YYYY-MM-DD')
            onFilter && onFilter({ startDate: start, endDate:end })
        }
        
    }
    const onFilterClear = () =>{
        onFilter && onFilter({ startDate: null,endDate:null, value:null })
    }
    return (
        <FilterDrop width={320}>
            <div className="filters-date">
                <div className="filters-date__row">
                    <div className="filters-date__label">Show report for the </div>
                    <div className="filters-date__vals">
                        <div
                            onClick={() => onDateClick(firstday, lastday)}
                            className="filters-date__val"><span>7 Days</span></div>
                        <div
                            onClick={() => onDateClick(firstMonthday, lastday)}
                            className="filters-date__val"><span>30 Days</span></div>
                        <div
                            onClick={() => onDateClick(firstThreeMonthday, lastday)}
                            className="filters-date__val"><span>90 Days</span></div>
                        {/* <div className="filters-date__val"><span>Custom</span></div> */}
                    </div>
                </div>
                {/* <div className="filters-date__row">
                      <div className="filters-date__label">Select your date range:</div>
                      <div className="filters-date__inputs">
                          <div className="filters-date__date">
                              <Icons name="Calendar" />
                              <DatePicker
                                  onChange={(date)=>setStDate(date)}
                                  value={stDate}
                              />
                          </div>
                          <div className="filters-date__date">
                              <Icons name="Calendar" />
                              <DatePicker
                                  onChange={(date)=>setEdDate(date)}
                                  value={edDate}
                              />
                          </div>
                      </div>
                  </div> */}
                <div className="filters-date__row filters-date__range">
                    {
                        isSingle ? (
                        <DatePicker
                            value={value}
                            onChange={(value) => setValue(value)}
                            inline
                        />
                        ) : (
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                        />
                        )
                    }
                </div>
                <div className="filters-form__buttons">
                    <Button title="Clear" onClick={() => onFilterClear()} size="small" bg="grey" />
                    <Button
                        onClick={() => handleFilter()}
                        title="Filter" htmlButtonType="submit" size="small" bg="green-alt" />
                </div>
            </div>
        </FilterDrop>
    )
}


export const PaymentDateFilter = (props) => {
    const { onFilter, isSingle, field } = props
    const history = useHistory();
    let query = queryString.parse(history.location.search);

    const [paymentStartDate, setStartDate] = useState<any>(new Date());
    const [paymentEndDate, setEndDate] = useState(null);
    const [value, setValue] = useState(null);

    // Predefined date ranges
    var lastday = new Date();
    var firstday = new Date(new Date().setDate(lastday.getDate() - 7));
    var firstMonthday = new Date(new Date().setDate(lastday.getDate() - 30));
    var firstThreeMonthday = new Date(new Date().setDate(lastday.getDate() - 90));

    const onDateClick = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    }

    const onChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    useEffect(() => {
        if (props.paymentStartDate) {
            setStartDate(new Date(props.paymentStartDate));
        }
        if (props.paymentEndDate) {
            setEndDate(new Date(props.paymentEndDate));
        }
        if (props.value) {
            setValue(props.value);
        }
    }, [props.paymentStartDate, props.paymentEndDate, props.value]);

    const handleFilter = () => {
        if (isSingle) {
            const start = value && dayjs(value).format('YYYY-MM-DD');
            field && onFilter && onFilter({ [field]: start });
        } else {
            const start = paymentStartDate && dayjs(paymentStartDate).format('YYYY-MM-DD');
            const end = paymentEndDate && dayjs(paymentEndDate).format('YYYY-MM-DD');
            onFilter && onFilter({ paymentStartDate: start, paymentEndDate: end });
        }
    }

    const onFilterClear = () => {
        onFilter && onFilter({ paymentStartDate: null, paymentEndDate: null, value: null });
    }

    return (
        <FilterDrop width={320}>
            <div className="filters-date">
                <div className="filters-date__row">
                    <div className="filters-date__label">Show report for the </div>
                    <div className="filters-date__vals">
                        <div onClick={() => onDateClick(firstday, lastday)} className="filters-date__val"><span>7 Days</span></div>
                        <div onClick={() => onDateClick(firstMonthday, lastday)} className="filters-date__val"><span>30 Days</span></div>
                        <div onClick={() => onDateClick(firstThreeMonthday, lastday)} className="filters-date__val"><span>90 Days</span></div>
                    </div>
                </div>
                <div className="filters-date__row filters-date__range">
                    {
                        isSingle ? (
                            <DatePicker
                                value={value}
                                onChange={(value) => setValue(value)}
                                inline
                            />
                        ) : (
                            <DatePicker
                                    selected={paymentStartDate}
                                onChange={onChange}
                                    startDate={paymentStartDate}
                                    endDate={paymentEndDate}
                                selectsRange
                                inline
                            />
                        )
                    }
                </div>
                <div className="filters-form__buttons">
                    <Button title="Clear" onClick={() => onFilterClear()} size="small" bg="grey" />
                    <Button
                        onClick={() => handleFilter()}
                        title="Filter" htmlButtonType="submit" size="small" bg="green-alt" />
                </div>
            </div>
        </FilterDrop>
    )
}