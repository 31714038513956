import React, { useEffect } from 'react'
import FormGroup from 'components/form/formGroup';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { changePassword } from 'store/actions/auth';
import Form from 'components/form';
import Button from 'components/button';
import { useForm } from 'react-hook-form';
import Icons from 'components/icons/svgIcons';
import classNames from 'classnames';
import CommonPasswordValidation from 'components/common/commonPasswordValidation';
import usePasswordValidationResolver from 'src/hooks/usePasswordValidation';

const ChangePassword = (props) => {
    const dispatch = useDispatch()
    const method = useForm();
    const { watch, setError} = method
    const errors = useSelector((state: RootStateTypes) => state.validateReducer.errors);
    const password: any = watch('NewPassword');
    const onSubmit = (data) => {
        dispatch(changePassword(data))
    }
    const {
        pass,
        isNotEight,
        isNotNum,
        isNotLowUp,
        isNotNonAlph
    } = usePasswordValidationResolver(password)
    const {erroList} = useSelector((state:RootStateTypes)=>state.validateReducer);
    useEffect(()=>{
        if(erroList && Array.isArray(erroList) && erroList.length > 0){
            erroList.map(item=>{
                Object.keys(item).map(key=>{
                    setError(key, {
                        type: "manual",
                        message:item[key]
                      })
                }
                  )
            })
        }
    },[erroList]);
    return (
        <FormGroup method={method} onSubmit={onSubmit}>
            <div className="account-settings-form account-profile__body">
                <div className="account-profile__row">
                    <Form
                        type="password"
                        name="CurrentPassword"
                        label="Current Password"
                        required={true}
                    />
                </div>
                <div className="account-profile__row">
                    <Form
                        name="NewPassword"
                        type="password"
                        label="New Password"
                        // warning="Password must be 8 character long"
                        validate={(value) => isNotEight && isNotLowUp && isNotNum && isNotNonAlph || ""}
                        // required={true}
                    />

                </div>
                <CommonPasswordValidation 
                    pass={pass}
                    isNotEight={isNotEight}
                    isNotLowUp={isNotLowUp}
                    isNotNum={isNotNum}
                    isNotNonAlph={isNotNonAlph}
                />
                <div className="account-profile__row">
                    <Form
                        type="password"
                        name="ConfirmNewPassword"
                        label="Confirm Password"
                        validate={(value) => value === watch("NewPassword") || "Password don't match."}
                        required={true}
                    />
                </div>
                {
                    errors && Array.isArray(errors) && (
                        <ul className="login-errors">
                            {
                                errors.map(item => {
                                    return (
                                        <li key={item}>{item}</li>
                                    )
                                })
                            }
                        </ul>
                    )
                }
                <div className="account-profile__button">
                    <Button
                        icon={<Icons name="Save" size={16} />}
                        bg="primary"
                        // type="button-outline" 
                        buttonType="icon-button"
                        title="Save Changes" htmlButtonType="submit" />
                    <Button bg="cancel"
                        // type="button-outline" 
                        title="Cancel" onClick={() => props.onModalClose()} />
                </div>
            </div>
        </FormGroup>
    )
}

export default ChangePassword
