import Container from 'components/layout/container'
import React, { useContext, useState } from 'react'
import PaymentMessage from './paymentMessage'
import classnames from 'classnames'
import { RootStateTypes } from 'ts/reducer'
import Icons from 'components/icons/svgIcons';
import { useSelector } from 'react-redux'
import connect from 'assets/images/connect.png';
import { useParams } from 'react-router-dom'
import Context from '../context';
import GatewayForm from './paymentGateawayForm'
import PaymentUpload from './paymentUpload'
import PaymentStatus from './paymentStatus'

const tabs = [
    /* { id: 1, name: 'Credit or Debit Cards', icon: <Icons size={40} name="CreditCardIcon" />, },*/
     { id: 2, name: 'NEPALPAY QR & connectIPS', icon: <img width={30} src={connect} />, },
    //  { id: 2, name: 'Conect IPS', icon: <Icons size={40} name="MobilePay" />, },
     { id: 3, name: 'Cash/Voucher Deposit', icon: <Icons size={40} className="upload-icon" name="Billing" />, }
]

function Payment() {
    const vals = useContext(Context)
    const [active, setActive] = useState(2);
    const {user} = useSelector((state:RootStateTypes)=>state.auth);
    // const params:any = useParams();
    const isAction = vals.isPaymnet
    const payNotSuccess = vals.isFailure || vals.isDecline;
    return (
        <div className="cards">
            <Container maxWidth={'max-content'}>
                <>
                    <div className="cards-container">
                        <div className="cards-left">
                        <div className="cards-tabs">
                                    <ul className="cards-tabs__list">
                                        {
                                            tabs.map(item => (
                                                <li key={item.id} onClick={() =>setActive(item.id)} className={classnames("cards-tabs__item", { "cards-tabs__active": active === item.id })}>
                                                {/* <li key={item.id} onClick={() => (!isAction || payNotSuccess) && setActive(item.id)} className={classnames("cards-tabs__item", { "cards-tabs__active": active === item.id })}> */}
                                                    <div className="cards-tabs__item-icon">
                                                        {item.icon}
                                                    </div>
                                                    <div className="cards-tabs__item-title">
                                                        {item.name}
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                        </div>

                    </div>
                    <PaymentMessage />
                    <PaymentStatus />
                    <div className="cards-tab">
                        <div className="cards-tab__form">
                            {active === 2 && <GatewayForm isSuccess={vals.isSuccess} />} 
                            {active === 3 && <PaymentUpload  />}
                        </div>
                    </div>
                </>
            </Container>
        </div>
    )
}

export default Payment