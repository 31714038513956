import React from 'react'
import isEmpty from 'isEmpty';

function TabItemHeader(props:any) {
    const {children,single,active} = props;
    // checking if ther is single or multiple Tab component inside Tabs component
    if(Array.isArray(children) === false || single){
        // Returning Same Tab component with extra props like isActive, onClick, name of activeTab
        return React.cloneElement(
                    children, 
                    {
                        isActive:true,
                        onClick:props.onClick,
                        activeTab:props.active,
                        ...children.props,
                    }
            )
    };
    return children.map((child, key) => {
        if (!isEmpty(child) && child) {

        const childProps = {
            isActive:active===child.props?.name,
            onClick:props.onClick,
            activeTab:props.active,
            key:'TabHeader'+key,
            ...child.props
        }
        return React.cloneElement(
                child, 
                childProps
              );
      }
    })
}

export default TabItemHeader
