import { DateFilter, TrekkingRouteFilter } from 'components/common/filters';
import Container from 'components/layout/container';
import Table from 'components/table';
import { Reason, TrekkingStatus } from 'components/table/commonTableComponents';
import Pagination from 'components/table/pagination';
import { pageSizeValues } from 'constants/contants';
import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'components/form';
import {getCard, getReturnCards} from 'store/actions/cardActions';
import { RootStateTypes } from 'ts/reducer';
import ResetFilter from 'components/common/resetFilter';
import DownloadAllCsv from 'components/common/downloadAllCsv';
import CommonSidebar from 'components/common/commonSidebar';
import ReturnCardDetail from './returnCardDetail';
import TrekkerSidebar from 'scenes/common/trekkers/trekkerSidebar';
import { getTrekking } from 'store/actions/trekkersAction';



const pageState = {
    pageindex:0,
    pageSize:pageSizeValues[0].value,
    totalRows:0,
}

const filterState = {
    routename:null,
    createdStartDate:null,
    createEndDate:null,
    startDate:null,
    endDate:null
}

function CardReturnTable() {
    const dispatch = useDispatch();
    const [page, setPage] = useState(pageState)
    const [data,setData] = useState([]);
    const [rowData,setRowData] = useState([]);
    const {control,register} = useForm();
    const [filter, setFilter] = useState(filterState)
    const {returnCards} =useSelector((state:RootStateTypes)=>state.cardReducer)
    const search:any = useWatch({ control, name: 'search' });
    const pageSize:any = useWatch({ control, name: 'pageSize' });
    useEffect(()=>{
        if(returnCards){
            setData(returnCards)
            const totalRows = returnCards ? returnCards[0]?.TotalRows : 0;
            returnCards && setPage(prev=>({...prev}))
            returnCards && setPage(prev=>({...prev,totalRows:totalRows}))
        }
    },[returnCards]);
    useEffect(()=>{
        getFront()
    },[page.pageSize,page.pageindex]);
    useEffect(()=>{
        getFront()
    },[filter]);
    const getFront = () =>{
        dispatch(getReturnCards({...page,...filter}))
    }
    useEffect(() => {
        if(pageSize && pageSize.value) {
          setPage(prev=>({...prev,pageSize:pageSize.value}));
        }
    }, [pageSize]);
    const [open,setOpen] = useState(false);
    const onFilter = async (items) => {
        await setFilter(prev=>({...filter,...items}))
        // getUsersDetails()
    }  
    const columns = [
        {
            Header: 'Card Number',
            accessor: 'CardNumber',
            width: 140,
        },
        {
            Header: 'Registered Route',
            accessor: 'TrekRoute',
            width: 220,
            Filter:({filter:fil,onchange}) =><TrekkingRouteFilter field="routename"  routename={filter?.routename} {...filter} onFilter={onFilter} />,
            isSort:true,
        },
        {
            Header: 'Regd Date',
            accessor: 'RegisterdDate',
            isSort:true,
            width: 220,
            Filter:({ filter, onChange }) =><DateFilter {...filterState} onFilter={onFilter} />
        },
        {
            Header: 'Start Date',
            accessor: 'TrekSartDate',
            width: 200,
            Filter:({ filter, onChange }) =><DateFilter isSingle field="createdStartDate" {...filterState} onFilter={onFilter} />,
            isSort:true,
        },
        {
            Header: 'End Date',
            accessor: 'TrekEndDate',
            width: 200,
            Filter:({ filter, onChange }) =><DateFilter isSingle field="createEndDate" {...filterState} onFilter={onFilter} />,
            isSort:true,
        },
        {
            Header: 'Status',
            accessor: 'CardStatus',
            width: 140,
            Cell:(props) =><TrekkingStatus {...props}  />,
        },
        // {
        //     Header: 'Remarks',
        //     accessor: 'Remarks',
        //     width: 200,
        //     Cell:(props) =><Reason {...props}  />,
        // },
    ];
    const onRowClick = async (row)=>{
        const voucher = row.original;
        if(voucher && voucher?.RegistrationId){
            setOpen(true)
            setRowData(voucher);
            await dispatch(getCard(voucher?.RegistrationId,voucher?.TrekkerId))
            await dispatch(getTrekking(null,voucher?.TrekkerId))
        }
    }
    // useEffect(() => {
    //     if(search){
    //         dispatch(getReturnCards({...page,name:search}))
    //     }
    // }, [search])
    const getSearch = () => {
        dispatch(getReturnCards({...page,name:search}))
    }
    const onPageChange = async (page) =>await setPage(prev=>({...prev,pageindex:page}))
    const onClearFilters = () => setFilter(filterState);
    const onModalClose = () => setOpen(false)
    const getDataCallBack = () =>{
        getFront();
        setRowData(null)
        onModalClose()
    }
    return (
        <div className="request-table">
        <Container>
                <div className="space-between search-alt">
                    <Form 
                        formName="reactSelect"
                        control={control}
                        name="pageSize"
                        value={pageSizeValues[0]}
                        defaultValue={pageSizeValues[0]}
                        className="page-nums"
                        width="175px"
                        placeholder="Choose..."
                        options={pageSizeValues}
                    />
                    <div className="flex">
                        
                        <ResetFilter onClearFilters={onClearFilters} filters={filter} />
                        {/* <DownloadAllCsv
                            api={environment.api.getVoucher}
                            headers={csvheaders}
                            extraParams={{ ...filter }}
                            fileName="Invoice"
                            dataField="VoucherData"
                            isData={true}
                        /> */}
                        <Form 
                            register={register}
                            name="search"
                            width="300px"
                            placeholder="Search..."
                            formName="searchInput"
                            onKeyUp={(name,value,e)=>{
                                if(e?.keyCode === 13){
                                    getSearch()
                                }
                            }}
                        />
                    </div>
                    
                </div>
            <div className="routes-table">
                <Table 
                    onRowClick={onRowClick} 
                    columns={columns} data={data} />
                <Pagination onPageChange={onPageChange} {...page} totalRows={page.totalRows} />
            </div>
            <TrekkerSidebar rowData={rowData} isReturn callback={getDataCallBack} open={open} onClose={onModalClose} />
        </Container>
    </div>
    )
}

export default CardReturnTable
