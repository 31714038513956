import React from 'react'
import classnames from 'classnames';
import Button from 'components/button';
import Icons from 'components/icons/svgIcons';
import { createPortal } from 'react-dom';
import Icon from 'components/icons/svgIcons'

function CommonSidebar(props) {
    const {open,title,onModalClose,children,onSave,className} = props;
    return (
        createPortal(<div className={classnames("common-sidebar",{"open":open,[className]:Boolean(className)})}>
            <div className="common-sidebar__header">
                <div className="common-sidebar__title">
                    <span>{title}</span>
                </div>
                <div onClick={()=>onModalClose()}  className="common-sidebar__close">
                    <Icons size={18} name="Close" />
                </div>
            </div>
            <div className="common-sidebar__body">
                {children}
            </div>
            {
                onSave && (
                    <div className="common-sidebar__footer">
                        <Button title="Cancel" bg="cancel" onClick={()=>onModalClose()} />
                        <Button onClick={()=>onSave()} title="Save Changes" bg="primary" icon={<Icon name="Save" size={14} />} buttonType="icon-button"  />
                    </div>
                )
            }
        </div>,document.body)
    )
}

export default CommonSidebar
