import RouteEnum from 'constants/routesEnum';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router';
import { getUser } from 'store/actions/auth';
import { RootStateTypes } from 'ts/reducer';
import { modulesList, userEnums, userNameType } from 'constants/contants';

const allowALl = [userEnums.free,userEnums.trekkingAgency,userEnums.ntb,userEnums.check,userEnums.taan]

const modules = {
    [modulesList.dashboad]:[userEnums.ntb,userEnums.taan],
    [modulesList.users]:[userEnums.ntb,userEnums.taan],
    [modulesList.addUser]:[userEnums.ntb],
    [modulesList.trekkers]:[userEnums.ntb,userEnums.taan,userEnums.trekkingAgency,userEnums.check],
    [modulesList.routes]:[userEnums.ntb,userEnums.taan,userEnums.free,userEnums.trekkingAgency,userEnums.check],
    [modulesList.routesAdd]:[userEnums.ntb],
    [modulesList.uploadDocuments]:[userEnums.trekkingAgency],
    [modulesList.uploadDocumentsStatus]:[userEnums.trekkingAgency],
    [modulesList.scan]:[userEnums.check],
    [modulesList.scannedTrekker]:[userEnums.check],
    [modulesList.billing]:[userEnums.ntb,userEnums.taan,userEnums.free,userEnums.trekkingAgency,userEnums.check],
    [modulesList.timsCard]:[userEnums.free],
    [modulesList.requests]: [userEnums.ntb, userEnums.taan],
    [modulesList.reports]:[userEnums.ntb],
    [modulesList.registerTrekkers]:[userEnums.free,userEnums.check,userEnums.trekkingAgency],
    [modulesList.trekkingHistory]:[userEnums.free],
    [modulesList.inbox]:[userEnums.trekkingAgency,userEnums.taan],
    [modulesList.locationModule]:[userEnums.trekkingAgency,userEnums.free,userEnums.check],
    [modulesList.settings]:allowALl,
    [modulesList.all]:allowALl,
    [modulesList.signup]:allowALl,
    [modulesList.login]:allowALl,
    [modulesList.logout]:allowALl,
    [modulesList.forgotPassword]:allowALl,
    [modulesList.passwordReset]:allowALl,
    [modulesList.checkMail]:allowALl,
    [modulesList.createPassword]:allowALl,
    [modulesList.notFound]: allowALl,
    [modulesList.selfScanTrekkers]: [userEnums.ntb, userEnums.taan],
    [modulesList.chalan]: [userEnums.ntb,],
   
    
}

const modulesNew = {
    [modulesList.userPassword]: [userNameType.userEmail,],
}
// Please wait for 10 minutes before your next payment The system will update your payment
// free - 1
// trekkingAgency -2
// ntb -userEnums.ntb
// check - userEnums.check
// taan - 6
function useAuth() {
    const {user,isAuthenticated} = useSelector((state:RootStateTypes)=>state.auth);
    const dispatch = useDispatch()
    if(!isAuthenticated){
        <Redirect to={RouteEnum.Login} />
        return null;
    }
    if(!user){
        dispatch(getUser());
    }
    const getAllowedModules = () =>{
        return {
            [modulesList.dashboad]:modules.dashboad.includes(user.UserType),
            [modulesList.users]:modules.users.includes(user.UserType),
            [modulesList.addUser]:modules.addUser.includes(user.UserType),
            [modulesList.trekkers]:modules.trekkers.includes(user.UserType),
            [modulesList.routes]:modules.routes.includes(user.UserType),
            [modulesList.routesAdd]:modules.routesAdd.includes(user.UserType),
            [modulesList.billing]:modules.billing.includes(user.UserType),
            [modulesList.timsCard]:modules.timsCard.includes(user.UserType),
            [modulesList.requests]:modules.requests.includes(user.UserType),
            [modulesList.scan]:modules.scan.includes(user.UserType),
            [modulesList.scannedTrekker]:modules.scannedTrekker.includes(user.UserType),
            [modulesList.uploadDocuments]:modules.uploadDocuments.includes(user.UserType),
            [modulesList.uploadDocumentsStatus]:modules.uploadDocumentsStatus.includes(user.UserType),
            [modulesList.reports]:modules.reports.includes(user.UserType),
            [modulesList.registerTrekkers]:modules.registerTrekkers.includes(user.UserType),
            [modulesList.inbox]:modules.inbox.includes(user.UserType),
            [modulesList.locationModule]:modules.locationModule.includes(user.UserType),
            [modulesList.settings]:modules.settings.includes(user.UserType),
            [modulesList.all]:modules.all.includes(user.UserType),
            [modulesList.signup]:modules.signup.includes(user.UserType),
            [modulesList.login]:modules.login.includes(user.UserType),
            [modulesList.logout]:modules.logout.includes(user.UserType),
            [modulesList.forgotPassword]:modules.forgotPassword.includes(user.UserType),
            [modulesList.passwordReset]:modules.passwordReset.includes(user.UserType),
            [modulesList.checkMail]:modules.checkMail.includes(user.UserType),
            [modulesList.createPassword]:modules.createPassword.includes(user.UserType),
            [modulesList.notFound]:modules.notFound.includes(user.UserType),
            [modulesList.trekkingHistory]: modules.trekkingHistory.includes(user.UserType),
            [modulesList.selfScanTrekkers]: modules.selfScanTrekkers.includes(user.UserType),
            [modulesList.chalan]: modules.chalan.includes(user.UserType),
            [modulesList.chalanlog]: modules.chalan.includes(user.UserType),
            [modulesList.userPassword]: modulesNew.userPassword.includes(user.Email),
            UserType: user.UserType
        }
    }
    return getAllowedModules()
}

export default useAuth
