import { BaseModel } from 'sjs-base-model';

export default class TrekkerModel extends BaseModel {
    UserRegistrationModel:UserRegistrationModel = null;
    TrekkersDetailsDtos:Array<TrekkerDetailsMOdel> = [];
    constructor(data) {
        super();

        this.update(data);
    }

    update(data) {
        super.update(data);
    }
}


export class UserRegistrationModel extends BaseModel {
    OtherSupportStaffs:string = '';
    PorterGuideContact:string = '';
    PorterGuideInfo:string = '';
    PorterGuideLicesenceNo:string = '';
    RegistrationId:string = '';
    TrekEntryDate:Date = null;
    TrekEntryPoint:string = '';
    TrekExitDate:Date = null;
    TrekExitPoint:string = '';
    TrekRoute:string = '';
    TrekkingAreaId:number = null;
    ID:any = null;
  
    constructor(data) {
      super();
  
      this.update(data);
    }
  
    update(data) {
      super.update(data);
  
    }
}


export class TrekkerDetailsMOdel extends BaseModel {
    Attachment:Array<AttachmentModel> = [];
    TrekkersDetails:TrekkerDetailModel = null;
    // TrekkersDetails:Array<TrekkerDetailModel> = [];
    Files:Array<any> = [];
  
    constructor(data) {
      super();
  
      this.update(data);
    }
  
    update(data) {
      super.update(data);
  
    }
}


export class AttachmentModel extends BaseModel{
    AttachmentId:number = null;
    CreatedBy:any = null;
    CreatedOn:any = null;
    FileFormat:any = null;
    FileLocation:string = "";
    FileType:string = "";
    ServerFileName:string = "";
    Size:string = "";
    UserFileName:string = "";
    constructor(data) {
      super();
  
      this.update(data);
    }
  
    update(data) {
      super.update(data);
  
    }
}


export class TrekkerDetailModel extends BaseModel{
    Address:string = "";
    City:string = "";
    ContactPerson:string = "";
    Designation:string = "";
    Dob:Date = null;
    VisaExpiryDate:Date = null;
    VisaIssueDate:Date = null;
    Gender:number = null;
    ID:number = null;
    Nationality:number = null;
    RegionalId:number = null;
    Email:string = "";
    FullName:string = "";
    HomeOfficeNo:string = "";
    InsuranceCompany:string = "";
    InsuranceCountry:string = "";
    InsuranceNational:string = "";
    Mobile:string = "";
    NationalityName:string = "";
    NativeAddress:string = "";
    NativeContactPerson:string = "";
    NativeDesignation:string = "";
    NativeMobile:string = "";
    NativeOfficeNo:string = "";
    NativeOrganization:string = "";
    Organization:string = "";
    PassportNumber:string = "";
    PolicyNumber:string = "";
    PolicyType:string = "";
    RegistrationId:string = "";
    UserPhotoUrl:string = "";
    VisaNumber:string = "";
    constructor(data) {
      super();
  
      this.update(data);
    }
  
    update(data) {
      super.update(data);
  
    }
}