import React, { useEffect, useState } from "react";
import { TabsInterface } from "ts/interfaces/componentInterface";
import isEmpty from "isEmpty";

import TabItem from "./tabItem";
import TabBlockItems from "./tabBlockItems";

function Tabs(props: TabsInterface) {
    const { children: childrens, activeTab, type } = props;

  const [active, setActive] = useState("");

  const [single, setSingle] = useState(null);
    //
    const children = Array.isArray(childrens) ? childrens.filter(x => x !== false) : childrens; 
  useEffect(() => {
    //Setting active tab by default, if more than 1 tab 1st tab is active
    if (Array.isArray(children)) {
        setSingle(false);
     
      if (isEmpty(activeTab)) {
        const {
          props: { name: tabName },
          // props: { label: tabLabel, children: tabChilren, name: tabName },
        } = children[0];
        setActive(tabName);
      } else {
        setActive(activeTab);
      }
    } else {
      setSingle(true);
      const {
        props: {name: tabName },
        // props: { label: tabLabel, children: tabChilren, name: tabName },
      } = children;
      setActive(tabName);
    }
    // const len = children.length;
  }, [activeTab]);


  const onClickTabItem = (tab: string) => {
    setActive(tab);
    props.onTabClick && props.onTabClick(tab)
  };

  const renderTabType = (): any => {
    switch (type) {
      case "block":
        //Tab style block with expand and collapse
        return (
          <TabBlockItems
            single={single}
            onClick={onClickTabItem}
            active={active}
            {...props}
          />
        );
      //Normal tab
      default:
        return (
          <TabItem
            single={single}
            onClick={onClickTabItem}
            active={active}
            {...props}
          />
        );
    }
  };
  return <div>{renderTabType()}</div>;
}

Tabs.defaultProps = {
  type: "normal",
  color:'primary'
};

export default Tabs;
