import React, { useEffect } from 'react'
import Common from '../login/common';
import Image from 'assets/images/check_email.png'
import QS from 'query-string';
import { useDispatch } from 'react-redux';
import { verifyChangeEmail } from 'store/actions/auth';
import Button from 'components/button';
import RouteEnum from 'constants/routesEnum';
import Icons from 'components/icons/svgIcons';

function VerifyEmail(props) {
    const search = props.location.search;
    const dispatch = useDispatch();
    useEffect(()=>{
        if(search){
            let tk:any = QS.parse(search);
            if(tk){
                const {token,verify,change} = tk;
                
                if(token){
                    const data = {
                        token:token.replace(/\s/g, '+'),
                        userid:verify.replace(/\s/g, '+'),
                        email:change.replace(/\s/g, '+')
                    }
                    dispatch(verifyChangeEmail(data,props.history))
                }
            }
        }
    },[search])
    return (
        <Common isFooter={false}>
            <div className="forgot center-text">
                {/* <div className="forgot-image">
                    <img src={Image} alt="Forgot Password Image" />
                </div> */}
                <div className="forgot-title login-form__container-title">
                    <span>Email changed Successfully!</span>
                </div>
                <div className="forgot-sub login-form__container-sub">
                    <span>We just verified and changed your email successfully.</span>
                </div>
                <div className="forgot-sub login-form__container-sub mt-md">
                    <Button 
                        buttonType="icon-button"
                        icon={<Icons name="Login" />}
                        bg='primary' 
                        title="Login" 
                        size="large" 
                        onClick={()=>props.history.push(RouteEnum.Login)}  
                    />
                </div>
            </div>
        </Common>
    )
}

export default VerifyEmail
