import React from 'react';
import classnames from 'classnames';

function getPageList(totalPages, page, maxLength) {

     if (maxLength < 5) throw "maxLength must be at least 5";

    function range(start, end) {
        return Array.from(Array(end - start + 1), (_, i) => i + start); 
    }

    var sideWidth = maxLength < 9 ? 1 : 2;
    var leftWidth = (maxLength - sideWidth*2 - 3) >> 1;
    var rightWidth = (maxLength - sideWidth * 2 - 2) >> 1;

    if (totalPages <= maxLength) {
        // no breaks in list
        return range(1, totalPages);
    }
    if (page <= maxLength - sideWidth - 1 - rightWidth) {
        // no break on left of page
        return range(1, maxLength - sideWidth - 1)
            .concat(0, range(totalPages - sideWidth + 1, totalPages));
    }
    if (page >= totalPages - sideWidth - 1 - rightWidth) {
        // no break on right of page
        return range(1, sideWidth)
            .concat(0, range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages));
    }
    // Breaks on both sides
    return range(1, sideWidth)
        .concat(0, range(page - leftWidth, page + rightWidth),
                0, range(totalPages - sideWidth + 1, totalPages));
}


const resolvedData= {
    count:100,
    next:1,
    page_size:20,
    previous:'as'
}
function Pagination(props) {
    const { 
        pageindex,
        pageSize,
        totalRows,
        onPageChange
    } = props;

    const [page,setPage] = React.useState(1);
    const {count,next,previous,page_size} = resolvedData;
    const isPrevDisabled = 0 == pageindex;
    const isNextDisabled = Math.floor(totalRows / pageSize) === pageindex
    if(Math.ceil(totalRows / pageSize)<2) return null;
    const isPage = totalRows / pageSize > 0 ;
    if(!isPage) return null;
    let pageNumbers = Math.ceil(totalRows / pageSize)
    let pages = getPageList(pageNumbers, pageindex + 1, 6)

    return (
                <div className="pagination">
            <div className="pagination-main">
                <div className="pagination-main__list">
                    <button
                        disabled={isPrevDisabled}
                        onClick={() => onPageChange(pageindex - 1)}
                        className="btn-pagination previous">
                        <span className='not-phone'>
                            Prev
                        </span>
                    </button>
                    <div className="pagination-buttons">
                        {
                           pages.map((e, i) => {
                                const numClass = classnames({
                                    "btn-pagination": true,
                                    "btn-pagination-out": e=== 0,
                                    "btn-pagination-num": true,
                                    "active": parseInt(e) === pageindex+1
                                })
                                return (
                                    <button
                                        // disabled={(i + 1) == page}
                                        key={"PageButton-"+e}
                                        // onClick={() => setPage(i + 1)}
                                        onClick={() => onPageChange(e-1)}
                                        disabled={e==0}
                                        className={numClass}>
                                        <span>{e=== 0 ? '...' : e}</span>
                                    </button>
                                )
                            }
                            )
                        }
                    </div>
                    <button
                        // disabled={!latestData || !latestData.next}
                        // onClick={() => setPage(old => (!latestData || !latestData.next ? old : old + 1))}
                        disabled={isNextDisabled}
                        // onClick={()=>setPage(page + 1)}
                        onClick={() => onPageChange(pageindex + 1)}
                        className="btn-pagination next">
                        <span className='phone'>
                            Next
                        </span>
                    </button>
                </div>
            </div>

        </div>
    )
}

export default Pagination
