import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'isEmpty';
import RoutesEnum from 'constants/routesEnum';
import useAuth from 'src/hooks/useAuth';
import { RootStateTypes } from 'ts/reducer';
import { uploadDocumentStatus } from 'constants/contants';
const PrivateRoute = ({ component: Component, name, isNotPrivate, children, auth, title, ...rest }: any) => (
  <Route
    {...rest}
    render={props => {
      return <ShowRoutes
        {...props}
        auth={auth}
        name={name}
        isNotPrivate={isNotPrivate}
        children={children}
        Component={Component}
        title={title}
      />
    }

    }
  />
);

const ShowRoutes = ({ Component, name, children, title, auth, isNotPrivate, ...rest }: any) => {
  const { isAuthenticated } = auth;
  const authed = useAuth()
  const { user } = useSelector((state: RootStateTypes) => state.auth)
  useEffect(() => {
    const { location: { pathname } } = rest;
    if (!isEmpty(title)) {
      document.title = title;
      rest.location.title = title
    } else {
      let title = pathname.split('/');
      if (!isEmpty(title) && title.length > 0 && Array.isArray(title)) {
        title = title[title.length - 1].toUpperCase();
      } else {
        title = "TIMS"
      }
      document.title = title;
    }
  }, []);
  if ((isAuthenticated === true) || isNotPrivate) {
    // if(rest.match.path === RoutesEnum.NewRegister ||rest.match.path ===  RoutesEnum.NewRegister+'/:regId' ||rest.match.path ===  RoutesEnum.NewRegister+'/:regId/:actionType'){
    //   dispatch(clearTrekker())
    // }
    if (authed && !authed[name] && authed.UserType) {
      const pathname = rest.location.pathname;
      
      // dispatch(toastMessage({type:'Danger',message:'Unauthorized'}))
      if (pathname === RoutesEnum.Dashboard || pathname === '/') {
        if (authed.UserType == 1) {
          return <Redirect to={RoutesEnum.NewRegister} />
        }
        if (authed.UserType == 2) {
          // return <Redirect to={RoutesEnum.UploadDocuments} />
          if (user && !user.IsDocumentUploaded) {
            return <Redirect to={RoutesEnum.UploadDocuments} />
          }
          if (user && user.IsVerified === null) {
            return <Redirect to={RoutesEnum.UploadDocumentsStatus + '/' + uploadDocumentStatus.sent} />
          }
          if (user && user.IsVerified === false) {
            return <Redirect to={RoutesEnum.UploadDocumentsStatus + '/' + uploadDocumentStatus.rejected} />
          }
          if (user && user.IsVerified === true && !user.IsApproved) {
            return <Redirect to={RoutesEnum.UploadDocumentsStatus + '/' + uploadDocumentStatus.approved} />
          }
          if (user && user.IsApproved === true) {
              return <Redirect to={RoutesEnum.NewRegister} />
          }
          return <Redirect to={RoutesEnum.NewRegister} />
        }
        if (authed.UserType == 5) {
          return <Redirect to={RoutesEnum.NewRegister} />
        }
        if (authed.UserType == 4) {
          return <Redirect to={RoutesEnum.Dashboard} />
        }
        if (authed.UserType == 6) {
          return <Redirect to={RoutesEnum.Dashboard} />
        }
      }
    }
    // console.log({a:"Here"})
    return <Component {...rest} title={title} children={children} />
  }

  // return <Component {...rest} title={title} children={children} />
  return <Redirect to={RoutesEnum.Login} />
}


PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
})
export default connect(mapStateToProps)(PrivateRoute);