import React from 'react'

const usePasswordValidationResolver = password =>{
    var eightChar = /.{8,}/
    var numReg = /(?=.*?[0-9])/
    var lowerUpper = /(?=.*?[A-Z])(?=.*?[a-z])/
    var nonAlphaNum = /(?=.*?[#?!@$%^&*-])/
    const pass = password || ''
    const isNotEight = eightChar.test(pass)
    const isNotNum = numReg.test(pass)
    const isNotLowUp = lowerUpper.test(pass)
    const isNotNonAlph = nonAlphaNum.test(pass)

    return {
        pass,
        isNotEight,
        isNotNum,
        isNotLowUp,
        isNotNonAlph
    }
}

export default usePasswordValidationResolver
