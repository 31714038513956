import React from 'react'
import Icons from '../icons/svgIcons';
import Dropdown from '../dropdown';

function FilterDrop({children,width}:any) {
    return (
        <div className="filters" style={{marginLeft:8}} >
            <Dropdown isFixed width={width} dropClass="filters-dropdown" isFilter customDropIcon={<Icons size={18} name="Filter" />} title="">
                <div className="filters-body">
                    {
                        children
                    }
                </div>
            </Dropdown>
        </div>
    )
}

export default FilterDrop
