import React, { useEffect, useState } from 'react'
import Container from 'components/layout/container';
import Heading from 'components/typography';
import Table from 'components/table';
import Button from 'components/button';
import './styles.scss';
import RouteEnum from 'constants/routesEnum';
import {Status,Reason, TrekkingStatus} from 'components/table/commonTableComponents';
import { useDispatch, useSelector } from 'react-redux';
import { getTrekkingHistory } from 'store/actions/registerTrekker';
import { RootStateTypes } from 'ts/reducer';
import Pagination from 'components/table/pagination';
import { Link, useHistory } from 'react-router-dom';
import Form from 'components/form';
import { useForm, useWatch } from 'react-hook-form';
import { pageSizeValues } from 'constants/contants';
import TrekkerSidebar from 'scenes/common/trekkers/trekkerSidebar';
import { getTrekking } from 'store/actions/trekkersAction';
import { getCard } from 'store/actions/cardActions';
import Icons from 'components/icons/svgIcons';

const RevisitBtn = ({value}) => {
    const history = useHistory()
    return(
        <div className="revisit-btn">
            <Button 
                bg="primary"
                title="Revisit"
                size="small"
                onClick={()=>history.push({
                    pathname: RouteEnum.NewRegister,
                    search: `?regId=${value}`,
                    state: { regId: value }
                  })}
            />
        </div>
    )
}

const CardLink = ({value,row,column:{extra}}) =>{
    const data = row.original
    const [open, setOpen] = useState(false);
    const onClose = () => setOpen(false);
    const dispatch = useDispatch();
    const onClick =() =>{
        // dispatch(getTrekker(row.original.RegistrationId))
        // dispatch(getCard(row.original.RegistrationId));
        dispatch(getTrekking(null,row.original.TrekkerId))
        dispatch(getCard(row.original.RegistrationId,row.original.TrekkerId))
        setOpen(true)
    }
    const onComplete = () => {
        onClose()
        extra?.getTrekking && extra?.getTrekking()
    }
    return(
        // <Link to={RouteEnum.TimsCards +'/' + data?.RegistrationId} >
        //     View
        //     {/* {value} */}
        // </Link>  
        <>
        <div
            style={{cursor:'pointer',color:'#0DAD4C',fontWeight:'bold'}}
            onClick={()=>onClick()}
            
            className="">
                {/* <Link to={RouteEnum.TimsCards +'/' + row.original.RegistrationId}>
                    {value}
                </Link> */}
               View
            
        </div>
            <TrekkerSidebar trek={{CardStatus:row.original.TrekingStatus}} callback={onComplete} onClose={onClose} open={open} />
        </>
    )
}

const columns = [
    {
        Header: 'Id',
        accessor: 'RowNum',
        width:80
    },
    {
        Header: 'Trekking Area',
        accessor: 'TrekingArea',
        width:300
    },
    {
        Header: 'Trekking Date',
        accessor: 'TrekDate',
        width:300
    },
    {
        Header: 'TIMS Card',
        accessor: 'TimsCard',
        width:200,
        Cell:CardLink
    },
    {
        Header: 'Trek Status',
        accessor: 'TrekingStatus',
        width:200,
        Cell:TrekkingStatus
    },
    {
        Header: '',
        accessor: 'RegistrationId',
        width: 120,
        Cell: RevisitBtn
    },
    // {
    //     Header: 'Reason',
    //     accessor: 'Reason',
    //     width:200,
    //     Cell:Reason
    // },
];

const pageState = {
    pageindex:0,
    pageSize:pageSizeValues[0].value,
    totalRows:0,
}

function TrekkingHistory(props) {
    const dispatch = useDispatch();
    const [page, setPage] = useState(pageState)
    const [data,setData] = useState([]);
    const user = useSelector((state:RootStateTypes)=>state.auth.user)
    useEffect(()=>{
        getTrekking()
    },[])
    
    const onPageChange = async (page) =>await setPage(prev=>({...prev,pageindex:page}))
    
    const {trekkingHistory} =useSelector((state:RootStateTypes)=>state.trekkersReducer)
    useEffect(()=>{
        if(trekkingHistory){
            setData(trekkingHistory)
            const totalRows = trekkingHistory ? trekkingHistory[0]?.TotalRows : 0;
            trekkingHistory && setPage(prev=>({...prev}))
            setPage(prev=>({...prev,totalRows:totalRows}))
        }
    },[trekkingHistory]);
    useEffect(()=>{
        getTrekking()
    },[page.pageSize,page.pageindex])
    const getTrekking = () =>{
        dispatch(getTrekkingHistory({username:user?.FullName,...page}))
    }
    const {control} = useForm();
    
    const pageSize:any = useWatch({ control, name: 'pageSize' });

    useEffect(() => {
        if(pageSize) {
          setPage(prev=>({...prev,pageSize:pageSize.value}));
        }
    }, [pageSize])
    
    return (
        <div className="trekking-history">
            <Container>
                <div className="space-between search-alt">
                    <Form 
                        formName="reactSelect"
                        control={control}
                        name="pageSize"
                        value={pageSizeValues[0]}
                        defaultValue={pageSizeValues[0]}
                        className="page-nums"
                        width="175px"
                        placeholder="Choose..."
                        options={pageSizeValues}
                    />
                    <Button 
                        onClick={()=>props.history.push(RouteEnum.NewRegister)}
                        // type="button-outline"
                        buttonType="icon-button"
                        icon={<Icons name="AddCircle" size={16} />}
                        title="Register new trek" bg="primary" />
                </div>
                <Table columns={columns} extra={{getTrekking}} data={data} />
                <Pagination onPageChange={onPageChange} {...page} totalRows={page.totalRows} />
                {/* <div className="flex-end pv-sm">
                    
                </div> */}
            </Container>            
        </div>
    )
}

export default TrekkingHistory
