import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { basicInputInterface } from 'ts/interfaces/formInterface'
import DatePicker from 'react-datepicker';
import Icons from "components/icons/svgIcons";
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import "react-datepicker/dist/react-datepicker.css";

function DateInput(props: basicInputInterface) {
    const { name, placeholder, value, disabled,isClearable=false, required, validate } = props;

  // const handleChange = (e: any) => {
  //   const { name, value } = e.target;
  //   props.onChange && props.onChange(name, value);
  // };
  const ref:any = useRef()
  useEffect(()=>{
    if(ref.current){
       ref.current.querySelector("input").disabled = false
    }
  },[ref])
  return (
    <>
      <div ref={ref} className="genericForm-group__date ss">
        {/* <input
          className="genericForm-group__date-customInput"
          type="date"
          id={name}
          name={name}
          onInput={(e) => handleChange(e)}
          disabled={disabled}
          pattern="\d{4}/\d{2}/\d{2}"
          data-date-format="YYYY MMMM DD" 
          placeholder={placeholder}
          value={value}
          onKeyUp={handleKeyUp}
        /> */}
        {/* <Controller 
          control={props.control && props.control}
          as={DatePicker}
          defaultValue={value}
          name={name}
          disabled={disabled}
          rules={{ required:required?  (props.label ? props.label :placeholder? placeholder:  '' ) + ' field is required' : false  }}
          className="genericForm-custom-date"
          format="y-MM-dd"
          yearPlaceholder="YYYY"
          monthPlaceholder="MM"
          dayPlaceholder="DD"
          calendarIcon={<Icons type="iconify" dataIcon="fluent:calendar-ltr-32-regular" />}
        /> */}
        
        <Controller
          control={props.control && props.control}
          // as={DatePicker}
          render={({ onChange, onBlur, value, ref }) => {
            return (
              <DatePicker
                // onChange={onChange}2021-04-08 18:15:00.000
                // onChange={(date:Date)=>{
                //   let finalDate:any =  date;
                //   if(date instanceof Date){
                //     const sadDate:any = dayjs(date).format('YYYY-MM-DDTHH:mm');
                //     if(sadDate instanceof Date){
                //       finalDate = sadDate;
                //     }
                //   }
                //   // console.log({finalDate})
                //   onChange(finalDate)
                // }}

                onChange={(date:any)=>{
                  onChange(date ? dayjs(date).format('YYYY-MM-DD') : null )
                }}
                dateFormat="yyyy/MM/dd"
                onBlur={onBlur}
                showMonthDropdown
                isClearable={isClearable}
                showYearDropdown
                dropdownMode="select"
                disabled={disabled}
                selected={value ? new Date(value) : null}
                placeholderText={placeholder}
              />
            )
          }}
          defaultValue={value}
          name={name}
          disabled={disabled}
          rules={{ required: required ? (props.label ? props.label : placeholder ? placeholder : '') + ' field is required' : false,validate:validate?validate:null }}
          className="genericForm-custom-date"
        />
        <div style={{fontSize:18,color:'#868686',margin:'0 4px'}} className="generic-group__icon">
          {/* <span className="iconify" data-icon="fa-regular:calendar-alt" data-inline="false"></span> */}
          <Icons name={"CalendarAlt"} size={16} />
        </div>

        {/* <DatePicker 
          value={value?value:null}
          format="y-MM-dd"
          yearPlaceholder="YYYY"
          monthPlaceholder="MM"
          dayPlaceholder="DD"
          
          calendarIcon={<Icons type="iconify" dataIcon="fluent:calendar-ltr-32-regular" />}
          disabled={disabled}
          className="genericForm-custom-date"
          onChange={handleChange}
        /> */}
      </div>
    </>
  );
}

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.any.isRequired,
  error: PropTypes.any,
  width: PropTypes.string,
  label: PropTypes.string,
  handleBlur: PropTypes.func,
  onChange: PropTypes.func,
};

DateInput.defaultProps = {
  name: "",
  placeholder: "YYYY / MM / DD",
};

export default DateInput;
