import { RouteEnum } from 'constants/routesEnum'
import React, { useEffect, useState } from 'react'
import Breadcrumbs from 'components/breadcrumb';
import Container from 'components/layout/container';
import Table from 'components/table';
import Form from 'components/form';
import './styles.scss';
import { useForm, useWatch } from 'react-hook-form';
import { Actions, Status } from 'components/table/commonTableComponents';
import TrekModal from './trekModal';
import { Link } from 'react-router-dom';
import Pagination from 'components/table/pagination';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { pageSizeValues } from 'constants/contants';
import { deleteRoute, getRoutes, updateRouteStatus } from 'store/actions/routesAction'
import Button from 'components/button';
import environment from 'environment';
import DownloadAllCsv from 'components/common/downloadAllCsv';
import { modulesList } from 'constants/contants';
import useAuth from 'src/hooks/useAuth';
import Icons from 'components/icons/svgIcons';
import Modal from 'components/modal';

const CellLink = ({ value, row }) => {
    return (
        <div className="">
            <Link to={RouteEnum.Route + '/' + row.original.RouteId}>
                {value}
            </Link>
        </div>
    )
}

const Province = ({ value }) => {
    const { routesProvince } = useSelector((state: RootStateTypes) => state.routeReducer)
    const provinceName = routesProvince && value && routesProvince.find(item => item.value == value)
    return (
        <div className="">
            {
                provinceName && provinceName.label
            }
        </div>
    )
}

const csvheaders = [
    { label: "Trekking Name", key: "RouteName" },
    { label: "Province", key: "ProvinceId" },
    { label: "Start Point", key: "StartPoint" },
    { label: "End Point", key: "EndPoint" },
    { label: "Status", key: "Status" },
]

const pageState = {
    pageindex: 0,
    pageSize: pageSizeValues[0].value,
    totalRows: 0,
}
function Routes(props) {
    const breadList = [{ title: 'Routes', to: RouteEnum.Route, isCurrent: true }]
    const { control, register } = useForm();
    const dispatch = useDispatch();
    const [page, setPage] = useState(pageState)
    const [data, setData] = useState([]);
    const [row, setRow] = useState<any>({});
    const allowed = useAuth()
    const user = useSelector((state: RootStateTypes) => state.auth.user)
    // useEffect(()=>{
    //     getRoutesDetails()
    // },[])
    const onRouteUpdate = () => {
        const routeData = {
            routeId: row.RouteId,
            status: false
        }
        dispatch(updateRouteStatus(routeData))
        onModalClose();
    }
    const onDisable = (value, row) => {
        const isActive = value === "Active"
        if (value === 'Disable') {
            setRow(row)
            setModalOpen(true)
        }
        if (value === 'Active') {
            // setModalOpen(true)
            const routeData = {
                routeId: row.RouteId,
                status: isActive
            }
            dispatch(updateRouteStatus(routeData))
        }
    }
    const columns = [
        {
            Header: 'Trekking Name',
            accessor: 'RouteName',
            minWidth: 240,
            isSort: true,
            Cell: CellLink
        },
        {
            Header: 'Province',
            accessor: 'ProvinceId',
            minWidth: 180,
            isSort: true,
            Cell: Province
        },
        {
            Header: 'Start Point',
            accessor: 'StartPoint',
            minWidth: 180
        },
        {
            Header: 'End Point',
            accessor: 'EndPoint',
            minWidth: 180
        },
        {
            Header: 'Status',
            accessor: 'Status',
            minWidth: 160,
            isSort: true,
            Cell: (props) => <Status {...props} onClick={onDisable} />
        },


    ];
    let finalCol: any = [...columns]
    if (allowed[modulesList.routesAdd]) {
        finalCol = [...finalCol, {
            Header: 'Actions',
            accessor: 'ids',
            width: 210,
            show: allowed[modulesList.routesAdd],
            Cell: (props) => <Actions {...props} module={modulesList.routesAdd} onDeleteFunc={onDelete} field="RouteId" onEditFunc={onEdit} />,
        },]
    }


    const onDelete = (id) => {
        dispatch(deleteRoute(id))
    }
    const onEdit = (item) => {
        props.history.push(RouteEnum.RouteAdd + '/' + item.RouteId)
    }
    const onPageChange = async (page) => await setPage(prev => ({ ...prev, pageindex: page }))

    const { routes } = useSelector((state: RootStateTypes) => state.routeReducer)
    useEffect(() => {
        if (routes && routes?.RoutesRecord) {
            setData(routes?.RoutesRecord)
            const totalRows = routes && routes.RoutesRecord ? routes.RoutesRecord[0]?.TotalRows : 0;
            routes?.RoutesRecord && setPage(prev => ({ ...prev }))
            routes?.RoutesRecord && setPage(prev => ({ ...prev, totalRows: totalRows }))
        }
    }, [routes]);
    const name: any = useWatch({ control, name: 'name' });
    useEffect(() => {
        getRoutesDetails()
    }, [page.pageSize, page.pageindex])
    const getRoutesDetails = () => {
        dispatch(getRoutes({ ...page }))
        // dispatch(getRoutes({name:user?.FullName,...page}))
    }
    const pageSize: any = useWatch({ control, name: 'pageSize' });
    useEffect(() => {
        if (pageSize && pageSize.value) {
            setPage(prev => ({ ...prev, pageSize: pageSize.value }));
        }
    }, [pageSize])
    // useEffect(() => {
    //     if (name) {
    //         dispatch(getRoutes({ ...page, name: name }))
    //     }
    // }, [name])
    const getSearch = () => {
        dispatch(getRoutes({ ...page, name: name ?? "" }))
    }
    const [modalOpen, setModalOpen] = useState(false);
    const onModalClose = () => { setModalOpen(false); setRow({}) }
    return (
        <>
            <Breadcrumbs isHome linkList={breadList} />
            <div className="route  common-container">

                <div className="routes">
                    <Container>
                        <div className="space-between search-alt">
                            <Form
                                formName="reactSelect"
                                control={control}
                                name="pageSize"
                                className="page-nums"
                                defaultValue={pageSizeValues[0]}
                                width="175px"
                                placeholder="Choose..."
                                options={pageSizeValues}
                            />
                            <div className="flex">

                                {/* <Form
                                    formName="reactSelect"
                                    name="action"
                                    control={control}
                                    width="150px"
                                    placeholder="Actions"
                                    options={[{ label: 'Delete', value: 1,},{ label: 'Print', value: 2,},{ label: 'CSV', value: 3,}]}
                                /> */}
                                <DownloadAllCsv headers={csvheaders} fileName="RoutesList" api={environment.api.getRoutes} dataField="RoutesRecord" />
                                <Form
                                    register={register}
                                    name="name"
                                    width="300px"
                                    placeholder="Search..."
                                    formName="searchInput"
                                    onKeyUp={(name,value,e)=>{
                                        if(e?.keyCode === 13){
                                            getSearch()
                                        }
                                    }}
                                />
                                {allowed[modulesList.routesAdd] && <Button
                                    style={{ height: 36 }}
                                    onClick={() => props.history.push(RouteEnum.RouteAdd)}
                                    buttonType="icon-button"
                                    // type="button-outline"
                                    icon={<Icons name="AddCircle" size={16} />}
                                    title="Add Route" bg="primary" />}
                            </div>
                        </div>
                        <div className="routes-table">
                            <Table columns={finalCol} data={data} />
                            {/* <Table isFixedWidth columns={finalCol} data={data} /> */}
                            <Pagination onPageChange={onPageChange} {...page} totalRows={page.totalRows} />
                        </div>
                    </Container>
                </div>
                <Modal
                    onModalClose={onModalClose}
                    className="disable-modal"
                    open={modalOpen}
                    width="450px"
                // title="Disable Route"
                >
                    <div className="disable">
                        <div className="disable__body">
                            {/* <div className="disable__content">Do you want to change disable Route. Once you disable, User will no longer see the Route details.</div> */}
                            <div className="disable__title">Disable Route</div>
                        </div>
                        <div className="disable__footer" style={{ gap: 12 }}>
                            <Button
                                size="small"
                                bg="cancel"
                                //  type="button-outline" 
                                title="Cancel" onClick={() => onModalClose()} />
                            <Button
                                buttonType="icon-button"
                                // type="button-outline"
                                // icon={<Icons name="Times" />}
                                size="small"
                                onClick={() => onRouteUpdate()}
                                bg="primary" title="Disable" />
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default Routes
