import types from 'constants/types';
import {UserInitialState} from 'ts/reducer';

const initialState:UserInitialState ={
    users:[],
    user:null,
    agencyUser:null
}

export default function UserReducer(state= initialState,action):UserInitialState{
    switch(action.type){
        case types.GET_USERS:
			return {
				...state,
				users: action.payload,
        };
        case types.GET_TAB_USER:
			return {
				...state,
				user: action.payload,
        };
        case types.GET_AGENCY_USER:
            return{
                ...state,
                agencyUser: action.payload,
            }
        case types.UPDATE_CURRENT_USER:
            return {
                ...state,
                user:{
                    ...state.user,
                    ...action.payload
                }
            }
        case types.ADD_REQUEST_NOTES:
            return{
                ...state,
                agencyUser: state.agencyUser && {
                    ...state.agencyUser,
                    AgencyUser:{
                        ...state.agencyUser.AgencyUser,
                        IsVerified: !action.payload?.IsRejectionNote,
                    },
                    UserNotes:[...state.agencyUser.UserNotes,{...action.payload,Type:action.types}]
                }
            }
        case types.APPROVE_DISABLE_USER:
            return{
                ...state,
                users: state.users && state.users.map(user=>{
                    if(user.UserId === action.UserId){
                        return {...user,Status:action.Status}
                    }
                    return user
                }),
            }
        case types.DELETE_USER:
			return {
				...state,
				users: state.users.filter(item=>item.id !==action.payload),
        };
        case types.ADD_USER:
			return {
				...state,
				users:[...state.users, action.payload],
        };
        case types.UPDATE_USER:
			return {
				...state,
				users: state.users && state.users.map(user=>{
                    if(user.Id === action.payload.Id){
                        return action.payload
                    }
                    return user
                }),
        };
        case types.APPROVE_DOC:
            return{
                ...state,
                agencyUser:{
                    ...state.agencyUser,
                    UserNotes:state.agencyUser.UserNotes ? [...state.agencyUser.UserNotes,action.payload] : [action.payload]
                }
        }
        case types.RESET_STATE:{
            return initialState
        }
        default:
        return state;
    }
}


