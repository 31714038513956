import React from 'react'
import {IconProps} from 'ts/interfaces/iconsInterface'
import classnames from 'classnames';
import isEmpty from 'isEmpty'
import getIconComponent from './getIconComponent'


function Icons(props:IconProps) {
    const { name,dataIcon, type, size, color, className, styles,justSVG } = props;
    const finalStyles = { fontSize: size, color: color, ...styles };
    const iconClasses = classnames({
        [getIconComponent({name})] : type === 'personal',
        'iconify' : type === 'iconify',
        [className] : !isEmpty(className)
    })
    if(type === 'iconify'){
        return <span style={finalStyles} className={iconClasses} data-icon={dataIcon} data-inline="false"></span>
    }
    return <i style={finalStyles} className={iconClasses}></i>
}

Icons.defaultProps = {
    type:'personal'
}

export default Icons
