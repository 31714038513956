import React, { useEffect, useRef } from "react";
import qr from "assets/images/qr.png";
import sw from "assets/images/sw.png";
import Breadcrumbs from "components/breadcrumb";
import RouteEnum from "constants/routesEnum";
import Button from "components/button";
import Icons from "components/icons/svgIcons";
import { getCard } from "store/actions/cardActions";
import { useDispatch, useSelector } from "react-redux";
import { RootStateTypes } from "ts/reducer";
import Pdf from "react-to-pdf";
import ReactToPrint from "react-to-print";

function Card(props) {
  const { card: cd }: any = useSelector(
    (state: RootStateTypes) => state.cardReducer
  );
  const ref = useRef(null);
  const linkList = [
    { to: RouteEnum.TimsCards, title: "TIMS Cards" },
    { to: RouteEnum.TimsCards + "/a", title: "TIMS Card", isCurrent: true },
  ];
  const component = (
    <div className="flex">
      <ReactToPrint
        trigger={() => (
          <Button
            bg="primary"
            // type="button-outline"
            size="small"
            title="Print"
            buttonType="icon-button"
            icon={<Icons name="Print" size={14} />}
          />
        )}
        content={() => ref.current}
      />

      <Pdf
        targetRef={ref}
        filename={cd?.CardNumber ? `${cd?.CardNumber}.pdf` : "tims-card.pdf"}
      >
        {({ toPdf }) => (
          <Button
            bg="primary"
            // type="button-outline"
            size="small"
            onClick={toPdf}
            title="Download"
            buttonType="icon-button"
            icon={<Icons name="Down" size={14} />}
          />
        )}
      </Pdf>
    </div>
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const id = props.match.params.id;
    if (id) {
      dispatch(getCard(id));
    }
  }, []);
  if (!cd) return null;
  return (
    <div className="">
      <Breadcrumbs linkList={linkList} component={component} />
      <div ref={ref} className="cards-preview">
        <div className="cards-preview__body">
          <div className="cards-preview__header">
            <span>User and TIMS Card information</span>
            <span>Nepal Tourism Board</span>
          </div>
          <div className="cards-preview__content">
            <div className="cards-preview__qrs">
              <div className="cards-preview__card">
                <div className="cards-preview__card-image">
                  <img src={sw} alt="Card Image" />
                  <div className="cards-preview__card-content">
                    <div className="cards-preview__card-by">
                      <div>TIMS</div>
                      <span>SAARC</span>
                    </div>
                    <div className="cards-preview__card-to">
                      <div className="cards-preview__card-to-name">
                        {cd?.FullName}
                      </div>
                      <div className="cards-preview__card-to-number">
                        {cd?.CardNumber}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cards-preview__qr">
                <div className="cards-preview__qr-image">
                  {cd?.QrCodes && (
                    <img
                      src={`data:image/jpeg;base64,${cd?.QrCodes}`}
                      alt="Tim Card Qr"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="cards-preview__details">
              <div className="cards-preview__detail">
                <div className="cards-preview__detail-header">
                  <div className="cards-preview__detail-title">
                    <span>Trek and TIMS Card Details</span>
                  </div>
                </div>
                <div className="cards-preview__detail-body">
                  <div className="cards-preview__detail-row">
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Trekking Area:
                      </div>
                      <div className="cards-preview__detail-value">
                        {cd?.TrekkingArea}
                      </div>
                    </div>
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Trek entry date:
                      </div>
                      <div className="cards-preview__detail-value">
                        {cd?.TrekEntryDate}
                      </div>
                    </div>
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Trek Exit date:{" "}
                      </div>
                      <div className="cards-preview__detail-value">
                        {cd?.TrekExitDate}
                      </div>
                    </div>
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        TIMS card number:
                      </div>
                      <div className="cards-preview__detail-value">
                        {cd?.CardNumber}
                      </div>
                    </div>
                  </div>
                  <div className="cards-preview__detail-row-2">
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Route of trekking
                      </div>
                      <div className="cards-preview__detail-value">
                        {cd?.TrekRoute}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cards-preview__detail">
                <div className="cards-preview__detail-header">
                  <div className="cards-preview__detail-title">
                    <span>User Details</span>
                  </div>
                </div>
                <div className="cards-preview__detail-body">
                  <div className="cards-preview__detail-row">
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Full Name:
                      </div>
                      <div className="cards-preview__detail-value">
                        {cd?.FullName}
                      </div>
                    </div>
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Regional
                      </div>
                      <div className="cards-preview__detail-value">
                        {cd?.Region}
                      </div>
                    </div>
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">Gender:</div>
                      <div className="cards-preview__detail-value">
                        {cd?.Gender}
                      </div>
                    </div>
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Passport number:
                      </div>
                      <div className="cards-preview__detail-value">
                        {cd?.PassportNumber}
                      </div>
                    </div>
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Visa number:
                      </div>
                      <div className="cards-preview__detail-value">
                        {cd?.VisaNumber}
                      </div>
                    </div>
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">Email: </div>
                      <div className="cards-preview__detail-value">
                        {cd?.Email}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
