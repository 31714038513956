import React from 'react';
import { Link } from 'react-router-dom'
import './styles.scss'
import classnames from 'classnames';
import { useSelector } from 'react-redux';

interface breadCrumbs{
    linkList: Array<listObj>,
    type?:string,
    isHome?:boolean,
    component?:any
}

interface listObj{
    isCurrent?:boolean,
    to:string,
    title:string,
    
}


function Breadcrumb(props: breadCrumbs) {
    const { linkList, isHome,component } = props;
    // const { user } = useSelector((state: any) => state.auth);
    return (
        <div className="breadcrumbs space-between">
            <ul className="breadcrumbs-list">
                {
                    isHome && (
                        <li className="breadcrumbs-list__item">
                            <Link to='/'>Home</Link>
                            <span className="iconify" data-icon="bi:chevron-right" data-inline="false"></span>
                        </li>
                    )
                }
                {
                    linkList && linkList.map((item: any,key) => {
                        const listClass = classnames({
                            "breadcrumbs-list__item": true,
                            "current": item.isCurrent
                        })
                        return (
                            <li key={'Crumb-'+key} className={listClass}>
                                {
                                    //@ts-ignore
                                    <Link disabled={item.isCurrent} to={item.to}>{item.title}</Link>
                                }
                                {item.isCurrent !==true && <span className="iconify" data-icon="bi:chevron-right" data-inline="false"></span>}
                            </li>
                        )
                    })
                }
            </ul>
            {component && component}
            {/* <div className="breadcrumbs-welcome">
                <p>
                    Hello <span>{user.user_name && user.user_name}</span>
                </p>
            </div> */}
        </div>
    )
}

Breadcrumb.defaultTypes = {
    type: 'admin'
}

export default Breadcrumb
