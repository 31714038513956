
export const REFRESH_TOKEN = "RefreshToken";
export const IDLE_TIME_OUT = "IdleTimeOut";
export const EXPIRES_MINUTES = "ExpiresMinutes";
export const EXPIRES_TIME = "ExpiresTIME";
export const AUTH_TOKEN = "TKENSSSF";

export const pageSizeValues = [
    { label: 'Showing 1-50', value: 50 },
    {label:'Showing 1-10',value:10},
    { label: 'Showing 1-20', value: 20 },
    { label: 'Showing 1-100', value: 100 },
    
   
]

export enum uploadDocumentStatus {
    sent="sent",
    approved="approved",
    rejected="rejected",
}

export enum userEnums {
    free=1,
    trekkingAgency=2,
    ntb=4,
    check=5,
    taan=6,
}

export const modulesList = {
    dashboad:'dashboad',
    users:'users',
    addUser:'addUser',
    trekkers: 'trekkers',
    selfScanTrekkers: 'selfScanTrekkers',
    routes:'routes',
    routesAdd:'routesAdd',
    billing:'billing',
    timsCard:'timsCard',
    requests:'requests',
    reports:'reports',
    settings:'settings',
    trekkingHistory:'trekkingHistory',
    inbox:'inbox',
    registerTrekkers:'registerTrekkers',
    all:'all',
    login:'login',
    scan:'scan',
    scannedTrekker:'scannedTrekker',
    locationModule:'locationModule',
    signup:'signup',
    uploadDocuments:'uploadDocuments',
    uploadDocumentsStatus:'uploadDocumentsStatus',
    forgotPassword:'forgotPassword',
    chalan:'chalan',
    passwordReset:'passwordReset',
    checkMail:'checkMail',
    createPassword:'createPassword',
    logout:'logout',
    notFound: 'notFound',
    chalanlog: 'chalanlog',
    userPassword: 'userPassword'
}


export enum cardsType {
    cards = "cards",
    cips = "cips",
} 

export enum userNameType {
    userEmail = "prempls23@gmail.com",
    
} 