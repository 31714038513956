import React, { useState } from 'react'
import classnames  from 'classnames';
import PropTypes from 'prop-types'
import isEmpty from 'isEmpty'
import Icons from 'components/icons/svgIcons';


const TextFieldGroup = ({
    error,
    info,
    theme,
    leftIcon,
    type,
    ...props
}) => {
    const [open,setOpen] = useState(false);
    const formClasses = classnames({
        "generic-group__form":true,
        ["generic-group__form-" + theme]:true
    })
    const isPass =  type === 'password'
  return (
      <div className={formClasses}>
            <div className="generic-group__form-group">
                <InputIcon icon={leftIcon} />
                <TextInput 
                    error={error}
                    type={isPass ? open ? "text" : "password" : type}
                    {...props}
                />    
                {
                   isPass && <InputIcon icon={<Icons styles={{cursor:'hover'}} onClick={()=>setOpen(prev=>!prev)} name={open ? "Eye" : "EyeClosed"} />} />
                }
            </div>
            {info && <small className="form-text text-muted">{info}</small>}
            {/* {error && (<div className="invalid-form">{error}</div>)} */}
      </div>

  );
};

const TextInput = (props) =>{
    const {
        type,
        error,
        placeholder,
        value,
        onBlur,
        validate = null,
        onKeyUp,
        isNoAlp,
        name,
        regex,
        isCustomeRegister,
        onChange,disabled,isDecimal,register, required,...rest } = props;
    const handleChange = (event) => {
        const { name, value } = event.target;
        onChange && onChange(name, value);
      };
    
      const handleKeyUp = (event) => {
        const { name, value } = event.target;
        onKeyUp && onKeyUp(event);
      };
    
      const handleBlur = (event) => {
        const { name, value } = event.target;
        onBlur && onBlur(name, value);
      };
    return(
        <div className="generic-group__form-group__input">
            <input 
                type={type} 
                className={classnames('generic-group__form-input',{ 'is-invalid': error })} 
                placeholder={placeholder} 
                value={value} 
                onBlur={handleBlur}
                name={name} 
                pattern={regex ? regex :isNoAlp ? "^[^a-z\s]{4,}$" :isDecimal ? "^\d*(\.\d{0,2})?$":null}
                onKeyUp={handleKeyUp}
                onChange={handleChange}
                ref={register && register ? 
                        isCustomeRegister? register : 
                        (register({required:required? (rest.label ? rest.label : placeholder? placeholder:  '' ) + ' field is required' : false,validate:validate}))
                    :null}
                disabled={disabled} />
        </div>

    )
}

const InputIcon = ({icon}) =>{
    if(!isEmpty(icon)){
        return(
            <div className="generic-group__form-group__icon">
                {icon}
            </div>
        )
    }
    return null
}

TextFieldGroup.propTypes={
    name:PropTypes.string.isRequired,
    placeholder:PropTypes.string,
    value:PropTypes.string.isRequired,
    info:PropTypes.string,
    error:PropTypes.string,
    type:PropTypes.string.isRequired,
    onChange:PropTypes.string.isRequired,
    disabled:PropTypes.string,
}

TextFieldGroup.defaultProps={
    type :'text'
}

export default TextFieldGroup;