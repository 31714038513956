import React, { useEffect, useState } from 'react'
import Container from 'components/layout/container';
import Table from 'components/table';
import Form from 'components/form';
import './styles.scss';
import { useForm, useWatch } from 'react-hook-form';
import { Status, Reason, TrekkingStatus } from 'components/table/commonTableComponents';
import Card from './card1'
import { Link } from 'react-router-dom';
import RouteEnum from 'constants/routesEnum';
import FilterDrop from 'components/common/filterDrop';
import FormGroup from 'components/form/formGroup';
import Button from 'components/button';
import DatePicker from 'react-datepicker';
import Icons from 'components/icons/svgIcons'
import { pageSizeValues } from 'constants/contants';
import Pagination from 'components/table/pagination';
import { getCard, getCards } from 'store/actions/cardActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import isEmpty from 'helpers/isEmpty';
import { CardStatusFilter, DateFilter, TrekkingRouteFilter } from 'components/common/filters';
import TrekkerSidebar from 'scenes/common/trekkers/trekkerSidebar';
import { getTrekking } from 'store/actions/trekkersAction';

const CardLink = ({value,row})=>{
    const [open, setOpen] = useState(false);
    const onClose = () => setOpen(false);
    const dispatch = useDispatch();
    const onClick =() =>{
        // dispatch(getTrekker(row.original.RegistrationId))
        // dispatch(getCard(row.original.RegistrationId));
        dispatch(getTrekking(null,row.original.TrekkerId))
        dispatch(getCard(row.original.RegistrationId,row.original.TrekkerId))
        setOpen(true)
    }
    return (
        <>
        <div
            style={{cursor:'pointer',color:'#0dad4c'}}
            onClick={()=>onClick()}
            className="">
                {/* <Link to={RouteEnum.TimsCards +'/' + row.original.RegistrationId}>
                    {value}
                </Link> */}
                {value || "View Details"}
            
        </div>
        <TrekkerSidebar trek={{CardStatus:row?.original?.TrekingStatus}} onClose={onClose} open={open} />
        </>

    )
}




const pageState = {
    pageindex:0,
    pageSize:pageSizeValues[0].value,
    totalRows:0,
}
const filterState = {
    startDate:null,
    endDate:null,
    status:"",
    country:"",
    trekkingArea:"",
    agency:"",
}
function TimsCards() {
    const dispatch = useDispatch();
    const { control, register } = useForm();
    const [filter, setFilter] = useState(filterState)
    const [page, setPage] = useState(pageState)
    const [data,setData] = useState([]);
    const onPageChange = async (page) =>await setPage(prev=>({...prev,pageindex:page}))
    const {cards} =useSelector((state:RootStateTypes)=>state.cardReducer)

    useEffect(()=>{
        getCardsAll()
    },[])
    useEffect(()=>{
        if(cards){
            setData(cards)
            const totalRows = !isEmpty(cards) ? cards[0]?.TotalRows : 0;
            cards && setPage(prev=>({...prev}))
            setPage(prev=>({...prev,totalRows:totalRows}))
        }
    },[cards]);
    useEffect(()=>{
        getCardsAll()
    },[page.pageSize,page.pageindex])
    useEffect(()=>{
        getCardsAll()
    },[filter])
    const getCardsAll = () =>{
        dispatch(getCards({routename:"",...filter,...page}))
    }
    const routename:any = useWatch({ control, name: 'routename' });
    const onFilter = async (items) => {
        await setFilter(prev=>({...filter,...items}))
    }  
    const pageSize:any = useWatch({ control, name: 'pageSize' });
    // useEffect(() => {
    //     dispatch(getCards({...filter,...page,routename}))
    // }, [routename])
    const getSearch = () =>{
        dispatch(getCards({...filter,...page,routename}))
    }
    useEffect(() => {
        if(pageSize) {
          setPage(prev=>({...prev,pageSize:pageSize.value}));
        }
      }, [pageSize])
      const columns = [
        {
            Header: 'Id',
            accessor: 'RowNum',
            width: 100
        },
        {
            Header: 'Card Number',
            accessor: 'CardNumber',
            width: 160,
            // Cell: Card
            Cell:CardLink
        },
        {
            Header: 'Trekking Route',
            accessor: 'TrekRoute',
            width: 200,
            Filter:({filter:filt,onchange}) =><TrekkingRouteFilter trekkingArea={filter?.trekkingArea} {...filter} onFilter={onFilter} />,
        },
        {
            Header: 'Regd. Date',
            accessor: 'RegisterdDate',
            width: 200,
            Filter:({ filter:filt, onChange }) =><DateFilter {...filter} onFilter={onFilter} />
        },
        {
            Header: 'Status',
            accessor: 'TrekingStatus',
            width: 160,
            Cell: TrekkingStatus,
            Filter:({ filter:filt, onChange }) =><CardStatusFilter {...filter} onFilter={onFilter} />
            // getProps: () => ({ someFunc: () => alert("clicked")})
        },
        // {
        //     Header: 'Reason',
        //     accessor: 'Reason',
        //     width: 160,
        //     Cell: Reason
        // },
    ];
    return (
        <div className="common-container">
            <Container>
                <div className="tims">
                    <div className="mb-md tims-search space-between search-alt">
                        <div className="">
                            <Form 
                                formName="reactSelect"
                                control={control}
                                name="pageSize"
                                value={pageSizeValues[0]}
                                defaultValue={pageSizeValues[0]}
                                className="page-nums"
                                width="175px"
                                placeholder="Choose..."
                                options={pageSizeValues}
                            />
                        </div>
                        <Form 
                            register={register}
                            name="routename"
                            width="300px"
                            placeholder="Search..."
                            formName="searchInput"
                            onKeyUp={(name,value,e)=>{
                                if(e?.keyCode === 13){
                                    getSearch()
                                }
                            }}
                        />
                    </div>
                    <div className="tims-table">
                        <Table isFixedWidth columns={columns} data={data} />
                        <Pagination onPageChange={onPageChange} {...page} totalRows={page.totalRows} />
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default TimsCards
