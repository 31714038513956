import types from 'constants/types';
import {SelectInitialState} from 'ts/reducer';


const initialState:SelectInitialState ={
    genders:[],
    region:[],
    userType:[],
    trekkingArea:[],
    agentUserType:[],
    wholeUserType:[],
    routes:[],
    countries:[],
    registrationTypes:[],
    checkpoints:[],
    paymentMethods:[],
    countriesByRegion:[],
    agencyUsers:[],
    scannedTrekekrs:[],
    trekkersPersionality: [],
    guides: []
}

export default function SelectReducer(state= initialState,action):SelectInitialState{
    switch(action.type){
      case types.GET_COUNTRIES:
        return{
          ...state,
          countries: action.payload,
        }
      case types.GET_COUNTRIES_REGION:
        return{
          ...state,
          countriesByRegion: action.payload,
        }
      case types.GET_PAYMENT_METHODS:
        return{
          ...state,
          paymentMethods:action.payload
        }
      case types.GET_REGION:
        return{
          ...state,
          region:action.payload
        }
      case types.GET_REGISTRATION_TYPE:
        return{
          ...state,
          registrationTypes:action.payload
        }
      case types.GET_FILTER_SCANNED_TREKKERS:
        return{
          ...state,
          scannedTrekekrs:action.payload
        }
      case types.GET_FILTER_AGENCY_USEER:
        return{
          ...state,
          agencyUsers:action.payload
        }
      case types.GET_GENDER:
        return {
            ...state,
            genders: action.payload,
        };
      case types.GET_USER_TYPES:
        return {
          ...state,
          userType: action.payload,
          wholeUserType:state.wholeUserType ? [...state.wholeUserType,...action.payload] : [...action.payload]
        };
      case types.GET_USER_AGENT_TYPES:
        return{
          ...state,
          agentUserType:action.payload,
          wholeUserType:state.wholeUserType ? [...state.wholeUserType,...action.payload] : [...action.payload]
        }
      case types.GET_TREKKING_AREA:
        return {
          ...state,
          trekkingArea: action.payload,
        };
      case types.GET_TREKKERS_PERSIONALITY:
        return{
          ...state,
          trekkersPersionality:action.payload
        }
      case types.GET_ROUTES_DROP:
        return{
          ...state,
          routes:action.payload
        }
        case types.GET_CHECKPOINT:
          return{
            ...state,
            checkpoints:action.payload
            }
        case types.GET_GUIDES:
            return {
                ...state,
                guides: action.payload
            };
      //   case types.RESET_STATE:{
      //     return initialState
      // }
      default:
          return state;
      }
}


