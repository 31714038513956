import React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
// import { Arrays, ArraysKey } from "../../helpers/getTextLabel";
import { reactSelectInterface } from "ts/interfaces/formInterface";
import isEmpty from 'isEmpty';
import { useForm, Controller } from 'react-hook-form';

//#region  NoOptionsMessage
// function NoOptionsMessage(props) {
//   return (
//     <Typography
//       color="textSecondary"
//       className={props.selectProps.classes.noOptionsMessage}
//       {...props.innerProps}
//     >
//       {props.children}
//     </Typography>
//   );
// }

// NoOptionsMessage.propTypes = {
//   /**
//    * The children to be rendered.
//    */
//   children: PropTypes.node,
//   /**
//    * Props to be passed on to the wrapper.
//    */
//   innerProps: PropTypes.object.isRequired,
//   selectProps: PropTypes.object.isRequired,
// };
// //#endregion  NoOptionsMessage

// //#region inputComponent
// function inputComponent({ inputRef, ...props }) {
//   return <div ref={inputRef} {...props} />;
// }

// inputComponent.propTypes = {
//   inputRef: PropTypes.oneOfType([
//     PropTypes.func,
//     PropTypes.shape({
//       current: PropTypes.any.isRequired,
//     }),
//   ]),
// };
// //#endregion inputComponent

// //#region Control
// function Control(props) {
//   const {
//     children,
//     innerProps,
//     innerRef,
//     selectProps: { classes, TextFieldProps },
//   } = props;
//   return (
//     <TextField
//       fullWidth
//       InputProps={{
//         inputComponent,
//         inputProps: {
//           className: classes && classes.input,
//           ref: innerRef,
//           children,
//           ...innerProps,
//         },
//       }}
//       {...TextFieldProps}
//     />
//   );
// }
// Control.propTypes = {
//   /**
//    * Children to render.
//    */
//   children: PropTypes.node,
//   /**
//    * The mouse down event and the innerRef to pass down to the controller element.
//    */
//   innerProps: PropTypes.shape({
//     onMouseDown: PropTypes.func.isRequired,
//   }).isRequired,
//   innerRef: PropTypes.oneOfType([
//     PropTypes.oneOf([null]),
//     PropTypes.func,
//     PropTypes.shape({
//       current: PropTypes.any.isRequired,
//     }),
//   ]).isRequired,
//   selectProps: PropTypes.object.isRequired,
// };
// //#endregion Control

// //#region  Option
// function Option(props) {
//   return (
//     <MenuItem
//       ref={props.innerRef}
//       selected={props.isFocused}
//       component="div"
//       style={{
//         fontWeight: props.isSelected ? 500 : 400,
//         backgroundColor: props.isSelected ? "#cfebff" : null,
//         borderBottom: props.isSelected ? "1px solid #c4c4c4" : null,
//         color: props.isSelected ? "#444" : "#333",
//         padding: props.isMulti ? 0 : 10,
//       }}
//       {...props.innerProps}
//     >
//       {props.isMulti ? (
//         <span>
//           <Checkbox checked={props.isSelected} color="primary" />
//           {props.children}
//         </span>
//       ) : (
//         props.children
//       )}
//     </MenuItem>
//   );
// }

// Option.propTypes = {
//   /**
//    * The children to be rendered.
//    */
//   children: PropTypes.node,
//   /**
//    * props passed to the wrapping element for the group.
//    */
//   innerProps: PropTypes.shape({
//     id: PropTypes.string.isRequired,
//     key: PropTypes.string.isRequired,
//     onClick: PropTypes.func.isRequired,
//     onMouseMove: PropTypes.func.isRequired,
//     onMouseOver: PropTypes.func.isRequired,
//     tabIndex: PropTypes.number.isRequired,
//   }).isRequired,
//   /**
//    * Inner ref to DOM Node
//    */
//   innerRef: PropTypes.oneOfType([
//     PropTypes.oneOf([null]),
//     PropTypes.func,
//     PropTypes.shape({
//       current: PropTypes.any.isRequired,
//     }),
//   ]).isRequired,
//   /**
//    * Whether the option is focused.
//    */
//   isFocused: PropTypes.bool.isRequired,
//   /**
//    * Whether the option is selected.
//    */
//   isSelected: PropTypes.bool.isRequired,
// };

// //#endregion Option

// //#region Placeholder
// function Placeholder(props) {
//   const { selectProps, innerProps = {}, children } = props;
//   return (
//     <Typography
//       color="textSecondary"
//       className={selectProps.classes && selectProps.classes.placeholder}
//       {...innerProps}
//     >
//       {children}
//     </Typography>
//   );
// }

// Placeholder.propTypes = {
//   /**
//    * The children to be rendered.
//    */
//   children: PropTypes.node,
//   /**
//    * props passed to the wrapping element for the group.
//    */
//   innerProps: PropTypes.object,
//   selectProps: PropTypes.object.isRequired,
// };
// //#endregion Placeholder

// //#region  SingleValue
// function SingleValue(props) {
//   return (
//     <Typography
//       className={props.selectProps.classes.singleValue}
//       {...props.innerProps}
//     >
//       {props.children}
//     </Typography>
//   );
// }

// SingleValue.propTypes = {
//   /**
//    * The children to be rendered.
//    */
//   children: PropTypes.node,
//   /**
//    * Props passed to the wrapping element for the group.
//    */
//   // innerProps: PropTypes.any.isRequired,
//   innerProps: PropTypes.any,
//   selectProps: PropTypes.object.isRequired,
// };

// //#endregion SingleValue

// //#region  ValueContainer
// function ValueContainer(props) {
//   const { value, isMulti } = props.selectProps;
//   const valueClasses =
//     props.selectProps.classes && props.selectProps.classes.valueContainer;
//   return value !== null && value.length > 1 && isMulti ? (
//     <div className={valueClasses}>
//       <Typography
//         className={props.selectProps.classes.singleValue}
//         {...props.innerProps}
//       >
//         {value.length} Selected
//       </Typography>
//       {props.children}
//     </div>
//   ) : (
//     <div className={valueClasses}>{props.children}</div>
//   );
// }

// ValueContainer.propTypes = {
//   /**
//    * The children to be rendered.
//    */
//   children: PropTypes.node,
//   selectProps: PropTypes.object.isRequired,
// };
// //#endregion ValueContainer

// MultiValue.propTypes = {
//   children: PropTypes.node,
//   isFocused: PropTypes.bool.isRequired,
//   removeProps: PropTypes.shape({
//     onClick: PropTypes.func.isRequired,
//     onMouseDown: PropTypes.func.isRequired,
//     onTouchEnd: PropTypes.func.isRequired,
//   }).isRequired,
//   selectProps: PropTypes.object.isRequired,
// };

// //#region  MultiValue
// function MultiValue(props) {
//   const { value } = props.selectProps;
//   return value.length === 1 ? (
//     <Typography
//       className={props.selectProps.classes.singleValue}
//       {...props.innerProps}
//     >
//       {value[0].label}
//     </Typography>
//   ) : (
//     <Typography
//       className={props.selectProps.classes.singleValue}
//       {...props.innerProps}
//     ></Typography>
//   );
// }

// //#endregion MultiValue

// //#region Menu
// function Menu(props) {
//   return (
//     <Paper
//       square
//       className={
//         props.selectProps && props.selectProps.isAbove
//           ? props.selectProps.classes.paperAbove
//           : props.selectProps.classes.paper
//       }
//       {...props.innerProps}
//     >
//       {props.children}
//     </Paper>
//   );
// }

// Menu.propTypes = {
//   /**
//    * The children to be rendered.
//    */
//   children: PropTypes.element.isRequired,
//   /**
//    * Props to be passed to the menu wrapper.
//    */
//   innerProps: PropTypes.object.isRequired,
//   selectProps: PropTypes.object.isRequired,
// };
// //#endregion Menu

// //#region AutoSelect

// const components: any = {
//   // Control,
//   Menu,
//   MultiValue,
//   // NoOptionsMessage,
//   // Option,
//   // Placeholder,
//   // SingleValue,
//   // ValueContainer,
// };

const renderLoading = (props) => {
    const { loading, loadingType, width } = props;
    if (loadingType === "skeleton") {
        return (
            <div className="form-group">
                <h2>Loading...</h2>
            </div>
        );
    } else if (loadingType === "circle") {
        return <h2>Loading...</h2>;
    } else {
        return <h2>Loading...</h2>;
    }
};


const renderOptions = (props: reactSelectInterface) => {
    const { options } = props;
    let setOption: any = [];

    if (options && Array.isArray(options)) {
        options.map((item) => {
            if (item.Text && item.Value) {
                item["label"] = item.Text;
                item["value"] = item.Value;
            }
            setOption.push(item);
        });
    }

    return setOption;
};

// const controlStyles = {
//     borderRadius: '1px solid black',
//     padding: '5px',
//     background: 'black',
//     color: 'white',
//   };
  
const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
          <span className="iconify" data-icon="entypo:chevron-thin-down" data-inline="false"></span>
        {/* <span className="iconify" data-icon="bi:chevron-down" data-inline="false"></span> */}
      </components.DropdownIndicator>
    );
  };

const ClearIndicator = props => {
  const {
    children = 'clear all',
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <div style={{ padding: '0px 5px' }}>{children}</div>
    </div>
  );
};


const ClearIndicatorStyles = (base, state) => ({
    ...base,
    cursor: 'pointer',
    color: state.isFocused ? 'blue' : 'black',
});

const DropIndicatorStyles = (base, state) => ({
    ...base,
    cursor: 'pointer',
    alignItems:'center !important',
    padding: '0 8px',
});

const controlStyles = (base, state) => {
    return ({
    ...base,
    border: '1px solid #cacccf',
    borderRadius:'4px',
    minHeight: '44px',
    height: '44px',
    // "& IndicatorsContainer": {
    //     font: "inherit",
    // },
})};

const PlaceholderStyle = (base,state) => ({
    ...base,
    color:'#7a7d81'
})

const SingleValueStyle = (base,state) => ({
    ...base,
    color:'#333',
    fontWeight:500,
    fontSize:16,
    lineHeight:'20px'
})


  

export const ReactSelect = (props: reactSelectInterface) => {
    const {
        multiple,
        placeholder,
        name,
        value,
        // validators,
        label,
        width,
        loading,
        defaultValue,
        options,
        loadingType,
        disabled,
        isAbove,
        isSearchable,
        required
    } = props;
    

    const selectStyles = {
        input: (base) => ({
            ...base,
            color: "#f2f2f2", //theme.palette.text.primary,
            "& input": {
                font: "inherit",
            },
        }),
    };
    return (
        <div 
            style={{width}}
            className="generic-group__reselect">
            <Controller
                control={props.control && props.control}
                defaultValue={value || defaultValue}
                name={name}
                options={options}
                isSearchable={isSearchable}
                isDisabled={disabled}
                isMultiple={multiple}
                placeholder={placeholder}
                as={Select}
                rules={{ required:required?  (props.label ? props.label :placeholder? placeholder:  '' ) + ' field is required' : false  }}
                clearValue={true}
                components={{ClearIndicator,DropdownIndicator}}
                styles={{ singleValue:SingleValueStyle,placeholder:PlaceholderStyle, indicatorsContainer:DropIndicatorStyles,clearIndicator: ClearIndicatorStyles,control:controlStyles }}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                    ...theme.colors,
                      text: '#fff',
                      primary25: '#00ba8814',
                      primary: '#bd242b',
                    },
                  })}
            />
        </div>

    );
}

ReactSelect.defaultProps = {
    name: "",
    value: null,
    multiple: false,
    placeholder: "",
    isSearchable:false,
    width: "100%",
};

export default ReactSelect;