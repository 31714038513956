import React, { useContext } from 'react'
import classnames from 'classnames';
import RegisterContext from './context';
import {steps} from './helpers';

function RegisterStepper(props) {
    const value = useContext(RegisterContext);
    const {activeStep} = value;
    console.log({value})
    return (
        <div className="register-stepper">
            <div className="register-stepper__container">
                <ul className={classnames(`register-stepper__list register-stepper__${activeStep}`)}>
                    {
                        steps.map((item,key)=>{
                            return(
                                <li key={item.step + key} className={classnames("register-stepper__item",{"step-active":activeStep===item.step})}>
                                    <div className="register-stepper__number">
                                        <span>{item.step}</span>
                                    </div>
                                    <div className="register-stepper__title">
                                        <span>{item.title}</span>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default RegisterStepper
