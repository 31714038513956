
import {IconName} from 'ts/interfaces/iconsInterface';
// import {IconName} from 'ts/interfaces/iconsInterface';


const getIconComponent = ({name}:IconName) =>{
    switch (name) {
        case "expandCollapse":
            return 'icon-expandCollapse';
        case "Billing":
            return 'icon-Billing';
        case "trekkingHistory":
            return 'icon-trekkingHistory';
        case "trekker":
            return 'icon-trekker';
        case "timsCard":
            return 'icon-timsCard';
        case "save":
            return 'icon-save';
        case "arrowRight":
          return 'icon-arrowRight';
        case "arrowDown":
            return 'icon-arrowDown';
        case "request":
          return 'icon-request';
        case "reports":
            return 'icon-reports';
        case "download":
          return 'icon-download';
        case "filter":
        return 'icon-filter';
        case "filterFilled":
            return 'icon-filterFilled';
        case "creditCard":
            return 'icon-creditCard';
        case "chevronDown":
            return 'icon-chevronDown';
        case "addCircle":
            return 'icon-addCircle';
        case "close":
            return 'icon-close';
        case "comments":
            return 'icon-comments';
        case "calendar":
            return 'icon-calendar';
        case "open":
            return 'icon-open';
        case 'uploadDocs':
            return 'icon-uploadDocs';
        case 'search':
            return 'icon-search';
        case 'pdf':
            return 'icon-pdf';
        case 'csv':
            return 'icon-csv';
        case 'mail':
            return 'icon-mail';
        case 'chevronRight':
            return 'icon-chevronRight';
        case 'checkbox':
            return 'icon-checkbox';
        case 'billing':
            return 'icon-billing';
        case 'notification':
            return 'icon-notification';
        case 'actions':
            return 'icon-actions';
        case 'remarks':
            return 'icon-remarks';
        case 'mobilePay':
            return 'icon-mobilePay'
        //#endregion
        default:
          return null;
    }
}

export default getIconComponent;