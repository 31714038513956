import React, { useState } from 'react'
import CommonSidebar from 'components/common/commonSidebar';
import Trekker from './trekker'
import { useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';

function TrekkerSidebar(props) {
    const {trek,isReturn,isScanned=false} = props
    const { trekker } = useSelector((state: RootStateTypes) => state.selfScanTrekkersReducer);
    return (
        <CommonSidebar className="trekker-sidebar" title="Trekker" open={props.open} onModalClose={props.onClose}>
            <Trekker isScanned={isScanned} rowData={props.rowData}  isReturn={isReturn} status={trek?.CardStatus} callback={props.callback}  trekker={trekker} />
        </CommonSidebar>
    )
}

export default TrekkerSidebar
