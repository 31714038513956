import types from 'constants/types';
import { Action, Reducer } from 'redux';
import {LoadingInitialState} from 'ts/reducer';

const initialState:LoadingInitialState = {
    loading: false,
    userLoading:false,
    loadingType: '',
};

export interface ActionType {
    payload:boolean,
    loadingType:string
    type:string
}

export const reducer:Reducer<LoadingInitialState> = (state:LoadingInitialState | undefined,incomingAction:Action):LoadingInitialState =>{
    if (state === undefined) {
        return initialState;
    }
    const action = incomingAction as ActionType;
    switch (action.type) {
        case types.ADD_LOADING:
            return {
                ...state,
                loading: action.payload,
                loadingType:action.loadingType
            };
        case types.ADD_USER_LOADING:
            return{
                ...state,
                userLoading:action.payload
        }
        case types.RESET_STATE:{
            return initialState
        }
        default:
            return state;
    }
}
export default reducer;