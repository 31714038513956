import https from 'services/http';
import Action from 'services/actionUtility';
import environment from 'environment';
import types from 'constants/types';
import RouteEnum from 'constants/routesEnum';
import getQueryUrl from 'helpers/getQueryUrl';
import {uploadDocumentStatus} from 'constants/contants'
import HttpErrorResponseModel from 'src/services/httpErrorResponseModel';
import TrekkerModel from 'models/TrekkerModel';

export const getTrekkers = (page) => async dispatch =>{
    const finalUrl = getQueryUrl(environment.api.getTrekkers,page)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_TREKKERS,
        message:'',
        type:'get',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const getDeletedTrekkers = (page) => async dispatch =>{
    const finalUrl = getQueryUrl(environment.api.getDeletedTrekkers,page)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_DELETED_TREKKERS,
        message:'',
        type:'get',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const getTrekkerVoucher = (id) => async dispatch =>{
    await dispatch({type:types.GET_TREKKER_VOUCHER,payload:null})
    const finalUrl = getQueryUrl(environment.api.getTrekkerVoucher,{id})
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_TREKKER_VOUCHER,
        message:'',
        // type:'get',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}
// export const getTrekkersByUser = (page) => async dispatch =>{
//     const finalUrl = getQueryUrl(environment.api.getTrekkersByUser,page)
//     const res = https.get(finalUrl)
//     const actionConfig = {
// 		dispatch,
// 		actionType:types.GET_TREKKERS,
//         message:'',
//         type:'get',
// 		effect:res,
//         isLoading:true
// 	}
// 	const response = await Action.createThunkEffect(actionConfig); 
//     if(response && response.Status){
//         // history.push(RouteEnum.Login)
//     }
// }



export const getRegistrationTrekker = (guid,id=null) => async dispatch =>{
    const res = https.get(`${environment.api.getTrekker}?id=${guid}&uid=${id}`)
    const actionConfig = {
		dispatch,
		actionType:types.GET_REGISTRATION_TREKKER,
        message:'',
		effect:res,
        isLoading:true,
        model:TrekkerModel
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const getTrekker = (guid,id=null) => async dispatch =>{
    const res = https.get(`${environment.api.getTrekker}?id=${guid}&uid=${id}`)
    const actionConfig = {
		dispatch,
		actionType:types.GET_TREKKER,
        message:'',
		effect:res,
        isLoading:true,
        model:TrekkerModel
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const getTrekking = (guid,id=null) => async dispatch =>{
    const res = https.get(`${environment.api.getTrekking}?id=${guid}&uid=${id}`)
    const actionConfig = {
		dispatch,
		actionType:types.GET_TREKKER,
        message:'',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const clearTrekker = () => async dispatch =>{
    dispatch({type:types.GET_TREKKER,payload:null})
}


export const getScannedTrekkers = (page) => async dispatch =>{
    const finalUrl = getQueryUrl(environment.api.getTrekkers,page)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_SCANNED_TREKKERS,
        message:'',
        type:'get',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const getScannedTrekkersByUser = (page) => async dispatch =>{
    const finalUrl = getQueryUrl(environment.api.getScannedTrekkersByUser,page)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_SCANNED_TREKKERS_BY_USER,
        message:'',
        type:'get',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

/*export const verifyScanTrekkers = (regId,trekId) => async dispatch =>{*/
export const verifyScanTrekkers = (trekId) => async dispatch => {
    // const finalUrl = getQueryUrl(environment.api.verifyScanTrekker,regId)
    /*const res = https.put(environment.api.verifyScanTrekker +'?registrationId='+regId+'&trekkerid='+trekId)*/
    /*const res = https.put(environment.api.verifyScanTrekker + '&trekkerid=' + trekId)*/
    const res = https.put(environment.api.verifyScanTrekker + '/' + trekId)
    const actionConfig = {
		dispatch,
		actionType:types.VERIFY_SCAN_TREKKER,
        message:'Scanned Successfully',
        type:'put',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const getSelfScannedTrekkers = (page) => async dispatch => {
    const finalUrl = getQueryUrl(environment.api.getSelfScannedTrekkers, page)
    const res = https.get(finalUrl)
    const actionConfig = {
        dispatch,
        actionType: types.GET_SELF_SCANNED_TREKKERS,
        message: '',
        type: 'get',
        effect: res,
        isLoading: true
    }
    const response = await Action.createThunkEffect(actionConfig);
    if (response && response.Status) {
        // history.push(RouteEnum.Login)
    }
}



export const uploadDocuments = (data,history) => async dispatch => {
    const res = https.post(environment.api.uploadAgencyDocuments,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_ROUTES,
        type:'post',
        message:'Documents uploaded',
        isLoading:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        history.push(RouteEnum.UploadDocumentsStatus + "/" + uploadDocumentStatus.sent)
    }
}



export const getPaymentEsewa = (param: any) => async (dispatch: Function) => {
    const res = https.get(environment.api.getEsewaPayment +'?applicationId='+param)
    const actionConfig = {
		dispatch,
		actionType:types.GET_PAYMENT_ESEWA,
        // message:'Documents uploaded',
        isLoading:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        
    }
};

export const getPaymentConnectIps = (param: any) => async (dispatch: Function) => {
    const res = https.get(environment.api.getCIPSPayment +'?applicationId='+param)
    const actionConfig = {
		dispatch,
		actionType:types.GET_PAYMENT_IPS,
        // message:'Documents uploaded',
        isLoading:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        
    }
};

export const getPaymentKhalti = (param: any) => async (dispatch: Function) => {
    const res = https.get(environment.api.getKhaltiPayment +'?applicationId='+param)
    const actionConfig = {
		dispatch,
		actionType:types.GET_PAYMENT_KHALTI,
        // message:'Documents uploaded',
        isLoading:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        
    }
};


export const SaveCIPS = (param: any) => async (dispatch: Function) => {
    const res = await https.post(environment.api.saveCIPSPayment + `?TXNID=${param}`);
    const isError = res instanceof HttpErrorResponseModel;
    return !isError;
  };
  
  export const SaveCIPSFailure = (param: any) => async (
    dispatch: Function
  ) => {
    const res = await https.post(environment.api.saveCIPSPaymentFailure + `?TXNID=${param}`);
    const isError = res instanceof HttpErrorResponseModel;
    return !isError;
  };


  
export const savePaymentKhalti = (param: any) => async (dispatch: Function) => {
    await https.post(environment.api.getKhaltiPayment, param);

// dispatch({ type: GET_PAYMENT_KHALTI, payload: res.Data });
};
//   export const SavePaymentEsewa = (param: any, history) => async (
//     dispatch: Function
//   ) => {
//     const res = await post(api.applyVacancy.payment_esewa + param, dispatch, "");
//     if (!res.Status) {
//       history.push(paymentStatus.esewaFailure);
//     }
  
//     // dispatch({ type: GET_PAYMENT_KHALTI, payload: res.Data });
//   };
  
//   export const SavePaymentFone = (param: any, history) => async (
//     dispatch: Function
//   ) => {
//     const res = await post(
//       api.applyVacancy.payment_fone + param,
//       dispatch,
//       "",
//       true,
//       true
//     );
  
//     if (!res.Status) {
//       history.push(paymentStatus.foneFailure);
//     }
  
//     // dispatch({ type: GET_PAYMENT_KHALTI, payload: res.Data });
//   };
  


export const clearPayment = (type) => async (dispatch: Function) => {
    if (type === "fone") {
      await dispatch({ type: types.GET_PAYMENT_FONE, payload: "" });
    }
    if (type === "esewa") {
      await dispatch({ type: types.GET_PAYMENT_ESEWA, payload: "" });
    }
    if (type === "ips") {
      await dispatch({ type: types.GET_PAYMENT_IPS, payload: "" });
    }
    if (type === "khalti") {
      await dispatch({ type: types.GET_PAYMENT_KHALTI, payload: null });
    }
    if (type === "card") {
        await dispatch({ type: types.GET_PAYMENT_CARD, payload: null });
    }
};


export const getPaymentCard = (data: any) => async (dispatch: Function) => {
    const finalUrl = getQueryUrl(environment.api.getCardPayment,data)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_PAYMENT_CARD,
        isJustData:true,
        // message:'Documents uploaded',
        isLoading:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response){
        window.open(response,"_self")
    }
};