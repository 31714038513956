import React from 'react'
import classnames from 'classnames';
import TabItemContent from './tabItemContent';
import TabItemHeader from './tabItemHeader';

function TabItem(props:any) {
    const {color,noBackground,isAlternative,component}=props;
    const tabsClass = classnames({
        tabs: true,
        "tabs-common": color === "common",
        "tabs-tertiary": color === "tertiary",
        "tabs-danger": color === "danger",
        "tabs-success": color === "success",
        "tabs-warning": color === "warning",
        "tabs-alternate":isAlternative,
        "tabs-no-bg":noBackground
    });
    return (
        <div className={tabsClass}>
            <ul className="tabs-list">
                {/* TabItemHeader renders Label part of tab */}
                <TabItemHeader {...props} />
                {
                    component && component
                }
            </ul>
            <div className="tabs-content">
                {/* TabItemHeader renders tab content of active tab */}
                <TabItemContent {...props} />
            </div>
        </div>
    )
}

export default TabItem
