import React from 'react'
import Common from '../login/common';
import Form from 'components/form';
import RoutesEnum from 'constants/routesEnum';
import FormGroup from 'components/form/formGroup';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { forgotPassword } from 'store/actions/auth';
import forgotImage from 'assets/images/forgot_password.png'
import Button from 'components/button';

function ForgotPassword(props) {
    const dispatch = useDispatch();
    const onSubmit = (data) => {
        dispatch(forgotPassword(data,props.history))
    }
    return (
        <Common isFooter={false}>
            <FormGroup onSubmit={onSubmit}>
                <div className="forgot">
                    {/* <div className="forgot-image">
                        <img src={forgotImage} alt="Forgot Password Image"/>
                    </div> */}
                    <div className="forgot-title login-form__container-title">
                        <span>Forgot your Password?</span>
                    </div>
                    <div className="forgot-sub login-form__container-sub">
                        <span>Enter your email and we'll send you a link to get back into your account.</span>
                    </div>
                    <div className="forgot-form">
                        <Form
                            placeholder="Email"
                            name="Email"
                            type="email"
                            required={true}
                        />
                        <div className="button-group button-group-bold">
                            <Button title="Reset" htmlButtonType="submit" bg="primary" />
                        </div>
                    </div>
                    <div className="forgot-footer">
                        {/* <span>Return to</span> */}
                        <Link to={RoutesEnum.Login} >Login</Link>
                    </div>
                </div>
            </FormGroup>
        </Common>
    )
}

export default ForgotPassword
