import React, { useEffect } from 'react';
import Overview from './overview';
import Gallery from './gallery';
import Map from './map';
import Breadcrumbs from 'components/breadcrumb';
import {Tabs, Tab} from 'components/tabs'
import './styles.scss';
import RouteEnum from 'constants/routesEnum';
import Icons from 'components/icons/svgIcons';
import { useDispatch, useSelector } from 'react-redux';
import { getRoute } from 'store/actions/routesAction';
import { RootStateTypes } from 'ts/reducer';
import { userEnums } from 'constants/contants';
import Button from 'components/button';

const routeTabs = [
    {id:1,name:'overview',label:'Overview'},
    {id:2,name:'gallery',label:'Gallery'},
    {id:3,name:'map',label:'Map'},
]

function Route(props) {
    
    // let comp = <Button onClick={()=>setOpen(true)} bg="primary" title="Add New User" buttonType="icon-button" icon={<Icons name="UserAdd" size={16} />} />
    const dispatch = useDispatch()
    const {id} = props.match.params;
    const {route} = useSelector((state:RootStateTypes)=>state.routeReducer)
    const {user} = useSelector((state:RootStateTypes)=>state.auth)
    const routeDetails = route?.RouteDto || {}
    const routes = route?.RouteDetail || []
    const images = route?.Attachment || {}
    const mapImages = route?.MapAttachment || {}
    const breadList = [{ title: 'Route', to: RouteEnum.Route},{ title:routeDetails?.RouteName, to: RouteEnum.Route + '/annapurna', isCurrent: true }];
    
    useEffect(()=>{
        dispatch(getRoute(id))
    },[]);
    const comp = user.UserType == userEnums.ntb ?   
                    <Button title="Update Route" onClick={()=>props.history.push(RouteEnum.RouteAdd +'/' + id)} bg="primary"   />:
                    <Button title="Register this Trek" onClick={()=>props.history.push({
                        pathname: RouteEnum.NewRegister,
                        state: { routeId: id }
                      })}  bg="primary"   />
    return (
        <>
            <Breadcrumbs isHome linkList={breadList} component={comp} />
            <div className="route common-container">
                <Tabs activeTab="overview">
                        {
                            routeTabs.map(item=>(
                                <Tab key={item.id} name={item.name} label={item.label} >
                                    {
                                        item.id === 1 ? <Overview routes={routes} routeDetails={routeDetails} /> :
                                        item.id === 2 ? <Gallery images={images} /> :
                                        item.id === 3 ? <Map images={mapImages} /> : null
                                    }
                                </Tab>
                            )
                        )
                    }
                </Tabs>
            </div>
        </>

    )
}

export default Route
