export default {
    GET_ERRORS:"GET_ERRORS",
    CLEAR_ERRORS : 'CLEAR_ERRORS',
    CLEAR_REGIS_TREKKERS : 'CLEAR_REGIS_TREKKERS',
    SET_CURRENT_USER : 'SET_CURRENT_USER',
    SET_CURRENT_PROFILE : 'SET_CURRENT_PROFILE',
    LOGIN_LOADING : 'LOGIN_LOADING',
    ADD_LOADING : 'ADD_LOADING',
    ADD_USER_LOADING : 'ADD_USER_LOADING',
    REMOVE_LOADING : 'REMOVE_LOADING',

    ADD_MESSAGE : 'ADD_MESSAGE',
    CLEAR_MESSAGE : 'CLEAR_MESSAGE',
    REMOVE_MESSAGE : 'REMOVE_MESSAGE',
    ADD_MESSAGES : 'ADD_MESSAGES',
    ADD_OTHER_MESSAGES : 'ADD_OTHER_MESSAGES',
    RESET_STATE : 'RESET_STATE',

    REGISTER_USER : 'REGISTER_USER',
    CHANGE__PASSWORD : 'CHANGE__PASSWORD',
    GET_USER : 'GET_USER',
    FORGOT_PASSWORD : 'FORGOT_PASSWORD',

    REGISTER_NEW_TREKKERS_LOADING : 'REGISTER_NEW_TREKKERS_LOADING',
    ADD_NEW_TREKKERS_LOADING : 'ADD_NEW_TREKKERS_LOADING',
    REGISTER_NEW_TREKKERS : 'REGISTER_NEW_TREKKERS',
    UPDATE_NEW_TREKKERS : 'UPDATE_NEW_TREKKERS',
    UPDATE_NEW_TREKKERS_DETAILS : 'UPDATE_NEW_TREKKERS_DETAILS',
    GET_TREKKING_HISTORY : 'GET_TREKKING_HISTORY',
    SET_CURRENT_TOKEN: 'SET_CURRENT_TOKEN',
    UPDATE_USER : "UPDATE_USER",
    UPDATE_CURRENT_USER: "UPDATE_CURRENT_USER",
    GET_TAB_USER:'GET_TAB_USER',
    GET_AGENCY_USER:'GET_AGENCY_USER',

    GET_SINGLE_USER_CARD : "GET_SINGLE_USER_CARD",
    GET_CARDS : "GET_CARDS",
    GET_RETURN_CARDS : "GET_RETURN_CARDS",
    RETURN_CARD : "RETURN_CARD",
    APPROVE_REJECT_CARD : "APPROVE_REJECT_CARD",
    GENERATE_CARD : "GENERATE_CARD",
    DELETE_TREKKER: "DELETE_TREKKER",
    GET_DASHBOARD:'GET_DASHBOARD',
    GET_DASHBOARD_TOTAL:'GET_DASHBOARD_TOTAL',
    GET_DASHBOARD_RECENT_TREKKERS:'GET_DASHBOARD_RECENT_TREKKERS',
    GET_DASHBOARD_TOP_TREKKERS:'GET_DASHBOARD_TOP_TREKKERS',
    GET_DASHBOARD_TOP_COUNTRY:'GET_DASHBOARD_TOP_COUNTRY',
    GET_NOTIFICATIONS:'GET_NOTIFICATIONS',
    UPDATE_NOTIFICATIONS:'UPDATE_NOTIFICATIONS',
    GET_BILLING: 'GET_BILLING',
    GET_CHALANLOG: 'GET_CHALANLOG',

    //Routes
    GET_ROUTES : 'GET_ROUTES',
    ADD_ROUTES : 'ADD_ROUTES',
    GET_ROUTE : 'GET_ROUTE',
    GET_TIMS_CHALAN : 'GET_TIMS_CHALAN',
    GET_USER_CHALAN : 'GET_USER_CHALAN',
    CLEAR_CHALAN : 'CLEAR_CHALAN',
    DELETE_ROUTE : 'DELETE_ROUTE',
    GET_ROUTES_PROVINCE : 'GET_ROUTES_PROVINCE',
    UPDATE_ROUTE :'UPDATE_ROUTE',
    UPDATE_ROUTE_STATUS :'UPDATE_ROUTE_STATUS',

    //Requests
    GET_REQUESTS:'GET_REQUESTS',
    GET_VOUCHERS:'GET_VOUCHERS',
    UPDATE_VOUCHER_STATUS:'UPDATE_VOUCHER_STATUS',
    ADD_USER_DETAILS:'ADD_USER_DETAILS',
    ADD_REQUEST_NOTES:'ADD_REQUEST_NOTES',
    APPROVE_DOC:'APPROVE_DOC',

    //Select types
    GET_GENDER : "GET_GENDER",
    GET_PAYMENT_METHODS : "GET_PAYMENT_METHODS",
    GET_USER_TYPES : "GET_USER_TYPES",
    GET_USER_AGENT_TYPES : "GET_USER_AGENT_TYPES",
    GET_TREKKING_AREA : "GET_TREKKING_AREA",
    GET_ROUTES_DROP : "GET_ROUTES_DROP",
    GET_CHECKPOINT : "GET_CHECKPOINT",
    GET_REGION : "GET_REGION",
    GET_TREKKERS_PERSIONALITY:'GET_TREKKERS_PERSIONALITY',
    GET_REGISTRATION_TYPE : "GET_REGISTRATION_TYPE",
    GET_FILTER_AGENCY_USEER : "GET_FILTER_AGENCY_USEER",
    GET_FILTER_SCANNED_TREKKERS : "GET_FILTER_SCANNED_TREKKERS",
    GET_COUNTRIES : "GET_COUNTRIES",
    GET_COUNTRIES_REGION : "GET_COUNTRIES_REGION",
    GET_REGISTRATION_TYPES : "GET_REGISTRATION_TYPES",


    //Trekkers
    GET_TREKKERS :'GET_TREKKERS',
    GET_DELETED_TREKKERS :'GET_DELETED_TREKKERS',
    GET_TREKKER_VOUCHER :'GET_TREKKER_VOUCHER',
    GET_SCANNED_TREKKERS: 'GET_SCANNED_TREKKERS',
    GET_SELF_SCANNED_TREKKERS: 'GET_SELF_SCANNED_TREKKERS',
    GET_SCANNED_TREKKERS_BY_USER :'GET_SCANNED_TREKKERS_BY_USER',
    GET_SCANNED_TREKKER :'GET_SCANNED_TREKKER',
    GET_TREKKER :'GET_TREKKER',
    GET_REGISTRATION_TREKKER :'GET_REGISTRATION_TREKKER',
    VERIFY_SCAN_TREKKER :'VERIFY_SCAN_TREKKER',
    GET_PAYMENT_ESEWA :'GET_PAYMENT_ESEWA',
    GET_PAYMENT_FONE :'GET_PAYMENT_FONE',
    GET_PAYMENT_IPS :'GET_PAYMENT_IPS',
    GET_PAYMENT_KHALTI :'GET_PAYMENT_KHALTI',
    GET_PAYMENT_CARD :'GET_PAYMENT_CARD',

    
    //Users
    GET_USERS : 'GET_USERS',
    ADD_USER : 'ADD_USER',
    UPDATE_ADMIN_USER : 'UPDATE_ADMIN_USER',
    DELETE_USER : 'DELETE_USER',
    EDIT_USER: 'EDIT_USER',
    APPROVE_DISABLE_USER: 'APPROVE_DISABLE_USER',

    //Guides
    GET_GUIDES: 'GET_GUIDES',
    GET_GUIDE: 'GET_GUIDE',
}