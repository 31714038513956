import React,{useEffect, useRef,useState}  from 'react'
import classnames from 'classnames';
import isEmpty from 'isEmpty';
import Icons from '../icons'
import './styles.scss'
import useEventListener from 'hooks/useEventListener';
import { createPortal } from 'react-dom';
const { Provider, Consumer:DropConsumer } = React.createContext<any>({ useOpen: null});
export {DropConsumer};
interface DropdownProps{
    children?:any,
    onClick?:Function,
    customDropIcon?:any,
    dropClass?:string,
    title?:any,
    isIcon?:boolean,
    isFixed?:boolean,
    isFilter?:boolean,
    callback?:any,
    dropComponent?:any,
    width?:number,
    isPointer?:boolean,
    lists?:Array<any>
}

function DropDown(props:DropdownProps) {
    const { 
        children,
        onClick,
        customDropIcon,
        isFilter,
        dropClass,
        dropComponent,
        lists,
        isIcon,
        isPointer,
        width,
        isFixed,
        callback,
        title,
        ...rest 
    } 
    = props;
    const settingsIcon = customDropIcon ? customDropIcon : <Icons type="iconify" dataIcon="bi:three-dots-vertical" />
    const ref = useRef(null);
    const contentRef = useRef(null);
    const [open,setOpen] = useState(false);
    const [call,setCall] = useState(true);
    const onDrop = (e:any) => {
        // e.stopPropagation();
        setOpen(!open);
        if(!open && callback){
            callback()
        }
    }
    const dropClasses = classnames({
        "dropdown":true,
        "dropdown__active":open,
        "dropdown__fixed":isFixed,
        [dropClass]:!isEmpty(dropClass)
    });
    useEventListener('click',(event:any)=>{
        // debugger
        const isCustomChecks = event.target && event.target.parentElement && !event.target.parentElement.classList.contains("custom-check")
        
        if(isFixed){
            const content = contentRef && contentRef.current && !contentRef.current.contains(event.target);
            
            if(content && ref && ref.current && isCustomChecks && !ref.current.contains(event.target)){
                setOpen(false);
            }
        }else{
            if(ref && ref.current && isCustomChecks && !ref.current.contains(event.target)){
                setOpen(false);
            }
        }
        // if(ref && ref.current && event.target.parentElement && !event.target.parentElement.classList.contains("custom-check")){
        //     setOpen(false);
        // }
    })
    // useEffect(()=>{
    //     if(open && callback && call){
    //         callback()
    //         setCall(false)
    //     }else{
    //         setCall(true)
    //     }
    // },[open,callback])
    // let rect = el.getBoundingClientRect();
    const dropRect = isFixed ? ref.current && ref.current.getBoundingClientRect() : {};
    const widthStyle = width ? {width} : {};
    const finalStyles:any = {
        position: 'fixed',
        top:dropRect?.top + dropRect?.width / 2,
        left:dropRect?.left + dropRect?.height / 2,
        // bottom:dropRect?.bottom,
        // right:dropRect?.right,
        ...widthStyle
    }
    const onlyWidth = {...widthStyle}
    return (
        <Provider value={{setOpen}}>
            <div
                ref={ref}
                className={dropClasses}
            >
                <div 
                    onClick={(e)=>{isFilter ? onDrop(e):e.stopPropagation();onDrop(e)}}
                    className="dropdown__main">
                    {isIcon && settingsIcon}
                    {title && <div className="dropdown__main-icon">{title}</div>}         
                </div>
                {
                    isFixed ? createPortal(
                        <div 
                            ref={contentRef}
                            style={finalStyles} className={classnames("dropdown__content",{"dropdown__content-active":open,"dropdown__content-pointer":isPointer})}>
                            <DropContent setOpen={setOpen} {...props} />
                        </div>,
                        document.body
                    ) : (
                        <div style={onlyWidth} className="dropdown__content">
                            <DropContent setOpen={setOpen} {...props} />
                        </div>
                    )
                }

            </div>
        </Provider>

    )
}

DropDown.defaultProps={
    isIcon:true
}

const DropContent = (props)=>{
    if(!isEmpty(props.lists)){

        return(
            <div className="dropdown__content-body">
                <ul className="dropdown__content-list">
                    {
                        props.lists.map((item,key)=>{
                            const onClick = () =>{
                                props.setOpen(false)
                                item.onClick && item.onClick();
                                
                            }
                            if(item.isCustom) return item.label
                            return(
                                <li 
                                    onClick={(e)=>{e.stopPropagation();onClick()}}
                                    className="dropdown__content-item" key={"Dropdown-"+key}>
                                    <span>
                                        {item.label}
                                    </span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }
    if(!isEmpty(props.children)){
        return(
            <div className="dropdown__content-body">
                {props.children}
            </div>
        )
    }
    return null
}

export default DropDown
