export enum RouteEnum{
  //Initial
  Signout = "/sign-out",
  NotFound = "/not-found",
  Switchuser = "/switch-user",
  ResetPassword = "/reset-password",
  Login = "/login",
  VerifyEmail = "/verify-email",
  Logout = "/logout",
  Register = "/register",

  //Forgot Password
    forgotPassword = "/forgot-password",
    UserPassword = "/change-user-password",
    chalan = "/chalan",
    chalanlog = "/chalanlog",
  registerSuccess = "/register-success",
  checkMail = "/check-mail",
  accountSettings = "/account-settings",
  createNewPassword = "/create-new-password",
  successPasswordChange = "/success-password-change",

  Dashboard ="/dashboard",
  Billing ="/billing",
  TrekkingHistory ="/trekking-history",
  TimsCards ="/tims-cards",
  Trekkers = "/trekkers",
  DeletedTrekkers = "/deleted-trekkers",
  SelfScanTrekkers = "/self-scan-trekkers",
  Route ="/route",
  RouteAdd ="/routes/add",
  Inbox ="/inbox",
  NewRegister="/new-register",
  Users="/users",
  TrekkingAgencyUser="/users/trekking-agency",
  FitAgencyUser="/users/fit",
  CheckPostUser="/users/checkpost",
  Request="/requests",
  Reports="/reports",
  Scan="/scan",
  ScannedTrekker="/scanned-trekker",
  LocationPath="/location",
  UploadDocuments="/upload-documents",
  UploadDocumentsStatus="/upload-documents-status",
};

export default RouteEnum;
