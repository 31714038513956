import React from 'react';
import {
    IRegisterTrekkerContext,
    TStep,
    StepperEnums,
    steps,
    
} from './helpers'



const RegisterTrekkerContext = React.createContext<IRegisterTrekkerContext>({activeStep:StepperEnums.userInfo,steps,userRegisterationModel:null});


export default RegisterTrekkerContext;