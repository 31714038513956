import React, { useEffect, useState } from 'react'
import Common from './common';
import Someimg from 'assets/images/celebrate.png'
import Button from 'components/button';
import RouteEnum from 'constants/routesEnum';
import Icons from 'components/icons/svgIcons';
import Modal from 'components/modal';
import { Link } from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {resendEmail} from 'store/actions/auth';
import QS from 'query-string';

function RegisterSuccess(props) {
    const [open, setOpen] = useState(true);
    const onModalClose = () => setOpen(false);
    const onModalOpen = () => setOpen(true);
    const dispatch = useDispatch();
    const {location:{search}} = props;
    console.log({props});
    useEffect(() => {
        onModalOpen();
    }, [])
    let email:any = QS.parse(search);
    const onResendEmail = () => {
        if(email){
            dispatch(resendEmail(email))
        }
    }

    return (
        <Common 
            title="Account Verification"
            sub="Please check your email for account verification."
            isFooter={false}>
            <div className="forgot">
                {/* <div className="forgot-image">
                    <img src={Someimg} alt="Forgot Password Image" />
                </div> */}
                {/* <div className="forgot-title login-form__container-title">
                    <span>Account Successfully Created</span>
                </div>
                <div className="forgot-sub login-form__container-sub">
                    <span>Your account is successfully created. Please Login to perform action.</span>
                </div> */}
                {/* <div className="success-btn">
                    <Button
                        onClick={() => props.history.push(RouteEnum.Login)}
                        title="Login Now" bg="primary" />
                </div> */}
                <div className="forgot-btns">
                    <Button
                        onClick={() => window.open("https://mail.google.com/")}
                        title="Open Gmail"
                        bg="primary" 
                    />
                    <Button
                            title="Open Webmail"
                            onClick={() => window.open("https://mail.google.com/")}
                            bg="primary" 
                    />
                </div>
            </div>
            <div className="mt-sm center text-red" style={{ textAlign: 'center', fontSize:14 }}>Note: Please check your spam. </div>
            {email && <div className="footer-link">
                <Button
                    title="Resend Email"
                    onClick={() => onResendEmail()}
                    bg="secondary" 
                    size="small"
                />
            </div>}
            <div className="footer-link">
                <Link to={RouteEnum.Login}>Login</Link>
            </div>
            {/* <Modal
                open={open}
                width={"400px"}
                onModalClose={onModalClose}
                className="email-modal"
            >
                <div className="email-modal__body">
                    <div className="email-modal__content">
                        <div className="email-modal__content-title">Account Verification</div>
                        <div className="email-modal__content-subtitle">Please check your email for account verification.</div>
                    </div>
                    <div className="email-modal__btns flex">
                        <Button
                            type="button-outline"
                            buttonType="icon-button"
                            title="Open Gmail"
                            // bg="primary"
                            onClick={() => window.open("https://mail.google.com/")}
                            icon={<Icons name="Gmail" />}
                        />
                        <Button
                            type="button-outline"
                            buttonType="icon-button"
                            title="Open Webmail"
                            onClick={() => window.open("https://mail.google.com/")}
                            // bg="grey"
                            icon={<Icons name="Webmail" />}
                        />
                    </div>
                </div>
            </Modal> */}
        </Common>
    )
}

export default RegisterSuccess
