import React, { useState } from 'react'
import Form from 'components/form';
import Icons from 'components/icons';
import SvgIcons from 'components/icons/svgIcons';
import avatar from 'assets/images/avt.png';
import Dropdown, { DropConsumer } from '../dropdown'
import Account from 'scenes/account';
import RouteEnum from 'constants/routesEnum';
import { useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import Notification from './notification';
import { useHistory } from 'react-router-dom';
import Sidebar from './sideblock';
import Avatar from 'react-avatar';
import { userEnums } from 'constants/contants';
import logo from 'assets/images/logo.png';
import logoWhite from 'assets/images/logo-white.png';
import classnames from 'classnames';

function Header(props) {
    const { title, isDocs } = props;
    const [open, setOpen] = useState(false)
    const onClose = () => setOpen(false);
    const history = useHistory();
    const onLogout = () => history.push(RouteEnum.Logout)
    const onRoutesSettings = () => history.push(RouteEnum.accountSettings)
    const { user } = useSelector((state: RootStateTypes) => state.auth);
    // const { notifications } = useSelector((state: RootStateTypes) => state.dashboardReducer);
    const pathman = history.location.pathname
    const isNotSidebar = pathman === RouteEnum.UploadDocuments || pathman.includes(RouteEnum.UploadDocumentsStatus)
    const isAdmin = userEnums.ntb === user?.UserType
    if (isNotSidebar && !isDocs) {
        return null;
    }
    const onOpen = () => {
        setOpen(true)
    }
    return (
        <header className={classnames("header",{"header-doc":isDocs})}>
            {!isDocs && <Sidebar open={open} onClose={onClose} />}
            <div className="header-body">
                {
                    isDocs && (
                        <div 
                                id="tims-logo" className="header-body__logo">
                            {/* <span>TIMS</span> */}
                            <img src={isDocs ? logoWhite : logo} alt="TIMS LOGO"/>
                        </div>
                    )
                }
                {
                    !isDocs && (
                        <>
                            <div onClick={() => onOpen()} className="header-body__menu">
                                <SvgIcons name="Burger" color="#fff" />
                            </div>
                            <div className="header-item">
                                <div className="header-title">
                                    <span>{title}</span>
                                </div>
                            </div>
                            <div className="header-item header-search-container">
                                <div className="header-search">
                                    <Form
                                        name="search"
                                        width="100%"
                                        formName="searchInput"
                                        placeholder="Search users, trekkers, TIMS cards details and so on..."
                                    />
                                </div>
                            </div>
                        </>
                    )
                }

                <div className="header-item">
                    {
                         !isDocs && !isAdmin && (
                            <div className="header-notification">
                                <Notification />
                            </div>
                         )
                    }
                    <div className="header-profile">
                       
                        <div className="header-profile__username"><span>{user?.UserName}</span><span>({user?.UserID})</span></div>
                        <Dropdown
                            width={166}
                            isFixed
                            isPointer
                            isFilter
                            title={
                                (
                                    <div className="header-profile__body">
                                        {/* <div className="header-profile__image">
                                            <img src={user?.UserImage ? user?.UserImage :avatar} alt="User Profile Image"/>
                                        </div> */}
                                        {/* <div className="header-profile__name">
                                            <span>{user?.FullName}</span>
                                        </div> */}
                                        <div className="header-profile__avatar">
                                            {
                                                //@ts-ignore
                                                <Avatar
                                                    name={user?.FullName}
                                                    src={user?.UserImage}
                                                    size={"40px"}
                                                    color={"#F0F1F6"}
                                                    round
                                                />
                                            }
                                            
                                        </div>

                                        {/* <div className="header-profile__dropdown">
                                            <Icons dataIcon="entypo:chevron-thin-down" type="iconify" />
                                        </div> */}
                                    </div>
                                )
                            }
                            isIcon={false}
                        >
                            <DropConsumer>
                                {value => {
                                    const onClick = (Func) => {
                                        Func();
                                        value.setOpen()
                                    }
                                    return (
                                        <div className="header-dropdown">
                                            {/* <div className="header-dropdown__header">
                                                    <div className="header-dropdown__heading">TIMS</div>
                                                </div> */}
                                            <div
                                                className="header-dropdown__body">
                                                {/* <Account /> */}
                                                {
                                                    !isDocs && (
                                                        <div onClick={() => onClick(onRoutesSettings)} className="header-dropdown__item">
                                                            <SvgIcons name="Setting" size={17} />
                                                            <span>Settings</span>
                                                        </div>
                                                    )
                                                }
                                                <div onClick={() => onClick(onLogout)} className="header-dropdown__item header-dropdown__item-logout">
                                                    <SvgIcons name="Logout" size={17} />
                                                    <span>Logout</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }}
                            </DropConsumer>

                        </Dropdown>

                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
