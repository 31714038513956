import React, { Component } from "react";
import { connect } from "react-redux";
import Loaders from "../loaders";

interface LaodingInterface {
  loading: boolean;
  userLoading:boolean,
  loadingType:string
}

export class Loading extends Component<LaodingInterface> {
  render() {
    const { loading,loadingType,userLoading } = this.props;
    if(userLoading){
      return <Loaders bodyBackground="rgba(250,250,250,1)" type={loadingType} loading={userLoading} />;
    }
    return <Loaders bodyBackground="transparent" type={loadingType} loading={loading} {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  loading: state.loadingReducer.loading,
  userLoading:state.loadingReducer.userLoading,
  loadingType: state.loadingReducer.loadingType,
});
//@ts-ignore
export default connect(mapStateToProps, {})(Loading);
