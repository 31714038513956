import https from 'services/http';
import Action from 'services/actionUtility';
import environment from 'environment';
import getQueryUrl from 'helpers/getQueryUrl';

export const verifyTrekker = (data) => async dispatch => {
    const final = getQueryUrl(environment.api.verifyTrekker,data)
    const res = https.get(final)
    const actionConfig = {
		dispatch,
		actionType:'types.GET_SINGLE_USER_CARD',
        message:'',
        // type:'get',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(!response){
        dispatch({
            type: 'ADD_MESSAGE',
            payload: { type: "danger", message: "Please enter the correct card and passport number" }
          });
        return false;
    }
    return response === true;
}


export const autoCheckin = (data) => async dispatch=>{
    const res = https.post(environment.api.autoCheckin,data)
    const actionConfig = {
		dispatch,
		actionType:'types.GET_SINGLE_USER_CARD',
        message:'Auto Checked In',
        // type:'get',
        type:'post',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        return true
        // history.push(RouteEnum.Login)
    }
    return false
}

