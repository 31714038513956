import React, { useEffect, useState } from 'react'
import Breadcrumbs from 'components/breadcrumb';
import RouteEnum from 'constants/routesEnum';
import { Tabs, Tab } from 'components/tabs'
import Button from 'components/button';
import Background from './background'
import Documents from './documents'
import Notes from './notes'
import './styles.scss';
import Modal from 'components/modal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getTrekkingAgencyUser } from 'store/actions/userActions';
import { RootStateTypes } from 'ts/reducer';
import { approveDocument } from 'store/actions/requestActions';
import Form from 'components/form';

const requestTabs = [
    { id: 1, name: 'Background', label: 'Background' },
    { id: 2, name: 'Documents', label: 'Documents' },
    { id: 3, name: 'Notes', label: 'Remarks' },
]


function Request(props) {
    const breadList = [{ title: 'Requests', to: RouteEnum.Request }, { title: props.location?.state?.detail?.OrganizationName ?? "", to: RouteEnum.Request, isCurrent: true }];
    const [open, setOpen] = useState(false);
    const onModalClose = () => setOpen(false)
    const { agencyUser: user } = useSelector((state: RootStateTypes) => state.userReducer)
    // const { detail } = props.location.state;
    const { control, register } = useForm();
    const note = useWatch({ name: 'Discription', control })
    const onApprove = () => {
        const data = {
            "userId": user?.AgencyUser?.UserId,
            "registrationId": "",
            "note": "",
            "createdDate": new Date(),
            "isRejectionNote": false
        }
        dispatch(approveDocument(data))
    }
    const onReject = () => {
        const data = {
            "userId": user?.AgencyUser?.UserId,
            "registrationId": "",
            "note": note,
            "createdDate": new Date(),
            "isRejectionNote": true
        }
        dispatch(approveDocument(data))
        setOpen(false)
    }
   
    const buttonComp = (
        <div className="request-buttons">
            {  
                user?.Documents.length > 0 ? <>
                    <Button bg="danger" onClick={() => setOpen(true)} size="small"
                        // type="button-outline" 
                        title="Reject" />
                    <Button bg="primary" onClick={() => onApprove()} size="small" title="Approve" />
                </> : null
            }
        </div>
    )

    const dispatch = useDispatch()
    const { id } = props.match.params;
    useEffect(() => {
        const params = {
            userId: id
        }
        dispatch(getTrekkingAgencyUser(params))
    }, [])
    const isVerified = user?.AgencyUser?.IsVerified === null;
    return (
        <div className="request">
            <Breadcrumbs linkList={breadList} />
            <div className="request-body">
                <Tabs isAlternative component={isVerified ? buttonComp : null} activeTab="Background">
                {/* <Tabs isAlternative component={buttonComp} activeTab="Background"> */}
                    {
                        requestTabs.map(item => (
                            <Tab key={item.id} name={item.name} label={item.label} >
                                {
                                    item.id === 1 ? <Background data={user?.AgencyUser} /> :
                                        item.id === 2 ? <Documents data={user?.Documents} data2={user?.DocumentsDateWise} /> :
                                            item.id === 3 ? <Notes data={user?.UserNotes} /> : null
                                }
                            </Tab>
                        )
                        )
                    }
                </Tabs>
            </div>
            <Modal
                width="600px"
                open={open}
                onModalClose={onModalClose}
                // title="Application Rejection"
                // className="request-rejects" 
                className="disable-modal"
            >
                <div className="disable">
                    <div className="disable__body">
                        <div className="disable__title">Application Rejection</div>
                        {/* <div className="disable__content">Do you want to disable user . They will no longer able to login.</div> */}
                        <div className="disable__input form-alt">
                            <Form
                                formName="textarea"
                                // checked
                                register={register}
                                label="Remarks"
                                required
                                name="Discription"

                            />
                        </div>
                    </div>
                    <div className="disable__footer" style={{ gap: 12 }}>
                        <Button
                            bg="cancel"
                            // type="button-outline" 
                            size="small"
                            title="Cancel" onClick={() => setOpen(false)} />
                        <Button
                            onClick={() => onReject()}
                            size="small"
                            htmlButtonType="submit" bg="primary" title="Reject" />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Request
