import React,{useEffect} from 'react';
import Routes from './routes';
// import store from './store/store';
import {Switch, useHistory} from "react-router-dom";
import Loading from 'components/common/loading';
import ToastMessages from 'components/common/toastMessages';
import { ConnectedRouter } from 'connected-react-router';
import './App.scss';
import { useDispatch, useSelector } from 'react-redux';
// import {
//   REFRESH_TOKEN,
//   EXPIRES_TIME,
//   EXPIRES_MINUTES,
//   AUTH_TOKEN,
// } from "constants/contants";
// import { setAuthToken } from 'helpers/axiosInstance';
// import RouteEnum from 'constants/routesEnum';
// import { getUser, logoutUser, refreshToken, setCurrentToken, setCurrentUser } from 'store/actions/auth';
import { RootStateTypes } from 'ts/reducer';
import { clearErrors } from 'store/actions/auth';

function App(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(()=>{
    // const token = localStorage.getItem(AUTH_TOKEN)
    // // Check for Token
    // if (token) {
    //     setAuthToken(token);
    //     const decoded = parseInt(localStorage.getItem(EXPIRES_TIME));
    //     store.dispatch(setCurrentUser(decoded));
    //     store.dispatch(setCurrentToken(token,decoded));
        
    //     const currentTime = Date.now() / 1000;
    //     dispatch(getUser());
    //     if (decoded < currentTime) {
    //         dispatch(logoutUser());
    //         props.history.push(RouteEnum.Login)
    //     }
    // }
  },[])
  const {errors}=useSelector((state:RootStateTypes)=>state.validateReducer);
  useEffect(() => {
      if(errors && errors.status && errors.status === 500){
          dispatch({
              type: 'ADD_MESSAGE',
              payload: { type: "danger", message: "Internal Server Error, Please contact support or try again later" }
            })
      }
      if(errors && Array.isArray(errors)){
        setTimeout(()=>{
          // dispatch(clearErrors())
        },5000)
      }
  }, [errors]);

    return (
      //@ts-ignore
      <ConnectedRouter history={props.history}>
            {
                //@ts-ignore
                <ToastMessages />
            }
            {
                //@ts-ignore
                <Loading />
            }
        <Switch>
          <Routes />
        </Switch>
      </ConnectedRouter>
  );
}

export default App;
