import React,{useState} from 'react'
import Modal from 'components/modal'
import Button from 'components/button'
import Icons from 'components/icons/svgIcons';

interface ConfProps{
    deleteFunction:Function,
    title?:any,
    content?:any,
    onButton?:boolean,
    children?:any,
    btnTitle?:any
}

function Confirm(props:ConfProps) {
    const {deleteFunction,title,onButton,children,content,btnTitle="Delete"} = props;
    const [open, setOpen] = useState(false)
    let childProps;
    if(children){
        childProps = {
            onClick:(e)=>{
                e.stopPropagation()
                setOpen(true)
            },
            ...children.props
        }
    }
    return (
        <>
        {
            children ? React.cloneElement(
                            children, 
                            childProps
                        ) :
            onButton ? (<span onClick={()=>setOpen(true)}>
                Delete
            </span>)
            :
            (
                <Button 
                    bg="danger"
                    // type="button-outline"
                    buttonType="icon-button"
                    icon={<Icons name="Times" />}
                    onClick={()=>setOpen(true)}
                    title="Delete"
                />
            )
        }

            <Modal 
                open={open}
                title={content ? content : `Are you sure you want to delete this ${title}?`}
                type="confirm"
                onModalClose={()=>setOpen(false)}
                func={()=>deleteFunction()}
                btnTitle={btnTitle}
            />
        </>
    )
}

export default Confirm;