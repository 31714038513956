import React, { 
    useRef,
    useState,  
    useImperativeHandle,
    forwardRef, } from 'react'
import Common from '../login/common';
import Icons from 'components/icons/svgIcons';
import Button from 'components/button';
import "./styles.scss";
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { uploadDocuments } from 'store/actions/trekkersAction';

function Documents({history}) {
    const dispatch = useDispatch()
    const {user} = useSelector((state:RootStateTypes)=>state.auth)
    const agencyReg = useRef(null);
    const taxRef = useRef(null);
    const registrationRef = useRef(null);
    const dotmRef = useRef(null);
    const taanRef = useRef(null);
    
    
    const onSubmit = async () =>{
        const isNull = !Boolean(await agencyReg?.current?.getImage()) || !Boolean(await taxRef?.current?.getImage()) || !Boolean(await registrationRef?.current?.getImage()) || !Boolean(await dotmRef?.current?.getImage()) || !Boolean(await taanRef?.current?.getImage());
        if(!isNull){
            const fd = new FormData();
            fd.append("UserId",user.UserID)
            fd.append("Files",await agencyReg.current.getImage())
            fd.append("Files",await taxRef.current.getImage())
            fd.append("Files",await registrationRef.current.getImage())
            fd.append("Files",await dotmRef.current.getImage())
            fd.append("Files",await taanRef.current.getImage());
            dispatch(uploadDocuments(fd,history))
        }
    }
    return (
        <div className="documents-upload__container">
            
            <Common
                title="Upload your Documents"
                isHeader
                sub="Upload the mentioned document in respective upload fields. We appreciate your patience and, it will take up to 24 hours to verify your documents. "
            >
                <div className="documents-upload">
                    <div className="documents-upload__title">.jpg, .png and pdf  | 1kb minimum  | maximum 3mb</div>
                    <div className="documents-upload__list">
                        <div className="documents-upload__list-row">
                            <UploadItem ref={agencyReg} label="Company Registration Document"  />
                            <UploadItem ref={taxRef} label="VAT/PAN"  />
                        </div>
                        <div className="documents-upload__list-row">
                            <UploadItem ref={registrationRef} label="DOT" />
                            <UploadItem ref={dotmRef} label="Tax clearance" />
                        </div>
                        <div className="documents-upload__list-row">
                            <UploadItem ref={taanRef} label="TAAN Renewal" />
                        </div>
                        <div className="documents-upload__list-row">
                            <div className="documents-upload__btn">
                                <Button  onClick={()=>onSubmit()} title="Submit" 
                                // type="button-outline" 
                                size="large" bg="primary" />
                            </div>
                        </div>
                    </div>
                </div>
            </Common>
        </div>

    )
}

export const UploadItem = forwardRef(({label}: any, ref)=> {
    const [file,setFile] = useState(null);
    const [error,setError] = useState([]);
    const allowedMinSize = .001;
    const allowedMaxSize = 3;
    const allowedExt = ".jpg,.jpeg,.png,.pdf"; 
    const isError = Boolean(error.length > 0);
    
    const onChange = async (e) => {
        const fl = e.target.files[0];
        if(fl){
            var FileSize = fl.size / 1024 / 1024; 
            const isfilestype = (/\.(jpe?g|pdf?|png)$/i).test(fl.name)
            setError([])
            if(FileSize > allowedMaxSize){
                await setError(prev=>([...prev,"The file size should not be more than 3MB."]))
                return;
            }
            if(FileSize < allowedMinSize){
                await setError(prev=>([...prev,"The file size should be atleast 1Kb."]));
                return;
            }
            if(!isfilestype){
                await setError(prev=>([...prev,"Only .jpg, .png and pdf extensions allowed"]));
                return;
            }
            setFile(fl)
        }
    }
    
    useImperativeHandle(ref, () => ({
        file,
        label,
        async getImage() {
            if(file){
                file.newType = label;
                return new File([file], label, { type: file.type,});
            }
            return null
        },
        async onSave() {
        },
      }));
    return(
        <div className={classNames("documents-upload__item",{"documents-upload__error":isError})}>
            <div className="payment-upload__upload">
                <div className="payment-upload__upload-icon">
                    <Icons name="UploadFiles" size={25} />
                </div>
                <div className="payment-upload__upload-input">
                    <label>
                        {label}
                        <input 
                            type="file"
                            multiple={false}
                            accept={allowedExt}
                            name={"upload_file"} 
                            onChange={(e) =>onChange(e)} 
                        />
                        {
                            file && <span className="payment-upload__upload-filename">{file.name}</span>
                        }
                    </label>
                </div>
            </div>
            {
                isError && (
                    <div className="documents-upload__error-list">
                        {
                            error.map(item=><span key={Math.random()} >{item}</span>)
                        }
                    </div>
                )
            }
        </div>
    )
})

export default Documents
