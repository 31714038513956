import React, { useEffect, useState } from 'react'
import Button from 'components/button';
import FailureIcon from 'assets/images/filledInfo.png'
import SuccessIcon from 'assets/images/filledCheck.png'
import Icons from 'components/icons/svgIcons';
import TrekkerSidebar from 'scenes/common/trekkers/trekkerSidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentCard, getPaymentConnectIps, getTrekker } from 'store/actions/trekkersAction';
import { getCard } from 'store/actions/cardActions';
import {cardsType} from 'constants/contants';
import { useParams } from 'react-router-dom';
import { RootStateTypes } from 'ts/reducer';
import Modal from 'components/modal'

export const PaymentFailure = (props) =>{
    const dispatch = useDispatch();
    const {isDecline} = props;
    const [open,setOpen] = useState(false);
    const {regId,payType} = useParams<any>();
    const {registeredTrekker} = useSelector((state:RootStateTypes)=>state.trekkersReducer);
    const id = registeredTrekker?.UserRegistrationModel?.RegistrationId;
    
    const onClick = () =>{
        if(payType === cardsType.cards){
            const data = {
                registrationId:regId,
                amount:props.amount
            }
            dispatch(getPaymentCard(data))
        }
        if(payType === cardsType.cips){
            dispatch(getPaymentConnectIps(id));
        }
    }
    useEffect(()=>{
        if(isDecline){
            setOpen(true);
        }
    },[isDecline])
    return(
        <div className="payment__status payment__failure">
            <div className="payment__status-icon">
                <img src={FailureIcon} alt="Payment Failure Icon" className="payment__status-icon-image"/>
            </div>
            <div className="payment__status-body">
                <div className="payment__status-title">
                    <span>Payment {isDecline ? "Declined" : "Failure"}</span>
                </div>
                <div className="payment__status-content">
                    <span>We aren't able to process your payment. {isDecline && "Your payment has been declined"}</span>
                </div>
            </div>
            <div className="payment__status-button">
                <Button onClick={()=>onClick()} title="Try again" bg="primary" />
            </div>
            <Modal 
                onModalClose={()=>setOpen(false)}
                open={open}
                width="600px"
            >
                <div className="decline-modal">
                    <div className="decline-modal__message">
                        <div className="decline-modal__message-title">Transaction Failed</div>
                        <div className="decline-modal__message-content mt-sm">Sorry! We could not process your transaction. Below are possible reasons of failure</div>
                        <ul className="decline-modal__message-reasons">
                            <li className="decline-modal__message-reason">
                                <span>You might have entered wrong card number, expiry date or CVV code.</span>
                            </li>
                            <li className="decline-modal__message-reason">
                                <span>You might have entered wrong OTP / Verification code.</span>
                            </li>
                            <li className="decline-modal__message-reason">
                                <span>Your card issuing bank might have declined the card processing. Please ask your bank to enable Online Transaction on your card.</span>
                            </li>
                        </ul>
                        <div className="decline-modal__message-content">
                            <span>If you think everything is fine then please try again.</span>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export const PaymentSuccess = (props) =>{
    const [open,setOpen] = useState(false);
    const onModalClose = () => setOpen(false);

    return(
        <div className="payment__status payment__success">
            <div className="payment__status-icon">
                <img src={SuccessIcon} alt="Payment Success Icon" className="payment__status-icon-image"/>
            </div>
            <div className="payment__status-body">
                <div className="payment__status-title">
                    <span>Payment Successful</span>
                </div>
                <div className="payment__status-content">
                    <span>Your payment has been sucessful. Enjoy the Trek.</span>
                </div>
            </div>
            <div className="payment__status-button">
                <TrekkerSidebar onClose={onModalClose} open={open} />
            </div>
        </div>
    )
}
