import React, { useEffect, useState } from 'react'
import Table from 'components/table';
import Form from 'components/form';
import FormGroup from 'components/form/formGroup';
import { Status, Actions, Reason } from 'components/table/commonTableComponents';
import Pagination from 'components/table/pagination';
import { modulesList, pageSizeValues } from 'constants/contants';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { deleteUsers, getUsers, disableUsers, approveUsers } from 'store/actions/userActions';
import Modal from 'components/modal'
import Button from 'components/button';
import RouteEnum from 'constants/routesEnum';
import { AgencyTypeFilter, CountryFilter, DateFilter, UserStatusFilter } from 'components/common/filters';
import queryString from 'query-string'
import isEmpty from 'isEmpty';
import Container from 'components/layout/container';
import DownloadAllCsv from 'components/common/downloadAllCsv';
import environment from 'environment';
import useAuth from 'src/hooks/useAuth';
import Icons from 'components/icons/svgIcons';

const pageState = {
    pageindex: 0,
    pageSize: pageSizeValues[0].value,
    totalRows: 0,
}

const csvheaders = [
    { label: "Id", key: "UserIIN" },
    { label: "Full Name", key: "FullName" },
    { label: "Email Address", key: "Email" },
    { label: "TIMS Card", key: "CardNumber" },
    { label: "Region", key: "Regional" },
    { label: "Registered Date", key: "RegisteredDate" },
    { label: "Status", key: "Status" },
    { label: "Company Name", key: "AgencyName" },
    { label: "Company Address", key: "ContactAddress" },
    { label: "Remarks", key: "Remarks" },
    { label: "Route/Trekking Area", key: "RouteName" },
    { label: "Checkpoint", key: "Checkpost" },
    { label: "Country", key: "CountryName" },
]

const filterState = {
    startDate: null,
    endDate: null,
    status: "",
    country: "",
    agency: "",
}
function CommonView({ userType, setOpen, history, onEditClick, addButton, setFlag, flag }) {
    let query = queryString.parse(history.location.search);
    const onSubmit = (data) => {
        // console.log(data)
    }
    // console.log(history)
    const { control, register } = useForm();
    const dispatch = useDispatch();
    const [page, setPage] = useState(pageState)
    const [filter, setFilter] = useState(filterState)
    const [userId, setUserId] = useState(null)
    const [data, setData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const onModalClose = () => { setModalOpen(false); setUserId(null) }
    const user = useSelector((state: RootStateTypes) => state.auth.user)
    const pageSize: any = useWatch({ control, name: 'pageSize' });
    // useEffect(()=>{
    //     getUsersDetails()
    // },[]);
    const onPageChange = async (page) => await setPage(prev => ({ ...prev, pageindex: page }))

    const { users } = useSelector((state: RootStateTypes) => state.userReducer)
    useEffect(() => {
        if (users) {
            setData(users)
            const totalRows = users ? users[0]?.TotalRows : 0;
            users && setPage(prev => ({ ...prev }))
            setPage(prev => ({ ...prev, totalRows: totalRows }))
        }
    }, [users]);
    useEffect(() => {
        getUsersDetails()
    }, [page.pageSize, page.pageindex,filter])
    // useEffect(() => {
    //     if (flag) {
    //         getUsersDetails()
    //         setFlag(false)
    //     }
    // }, [flag])
    // useEffect(() => {
    //     getUsersDetails()
    // }, [filter])
    // useEffect(()=>{
    //     if(!isEmpty(query)){
    //         console.log({query})
    //         // getUsersDetails()
    //     }
    // },[query]) 
    const onFilter = async (items) => {
        await setFilter(prev => ({ ...filter, ...items }))
    }
    useEffect(() => {
        if (pageSize) {
            setPage(prev => ({ ...prev, pageSize: pageSize.value }));
        }
    }, [pageSize,])

    const getUsersDetails = () => {
        // if(!isEmpty(query)){
        //     dispatch(getUsers({userType,...page,...query}))
        //     return
        // }
        dispatch(getUsers({ userType, ...page, ...filter }))
        // dispatch(getRoutes({name:user?.FullName,...page}))
    }
    const onEdit = (data) => onEditClick(data);
    const onDelete = (id) => {
        dispatch(deleteUsers(id + ""))
    }
    const search: any = useWatch({ control, name: 'search' });
    const columns = [
        {
            Header: 'ID',
            accessor: 'UserIIN',
            width: 160,
        },
        {
            Header: 'Full Name',
            accessor: 'FullName',
            isSort: true,
            width: 200
        },
        // {
        //     Header: 'Roles',
        //     accessor: 'roles',
        //     width: 200
        // },
        {
            Header: 'Email Address',
            accessor: 'Email',
            width: 220
        },
        {
            Header: 'Regd Date',
            accessor: 'RegisterDate',
            isSort: true,
            width: 220,
            Filter: ({ filter, onChange }) => <DateFilter {...filterState} onFilter={onFilter} />
        },
        {
            Header: 'Status',
            accessor: 'Status',
            width: 160,
            Filter: ({ filter, onChange }) => <UserStatusFilter {...filterState} onFilter={onFilter} />,
            Cell: (props) => <Status {...props} onClick={onStatusChange} />,
        },
        // {
        //     Header: 'Actions',
        //     accessor: 'ids',
        //     width: 200,
        //     Cell:(props) => <Actions {...props} onDeleteFunc={onDelete} field="UserId" onEditFunc={onEdit} />,
        // },
    ];

    const CheckPostColumn = [
        {
            Header: 'Route/Trekking Area',
            accessor: 'RouteName',
            isSort: true,
            width: 200
        },
        {
            Header: 'Checkpoint',
            accessor: 'Checkpost',
            isSort: true,
            width: 180
        },

    ]
    const FITColumn = [
        {
            Header: 'Country',
            accessor: 'CountryName',
            width: 200,
            Filter: ({ filter: filt, onChange }) => <CountryFilter  {...filter} onFilter={onFilter} />,
        },
        {
            Header: 'Remarks',
            accessor: 'Remarks',
            width: 200,
        },
    ]
    let finalColumn: any = [...columns];
    if (userType === '1') {
        finalColumn = [...finalColumn, ...FITColumn]
    }
    if (userType === '5') {
        finalColumn.splice(3, 0, ...CheckPostColumn);
        finalColumn = [
            ...finalColumn,
            {
                Header: 'Remarks',
                accessor: 'Remarks',
                width: 200,
                // Cell:(props) =><Reason {...props} onClick={onStatusChange}  />,
            },
        ]
    }
    if (userType === '2') {

        finalColumn.splice(1, 0, {
            Header: 'Company Name',
            accessor: 'AgencyName',
            width: 180,
            Filter: ({ filter: filt, onChange }) => <AgencyTypeFilter agency={filter.agency} {...filter} onFilter={onFilter} />,
        });
        finalColumn.splice(3, 0, {
            Header: 'Company Address',
            accessor: 'ContactAddress',
            isSort: true,
            width: 220
        });
        finalColumn = [...finalColumn, {
            Header: 'Remarks',
            accessor: 'Remarks',
            width: 200
        },]
    }
    if (userType === '6' || userType === '4') {

        finalColumn = [...finalColumn, {
            Header: 'Remarks',
            accessor: 'Remarks',
            width: 200
        },]
    }
    const onStatusChange = (value, original) => {
        // setModalOpen(true)UserId
        if (original.UserId) {
            setUserId(original.UserId)
        }
        if (value === 'Disable') {
            setModalOpen(true)
        }
        if (value === 'Active') {
            // setModalOpen(true)
            const data = {
                UserId: original.UserId
            }
            dispatch(disableUsers(data,true))
            // dispatch(approveUsers(original.UserId))
        }
    }
    const onRowClick = (row) => {
        if (userType === '2') {
            history.push(RouteEnum.TrekkingAgencyUser + '/' + row.original.UserId)
            return;
        }
        if (userType === '1') {
            history.push(RouteEnum.FitAgencyUser + '/' + row.original.UserId)
            return;
        }
        if (userType === '5') {
            history.push(RouteEnum.CheckPostUser + '/' + row.original.UserId)
            return;
        }
        history.push(RouteEnum.Users + '/' + row.original.UserId)
    }
    const onUserDisable = (comments) => {
        const data = {
            ...comments,
            UserId: userId
        }
        dispatch(disableUsers(data,false))
        setModalOpen(false)
        setUserId(null)
    }
    // useEffect(() => {
    //     if (typeof search !== 'undefined') {
    //         dispatch(getUsers({ userType, ...page, ...filter, username: search }))
    //     }
    // }, [search]);
    const getSearch = () => {
        dispatch(getUsers({ userType, ...page, ...filter, username: search ?? "" }))
    }
    const allowed = useAuth()
    return (
        <Container>
            <div className="users-taan">
                {/* <FormGroup onSubmit={onSubmit}> */}
                <div className="space-between search-alt">
                    <Form
                        formName="reactSelect"
                        control={control}
                        name="pageSize"
                        value={pageSizeValues[0]}
                        defaultValue={pageSizeValues[0]}
                        className="page-nums"
                        width="175px"
                        placeholder="Choose..."
                        options={pageSizeValues}
                    />
                    <div className="flex">


                        <DownloadAllCsv
                            api={environment.api.getUsers}
                            headers={csvheaders}
                            fileName="Users"
                            extraParams={{ userType, ...filter }}
                            isData={false}
                        />
                        <Form
                            name="search"
                            width="300px"
                            register={register}
                            placeholder="Search..."
                            formName="searchInput"
                            onKeyUp={(name,value,e)=>{
                                if(e?.keyCode === 13){
                                    getSearch()
                                }
                            }}
                        />
                        {allowed[modulesList.addUser] && addButton}
                        {/* <Form
                            formName="reactSelect"
                            name="action"
                            control={control}
                            width="150px"
                            placeholder="Actions"
                            options={[{ label: 'Delete', value: 1,},{ label: 'Print', value: 2,},{ label: 'CSV', value: 3,}]}
                        /> */}
                    </div>
                </div>
                {/* </FormGroup> */}
                <div className="routes-table">
                    <Table onRowClick={onRowClick} isFixedWidth columns={finalColumn} data={data} />
                    <div className="flex-end">
                        <Pagination onPageChange={onPageChange} {...page} totalRows={page.totalRows} />
                    </div>

                </div>
                <Modal
                    onModalClose={onModalClose}
                    className="disable-modal"
                    open={modalOpen}
                    width="450px"
                    // title="Disable User"
                >
                    <FormGroup onSubmit={onUserDisable}>
                        <div className="disable">
                            <div className="disable__body">
                                <div className="disable__title">Disable User</div>
                                {/* <div className="disable__content">Do you want to disable user . They will no longer able to login.</div> */}
                                <div className="disable__input form-alt">
                                    <Form
                                        placeholder="Reason for disable"
                                        formName="textarea"
                                        name="comments"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="disable__footer" style={{ gap: 12 }}>
                                <Button 
                                    bg="cancel" 
                                    // type="button-outline" 
                                    size="small"
                                    title="Cancel" onClick={() => onModalClose()} />
                                <Button
                                    // buttonType="icon-button"
                                    // type="button-outline"
                                    // icon={<Icons name="Times" />}
                                    size="small"
                                    htmlButtonType="submit" bg="primary" title="Disable" />
                            </div>
                        </div>
                    </FormGroup>
                </Modal>
            </div>
        </Container>

    )
}

export default CommonView
