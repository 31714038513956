import React, { useEffect } from 'react'
import DocumentCraft from 'assets/images/document-craft.png';
import Common from '../login/common';
import Button from 'components/button';
import {uploadDocumentStatus} from 'constants/contants'
import RouteEnum from 'constants/routesEnum';
import { useDispatch, useSelector } from 'react-redux';
import { approveUsers } from 'store/actions/userActions';
import { RootStateTypes } from 'ts/reducer';
function DocumentStatus(props) {
    const {type} = props.match.params;
    const dispatch = useDispatch()
    const {user} = useSelector((state:RootStateTypes)=>state.auth)
    // approveUsers
    useEffect(()=>{
        const getData = () =>{
            if(type && uploadDocumentStatus.approved === type){
                dispatch(approveUsers(user?.UserID))
            }
        }
        getData()
    },[type])
    return (
        <div className="documents-upload__container documents-upload__container-status">
            <Common isHeader>
                <div className="documents-upload documents-upload-status">
                    {/* <div className="documents-upload__img">
                        <div className="documents-upload__img-container">
                            <img src={DocumentCraft} alt="DocumentCraft" />
                        </div>
                    </div> */}
                    <div className="documents-upload__titles">
                        <div className="documents-upload__titled">
                            {
                                uploadDocumentStatus.sent === type ? "Document Received!" : 
                                uploadDocumentStatus.rejected === type ? "Document Rejected" :
                                uploadDocumentStatus.approved === type ? "Document Approved" : null
                            }
                            
                        </div>
                        <div className="documents-upload__sub">
                            {
                                uploadDocumentStatus.sent === type ? "Document approval takes may upto 24 hours or two working days." : 
                                uploadDocumentStatus.rejected === type ? "Your document has been rejected. For more information check your email" :
                                uploadDocumentStatus.approved === type ? "Your document has been approved. For more actions, visit Dashboard." : null
                            }
                        </div>
                    </div>
                    <div className="documents-upload__button login-btn-block">
                        {
                            uploadDocumentStatus.rejected === type ? <Button onClick={()=>props.history.push(RouteEnum.UploadDocuments)} title="Re-submit" size="large" bg="primary" /> :
                            uploadDocumentStatus.approved === type ? <Button onClick={()=>props.history.push(RouteEnum.Trekkers)} title="Go to Dashboard" size="large" bg="primary" /> : null
                        }
                        
                    </div>
                </div>
            </Common>
        </div>
    )
}

export default DocumentStatus
