import RouteEnum from 'constants/routesEnum';
import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/breadcrumb';
import Table from 'components/table';
import Form from 'components/form';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { pageSizeValues, userEnums } from 'constants/contants';
import { getTrekker, getTrekkers, getTrekking, getSelfScannedTrekkers } from 'store/actions/trekkersAction';
import { useForm, useWatch } from 'react-hook-form';
import Pagination from 'components/table/pagination';
import { getCard } from 'store/actions/cardActions';
import { CardStatusFilter, CountryFilter, DateFilter, RegionFilter, RegTypeFilter, TrekkingRouteFilter } from 'components/common/filters';
import DownloadAllCsv from 'components/common/downloadAllCsv';
import environment from 'environment';
import TrekkerSidebar from './trekkerSidebar';
import ResetFilter from 'components/common/resetFilter';
import { TrekkingStatus } from 'components/table/commonTableComponents';
import Button from 'components/button';
import { useHistory } from 'react-router-dom';

const RevisitBtn = ({value}) => {
    const history = useHistory()
    return(
        <div className="revisit-btn">
            <Button 
                bg="primary"
                title="Revisit"
                size="small"
                onClick={()=>history.push({
                    pathname: RouteEnum.NewRegister,
                    search: `?regId=${value}`,
                    state: { regId: value }
                  })}
            />
        </div>
    )
}

const pageState = {
    pageindex: 0,
    pageSize: pageSizeValues[0].value,
    totalRows: 0,
}

const csvheaders = [
    { label: "Id", key: "RowNum" },
    { label: "Full Name", key: "FullName" },
    { label: "Trekking Region", key: "TrekingArea" },
    { label: "TIMS Card", key: "CardNumber" },
    { label: "Region", key: "Regional" },
    { label: "Country", key: "Country" },
    { label: "Registered Date", key: "RegisteredDate" },
    { label: "Card Status", key: "CardStatus" },
]


const filterState = {
    startDate: null,
    endDate: null,
    cardStatus: "",
    country: "",
    region: "",
    trekkingArea: "",
    userAgencyType: ""
}


function TrekkerRoute(props) {
    console.log('....',props)
    const dispatch = useDispatch();
    const [page, setPage] = useState(pageState)
    const [trek, setTrek] = useState(null)
    const [filter, setFilter] = useState(filterState)
    const [data, setData] = useState([]);
    const onPageChange = async (page) => await setPage(prev => ({ ...prev, pageindex: page }))
    const { selfScannedTrekkers, trekker } = useSelector((state: RootStateTypes) => state.selfScanTrekkersReducer)
    const breadList = [{ title: 'Self Scan Trekkers', to: RouteEnum.TrekkingHistory, isCurrent: true }];
    const user = useSelector((state: RootStateTypes) => state.auth.user)
    const userId =  props.userId ? props.userId : user?.UserType === userEnums.ntb ? null : user.UserID
    useEffect(() => {
        if (selfScannedTrekkers) {
            setData(selfScannedTrekkers)
            const totalRows = selfScannedTrekkers ? selfScannedTrekkers[0]?.TotalRows : 0;
            selfScannedTrekkers && setPage(prev => ({ ...prev }))
            setPage(prev => ({ ...prev, totalRows: totalRows }))
        }
    }, [selfScannedTrekkers]);
    useEffect(() => {
        getTrekkings()
    }, [page.pageSize, page.pageindex])
    useEffect(() => {
        getTrekkings()
    }, [filter])
    useEffect(() => {
        if (trek) {
            dispatch(getTrekking(null,trek.ID))
            // dispatch(getTrekker(trek.RegistrationId))
            dispatch(getCard(trek.RegistrationId,trek.ID))
            // dispatch(getCard(trek.RegistrationId))
        }
    }, [trek])
    const onComplete = () =>{
        onClose()
        getTrekkings();
    }
    const getTrekkings = () => {
        dispatch(getSelfScannedTrekkers({ ...page, ...filter, userId }))
        // dispatch(getTrekkers({username:user?.UserName,...page,...filter}))
    }
    const { control, register } = useForm();
    const [open, setOpen] = useState(false);
    const pageSize: any = useWatch({ control, name: 'pageSize' });
    const search: any = useWatch({ control, name: 'search' });
    useEffect(() => {
        if (pageSize && pageSize.value) {
            setPage(prev => ({ ...prev, pageSize: pageSize.value }));
        }
    }, [pageSize])
    const isTannUser =
        user?.UserType === userEnums.taan;
    const onRowClick = (data) => {
        if (!isTannUser) {
            setTrek(data.original)
            setOpen(true)
        }
    }
    const onClose = () => {
        setOpen(false)
        setTrek(null)
    }
    const onFilter = async (items) => {
        await setFilter(prev => ({ ...filter, ...items }))
    }
    // useEffect(() => {
    //     if (search) {
    //         dispatch(getSelfScannedTrekkers({ ...page, ...filter, userId, username: search }))
    //     }
    // }, [search])
    const getSearchScannedTrekkers = () =>{
        dispatch(getSelfScannedTrekkers({ ...page, ...filter, userId, username: search }))
    }
    const isTrekkingAgency = user.UserType === userEnums.trekkingAgency;
    const beforecolumns = [
        {
            Header: 'ID',
            accessor: 'RowNum',
            width: 80,
        },
        {
            Header: 'Full Name',
            accessor: 'FullName',
            width: 200,
            isSort: true
        },
        // {
        //     Header: 'Email',
        //     accessor: 'Email',
        //     width: 300
        // },
        {
            Header: 'Trekking Region',
            accessor: 'TrekingArea',
            width: 200,
            Filter: ({ filter: filt, onchange }) => <TrekkingRouteFilter trekkingArea={filter?.trekkingArea} {...filter} onFilter={onFilter} />,
            isSort: true
        },
        {
            Header: 'TIMS Card',
            accessor: 'CardNumber',
            width: 200,
            isSort: true
        },
        {
            Header: 'Region',
            accessor: 'Regional',
            width: 160,
            isSort: true,
            Filter: ({ filter: filt, onchange }) => <RegionFilter region={filter?.region} {...filter} onFilter={onFilter} />,
        },

        {
            Header: 'Country',
            accessor: 'Country',
            Filter: ({ filter: filt, onChange }) => <CountryFilter country={filter?.country} {...filter} onFilter={onFilter} />,
            width: 200,
            isSort: true,
        },
        {
            Header: 'Reg. Date',
            accessor: 'RegisteredDate',
            width: 200,
            isSort: true,
            Filter: ({ filter: filt, onChange }) => <DateFilter {...filter} onFilter={onFilter} />
        },
        // {
        //     Header: 'Register Name',
        //     accessor: 'RegisterName',
        //     width: 200,
        // },

        {
            Header: 'Status',
            accessor: 'CardStatus',
            Filter: ({ filter: filt, onchange }) => <CardStatusFilter trekkingArea={filter?.trekkingArea} {...filter} onFilter={onFilter} />,
            width: 200,
            Cell: TrekkingStatus
        },
        // {
        //     Header: '',
        //     accessor: 'RegistrationId',
        //     width: 120,
        //     Cell: RevisitBtn
        // },
    ];
    let columns:any = beforecolumns;
    if(!isTrekkingAgency){
        const col = {
            Header: 'Agency',
            accessor: 'RegisterName',
            width: 200,
            Filter:({ filter:filt, onChange }) =><RegTypeFilter users={filter?.userAgencyType} field="userAgencyType"  {...filter} onFilter={onFilter} />,
        }
        columns.splice(7,0,col)
    }
    const onClearFilters = () => setFilter(filterState)
    return (
        <>
            {/* <Breadcrumbs isHome linkList={breadList} /> */}

            <>
                <div className="space-between search-alt">
                    <Form
                        formName="reactSelect"
                        control={control}
                        name="pageSize"
                        value={pageSizeValues[0]}
                        defaultValue={pageSizeValues[0]}
                        width="175px"
                        className="page-nums"
                        placeholder="Choose..."
                        options={pageSizeValues}
                    />
                    <div className="flex">
                        <ResetFilter onClearFilters={onClearFilters} filters={filter} />
                        <DownloadAllCsv
                            api={environment.api.getSelfScannedTrekkers}
                            headers={csvheaders}
                            extraParams={{ ...filter }}
                            fileName="TrekkersList"
                            // extraParams={{username:user?.FullName,}}
                            isData={false}
                        />
                        <Form
                            register={register}
                            name="search"
                            width="300px"
                            placeholder="Search..."
                            formName="searchInput"
                            onKeyUp={(name,value,e)=>{
                                if(e?.keyCode === 13){
                                    getSearchScannedTrekkers()
                                }
                            }}
                        />
                    </div>
                </div>
                {/* </FormGroup> */}
                <div className="routes-table">
                    <Table isFixedWidth onRowClick={onRowClick} columns={columns} data={data} />
                    {/* <Table columns={columns} data={data} isRowSelect /> */}
                    <Pagination onPageChange={onPageChange} {...page} totalRows={page.totalRows} />
                </div>
            </>
            <TrekkerSidebar callback={onComplete} trek={trek} rowData={trek} onClose={onClose} open={open} />
        </>
    )
}

export default TrekkerRoute
