import Container from 'components/layout/container';
import RouteEnum from 'constants/routesEnum';
import Trekkers from './deleted-trekkers-list'
import Breadcrumbs from 'components/breadcrumb';
import { useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { userEnums } from 'constants/contants';
import classnames from 'classnames';
import  './styles.scss';

function DeletedTrekkerRoute() {
    const breadList = [{ title: 'Trekkers', to:RouteEnum.DeletedTrekkers, isCurrent: true }]
    const {user} = useSelector((state:RootStateTypes)=>state.auth);
    const isNTB = user.UserType === userEnums.ntb;
    if(!isNTB){
        return <>Unauthorized</>
    }
    return (
        <>
            <Breadcrumbs isHome linkList={breadList} />
            <div className="trekkers  common-container">
                <div className={classnames("routes")}>
                    <Container>
                        <Trekkers />
                    </Container>
                </div>
            </div>
        </>

    )
}

export default DeletedTrekkerRoute
