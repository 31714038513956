import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import isEmpty from "isEmpty";
import TextInput from "./formInput";
import './styles.scss'
import RadioInput from './radioInput';
import Checkinput from './checkInput';
import TextArea from './textArea';
import RadioGroup from './radioGroup';
import CheckGroup from './checkGroup';
import UploadInput from './uploadInput';
import SelectInput from './selectInput'
import SearchInput from './searchInput';
import ReactSelect from './reactSelect';
import DateInput from './dateInput';
import UploadDrop from './uploadDrop';
import Icons from 'components/icons/svgIcons';
import UploadDropAlt from './uploadDropAlt';
import ReactQuilled from './reactQuill';
import { disableUsers } from "store/actions/userActions";

interface Props {
  formName:
    | "textinput"
    | "textarea"
    | "reactselect"
    | "selectInput"
    | "dateinput"
    | "chipsinput"
    | "uploadInput"
    | "checkgroup"
    | "asyncautoselect"
    | "radioinput"
    | "reactSelect"
    | "reactcalendar"
    | "react-quill"
    | "searchInput"
    | "uploadDrop"
    | "radiogroup"
    | "uploadDropAlt"
    | "checkinput";
  name: string;
  btnText?:string;
  isSearchable?:boolean,
  theme?:string;
  control?:any;
  label?: string;
  warning?:string;
  isBlob?:boolean,
  noImageShow?:boolean,
  alignment?: string;
  size?: string;
  width?: string | number;
  className?: string;
  validators?: [];
  type?: string;
  isDecimal?:boolean;
  placeholder?: string;
  onChange?: Function;
  onKeyUp?: Function;
  value?: any;
  isDisabled?: boolean;
  validate?: any;
  disabled?:boolean,
  options?: any[];
  labelPosition?:string,
  error?: any;
  multiple?: boolean;
  isArray?: boolean;
  isArrayKeys?: boolean;
  radioPosition?:string;
  labels?:any[],
  leftIcon?:any
  checked?:boolean,
  checkLabel?:string,
  defaultValue?:any,
  required?:any,
  isNoAlp?:boolean,
  register?:any,
  images?:any,
  regex?:any,
  style?:any,
  image?:any,
  ref?:any;
  isCustomeRegister?:boolean
  isClearable?:boolean
  radioLabel?:any
}
declare module "react" {
  interface HTMLAttributes<T> extends DOMAttributes<T> {
    validators?: [];
  }
}

class index extends React.Component<Props, {}> {
  public static defaultProps = {
    formName: "textinput",
    name: "",
    label: "",
    autoFocus: false,
    alignment: "vertical",
    validators: [],
    theme:'tertiary'
  };

  constructor(props: Props) {
    super(props);
    this.getDynamicComponent = this.getDynamicComponent.bind(this);
  }

  getDynamicComponent(): any {
    let { formName } = this.props;
    // formName = formName.toLowerCase();

    switch (formName) {
      case "textinput":
        return TextInput;
      case "textarea":
        return TextArea;
      case "searchInput":
        return SearchInput
      case 'selectInput':
        return SelectInput;
      case 'dateinput':
        return DateInput;
      case "checkinput":
        return Checkinput;
      case "radioinput":
        return RadioInput;
      case "react-quill":
        return ReactQuilled;
      case "reactSelect":
        return ReactSelect;
      case "uploadDrop":
        return UploadDrop;
      case "radiogroup":
        return RadioGroup;  
      case 'checkgroup':
        return CheckGroup
      case 'uploadInput':
        return UploadInput;
      case "uploadDropAlt":
        return UploadDropAlt;
      default:
        return null;
    }
  }

  render() {
    const {
      formName,
      name,
      label,
      theme,
      alignment,
      size,
      width,
      className,
      style,
      validators,
      warning,
      error,
    } = this.props;
    let inputclass = classnames({
      "generic-group": true,
      "form-disabled":this.props.disabled,
      "generic-group-area": formName==='textarea',
      'error': !isEmpty(error) && error[name],
      horizontalGenericForm: alignment && alignment == "horizontal",
      [className]: className,
      [size]: size,
    });

    let labelClass = classnames("generic-group__label",{["generic-group__label-" + theme]:theme})
    let DynamicComponent = this.getDynamicComponent();
    return (
      <div
        id={name}
        validators={validators}
        className={inputclass}
        style={{ width, ...style ? style : {} }}
      >
        {
          label && (
            <div className="space-between">
                <label htmlFor={name} className={labelClass}>
                    {label}
                </label>
                {warning && <div className="generic-group__warning">
                  {warning}
                  <Icons name="Warning"/>
                </div>}
            </div>
            
          )
        }
        {DynamicComponent ? (
          <DynamicComponent {...this.props} />
        ) : (
          "Invalid form name"
        )}

        {error && error[name] && error[name]?.message && <div className="generic-group__message"><Icons size={15} name="Error"/> {error[name].message}</div>}
      </div>
    );
  }
}

export default index;
