import React from 'react'
import Common from '../login/common';
import forgotImage from 'assets/images/celebrate.png'
import Button from 'components/button';
import RouteEnum from 'constants/routesEnum';


function SuccessPasswordReset(props) {
    return (
        <Common isFooter={false}>
            <div className="forgot">
                {/* <div className="forgot-image">
                    <img src={forgotImage} alt="Forgot Password Image"/>
                </div> */}
                <div className="forgot-title login-form__container-title">
                    <span>Password Reset Successfully</span>
                </div>
                <div className="forgot-sub login-form__container-sub">
                    <span>The password is reset successfully. Please login with your new password.</span>
                </div>
                <div className="mt-md login-btn-block">
                    <Button bg="primary" size='large' title="Login Now" onClick={()=>props.history.push(RouteEnum.Login)} />
                </div>
            </div>
        </Common>
    )
}

export default SuccessPasswordReset
