import React from "react";

interface Loading{
    loading?:boolean,
    name?:string
}

const Skeleton = (props:Loading) => {
    const { loading } = props;
    if(!loading){
        return null
    }
    const switchComponent = () => {
        const { name } = props;
        switch(name){
        case "table":
            return <div className="">Table</div>
        default :
            return null
        }
    }
    return(
        <div className="skeleton-loading">
            {
                switchComponent()
            }
        </div>
    ) 
    
    
    
};

export default Skeleton