import React, { useRef } from 'react'
import bg4 from 'assets/images/4.png';
import b from 'assets/images/b1.jpg';
import b2 from 'assets/images/b2.jpg';
import b3 from 'assets/images/b3.jpg';
import Slider from "react-slick";
import Header from 'components/layout/header';
import classnames from 'classnames';
import { NavLink, Link } from 'react-router-dom';
import RouteEnum from 'constants/routesEnum';
import logo from 'assets/svg/nepal.svg';
import ntb from 'assets/svg/ntb.svg';
import taan from 'assets/svg/taan.svg';
import mount from 'assets/svg/mountain.svg';
// import logo from 'assets/images/logo-white.png';
// import logo from 'assets/images/logo.png';

const sec = [
    { id: 1, image: b, title: '', subtitle: ' ' },
    { id: 1, image: b2, title: '', subtitle: ' ' },
    { id: 1, image: b3, title: '', subtitle: ' ' },
]

interface CommonProps {
    title?: string,
    sub?: any,
    children: any,
    isFooter?: boolean,
    isHeader?: boolean,
    isLogin?: boolean,
    headerText?: string,
    headerTextSub?: string,
}

function Common({ children, title, sub, isFooter = false, isHeader, isLogin, headerText = null, headerTextSub = null }: CommonProps) {

    // const bgImage = {backgroundImage:`url(${bg})`};
    const slider = useRef<any>(null);
    const settings = {
        arrow: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToScroll: 1,
    };
    const isRegister = title === 'Register';
    return (
        <div className="login-container">
            {
                !isHeader && (
                    <div className="login-header">
                        {/* <div className="login-header__title">
                            <span>TIMS</span>
                        </div> */}
                        <div className="login-header__logo">
                            <Link to="/">
                                <img src={logo} alt="" />
                            </Link>

                        </div>
                        {/* <div className="login-header__nav">
                            <ul className="login-header__links">
                                <li className="login-header__link">
                                    <NavLink activeClassName="link-active" to={RouteEnum.LocationPath}>
                                        <span>Self check-in</span>
                                    </NavLink>
                                </li>
                                <li className="login-header__link">
                                    <NavLink activeClassName="link-active" to={RouteEnum.Login}>
                                        <span>Sign in</span>
                                    </NavLink>
                                </li>
                                <li className="login-header__link login-header__link-sign">
                                    <NavLink activeClassName="link-active" to={RouteEnum.Register}>
                                        <span>Sign up</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                )
            }
            {isHeader && <Header isDocs={true} />}

            <div className="login">

                {/* <div className="login-side login-bg">
                    <div className="login-slide">
                        <Slider ref={slider} {...settings}>
                            {
                                sec.map((item, key) => {
                                    return (
                                        <div key={"Image-" + key} className="login-image">
                                            <div className="login-image-bg">
                                                <img src={item.image} alt={item.title} />
                                            </div>
                                            <div className="login-title">
                                                <div className="">{item.title}</div>
                                                <div className="">{item.subtitle}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                        <div className="login-footer" >
                            <div className="login-socials">
                                <div className="login-socials-item"><span className="iconify" data-icon="fa-brands:facebook-f" data-inline="false"></span></div>
                                <div className="login-socials-item"><span className="iconify" data-icon="fa-brands:instagram" data-inline="false"></span></div>
                                <div className="login-socials-item"><span className="iconify" data-icon="fa-brands:twitter" data-inline="false"></span></div>
                            </div>
                            <div className="login-terms">
                                <span>Terms & Condition | Privacy Policy</span>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="login-side login-side-form">
                    {headerText && <HeaderText headerText={headerText} headerTextSub={headerTextSub} />}

                    <div className="login-form">
                        <div className="login-form__container" >
                            <div className="login-form__container-top">
                                {
                                    (sub || title) && (
                                        <div className="login-form__container-header">
                                            <div className="login-form__container-title">
                                                {title}
                                            </div>

                                            <div className="login-form__container-sub">
                                                {sub}
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="login-form__container-body">
                                    {children}
                                </div>
                            </div>
                            {/* <div className="login-form__container-bottom">
                            {
                                isFooter && (
                                    <div className={classnames("login-form__container-footer")} style={{ marginTop: 25 }}>
                                        <span>By clicking the button above, you agree to our
                                            <div>
                                                <span className="login-form__container-footer-link"> Terms of use</span> &
                                                <span className="login-form__container-footer-link">  Privacy Policy</span>
                                            </div>
                                        </span>
                                    </div>
                                )
                            }
                        </div> */}


                        </div>
                    </div>
                </div>
                
            </div>
            <div className="login-footered">
                <div className="login-footered__left">
                    <div className="login-footered__sup">
                        <img src={ntb} />
                    </div>
                    <div className="login-footered__sup">
                        <img src={taan} />
                    </div>
                </div>
                <div className="login-footered__right">
                    <img src={mount} />
                </div>
            </div>
        </div>

    )
}

const HeaderText = ({ headerText, headerTextSub }) => {
    return <div className="login-header-text">
        <div>{headerText}</div>
        <div>({headerTextSub})</div>
    </div>
}

export default Common
