import React, { useState } from 'react'
import {  Controller } from 'react-hook-form';
import Icons from 'components/icons/svgIcons';
import Button from 'components/button';
import { useDropzone } from 'react-dropzone';
import isEmpty from 'helpers/isEmpty';
import NIcons from 'components/icons'
import CropImage from './cropImage';


export const UploadDrop = (props) => {
  const {
    name,
    multiple,
    clearErrors,
    control,
    required,
    setError,
    ...rest
  } = props;
  return (
    <Controller
      rules={{ required: required ? 'Upload field is required' : false }}
      required={required}
      render={({ onChange, value }) => {
        return(
        <Dropzone
          multiple={multiple}
          name={name}
          file={value}
          clearErrors={clearErrors}
          setError={setError}
          onChange={file =>onChange(file)}
          {...rest}
          onRemove={() => onChange(null)}
        />
      )}}
      name={name}
      control={control && control}
      defaultValue=''
    />
  )
}

const Dropzone = ({
  multiple,
  onChange,
  setError,
  clearErrors,
  name,
  file,
  onRemove,
  disabled,
  image,
  ...rest
}) => {
  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    multiple,
    accept: 'image/jpeg, image/png',
    ...rest,
  });
  const [open, setOpen] = useState(false);
  const [change, setChange] = useState(false);
  const onModalClose = () => setOpen(false);
  // useEffect(() => {
  //   if (file) {
  //     setOpen(true)
  //   }
  // }, [file])
  const handleImageChange = (e) => {
    const files = e.target.files;
    const allowedMinSize = .001;
    const allowedMaxSize = 3;
    var isError = false;
    if(files){
      for(var i = 0;i<files.length;i++){
        const fl = files[i];
        var FileSize = fl.size / 1024 / 1024; 
        const isfilestype = (/\.(jpe?g|pdf?|png)$/i).test(fl.name)
        // setError(name,"")
        if(FileSize > allowedMaxSize){
          setError && setError(name, {
            type: "manual",
            message: "Max File Size 3MB.",
          });
          isError=true
          return;
        }
        if(FileSize < allowedMinSize){
          setError && setError(name, {
              type: "manual",
              message: "Min File Size 1Kb.",
            });
            isError=true
            return;
        }
        if(!isfilestype){
          setError && setError(name, {
            type: "manual",
            message: "Only .jpg, .png and pdf extensions allowed",
          });
            isError=true
            return;
        }
        clearErrors && clearErrors(name)
      }
    }
    if(!isError){
      onChange(multiple ? e.target.files : e.target.files[0])
      setOpen(true);
    }
  }
  const handleImageCrop = (file) =>{
    onChange(file)
    
  }
  // console.log(crop)
  const urls = !isEmpty(file) ?URL.createObjectURL(file) :null;
  return (
    <div className="" key='uniqueKey'>
      {/* {
        !isEmpty(file) ? (
          <div className="generic-group__drop">
            <CropImage onChange={handleImageCrop} file={file} open={open} onModalClose={onModalClose}  />
            <div className="generic-group__drop-image">
              <img src={URL.createObjectURL(file)} alt="File Upload Image" />
            </div>
            {!disabled &&
              <div onClick={() => onRemove()} className="generic-group__remove">
                <NIcons type="iconify" dataIcon="clarity:times-circle-solid" />
              </div>
            }
          </div>
        ) : (
          <div className="generic-group__drop" {...getRootProps()}>
            <input disabled={disabled} name={name} {...getInputProps({ onChange: handleImageChange })} />
            <div className="generic-group__drop-icon">
              <Icons name="UploadIcon" size={42} color={"#A0A4A8"} />
            </div>
            <div className="generic-group__drop-title"><span>Upload or Drag your photo(2x2 inch) here.</span></div>
          </div>
        )
      } */}
      {
        !isEmpty(file) ? (
          <div style={{display:!isEmpty(file)?'block' :'none'}} className="generic-group__drop">
            <CropImage onChange={handleImageCrop} file={file} open={open} onModalClose={onModalClose}  />
            <div className="generic-group__drop-image">
              <img src={URL.createObjectURL(file)} alt="File Upload Image" />
            </div>
            {!disabled &&
              <div onClick={() => onRemove()} className="generic-group__remove">
                <NIcons type="iconify" dataIcon="clarity:times-circle-solid" />
              </div>
            }
          </div>
        ) : image && !change ? (
          <div className="generic-group__drop">
            <div className="generic-group__drop-image">
              <img src={'/'+image} alt="Trekker Image" />
            </div>
          </div>
          
        ) : null
      }

        <div style={{display:!(isEmpty(file) )?'none' : image && !change ? 'none' :'block'}} className="generic-group__drop" {...getRootProps()}>
        {/* <div style={{display:((isEmpty(file) ) && (image && change))?'block' :'none'}} className="generic-group__drop" {...getRootProps()}> */}
            <input disabled={disabled} name={name} {...getInputProps({ onChange: handleImageChange })} />
            <div className="generic-group__drop-icon">
              <Icons name="UploadIcon" size={42} color={"#A0A4A8"} />
            </div>
            <div className="generic-group__drop-title"><span>Upload or Drag your photo(2x2 inch) here.</span></div>
        </div>
              
      {isEmpty(file) && image && !disabled && (
          <div className="mt-sm center" style={{textAlign:'center'}} >
              <Button  
                title={change? "Cancel" : "Change"}
                bg={change ? "cancel" : "primary"}
                onClick={()=>setChange(!change)}
              />
          </div>
      )}
    </div>

  )
}


export default UploadDrop