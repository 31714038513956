import Button from 'components/button';
import Icons from 'components/icons/svgIcons';
import RouteEnum from 'constants/routesEnum';
import React, { useState } from 'react';
import QrReader from 'react-qr-reader'
import { useDispatch } from 'react-redux';
import { getCard } from 'store/actions/cardActions';
import { autoCheckin } from 'store/actions/checkinAction';
import { verifyScanTrekkers } from 'store/actions/trekkersAction';
import './styles.scss'

function ScanTrekker(props) {
    const [result,setResult] = useState(null)
    const [error,setError] = useState(null)
    const [isScan,setIsScan] = useState(false)
    const dispatch = useDispatch()
    const onScan = async (data) => {
        console.log("........data", data);
        if(data){
            setIsScan(false);
            setResult(data);
            const parsedData = JSON.parse(data);
            /*if(parsedData && parsedData.RegistrationId){*/
            if (parsedData) {
                /*dispatch(verifyScanTrekkers(parsedData.RegistrationId,parsedData.TrekkerId))*/
                dispatch(verifyScanTrekkers(parsedData.TrekkerId))
                // const d = {
                //     cardno:parsedData.CardNumber,
                //     passport:parsedData.PassportNumber,
                //     place:'Pokhara'
                // }
                // const res = await dispatch(autoCheckin({...d}))
                props.history.push(RouteEnum.ScannedTrekker +'/' + parsedData.RegistrationId + '/' + parsedData.TrekkerId)

            }else{
                setError('Invalid QR Code')
            }
            // dispatch(getCard(data))
        }else{
            setError("Invalid QR Code")
        }
    }

    return (
        <div className="scan">
            <div className="scan-container">
                <div className="scan-block">
                    <div  className="center text-center scan-block__title" style={{textAlign:'center'}} >Scan Trekker QR Code</div>
                     <div className="scan-block__sub">Scan Trekker</div> 
                    <div className="scan-block__qr">
                        {isScan && <QrReader
                            delay={1000}
                            onError={(err)=>console.log(err)}
                            onScan={data => onScan(data)}
                            style={{ width: '100%' }}
                        />}
                    </div>
                    <div className="scan-block__btn">
                        <Button
                            buttonType="icon-button"
                            buttonClass="primary-stroke"
                            icon={<Icons name="Scan" />}
                            title={isScan ? "Cancel Scan QR" : "Scan QR"} bg="primary" onClick={()=>setIsScan(prev=>!prev)} />
                        {
                            error && <p className="error">{error}</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScanTrekker