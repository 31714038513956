import React from "react";
import Skeleton from "./skeleton";
import Spinner from "./spinner";
import LogoLoadinng from "./logoLoading";

interface Loaders {
  type?: "skeleton" | "spinner" | string;
  name?: string;
  backgroundColor?: string;
  bodyBackground?: string;
  foregroundColor?: string;
  loading: boolean;
}

function Loaders(props: Loaders) {
  const loaderComponent = () => {
    const { type } = props;
    switch (type) {
      case "skeleton":
        return <Skeleton {...props} />;
      case "spinner":
        return <Spinner {...props} />;
      case "rigoLoading":
          return <LogoLoadinng {...props} />;
      default:
        // return null
        return <Spinner {...props} />;
    }
  };
  return loaderComponent();
}

Loaders.defaultProps = {
  // type: "normal",
  loading: false,
};

export default Loaders;
