import types from "constants/types";
import { CardInitialState } from "ts/reducer";

const initialState: CardInitialState = {
  cards: [],
  returnCards: [],
  card: null,
};

export default function CardReducer(state = initialState, action): CardInitialState {
  switch (action.type) {
    case types.GET_SINGLE_USER_CARD:
      return {
        ...state,
        card: action.payload,
      };
    case types.GET_CARDS:
      return {
        ...state,
        cards: action.payload,
      };
    case types.GET_RETURN_CARDS:
      return {
        ...state,
        returnCards: action.payload,
      };
    case types.RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
