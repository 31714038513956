import React from 'react'
import {TabInterface} from 'ts/interfaces/componentInterface'
import classnames from 'classnames';

// declare module 'react' {
//     interface HTMLAttributes<T> extends DOMAttributes<T> {
//       name?: []
//     }
// }

function Tab(props:TabInterface) {

    const onClick = (e:any) => {
        const {  onClick, id, onTabClick } = props;
        onClick(props.name, id);
        onTabClick && onTabClick(props.name,id);
    };

    const tabClasses = classnames({
        "tabs-list__item":!props.isBlock,
        "tabs-block__item":props.isBlock,
        "tab-active":props.isActive,
    })
    return (
        <li id={props.name} className={tabClasses} onClick={(e) => onClick(e)}>
            <span>
            {props.label}
            </span>
            
        </li>
    )
}

export default Tab
