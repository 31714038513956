import React, { useState, useEffect, useContext } from 'react';
import Container from 'components/layout/container';
import Heading from 'components/typography';
import Form from 'components/form';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { addTrekkerUserDetails, updateInsuranceDetails, updateUserDetails } from 'store/actions/registerTrekker';
import Button from 'components/button';
import Icons from 'components/icons/svgIcons';
import classnames from 'classnames'
import { useForm, useWatch } from 'react-hook-form';
import { getCountriesByRegion } from 'store/actions/selectAction';
import { userEnums } from 'constants/contants';
import InsuranceForm from './insuranceForm';
import classNames from 'classnames';
import dayjs from 'dayjs';
import isEmpty from 'helpers/isEmpty';
import FormGroup from 'components/form/formGroup';
import Context from '../context'
import { IUserDetailsModel } from '../helpers';
import { DiplomatModal } from './diplomat-popup'

const compareDates = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return d2 > d1
}

const checkIfChildRange = (d2) => {
    const newTenyearsFromNow = dayjs(new Date()).subtract(10, "year")
    const isBefore = dayjs(d2).isBefore(newTenyearsFromNow);
    return isBefore
}

function UserDetailsForm(props) {
    const { isEdit, onRemove, isRemovable, trekItem, isNewTrekkerRow = false, currentUser } = props;
    const method = useForm<IUserDetailsModel>();
    const vals = useContext(Context);
    const registeredTrekker = vals.registered;
    const disabled = vals.activeStep > 1;
    const { errors, watch, control, setValue, getValues } = method;
    const [isDiplomat, setIsDiplomat] = useState(false);
    const [isEditable, setEditable] = useState(false);
    const [isInsEditable, setInsEditable] = useState(false);
    const { genders, countries } = useSelector((state: RootStateTypes) => state.selectReducer)
    const { trekkersPersionality, routes } = useSelector((state: RootStateTypes) => state.selectReducer);
    const { user } = useSelector((state: RootStateTypes) => state.auth);
    const { trekLoading } = useSelector((state: RootStateTypes) => state.trekkersReducer);
    const isFree = user.UserType === userEnums.free;
    const finalCountries = isFree ? countries.filter(item => item.value !== 524) : countries;
    const regionId: any = useWatch({ control, name: 'RegionalId' });
    const nationalityId: any = watch('Nationality');
    const visaIssueDate = watch('VisaIssueDate');
    const dispatch = useDispatch();
    const isDisabled = disabled && !isEditable && Boolean(currentUser);
    const isInsDisabled = disabled && !isInsEditable && Boolean(currentUser);

    const fullName = watch('FullName');
    useEffect(() => {
        if (user) {
            setValue('ContactPerson', user?.FullName);
            setValue('Organization', user?.OrganizationName);
            // setValue('Designation', user?.Designation);
            setValue('Mobile', user?.ContactNo);
            setValue('HomeOfficeNo', user?.ContactNo);
            setValue('Address', user?.ContactAddress);
        }
    }, [user]);
    useEffect(() => {
        if (trekkersPersionality && trekkersPersionality.length) {
            const normal = trekkersPersionality.find(item => item.label === 'Normal')
            if (normal) {
                setValue('RegionalId', normal);
            }
        }
    }, [trekkersPersionality]);
    useEffect(() => {
        if (regionId) {
            if(regionId?.value === '4'){
                setValue("RegionalId","")
                setIsDiplomat(true)
            }
        }
    }, [regionId]);

    const onDiplomatClose = () => {
        setIsDiplomat(false)
    }
    
    // console.log({currentUser})
    useEffect(() => {
        if (currentUser && currentUser?.TrekkersDetails) {
            Object.keys(currentUser?.TrekkersDetails).map(item => {
                const val = currentUser?.TrekkersDetails[item]
                // console.log({val})
                if (item === 'RegionalId') {
                    const regioned = trekkersPersionality && trekkersPersionality.find(re => (val == '4' || val == '5') ? re.value == val : re.value == '3');
                    setValue(item, regioned)
                    // RegionID
                    return
                }
                if (item === 'Nationality') {
                    const cont = countries && countries.find(re => re.value == val)
                    setValue(item, cont)
                    return
                }
                if (item === 'Gender') {
                    const gen = genders && genders.find(re => re.value == val)
                    setValue(item, gen)
                    return
                }
                if (item === 'InsuranceCountry') {
                    const conted = countries && countries.find(re => re.value == val)
                    setValue(item, conted)
                    return
                }
                setValue(item, val)
            })
        }
    }, [currentUser,]);
    const isError = !isEmpty(errors);
    const onClick = () => {
        console.log("Cloicked")
    }
    const onSubmit = async (data) => {
        // console.log({data})
        const formData = new FormData();
        if (isEdit) {

            Object.keys(data).map(item => {
                const name = item.charAt(0).toUpperCase() + item.slice(1);
                if (item === 'Files' && data[item]) {
                    console.log({item,data})
                    formData.append(item, data[item], data[item]?.name)
                    return;
                }
                if (item === 'Nationality' || item === 'Gender' || item === 'RegionalId' || item === 'TrekkingAreaId') {

                    formData.append('UserRegistrationModel.' + name, data[item].value)
                    return;
                }
                formData.append('UserRegistrationModel.' + name, data[item])
            })
            const id = currentUser?.TrekkersDetails?.RegistrationId;
            const trekId = currentUser?.TrekkersDetails?.ID;

            formData.append('UserRegistrationModel.RegistrationId', id)
            formData.append('UserRegistrationModel.ID', trekId)
            
            const res = await dispatch(updateUserDetails(formData));
            if (res) {
                setEditable(false);
            }
            return;
        }
        Object.keys(data).map(dataName => {
            let item = dataName;
            const name = item;
            // const name = item.charAt(0).toUpperCase() + item.slice(1);
            if (item === 'Files') {
                formData.append(`Files`, data[dataName], data[dataName].name)
                return;
            }
            if ((item === 'VisaIssueDate' || item === "VisaExpiryDate") && !data[dataName]) {
                return;
            }
            if (item === 'Nationality' || item === 'Gender') {

                formData.append(`TrekkersDetailsDtos[0].TrekkersDetails.` + name, data[dataName].value)
                return;
            }
            if (item === 'RegionalId') {
                if (data[dataName].value == '3') {
                    formData.append(`TrekkersDetailsDtos[0].TrekkersDetails.` + name, data['Nationality'].RegionID)
                    return;
                }
                formData.append(`TrekkersDetailsDtos[0].TrekkersDetails.` + name, data[dataName].value)
                return;
            }
            if (item === 'InsuranceCountry') {
                if (data[dataName] != undefined) {
                    formData.append(`TrekkersDetailsDtos[0].TrekkersDetails.` + name, data[dataName].value + "")
                }
                // formData.append('TrekkersDetailsDtos[0].TrekkersDetails.'+name,"Nepal")
                return;
            }
            // newData.TrekkersDetailsDtos[0].TrekkersDetails[row] = newData.TrekkersDetailsDtos[0].TrekkersDetails[row] ?:{...newData.TrekkersDetailsDtos[0].TrekkersDetails[row],[name]:data[dataName]}} ::{[name]:data[dataName]}}
            // debugger

            formData.append(`TrekkersDetailsDtos[0].TrekkersDetails.` + name, data[dataName])
        })
        const id = registeredTrekker.UserRegistrationModel.RegistrationId;
        formData.append(`TrekkersDetailsDtos[0].TrekkersDetails.RegistrationId`, id)
         const res = await dispatch(addTrekkerUserDetails(formData))
        if (res && onRemove) {
            onRemove(trekItem);
        }
    }
    const onInsuranceEdit = async () => {
        if(!isEditable) setInsEditable(prev=>!prev);
        if (isInsEditable) {
            const values = getValues();
            if (currentUser?.TrekkersDetails) {
                const id = currentUser?.TrekkersDetails?.RegistrationId;
                const trekId = currentUser?.TrekkersDetails?.ID;
                const data = {
                    userRegistrationModel: {
                        InsuranceCompany: values['InsuranceCompany'] ?? "",
                        InsuranceCountry: values['InsuranceCountry']?.value ?? null,
                        PolicyNumber: values['PolicyNumber'] ?? "",
                        PolicyType: values['PolicyType'] ?? "",
                        RegistrationId: id,
                        ID: trekId
                    }
                }
                const res = await dispatch(updateInsuranceDetails(data));
                if (res) {
                    setInsEditable(false);
                }
            }
        }
    };

    return (
        <FormGroup onSubmit={onSubmit} method={method} >
            <DiplomatModal 
                isOpen={isDiplomat}
                onClose={onDiplomatClose}
            />
            <div className={classNames("pv-sm", { "is-user-error": isError })}>
                <Container isInitialCollapse={true} isIcon={isError} maxWidth={'max-content'} isCollapse title={fullName ? fullName : "Trekker Details"} >
                    <div className="space-between">
                        {/* <Heading>
                        User Details
                    </Heading> */}
                        <div></div>
                        <div className="flex">
                            {isEdit && currentUser && <Button bg="primary" title={isEditable ? "Done" : "Edit"}
                                htmlButtonType={isEditable ? "submit" : "button"}
                                onClick={(e) => {
                                    if (!isEditable) {
                                        e.preventDefault();
                                        setEditable(prev => !prev)
                                        return;
                                    } else {
                                        // handleSubmit(onSubmit)
                                    }
                                }} buttonType="icon-button" icon={<Icons name="EditOutline" />} />}
                            {isEditable && <Button bg="grey" title={"Cancel"} onClick={() => setEditable(false)} />}
                        </div>
                    </div>
                    <div className="form-group pv-sm">
                        <div className="flex" style={{ marginBottom: 20 }}>
                            <Form
                                name={"Files"}
                                formName="uploadDrop"
                                disabled={isDisabled}
                                image={currentUser?.Attachment[0]?.FileLocation ?? null}
                                multiple={false}
                                required={!isEdit}
                                {...method}
                            />
                            <div className="flex-1" style={{ marginLeft: 24 }}>
                                {/* <div className="form-row" style={{ fontSize: 14, color: "#ff0000" }}> Note- For Diplomat please visit Nepal Turisom Board.</div> */}
                                <div className="form-row">
                                    <Form
                                        name={"FullName"}
                                        placeholder="Full Name"
                                        label="Full Name"
                                        className="required"
                                        required={true}
                                        disabled={isDisabled}
                                        width={330}
                                        {...method}
                                    />
                                    <Form
                                        name={"RegionalId"}
                                        placeholder="Type"
                                        label="Type"
                                        className="required"
                                        formName="reactSelect"
                                        options={trekkersPersionality ? trekkersPersionality.map(item => ({ ...item, label: item.label })) : []}
                                        required={true}
                                        width={330}
                                        disabled={isDisabled}
                                        {...method}
                                    />                                   
                                </div>
                                <div className="form-row">
                                    <Form
                                        name={"Nationality"}
                                        placeholder="Nationality"
                                        label="Nationality"
                                        className="required"
                                        required={true}
                                        isSearchable={true}
                                        width={330}
                                        disabled={isDisabled}
                                        options={finalCountries}
                                        formName={"reactSelect"}
                                        {...method}
                                    />
                                    <Form
                                        name={"City"}
                                        placeholder="City"
                                        label="City"
                                        className="required"
                                        required={true}
                                        width={330}
                                        disabled={isDisabled}
                                        {...method}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <Form
                                name={"Gender"}
                                placeholder="Gender"
                                label="Gender"
                                className="required"
                                formName="reactSelect"
                                options={genders}
                                required={true}
                                disabled={isDisabled}
                                width={270}
                                {...method}
                            />
                            <Form
                                name={"Dob"}
                                placeholder="Date of birth"
                                label="Date of birth"
                                className="required"
                                formName="dateinput"
                                required={true}
                                // validate={(value) => compareDates(value, new Date()) || "Date of birth can not be greter than today Date"}
                                validate={(value) => {
                                    if (value) {
                                        if (regionId?.value == '5' && checkIfChildRange(value)) {
                                            return "Child's date of birth can not be greater than 9 years of age";
                                        }
                                        if (regionId?.value != '5' && !checkIfChildRange(value)) {
                                            return "Please select type  Child for age below 10 years.";
                                        }
                                    }
                                    if (compareDates(value, new Date())) {
                                        return true;
                                    } else {
                                        return "Date of birth can not be greter than today Date";
                                    }

                                }}
                                width={270}
                                disabled={isDisabled}
                                {...method}
                            />
                            <Form
                                name={"Email"}
                                type="email"
                                placeholder="Email"
                                label="Email"
                                className="required"
                                required={true}
                                width={270}
                                disabled={isDisabled}
                                {...method}
                            />
                        </div>
                        <div className="form-row">
                            <Form
                                name={"PassportNumber"}
                                // type="number"
                                placeholder={nationalityId && nationalityId.value == 356 ? "Passport Number / Aadhaar number" : "Passport Number"}
                                label={nationalityId && nationalityId.value == 356 ? "Passport Number / Aadhaar number" : "Passport Number"}
                                className="required"
                                required={true}
                                disabled={isDisabled}
                                width={250}
                                {...method}
                            />

                            {
                                // nationalityId && (nationalityId.value != 356 && nationalityId.value != 524) && 
                                (
                                    <>
                                        <Form
                                            name={"VisaNumber"}
                                            // type="number"
                                            placeholder="Visa Number"
                                            label="Visa Number"
                                            // required={true}
                                            disabled={isDisabled}
                                            width={198}
                                            style={{ display: nationalityId && (nationalityId.value != 356 && nationalityId.value != 524) ? 'block' : 'none' }}
                                            {...method}
                                        />
                                        <Form
                                            name={"VisaIssueDate"}
                                            placeholder="Visa Issue Date"
                                            label="Visa Issue Date"
                                            formName="dateinput"
                                            // required={true}
                                            disabled={isDisabled}
                                            width={198}
                                            style={{ display: nationalityId && (nationalityId.value != 356 && nationalityId.value != 524) ? 'block' : 'none' }}
                                            {...method}
                                        />
                                        <Form
                                            name={"VisaExpiryDate"}
                                            placeholder="Visa Expiry Date"
                                            label="Visa Expiry Date"
                                            formName="dateinput"
                                            // required={true}
                                            disabled={isDisabled || !visaIssueDate}
                                            // validate={nationalityId && (nationalityId.value != 356 && nationalityId.value != 524) ? (value) => {console.log(compareDates(watch("VisaIssueDate-"+row), value) || "Visa Expiry Date can not be less than Entry Date");return compareDates(watch("VisaIssueDate-"+row), value) || "Visa Expiry Date can not be less than Entry Date"}:{}}
                                            width={198}
                                            // required={nationalityId && (nationalityId.value != 356 && nationalityId.value != 524) }
                                            style={{ display: nationalityId && (nationalityId.value != 356 && nationalityId.value != 524) ? 'block' : 'none' }}
                                            // register={register}
                                            {...method}
                                        />
                                    </>
                                )
                            }

                        </div>
                    </div>
                    <Heading style={{ marginTop: 16 }} >
                        Emergency Contact Details
                    </Heading>
                    <div className="form-tabs">
                        <div className="form-tab form-tab__active">
                            <span>Nepal contact</span>
                        </div>
                    </div>
                    {/* <div style={{ display: tab === 0 ? 'block' : 'none' }} className="form-group pt-sm"> */}
                    <div className="form-group pt-sm">
                        <div className="form-row">
                            <Form
                                name={"ContactPerson"}
                                placeholder="Contact Person Name"
                                label="Contact Person Name"
                                // required={true}
                                width={270}
                                disabled={isDisabled}
                                {...method}
                            />
                            <Form
                                name={"Organization"}
                                label="Organization Name"
                                placeholder="Organization Name"
                                // required={true}
                                width={350}
                                disabled={isDisabled}
                                {...method}
                            />
                            {/*<Form*/}
                            {/*    name={"Designation"}*/}
                            {/*    placeholder="Designation"*/}
                            {/*    label="Designation"*/}
                            {/*    // required={true}*/}
                            {/*    width={270}*/}
                            {/*    disabled={isDisabled}*/}
                            {/*    control={control}*/}
                            {/*    register={register}*/}
                            {/*    error={error}*/}
                            {/*    {...rest}*/}
                            {/*/>*/}
                        </div>
                        <div className="form-row">
                            <Form
                                name={"Mobile"}
                                placeholder="Mobile Number"
                                label="Mobile Number"
                                // required={true}
                                width={270}
                                disabled={isDisabled}
                                type="number"
                                {...method}
                                leftIcon={<span className="phone-icon">977</span>}
                            />
                            <Form
                                name={"HomeOfficeNo"}
                                placeholder="office/home telephone num..."
                                label="office/home telephone num..."
                                //  required={true}
                                disabled={isDisabled}
                                width={270}
                                type="number"
                                {...method}
                                leftIcon={<span className="phone-icon">977</span>}
                            />
                            <Form
                                name={"Address"}
                                placeholder="Address"
                                label="Address"
                                // required={true}
                                disabled={isDisabled}
                                width={270}
                                {...method}
                            />
                        </div>
                    </div>
                    <div className="form-tabs mt-md">
                        <div className="form-tab form-tab__active">
                            <span>Home country contact</span>
                        </div>
                    </div>
                    <div className="form-group pt-sm">
                        <div className="form-row">
                            <Form
                                name={"NativeContactPerson"}
                                placeholder="Contact Person Name"
                                label="Contact Person Name"
                                // required={true}
                                width={270}
                                disabled={isDisabled}
                                {...method}
                            />
                            {/* <Form
                                    name="NativeCountry"
                                    placeholder="Country"
                                    label="Country"
                                    required={true}
                                    width={270}
                                    disabled={isDisabled}
                                    options={countriesByRegion}
                                    formName={"reactSelect"}
                                    control={control}
                                    register={register}
                                    error={error}
                                    {...rest}
                                /> */}
                            {/* <Form
                                    name="NativeOrganization"
                                    label="Native Organization Name"
                                    placeholder="Native Organization Name"
                                    required={true}
                                    disabled={isDisabled}
                                    width={270}
                                    control={control}
                                    register={register}
                                    error={error}
                                    {...rest}
                                /> */}
                            <Form
                                name={"NativeDesignation"}
                                placeholder="City"
                                label="City"
                                // required={true}
                                width={270}
                                disabled={isDisabled}
                                {...method}
                            />
                            {/* <Form
                                    name="Citys"
                                    placeholder="City"
                                    label="City"
                                    required={true}
                                    width={270}
                                    disabled={isDisabled}
                                    control={control}
                                    register={register}
                                    error={error}
                                    {...rest}
                                /> */}
                            {/* </div>
                            <div className="form-row"> */}
                            <Form
                                name={"NativeMobile"}
                                placeholder="Mobile Number Eg. (+977 98901827636)"
                                label="Mobile Number"
                                // required={true}
                                width={270}
                                disabled={isDisabled}
                                // type="number"
                                {...method}
                            // leftIcon={<span className="phone-icon">001</span>}
                            />

                        </div>
                        <div className="form-row">
                            <Form
                                name={"NativeOfficeNo"}
                                placeholder="office/home telephone num..."
                                label="Office/Home Telephone"
                                //  required={true}
                                width={270}
                                disabled={isDisabled}
                                type="number"
                                {...method}
                            />
                            <Form
                                name={"NativeAddress"}
                                placeholder="Address"
                                label="Address"
                                //   required={true}
                                disabled={isDisabled}
                                width={270}
                                {...method}
                            />
                        </div>
                    </div>
                    {/* <InsuranceForm isNewTrekkerRow={isNewTrekkerRow} method={method} {...props} /> */}
                    {/* {!isNewTrekkerRow && !isEdit && <div style={{ textAlign: 'center' }} className='mt-sm' > */}
                    <div className="pv-md">
                        {/* <Container maxWidth={'max-content'}> */}
                        <div className="space-between">
                            <Heading>
                                Insurance Details
                            </Heading>
                            <div className="flex">
                                {isEdit && !isNewTrekkerRow && <Button bg="primary" title={isInsEditable ? "Done" : "Edit"} onClick={() => onInsuranceEdit()} buttonType="icon-button" icon={<Icons name="EditOutline" />} />}
                                {isInsEditable && <Button bg="grey" title={"Cancel"} onClick={() => setInsEditable(false)} />}
                            </div>
                        </div>
                        <div className="form-group pt-sm">
                            <div className="form-row">
                                <Form
                                    name={"InsuranceCompany"}
                                    placeholder="Insurance company name"
                                    label="Insurance company name"
                                    // required={true}
                                    disabled={isInsDisabled}
                                    width={"410px"}
                                    {...method}
                                />
                                <Form
                                    name={"InsuranceCountry"}
                                    placeholder="Country"
                                    label="Country"
                                    options={countries}
                                    formName={"reactSelect"}
                                    isSearchable={true}
                                    // required={true}
                                    width={"410px"}
                                    disabled={isInsDisabled}
                                    {...method}
                                />
                            </div>
                            <div className="form-row">
                                <Form
                                    name={"PolicyNumber"}
                                    placeholder="Policy Number"
                                    label="Policy Number"
                                    // required={true}
                                    width={"410px"}
                                    disabled={isInsDisabled}
                                    {...method}
                                />
                                <Form
                                    name={"PolicyType"}
                                    placeholder="Policy Type"
                                    label="Policy Type"
                                    className="required"
                                    required={true}
                                    disabled={isInsDisabled}
                                    width={"410px"}
                                    {...method}
                                />
                            </div>
                        </div>
                        {/* </Container> */}
                    </div>
                    {!isEdit && <div style={{ textAlign: 'center' }} className='mt-sm' >
                        <Button
                            bg="primary"
                            title={trekLoading ? "...Saving Trekker" : "Save Trekker"}
                            htmlButtonType='submit'
                            isDisabled={trekLoading}
                        // onClick={() => onClick()}
                        />
                    </div>}
                    {
                        isRemovable && trekItem && !isEdit && <Button
                            bg="danger"
                            title="Remove Trekker"
                            isDisabled={trekLoading}
                            onClick={() => onRemove(trekItem)}
                        />
                    }
                </Container>
            </div>
        </FormGroup>
    )
}

export default UserDetailsForm