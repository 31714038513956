import React from 'react'

function Reports() {
    return (
        <div>
            Reports
        </div>
    )
}

export default Reports
