import React, { useContext, useEffect, useState } from 'react'
import Container from 'components/layout/container';
import Heading from 'components/typography';
import Form from 'components/form';
import FormGroup from 'components/form/formGroup';
import Button from 'components/button';
import Icons from 'components/icons/svgIcons';
import IconsN from 'components/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { registerNewTrekker, updateInsuranceDetails, updateTrekkingDetails } from 'store/actions/registerTrekker';
import { getRoute } from 'store/actions/routesAction';
import { getGuide } from 'store/actions/guidesAction';
import { userEnums } from 'constants/contants';
import { useForm } from 'react-hook-form';
import {IUserRegisterationModel, StepperEnums} from '../helpers'
import { useHistory, useLocation } from 'react-router-dom';
import Context from '../context'
import RouteEnum from 'constants/routesEnum';

const compareDates = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return d2 > d1
}


function TrekForm(props) {
    const {  } = props;
    const vals = useContext(Context);
    const isEdit = vals?.isDto
    const disabled = vals.activeStep > 1;
    const {state} = useLocation()
    const history = useHistory()
    const routeState:any = state;
    const { routes, guides } = useSelector((state: RootStateTypes) => state.selectReducer)
    const method = useForm<IUserRegisterationModel>();
    const { setError, setValue, clearErrors, watch,reset,handleSubmit } = method;
    const [isEditable, setEditable] = useState(false);
    const dispatch = useDispatch();
    const { loading:TrekkerLoading  } = useSelector((state: RootStateTypes) => state.trekkersReducer);
    const { route } = useSelector((state: RootStateTypes) => state.routeReducer)
    const { guide } = useSelector((state: RootStateTypes) => state.guideReducer)
    const trekkingAreaId = watch("TrekkingAreaId");
    const trekEntryDate = watch("TrekEntryDate");
    const trekExitDate = watch("TrekExitDate");
    const { user } = useSelector((state: RootStateTypes) => state.auth);
    const registeredTrekker = vals.registered;
    useEffect(() => {
        if (trekExitDate && trekEntryDate) {
            const isError = !compareDates(trekEntryDate, trekExitDate);
            if (isError) {
                setError("TrekExitDate", {
                    type: "manual",
                    message: "Trek Exit Date can not be less than Entry Date",
                })
            } else {
                clearErrors('TrekExitDate');
            }
        }
    }, [trekExitDate, trekEntryDate]);

    useEffect(() => {
        if (trekkingAreaId && trekkingAreaId.value) {
            dispatch(getRoute(trekkingAreaId.value))
        }
    }, [trekkingAreaId]);

    useEffect(() => {
        if (routeState && routeState?.routeId) {
            dispatch(getRoute(routeState?.routeId))
            const route = routes && routes.find(item => item.value === routeState?.routeId)
            setValue('TrekkingAreaId', route)
        }
    }, [routeState])
    // console.log({vals})
    useEffect(() => {
        if ((vals.activeStep === 2 && registeredTrekker)) {
            if (registeredTrekker.UserRegistrationModel) {
                Object.keys(registeredTrekker.UserRegistrationModel).map(item => {
                    const val = registeredTrekker.UserRegistrationModel[item]
                    if (item === 'TrekkingAreaId') {
                        const ro = routes && routes.find(re => re.value == val)
                        setValue(item, ro)
                        return
                    }
                    if (item === 'PorterGuideName') {
                        const guid = guides && guides.find(re => re.value == val)
                        setValue(item, guid)
                        return
                    }
                    setValue(item, val)
                })
            }
        }
    }, [vals]);

    // useEffect(() => {
    //     if (PorterGuideName && PorterGuideName.value) {
    //         dispatch(getGuide(PorterGuideName.value))
    //     }
    // }, [PorterGuideName]);
    useEffect(() => {
        if (routeState && routeState?.routeId) {
            dispatch(getGuide(routeState?.routeId))
            const guide = guides && guides.find(item => item.value === routeState?.routeId)
            setValue('PorterGuideName', guide)
        }
    }, [routeState])

    useEffect(() => {
        if (guide) {
            setValue("ContactNofo", guide?.ContactNofo)
            setValue("LicenseNumber", guide?.LicenseNumber)
        }
    }, [guide])

    useEffect(() => {
        if (route) {
            if (route.RouteDto) {
                setValue("TrekEntryPoint", route?.RouteDto?.StartPoint)
                setValue("TrekExitPoint", route?.RouteDto?.EndPoint)
            }
            if (route.RouteDetail) {
                setValue("TrekRoute", route.RouteDetail.map(item => item.Route).join(","))
            }
        }
    }, [route])
    const onSaveNext = (data) => {
        console.log("Next",{data})

        vals.setActiveStepper(StepperEnums.review);
        if(data?.RegistrationId){
            history.push(RouteEnum.NewRegister+ '/' +data?.RegistrationId)
        }
    }
    const onSubmit = async (data) => {
        if(isEdit){
            const id = registeredTrekker.UserRegistrationModel.RegistrationId;
            const finalEdit = {
                userRegistrationModel: {
                    TrekkingAreaId: data.TrekkingAreaId.value,
                    TrekExitDate: data.TrekExitDate,
                    TrekEntryPoint: data.TrekEntryPoint,
                    TrekExitPoint: data.TrekExitPoint,
                    TrekEntryDate: data.TrekEntryDate,
                    TrekRoute: data.TrekRoute,
                    RegistrationId: id,
                    PorterGuideInfo: data.PorterGuideInfo,
                    PorterGuideContact: data.PorterGuideContact,
                    PorterGuideLicesenceNo: data.PorterGuideLicesenceNo,
                    OtherSupportStaffs: data.OtherSupportStaffs
                }
            }
            await dispatch(updateTrekkingDetails(finalEdit));
            await setEditable(prev => false);
            return 
        }
        const formData = new FormData();
        formData.append('UserRegistrationModel.TrekEntryDate', data?.TrekEntryDate)
        formData.append('UserRegistrationModel.TrekExitDate', data?.TrekExitDate)
        formData.append('UserRegistrationModel.TrekkingAreaId', data?.TrekkingAreaId?.value)
        formData.append('UserRegistrationModel.TrekRoute', data?.TrekRoute)
        formData.append('UserRegistrationModel.TrekEntryPoint', data?.TrekEntryPoint)
        formData.append('UserRegistrationModel.TrekExitPoint', data?.TrekExitPoint)
        formData.append('UserRegistrationModel.PorterGuideInfo', data?.PorterGuideInfo)
        formData.append('UserRegistrationModel.PorterGuideLicesenceNo', data?.PorterGuideLicesenceNo)
        formData.append('UserRegistrationModel.PorterGuideContact', data?.PorterGuideContact)
        formData.append('UserRegistrationModel.OtherSupportStaffs', data?.OtherSupportStaffs)
        
        const res = dispatch(registerNewTrekker(formData, onSaveNext));
        if(res){

        }
    }
    const isDisabled = disabled && !isEditable;
    const isCheck = user.UserType === userEnums.trekkingAgency;
    return (
        <FormGroup onSubmit={onSubmit} method={method}  >
            <div className="pv-md">
                <Container maxWidth={'max-content'}  >
                    <div className="space-between">
                        <Heading>
                            Trek Details
                        </Heading>
                        <div className="flex">
                            {isEdit && <Button bg="primary" title={isEditable ? "Done" : "Edit"}
                            htmlButtonType={isEditable ? "submit" : "button"} 
                            // htmlButtonType="button"
                            onClick={(e) =>{
                               if(!isEditable){
                                e.preventDefault();
                                setEditable(prev=>!prev)
                                return;
                               }else{
                                // handleSubmit(onSubmit)
                               }
                            }} buttonType="icon-button" icon={<Icons name="EditOutline" />} />}
                            {isEditable && <Button bg="grey" title={"Cancel"} onClick={() => setEditable(false)} />}
                        </div>
                    </div>

                    <div className="form-group pt-sm">
                        <div className="form-row">
                            <Form
                                name="TrekkingAreaId"
                                // options={[{label:'Something',value:0}]}
                                options={routes}
                                width="400px"
                                formName="reactSelect"
                                placeholder="Trekking Area"
                                label="Trekking Area"
                                className="required"
                                required={true}
                                isSearchable={true}
                                disabled={isDisabled}
                            />
                            <Form
                                name="TrekEntryDate"
                                formName="dateinput"
                                // type="date"
                                width="200px"
                                placeholder="Trek entry date"
                                label="Trek entry date"
                                className="required"
                                required={true}
                                disabled={isDisabled}
                            />
                            <Form
                                name="TrekExitDate"
                                formName="dateinput"
                                width="200px"
                                placeholder="Trek exit date"
                                label="Trek exit date"
                                className="required"
                                validate={(value) => compareDates(watch("TrekEntryDate"), value) || "Trek Exit Date can not be less than Entry Date"}
                                required={true}
                                disabled={isDisabled || !trekEntryDate}
                            />
                        </div>
                        <div className="form-row">
                            <Form
                                name="TrekRoute"
                                width="400px"
                                formName="textarea"
                                placeholder="Route of trekking"
                                label="Route of trekking"
                                // required={true}
                                // disabled={true}
                                disabled={isDisabled}
                            />
                            <Form
                                name="TrekEntryPoint"
                                placeholder="Trek entery point"
                                label="Trek entry point"
                                // required={true}
                                width="200px"
                                // disabled={true}
                                disabled={isDisabled}
                            />
                            <Form
                                name="TrekExitPoint"
                                placeholder="Trek exit point"
                                label="Trek exit point"
                                // required={true}
                                width="200px"
                                // disabled={true}
                                disabled={isDisabled}
                            />

                        </div>
                        <div className="form-row">
                            {
                                isCheck && (
                                    <>
                                        <Form
                                            name="PorterGuideInfo"
                                            // type="number"
                                            placeholder="Guide Name"
                                            label="Guide Name"
                                            className="required"
                                            required={true}
                                            disabled={isDisabled}
                                            width={"400px"}
                                        />
                                        <Form
                                            name="PorterGuideLicesenceNo"
                                            // type="number"
                                            placeholder="Guide ID"
                                            label="Guide ID"
                                            className="required"
                                            required={true}
                                            disabled={isDisabled}
                                            width={"200px"}
                                        />
                                        <Form
                                            name="PorterGuideContact"
                                            type="number"
                                            placeholder="Guide Contact No."
                                            label="Guide Contact No."
                                            className="required"
                                            required={true}
                                            disabled={isDisabled}
                                            width={"200px"}
                                        />

                                    </>
                                )
                            }
                        </div>
                        <div className="form-row">
                            {
                                isCheck && (
                                    <Form
                                            name="OtherSupportStaffs"
                                            width="400px"
                                            formName="textarea"
                                            placeholder="Other support staffs"
                                            label="Other support staffs"
                                            disabled={isDisabled}
                                        />
                                )
                            }
                        </div>
                    </div>

                </Container>
                {!isEdit && <div className='space-between mt-md center'>
                    <div></div>
                    <Button 
                        bg="primary"
                        //  type="button-outline" 
                        htmlButtonType={"submit"} 
                        buttonType="icon-button" 
                        title={TrekkerLoading ? "Updating..." :"Next" }
                        size="large" 
                        isDisabled={TrekkerLoading}
                        rightIcon={<IconsN type="iconify" dataIcon="bi:arrow-right" />} 
                    />
                </div>}
            </div>
        </FormGroup>
    )
}

export default TrekForm