import React, { useEffect } from 'react'
import Form from 'components/form';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { uploadImage,updateName } from 'store/actions/auth';
import './styles.scss'
import { useForm, useWatch } from 'react-hook-form';
import Avatar from 'react-avatar';
import {onlyText,emailValid,onlyNum} from 'constants/regex';

function ChangeAccount(props) {
    const dispatch = useDispatch()
    const {user} = useSelector((state:RootStateTypes)=>state.userReducer)
    const methods = useForm();
    const {setValue,register,control:ctr} = props.method;
    const {control,} = methods;
    const image = useWatch({control,name:"profile_image"})
    // const onSubmit = (data) => {
    //     const nameData = {
    //         fullName: data.fullName,
    //         userId:user.Id
    //     }
    //     dispatch(updateName(nameData,true))
    // }
    // const onUpload = (data) =>{
    //     const fd = new FormData();
    //     fd.append('Files', data.profile_image);
    //     fd.append('UserId',user.UserID);
    //     dispatch(uploadImage(fd,true))
    //     props.onModalClose()
    //     // uploadImage
    // }
    useEffect(()=>{
        if(user && user?.FullName){
            setValue("fullName",user?.FullName)
        }
    },[user,setValue])
    return (
        <div className="account-profile">
            <div className="account-settings-form account-profile__body">
            {/* <div className="account-profile__upload-container">
                    <div className="account-profile__upload-avatar">
                        <Avatar
                            name={user?.FullName}
                            size={"80px"}
                            round
                            src={image ? URL.createObjectURL(image) : user?.UserImage}
                        />
                    </div>
                    <div className="account-profile__upload-right">
                        {image && <span
                            title="Remove"
                            className="account-profile__upload-remove"
                            onClick={() => setValue("profile_image", null)}
                        >
                            Remove
                        </span>
                        }
                        <div className="account-profile__upload">
                            <Form
                                name="profile_image"
                                formName="uploadInput"
                                btnText={image ? "Change Picture" : "Add Picture"}
                                control={ctr}
                                noImageShow
                            />
                        </div>
                    </div>

                </div> */}
                {/* <FormGroup onSubmit={onSubmit}> */}
                    <div className="account-profile__details">
                        <div className="account-profile__row">
                            <Form
                               name="fullName"
                               label="Full Name"
                               register={register}
                            //    validate={(val)=> !onlyText(val) || 'Only text allowed'}
                               control={ctr}
                               required={true}
                            ></Form>
                        </div>
                    </div>
                    {/* <div className="account-profile__button">
                        <Button
                            icon={<Icons name="Save" size={16} />}
                            bg="primary" 
                            // type="button-outline" 
                            buttonType="icon-button"
                            title="Save Changes"
                            htmlButtonType="submit"

                        />
                        <Button
                            bg="cancel"
                            title="Cancel"
                            // type="button-outline"
                            onClick={()=>props.onModalClose()}
                        />
                    </div> */}
                {/* </FormGroup> */}
            </div>
        </div>
    )
}

export default ChangeAccount
