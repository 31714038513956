import https from 'services/http';
import Action from 'services/actionUtility';
import environment from 'environment';
import types from 'constants/types';
import RouteEnum from 'constants/routesEnum';
import getQueryUrl from 'helpers/getQueryUrl';



export const getUsers = (page):any => async dispatch =>{
    const finalUrl = getQueryUrl(environment.api.getUsers,page)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
        type:'get',
		actionType:types.GET_USERS,
        message:'',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}



export const addUsers = (data,callbackFunc=null):any => async dispatch =>{
    const res = https.post(environment.api.addUser,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_USER,
        message:'User Added Successfully',
		effect:res,
        isLoading:true,
        type:'post',
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
        callbackFunc && callbackFunc()
    }
}

export const updateUsers = (data):any => async dispatch =>{
    const res = https.put(environment.api.updateUser,data)
    const actionConfig = {
		dispatch,
        type:'put',
		actionType:types.UPDATE_ADMIN_USER,
        message:'User Updated Successfully',
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const deleteUsers = (id,history=null):any => async dispatch =>{
    const res = https.delete(environment.api.deleteUser+id)
    const actionConfig = {
		dispatch,
        type:'delete',
		actionType:types.DELETE_USER,
        message:'User Deleted Successfully',
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        if(history){
            history.push(RouteEnum.Users)
        }
        // history.push(RouteEnum.Login)
    }
}


export const disableUsers = (data,status):any => async dispatch =>{
    const res = https.put(environment.api.disableUser,data)
    const actionConfig = {
		dispatch,
        type:'put',
		actionType:types.APPROVE_DISABLE_USER,
        message:status ? 'User Enabled Successfully' : 'User Disabled Successfully',
		effect:res,
        isLoading:true,
        extraPayload:{...data,Status:status ? 'Active' : 'Disabled' }
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const approveUsers = (id):any => async dispatch =>{
    const res = https.put(environment.api.approveUser+"?userId="+id,)
    const actionConfig = {
		dispatch,
        type:'put',
		actionType:types.APPROVE_DISABLE_USER,
        message:'User Approved Successfully',
		effect:res,
        extraPayload:{UserId:id,Status:'Active'}
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const getUser = (id) =>async dispatch =>{
    const res = https.get(environment.api.getTableUser + id,);
    const actionConfig = {
      dispatch,
      actionType: types.GET_TAB_USER,
      message: "",
    //   type:'get',
      effect: res,
    };
    const response = await Action.createThunkEffect(actionConfig);
    if (response && response.Status) {
      // history.push(RouteEnum.Login)
    }
}


export const getTrekkingAgencyUser = (id) =>async dispatch =>{
    const finalUrl = getQueryUrl(environment.api.getAgencyUser,id)
    const res = https.get(finalUrl);
    const actionConfig = {
      dispatch,
      actionType: types.GET_AGENCY_USER,
      message: "",
      type:'get',
      effect: res,
    };
    const response = await Action.createThunkEffect(actionConfig);
    if (response && response.Status) {
      // history.push(RouteEnum.Login)
    }
  }
  