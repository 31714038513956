import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as Add } from "assets/icons/add.svg";
import { ReactComponent as Agency } from "assets/icons/agency.svg";
import { ReactComponent as MinusCircle } from "assets/icons/minus-circle.svg";
import { ReactComponent as Billing } from "assets/icons/billing.svg";
import { ReactComponent as DashboardAlt } from "assets/icons/dashboard_alt.svg";
import { ReactComponent as Collapse } from "assets/icons/collapse.svg";
import { ReactComponent as Camera } from "assets/icons/camera.svg";
import { ReactComponent as Register } from "assets/icons/register.svg";
import { ReactComponent as EditOutline } from "assets/icons/Edit-outline.svg";
import { ReactComponent as Logout } from "assets/icons/logout.svg";
import { ReactComponent as Scan } from "assets/icons/scan.svg";
import { ReactComponent as TAAN } from "assets/icons/taan.svg";
import { ReactComponent as TimsCard } from "assets/icons/tims_card.svg";
import { ReactComponent as TrekkingHistory } from "assets/icons/trekking_history.svg";
import { ReactComponent as UsersCouple } from "assets/icons/users_couple.svg";
import { ReactComponent as AddCircle } from "assets/icons/add-circle.svg";
import { ReactComponent as BellIcon } from "assets/icons/bell.svg";
import { ReactComponent as CardIcon } from "assets/icons/card.svg";
import { ReactComponent as HistoryIcon } from "assets/icons/history.svg";
import { ReactComponent as RoutesIcon } from "assets/icons/routes.svg";
import { ReactComponent as TrekkersIcon } from "assets/icons/trekkers.svg";
import { ReactComponent as Trekker } from "assets/icons/trekker.svg";
import { ReactComponent as Trekkers2Icon } from "assets/icons/trekkers2.svg";
import { ReactComponent as CaretUpIcon } from "assets/icons/caret_up.svg";
import { ReactComponent as CaretDownIcon } from "assets/icons/caret_down.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload_icon.svg";
import { ReactComponent as Upload } from "assets/icons/upload.svg";
import { ReactComponent as CreditCardIcon } from "assets/icons/credit_card.svg";
import { ReactComponent as PaymentIcon } from "assets/icons/payment_gateway.svg";
import { ReactComponent as ReasonIcon } from "assets/icons/reason.svg";
import { ReactComponent as UserAddIcon } from "assets/icons/user_add.svg";
import { ReactComponent as UserAddAlt } from "assets/icons/user_add_alt.svg";
import { ReactComponent as Users } from "assets/icons/users.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import { ReactComponent as Dashboard } from "assets/icons/dashboard.svg";
import { ReactComponent as Warning } from "assets/icons/warning.svg";
import { ReactComponent as Download } from "assets/icons/download.svg";
import { ReactComponent as Eye } from "assets/icons/eye.svg";
import { ReactComponent as Re } from "assets/icons/re.svg";
import { ReactComponent as Mail } from "assets/icons/mail.svg";
import { ReactComponent as Filter } from "assets/icons/filter.svg";
import { ReactComponent as Calendar } from "assets/icons/calendar.svg";
import { ReactComponent as Prints } from "assets/icons/prints.svg";
import { ReactComponent as DownloadRegular } from "assets/icons/downloadRegular.svg";
import { ReactComponent as UploadFiles } from "assets/icons/uploadFiles.svg";
import { ReactComponent as Remarks } from "assets/icons/remarks.svg";
import { ReactComponent as TimCard } from "assets/icons/timCard.svg";
import { ReactComponent as MobilePay } from "assets/icons/mobilePay.svg";
import { ReactComponent as Setting } from "assets/icons/Setting.svg";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { ReactComponent as Burger } from "assets/icons/burger.svg";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as User } from "assets/icons/user.svg";
import { ReactComponent as Lock } from "assets/icons/lock.svg";
import { ReactComponent as EyeClosed } from "assets/icons/eyeslash.svg";
import { ReactComponent as ArrowDown } from "assets/icons/arrowDown.svg";
import { ReactComponent as Location } from "assets/icons/location.svg";
import { ReactComponent as ChevronDown } from "assets/icons/chevron-down.svg";
import { ReactComponent as Login } from "assets/icons/logout.svg";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Save } from "assets/icons/save.svg";
import { ReactComponent as Copy } from "assets/icons/copy.svg";
import { ReactComponent as UserAlt } from "assets/icons/user_alt.svg";
import { ReactComponent as CalendarAlt } from "assets/icons/calendar_alt.svg";
import { ReactComponent as Down } from "assets/icons/down.svg";
import { ReactComponent as Print } from "assets/icons/print.svg";
import { ReactComponent as Times } from "assets/icons/times.svg";
import { ReactComponent as Check } from "assets/icons/check.svg";
import { ReactComponent as Gmail } from "assets/icons/gmail.svg";
import { ReactComponent as Webmail } from "assets/icons/webmail.svg";
import { ReactComponent as CardAlt } from "assets/icons/card_alt.svg";
import { ReactComponent as List } from "assets/icons/list.svg";
import { ReactComponent as Info } from "assets/icons/info.svg";
import { ReactComponent as Profit } from "assets/icons/profit.svg";
import { ReactComponent as Error } from "assets/icons/error.svg";
import { ReactComponent as CheckCircle } from "assets/icons/checkCircle.svg";


import { ReactComponent as Sd_Dashboard } from "assets/icons/sidebar/Dashboard.svg";
import { ReactComponent as Sd_Invoice } from "assets/icons/sidebar/invoice.svg";
import { ReactComponent as Sd_Request } from "assets/icons/sidebar/request.svg";
import { ReactComponent as Sd_Route } from "assets/icons/sidebar/Route.svg";
import { ReactComponent as Sd_TimsCard } from "assets/icons/sidebar/tims card.svg";
import { ReactComponent as Sd_Trekker } from "assets/icons/sidebar/trekker.svg";
import { ReactComponent as Sd_Trekking_History } from "assets/icons/sidebar/Trekking History.svg";
import { ReactComponent as Sd_Users } from "assets/icons/sidebar/Users.svg";
import { ReactComponent as Sd_Scan } from "assets/icons/sidebar/scan.svg";
import { ReactComponent as Sd_AddUser } from "assets/icons/sidebar/add-user.svg";
import { ReactComponent as Sd_Trekkers2Icon } from "assets/icons/trekkers2.svg";

interface SvgIconsProps {
  name: 'Bell' |
  'Sd_Dashboard' |
  'Info' |
  'Profit' |
  'Sd_Invoice' |
  'Sd_Request' |
  'Sd_Route' |
  'Sd_AddUser' |
  'Sd_TimsCard' |
  'Sd_Trekker' |
  'Sd_Trekkers2Icon' |
  'Sd_Scan' |
  'TrekkersIcon' |
  'Error' |
  'Sd_Trekking_History' |
  'Re' |
  'Sd_Users' |
  'Print' |
  'DownloadRegular' |
  'User' |
  'Down' |
  'CheckCircle' |
  'Lock' |
  'Gmail' |
  'Webmail' |
  'List' |
  'Cog' |
  'EyeClosed' |
  'UploadFiles' |
  'ChevronDown' |
  'Login' |
  'Remarks' |
  'TimCard' |
  'CalendarAlt' |
  'UserAlt' |
  'Check' |
  'MobilePay' |
  'CardAlt' |
  'Menu' |
  "Copy" |
  'Setting' |
  'Save' |
  'UserAddAlt' |
  'Add' |
  'EditOutline' |
  'Agency' |
  'Billing' |
  'DashboardAlt' |
  'Collapse' |
  'Location' |
  'Register' |
  'MinusCircle' |
  'Logout' |
  'Scan' |
  'TAAN' |
  'TimsCard' |
  'Trekker' |
  'Camera' |
  'ArrowDown' |
  'TrekkingHistory' |
  'UsersCouple' |
  'AddCircle' |
  'History' |
  'Trekkers2' |
  'Dashboard' |
  'Card' |
  'Users' |
  'Trash' |
  'Edit' |
  'UserAdd' |
  'Reason' |
  'Routes' |
  'Trekkers' |
  'CaretDown' |
  'CaretUp' |
  'UploadIcon' |
  'Upload' |
  'CreditCardIcon' |
  'Warning' |
  'Download' |
  'Eye' |
  'Mail' |
  'Filter' |
  'Calendar' |
  'Close' |
  'Times' |
  'Burger' |
  'PaymentIcon';
  size?: number;
  color?: string;
  className?: string;
  styles?: object;
  justSVG?: boolean;
  stroke?: string;
  onClick?: any;
}

function getIconComponent(name) {

  switch (name) {
    case "Sd_Dashboard":
      return Sd_Dashboard;
    case "Info":
      return Info;
    case "Re":
      return Re;
    case "Profit":
      return Profit;
    case "Sd_Scan":
      return Sd_Scan;
    case "Error":
      return Error;
    case "Sd_AddUser":
      return Sd_AddUser;
    case "Sd_Invoice":
      return Sd_Invoice;
    case "Sd_Request":
      return Sd_Request;
    case "Sd_Route":
      return Sd_Route;
    case "Sd_TimsCard":
      return Sd_TimsCard;
    case "Sd_Trekker":
          return Sd_Trekker;
      case "Sd_Trekkers2Icon":
          return Sd_Trekkers2Icon;
    case "CheckCircle":
      return CheckCircle
    case "Sd_Trekking_History":
      return Sd_Trekking_History;
    case "Sd_Users":
      return Sd_Users;
    case "ArrowDown":
      return ArrowDown;
    case "Print":
      return Print;
    case 'CardAlt':
      return CardAlt
    case "Check":
      return Check;
    case "List":
      return List;
    case "Times":
      return Times;
    case "ChevronDown":
      return ChevronDown;
    case "UserAlt":
      return UserAlt;
    case "EditOutline":
      return EditOutline;
    case "Webmail":
      return Webmail;
    case "Gmail":
      return Gmail
    case "Down":
      return Down;
    case "User":
      return User;
    case "Copy":
      return Copy;
    case "Menu":
      return Menu;
    case "UserAddAlt":
      return UserAddAlt;
    case "Location":
      return Location;
    case "Lock":
      return Lock;
    case "Save":
      return Save;
    case "EyeClosed":
      return EyeClosed;
    case "Bell":
      return BellIcon;
    case "CalendarAlt":
      return CalendarAlt;
    case "Cog":
      return Cog;
    case "Add":
      return Add;
    case "Login":
      return Login;
    case "Agency":
      return Agency;
    case "Billing":
      return Billing;
    case "Calendar":
      return Calendar;
    case "DashboardAlt":
      return DashboardAlt;
    case "Collapse":
      return Collapse;
    case "Register":
      return Register;
    case "MinusCircle":
      return MinusCircle; 
    case "Logout":
      return Logout;
    case 'Camera':
      return Camera;
    case "Scan":
      return Scan;
    case "TAAN":
      return TAAN;
    case "TimsCard":
      return TimsCard;
    case "Trekker":
      return Trekker;
    case "TrekkingHistory":
      return TrekkingHistory;
    case "UsersCouple":
      return UsersCouple;
    case "AddCircle":
      return AddCircle;
    case "Card":
      return CardIcon;
    case "Burger":
      return Burger;
    case "History":
      return HistoryIcon;
    case "Routes":
      return RoutesIcon;
      case "TrekkersIcon":
      return TrekkersIcon;
    case "Trekkers2":
      return Trekkers2Icon;
    case "CaretUp":
      return CaretUpIcon;
    case "CaretDown":
      return CaretDownIcon;
    case "UploadIcon":
      return UploadIcon;
    case 'PaymentIcon':
      return PaymentIcon;
    case 'CreditCardIcon':
      return CreditCardIcon;
    case 'Reason':
      return ReasonIcon;
    case 'Upload':
      return Upload;
    case 'UserAdd':
      return UserAddIcon;
    case 'Users':
      return Users;
    case 'Trash':
      return Trash;
    case 'Edit':
      return Edit;
    case 'Dashboard':
      return Dashboard;
    case 'Warning':
      return Warning;
    case 'Download':
      return Download;
    case 'Eye':
      return Eye;
    case 'Mail':
      return Mail;
    case 'Filter':
      return Filter;
    case 'Prints':
      return Prints;
    case 'DownloadRegular':
      return DownloadRegular;
    case 'UploadFiles':
      return UploadFiles;
    case 'Remarks':
      return Remarks;
    case 'TimCard':
      return TimCard;
    case 'MobilePay':
      return MobilePay;
    case "Setting":
      return Setting;
    case 'Close':
      return Close;
    default:
      return null;
  }
}


const SvgIconed = (props: SvgIconsProps) => {
  const { name, size, color, className, styles, onClick, stroke } = props;

  if (!name) return null;

  let ReactIcon = getIconComponent(name);
  const finalStyles = {
    // display: "flex",
    fontSize: size,
    width: size,
    height: size,
    color: color,
    fill: color,
    stroke: stroke,
    '& path': { fill: color },
    ...styles,
  };
  return (
    <ReactIcon
      style={finalStyles}
      className={className}
      onClick={(event) => {
        onClick && onClick(event);
      }}
    />
  );
}

SvgIconed.defaultProps = {
  name: "",
  size: 20,
  color: "",
  className: "",
  styles: "",
};

export default SvgIconed;
