import React from 'react'
import { useSelector } from 'react-redux'
import { RootStateTypes } from 'ts/reducer'
import CommonImageModal from 'components/common/commonImageModal';

function Documents() {
    const { agencyUser } = useSelector((state: RootStateTypes) => state.userReducer)
    return (
        <div className="request-documents">
            <div className="request-documents__container">
                {
                    agencyUser && agencyUser.Documents && agencyUser.Documents.map((item,key)=>(
                        <div key={"Doc-"+key}  className="request-documents__doc">
                            <div className="request-documents__doc-img">
                                <CommonImageModal src={'/'+item.ImagePath}  alt={item.DocumentType} />
                            </div>
                            <div className="request-documents__doc-details">
                                <div>{item.DocumentType}</div>
                                <span>{item.CreatedOn}</span>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Documents
