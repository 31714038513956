import https from 'services/http';
import Action from 'services/actionUtility';
import HttpErrorResponseModel from 'services/httpErrorResponseModel';
import environment from 'environment';
import types from 'constants/types';


export const getGender = ():any => async dispatch =>{
    const res = https.get(environment.api.gender)
    const actionConfig = {
		dispatch,
		actionType:types.GET_GENDER,
        message:'',
        isSelect:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const getPaymentMethods = ():any => async dispatch =>{
    const res = https.get(environment.api.getPaymentMethods)
    const actionConfig = {
		dispatch,
		actionType:types.GET_PAYMENT_METHODS,
        message:'',
        isSelect:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}



export const getUserType = ():any => async dispatch =>{
    const res = https.get(environment.api.userType)
    const actionConfig = {
		dispatch,
		actionType:types.GET_USER_TYPES,
        message:'',
        isSelect:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if (response && response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const getUserAgentType = ():any => async dispatch =>{
    const res = https.get(environment.api.userAgentType)
    const actionConfig = {
		dispatch,
		actionType:types.GET_USER_AGENT_TYPES,
        message:'',
        isSelect:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if (response && response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const getTrekkingArea = ():any => async dispatch =>{
    const res = https.get(environment.api.trekkingArea)
    const actionConfig = {
		dispatch,
		actionType:types.GET_TREKKING_AREA,
        message:'',
        isSelect:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const getRouteDrop = ():any => async dispatch =>{
    const res = https.get(environment.api.getRouteDrop)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROUTES_DROP,
        message:'',
        isSelect:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const getCheckpoint = (routeId=null):any => async dispatch =>{
    const finalUrl = routeId ? (environment.api.getCheckpoint + '?routeId='+routeId) :environment.api.getCheckpoint 
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_CHECKPOINT,
        message:'',
        isSelect:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}



export const getCountries = ():any => async dispatch =>{
    const res:any = await https.get(environment.api.getCountries);
    const isError = res?.data instanceof HttpErrorResponseModel;
    if (res && !isError) {
        if(res?.data){
            const countries = res?.data.map(item=>({...item,value:item.CountryId,label:item.CountryName}))
            dispatch({type:types.GET_COUNTRIES,payload:countries});
        }
    }
    if (isError) {
    dispatch({ type: types.GET_ERRORS, payload: res });
    return res;
    }
}

export const getCountriesByRegion = (id):any => async dispatch =>{
    const res:any = await https.get(environment.api.getCountriesByRegion+'/'+id);
    const isError = res?.data instanceof HttpErrorResponseModel;
    if (res && !isError) {
        if(res?.data){
            const countries = res?.data.map(item=>({...item,value:item.CountryId,label:item.CountryName}))
            dispatch({type:types.GET_COUNTRIES_REGION,payload:countries});
        }
    }
    if (isError) {
    dispatch({ type: types.GET_ERRORS, payload: res });
    return res;
    }
}

export const getRegions = ():any => async dispatch =>{
    const res = https.get(environment.api.getRegion)
    const actionConfig = {
		dispatch,
		actionType:types.GET_REGION,
        message:'',
        isSelect:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const getTrekkerPersionality = ():any => async dispatch =>{
    const res = https.get(environment.api.getTrekkerPersionality)
    const actionConfig = {
		dispatch,
		actionType:types.GET_TREKKERS_PERSIONALITY,
        message:'',
        isSelect:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


// ​/v1​/register​/trekker-personality 


export const getRegisterType = (userid=null):any => async dispatch =>{
    // const res:any = await https.get(environment.api.getRegisterType);
    const res:any = await https.get(environment.api.getRegisterType+'?userid=0');
    const isError = res?.data instanceof HttpErrorResponseModel;
    if (res && !isError) {
        if(res?.data){
            const countries = res?.data.map(item=>({...item,value:item.RegisterTypeValue,label:item.RegisterType}))
            dispatch({type:types.GET_REGISTRATION_TYPE,payload:countries});
        }
    }
    if (isError) {
    dispatch({ type: types.GET_ERRORS, payload: res });
    return res;
    }
}

export const getFilterAgencyUsers = ():any => async dispatch =>{
    // const res = https.get(environment.api.getFilterAgencyUsers)
    // const actionConfig = {
	// 	dispatch,
	// 	actionType:types.GET_FILTER_AGENCY_USEER,
    //     message:'',
    //     isSelect:true,
	// 	effect:res,
	// }
	// const response = await Action.createThunkEffect(actionConfig); 
    // if(response && response.Status){
    //     // history.push(RouteEnum.Login)
    // }
    const res:any = await https.get(environment.api.getFilterAgencyUsers);
    const isError = res?.data instanceof HttpErrorResponseModel;
    if (res && !isError) {
        if(res?.data){
            const countries = res?.data.map(item=>({...item,value:item.UserId,label:item.Agency}))
            dispatch({type:types.GET_FILTER_AGENCY_USEER,payload:countries});
        }
    }
    if (isError) {
    dispatch({ type: types.GET_ERRORS, payload: res });
    return res;
    }
}


export const getFilterScannedTrekkers = (userid):any => async dispatch =>{
    const res = https.get(environment.api.getFilterScannedTrekkers+'?userid='+userid)
    const actionConfig = {
		dispatch,
		actionType:types.GET_FILTER_SCANNED_TREKKERS,
        message:'',
        isSelect:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const getGuides = (): any => async dispatch => {
    const res = https.get(environment.api.getGuides)
    const actionConfig = {
        dispatch,
        actionType: types.GET_GUIDES,
        message: '',
        isSelect: true,
        effect: res,
    }
    const response = await Action.createThunkEffect(actionConfig);
    if (response && response.Status) {
        // history.push(RouteEnum.Login)
    }
}