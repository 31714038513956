import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "../node_modules/slick-carousel/slick/slick.css"; 
import "../node_modules/slick-carousel/slick/slick-theme.css";

import store from 'store/store';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import environment from 'environment';
import { AUTH_TOKEN, EXPIRES_TIME } from 'constants/contants';
import { setAuthToken } from 'helpers/axiosInstance';
import { getUser, logoutUser, setCurrentToken, setCurrentUser } from 'store/actions/auth';
import RouteEnum from 'constants/routesEnum';



(async (window) => {
    
  const initialState = {};
  // const history = createBrowserHistory();
  const history = createBrowserHistory<any>({ basename: environment.route.baseRoute });
  // const store = rootStore(initialState, history);
  const rootEl = document.getElementById('root');
    //   if(localStorage.RefreshToken){
    //       await store.dispatch(refreshToken());
    //   }
    const token = localStorage.getItem(AUTH_TOKEN)
    // Check for Token
    if (token) {
        setAuthToken(token);
        const decoded = parseInt(localStorage.getItem(EXPIRES_TIME));
        store.dispatch(setCurrentUser(decoded));
        store.dispatch(setCurrentToken(token,decoded));
        
        const currentTime = Date.now() / 1000;
        //@ts-ignore
        await store.dispatch(getUser());
        if (decoded < currentTime) {//@ts-ignore
          await store.dispatch(logoutUser());
            history.push(RouteEnum.Login)
        }
    }
  
  const render = (Component, el) => {
      ReactDOM.render(
          <Provider store={store}>
              <Component history={history} dispatch={store.dispatch} />
          </Provider>,
          el
      );
  };
  render(App, rootEl);
})(window);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();