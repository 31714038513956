import React from "react";
import PropTypes from "prop-types";
import classnames from 'classnames';
interface Props {
  name: string,
  type: string,
  value: any,
  autoFocus: boolean,
  placeholder: string,
  disabled:boolean,
  onChange:Function,
  onKeyUp:Function,
  onBlur:Function,
  theme:string,
  label?:string,
  required?:any
  register?:any
  isCustomeRegister?:boolean
  validate?:any
};

const TextArea = (props:Props) => {
  const { name, type, value, autoFocus,register,required, disabled, placeholder,theme, isCustomeRegister=false,validate } = props;

  const handleChange = (event) => {
    const { name, value } = event.target;
    props.onChange && props.onChange(name, value);
  };

  const handleKeyUp = (event) => {
    const { name, value } = event.target;
    props.onKeyUp && props.onKeyUp(name, value);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    props.onBlur && props.onBlur(name, value);
  };
  const formClasses = classnames({
    "generic-group__form":true,
    ["generic-group__form-" + theme]:true
})
console.log({isCustomeRegister})
  return (
    <div className={formClasses}>
    <div className="generic-group__form-group">
        <textarea
          className="generic-group__form-textarea"
          placeholder={placeholder}
          value={value}
          name={name}
          disabled={disabled}
          ref={register && register ? 
            isCustomeRegister? register : 
            (register({required:required? (props?.label ? props.label : placeholder? placeholder:  '' ) + ' field is required' : false,validate:validate}))
        :null}
          autoFocus={autoFocus}
          onKeyUp={handleKeyUp}
          onBlur={handleBlur}
          onChange={handleChange}
        />
    </div>
</div>

  );
};



TextArea.defaultProps = {
  name: "",
  type: "text",
  autoFocus: false,
};

export default TextArea;
