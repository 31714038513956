export const onlyText = (text: string) => {
    return !/^[a-zA-Z ]+$/g.test(String(text));
}



export const emailValid = async (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};


export const onlyNum = (text: string, len: number) => {
    return text.length === len && /^[0-9]*$/.test(String(text));
}