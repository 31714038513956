import types from 'constants/types';
import {DashboardInitialState} from 'ts/reducer';
const initialState:DashboardInitialState ={
    dashboard:[],
    dashboardTotal:null,
    notifications:[],
    recentTrekkers:null,
    topTrekkers:null,
    topCountry:null,
}

export default function DashboardReducer(state= initialState,action):DashboardInitialState{
    switch(action.type){
        case types.GET_DASHBOARD:
			return {
				...state,
				dashboard: action.payload,
        };
        case types.GET_DASHBOARD_RECENT_TREKKERS:
			return {
				...state,
				recentTrekkers: action.payload,
        };
        case types.GET_DASHBOARD_TOP_TREKKERS:
			return {
				...state,
				topTrekkers: action.payload,
        };
        case types.GET_DASHBOARD_TOP_COUNTRY:
			return {
				...state,
				topCountry: action.payload,
        };
        case types.GET_NOTIFICATIONS:
			return {
				...state,
				notifications: action.payload ?? [],
        };
        case types.UPDATE_NOTIFICATIONS:
			return {
				...state,
				notifications: state.notifications && state.notifications.map(notif=>{
                    if(action.id === null || notif.Id === action.id){
                        return {
                            ...notif,
                            IsRead:true,
                        }
                    }
                    return notif
                }),
        };
        case types.GET_DASHBOARD_TOTAL:
            return{
                ...state,
				dashboardTotal: action.payload,
            }
        case types.RESET_STATE:{
            return initialState
        }
        default:
        return state;
    }
}


