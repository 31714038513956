import Container from 'components/layout/container';
import RouteEnum from 'constants/routesEnum';
import React, { useEffect, useState } from 'react';
import Trekkers from 'scenes/common/selfScanTrekkers'
import Breadcrumbs from 'components/breadcrumb';
import { useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { userEnums } from 'constants/contants';
import classnames from 'classnames';
import  './styles.scss';

function TrekkerRoute() {
    const breadList = [
        { title: 'Self Scan Trekkers', to: RouteEnum.SelfScanTrekkers, isCurrent: true }]
    const { user } = useSelector((state: RootStateTypes) => state.auth);
    return (
        <>
            <Breadcrumbs isHome linkList={breadList} />
            <div className="trekkers  common-container">
                <div className="routes">
                    <Container>
                        <Trekkers />
                    </Container>
                </div>
            </div>
        </>

    )
}

export default TrekkerRoute
