import React from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./privateRoute";

export function RouteNest(props:any) {
    return (
        // <Switch>
        <PrivateRoute
            exact={props.exact}
            path={props.path}
            children={props.children}
            component={props.component}
            childRef={props.forwardRef}
            isNotPrivate={props.isNotPrivate}
            name={props.name}
            {...props}
        />
        // </Switch>
        // <Route exact={props.exact} path={props.path} render={ p => <props.component {...p} children={props.children}/> } />
    );
}
