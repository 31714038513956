import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateTypes } from 'ts/reducer';
import Modal from 'components/modal';
import ChangePassword from './changePassword';
import ChangeAccount from './changeAccount';
import ChangeEmail from './changeEmail';
import { userEnums } from 'constants/contants';
import './styles.scss';
import { getTrekkingAgencyUser } from 'store/actions/userActions';
import CommonSidebar from 'components/common/commonSidebar';
import AgencyForm from './agencyForm';
import CheckForm from './checkForm';
import { useForm, useWatch } from 'react-hook-form';
import { updateAgencyDetails, updateCheckDetails, updateName, uploadImage } from 'store/actions/auth';
import { Tabs, Tab } from 'components/tabs'
import Documents from './documents';
import Button from 'components/button';
import Icons from 'components/icons/svgIcons';
import { clearErrors } from 'store/actions/auth';

const items = [
    {
        id: 1,
        title: 'Account Name',
        field: 'FullName',
    },
    {
        id: 2,
        title: 'Agency Settings',
        field: 'OrganizationName',
    },
    {
        id: 2.1,
        title: 'Checkpost Settings',
        field: 'FullName',
    },
    {
        id: 3,
        title: 'Email',
        field: 'Email',
    },
    {
        id: 4,
        title: 'Password',
        field: null,
    }
]

function AccountSettings() {
    const [open, setOpen] = useState(false);
    const [checkOpen, setCheckOpen] = useState(false);
    const [accOpen, setAccOpen] = useState(false);
    const [sideOpen, setSideOpen] = useState(false);
    const [active, setActive] = useState(null);
    const [check, setCheck] = useState(null);
    const dispatch = useDispatch();
    
    const onModalClose = () => {
        setOpen(false);
        setActive(null);
        dispatch(clearErrors())
    }
    const onSideModalClose = () => {
        setSideOpen(false);
        setActive(null);
        dispatch(clearErrors())
    }
    const onCheckSideModalClose = () => {
        setCheckOpen(false);
        setCheck(null);
        dispatch(clearErrors())
    }
    const onModalOpen = (num) => {
        setActive(num);
        setOpen(true)
    }
    const onChecknModalOpen = (num) => {
        setCheck(num);
        setCheckOpen(true)
    }
    const { user } = useSelector((state: RootStateTypes) => state.auth);
    const isAgency = userEnums.trekkingAgency === user?.UserType;
    const isCheck = userEnums.check === user?.UserType;
    useEffect(() => {
        if (user) {
            if (userEnums.trekkingAgency === user.UserType) {
                const params = {
                    userId: user.UserID
                }
                dispatch(getTrekkingAgencyUser(params))
            }
        }
    }, [user]);
    const getComponents = () => {
        switch (active) {
            // case 1:
            //     return <ChangeAccount onModalClose={onModalClose} />
            case 3:
                return <ChangeEmail onModalClose={onModalClose} />
            case 4:
                return <ChangePassword onModalClose={onModalClose} />
            default:
                return null
        }
    }
    const method = useForm();
    const { handleSubmit, control, errors, getValues,setValue } = method;
    const isComponents = Boolean(getComponents());
    const { agencyUser } = useSelector((state: RootStateTypes) => state.userReducer)
    const image = useWatch({control:control,name:"profile_image"})
    const onSubmit = async (data) => {
        data['organizationPanNumber'] = data['organizationPanNumber'];
        data['organizationName'] = data['organizationName'];
        data['userId'] = user.UserID
        const flag = await dispatch(updateAgencyDetails(data))
        if(flag){
            onSideModalClose()
        }
    }
    const onCheckSubmit = async (data) => {
        // data['organizationPanNumber'] = data['organizationPanNumber'];
        // data['organizationName'] = data['organizationName'];
        data['CheckPointID'] = data.CheckPointID.value
        data['ProvinceId'] = data.ProvinceId.value
        data['RouteID'] = data.RouteID.value
        data['UserId'] = user.UserID
        const flag = dispatch(updateCheckDetails(data))
        if(flag){
            onCheckSideModalClose()
        }
    }
    const onSave = () => {
        handleSubmit((data) => {
            onSubmit(data)
        })()
    }
    const onCheckSave = () => {
        handleSubmit((data) => {
            onCheckSubmit(data)
        },(e)=>console.log({e}))()
    }
    const onAccSave = () => {
        handleSubmit((data) => {
            
            if(data.fullName){
                const nameData = {
                    fullName: data.fullName,
                    userId:user.UserID
                }
                dispatch(updateName(nameData))
            }
            if(data.profile_image){
                const fd = new FormData();
                fd.append('Files', data.profile_image);
                fd.append('UserId',user.UserID);
                dispatch(uploadImage(fd))
                setValue("profile_image", null)
            }
            setAccOpen(false)
        },(e)=>console.log({e}))()
    }
    
    const profileComp = (
        <ul className="account-settings__list">
            {
                items.map(item => {
                    if ((isAgency || isCheck) && item.id === 1) {
                        return null;
                    }
                    if ((!isAgency)&& item.id === 2) {
                        return null;
                    }
                    if ((!isCheck)&& item.id === 2.1) {
                        return null;
                    }
                    return <li key={item.id} className="account-settings__item">
                        <div className="account-settings__label">{item.title}</div>
                        <div className="account-settings__value">
                            {isAgency ? item.field && agencyUser && agencyUser.AgencyUser[item.field] : item.field && user[item.field]}
                        </div>
                        <div className="account-settings__btn">
                            <Button
                                title="Change"
                                icon={<Icons name="Cog" size={16} />}
                                buttonType="icon-button"
                                onClick={() => item.id === 1 ? setAccOpen(true) : isAgency && item.id === 2 ? setSideOpen(true) : isCheck && item.id === 2.1 ? onChecknModalOpen(item.id) : onModalOpen(item.id)}
                                bg="primary"
                            // type="button-outline"
                            />
                            {/* <button
                                onClick={()=>isAgency&& item.id === 2 ?setSideOpen(true) :onModalOpen(item.id)}
                                >Change</button> */}
                        </div>
                    </li>
                })
            }
        </ul>
    )
    return (
        <div className="account-settings">
            <div className="account-settings__container">
                {
                    isAgency ? (
                        <Tabs isAlternative activeTab="Profile">
                            <Tab name={"Profile"} label={"Profile"} >
                                {profileComp}
                            </Tab>
                            <Tab name={"Document"} label={"Document"} >
                                <Documents />
                            </Tab>
                        </Tabs>
                    ) : (
                        profileComp
                    )
                }
                {!isAgency && !isCheck && <CommonSidebar onSave={onAccSave} title="Account Name" open={accOpen} onModalClose={()=>setAccOpen(false)}>
                    <ChangeAccount method={method} onModalClose={()=>setAccOpen(false)} />
                </CommonSidebar>}
                {isAgency && <CommonSidebar onSave={onSave} title="Update Agency Information" open={sideOpen} onModalClose={onSideModalClose}>
                    <AgencyForm method={method} />
                </CommonSidebar>}
                {isCheck && <CommonSidebar onSave={onCheckSave} title="Update Checkpost Information" open={checkOpen} onModalClose={onCheckSideModalClose}>
                    <CheckForm method={method} />
                </CommonSidebar>}
                {
                    isComponents &&
                    <Modal
                        open={open}
                        onModalClose={onModalClose}
                        width="500px"
                        className="account-settings-modal"
                        title={active === 1 ? "Change Account Name" : active === 3 ? "Change Email" : active === 4 ? "Change Password" : null}
                    >
                        {getComponents()}
                    </Modal>
                }
            </div>
        </div>
    )
}

export default AccountSettings
