import types from "constants/types";
import { SelfScanTrekkersInitialState } from "ts/reducer";

const initialState: SelfScanTrekkersInitialState = {
  trekkers: [],
  registeredTrekker: null,
  scannedTrekkers: [],
  selfScannedTrekkers: [],
  voucherTrekker:null,
  trekker: null,
  trekkingHistory: [],
  esewa: null,
  khalti: null,
  fone: null,
  ips: null,
  cardPay: null,
};

export default function TrekkerReducer(state = initialState, action): SelfScanTrekkersInitialState {
  switch (action.type) {
    case types.REGISTER_NEW_TREKKERS:
      return {
        ...state,
        trekkers: [...state.trekkers, action.payload],
        registeredTrekker: action.payload,
      };
    case types.GET_TREKKER_VOUCHER:
      return{
        ...state,
        voucherTrekker:action.payload
      }
    case types.GET_SELF_SCANNED_TREKKERS:
      return{
        ...state,
        selfScannedTrekkers:action.payload
      }
    case types.GET_TREKKER:
      return {
        ...state,
        trekker: action.payload,
        registeredTrekker: action.payload,
      };
    case types.GET_SCANNED_TREKKERS_BY_USER:
      return {
        ...state,
        scannedTrekkers: action.payload,
      };
    case types.GET_TREKKING_HISTORY:
      return {
        ...state,
        trekkingHistory: action.payload,
      };
    case types.GET_TREKKERS:
      return {
        ...state,
        trekkers: action.payload,
      };
    case types.GET_PAYMENT_ESEWA:
      return {
        ...state,
        esewa: action.payload,
      };

    case types.GET_PAYMENT_FONE:
      return {
        ...state,
        fone: action.payload,
      };

    case types.GET_PAYMENT_IPS:
      return {
        ...state,
        ips: action.payload,
      };

    case types.GET_PAYMENT_KHALTI:
      return {
        ...state,
        khalti: action.payload,
      };
    
    case types.GET_PAYMENT_CARD:
      return {
        ...state,
        cardPay: action.payload,
      };
      case types.RESET_STATE:{
        return initialState
    }
    default:
      return state;
  }
}
