import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { pageSizeValues } from 'constants/contants';
import { useForm, useWatch } from 'react-hook-form';
import Pagination from 'components/table/pagination';
import Table from 'components/table';
import Form from 'components/form';
import FormGroup from 'components/form/formGroup';
import Container from 'components/layout/container';
import RouteEnum from 'constants/routesEnum';
import { getVouchers } from 'store/actions/requestActions';
import { PaymentMethodFilter, TrekkingRouteFilter } from 'components/common/filters';
import { Actions, TrekkingStatus } from 'components/table/commonTableComponents';
import Icons from 'components/icons/svgIcons';
import CommonSidebar from 'components/common/commonSidebar';
import VoucherDetails from './voucherDetails';
import ResetFilter from 'components/common/resetFilter';
import DownloadAllCsv from 'components/common/downloadAllCsv';
import environment from 'environment';
import { getCard } from 'store/actions/cardActions';

// const ActionCells = ({value,row,setFlag,...props}) =>{
//     const [open,setOpen] = useState(false);
//     const dispatch = useDispatch()
//     const onModalClose = (val=false) =>{
//         if(val){
//             setFlag(true);
//         }
//         setOpen(false);
//     }
//     const onOpen = async () =>{
        
//         // const voucher = row.original;
//         // if(voucher && voucher?.RegistrationId){
//         //     await dispatch(getCard(voucher?.RegistrationId))
//         // }
//         setOpen(true);
//     }
//     return(
//         <div className="table-actions">
//             <div 
//                 onClick={()=>onOpen()}
//                 className="table-actions__icon"><Icons name="Eye" /></div>
//             <div className="table-actions__icon"><Icons name="Download" /></div>
//             {/* <div className="table-actions__icon"><Icons name="Mail" /></div> */}
//             {/* <Modal width="675px" onModalClose={onModalClose} open={open}>
//                 <Invoice />
//             </Modal> */}
//             {
//                 open && (
//                     <CommonSidebar title="Voucher" open={open} onModalClose={onModalClose}>
//                         <VoucherDetails voucher={row.original} onModalClose={onModalClose} />
//                     </CommonSidebar>  
//                 )
//             }

//         </div>
//     )
// }

const Province = ({value}) =>{
    const { paymentMethods } = useSelector((state: RootStateTypes) => state.selectReducer)
    const provinceName = paymentMethods && value && paymentMethods.find(item=>item.value == value)
    return(
        <div className="">
            {
                provinceName && provinceName.label
            }
        </div>
    )
}
const csvheaders = [
    { label: "Invoice ID", key: "InvoceNumber" },
    { label: "Registrar Agency", key: "TrekkerName" },
    { label: "Trekking Route", key: "TrekRoute" },
    { label: "Payment Medium", key: "PymentMethod" },
    { label: "Amount", key: "Amount" },
    { label: "Payment Date", key: "PaymmentDate" },
]


const pageState = {
    pageindex:0,
    pageSize:pageSizeValues[0].value,
    totalRows:0,
}
const filterState = {
    trekkingArea:null,
    paymethod:null,
}
function InvoiceTable() {
    const dispatch = useDispatch();
    const [page, setPage] = useState(pageState)
    // const [data,setData] = useState([]);
    const {control,register} = useForm();
    const [filter, setFilter] = useState(filterState)
    const [flag, setFlag] = useState(false)
    const [data,setData] = useState([]);
    const onPageChange = async (page) =>await setPage(prev=>({...prev,pageindex:page}))
    const pageSize:any = useWatch({ control, name: 'pageSize' });
    const {vouchers} =useSelector((state:RootStateTypes)=>state.requestReducer)
    const search:any = useWatch({ control, name: 'search' });
    useEffect(()=>{
        if(vouchers && vouchers?.VoucherData){
            setData(vouchers?.VoucherData)
            const totalRows = vouchers ? vouchers?.VoucherData[0]?.TotalRows : 0;
            vouchers?.VoucherData && setPage(prev=>({...prev}))
            vouchers?.VoucherData && setPage(prev=>({...prev,totalRows:totalRows}))
        }
    },[vouchers]);
    const onFilter = async (items) => {
        await setFilter(prev=>({...filter,...items}))
        // getUsersDetails()
    }  
    const [open,setOpen] = useState(false);
    const [voucher,setVoucher] = useState(null);
    const onModalClose = (val=false) =>{
        if(val){
            setFlag(true);
        }
        setOpen(false);
        setVoucher(null)
    }
    const onRowClick = async (row)=>{
        const voucher = row.original;
        if(voucher && voucher?.RegistrationId){
            setVoucher(voucher)
            setOpen(true)
            // await dispatch(getCard(voucher?.RegistrationId,voucher?.TrekkerId))
        }
    }
    // useEffect(()=>{ 
    //     getVouchersFront()
    // },[]);
    
    useEffect(()=>{
        getVouchersFront()
    },[page.pageSize,page.pageindex]);
    useEffect(()=>{
        getVouchersFront()
    },[filter]) 
    // useEffect(()=>{
    //     if(flag){
    //         getVouchersFront()
    //         setFlag(false)
    //     }
    // },[flag])
    const getVouchersFront = () =>{
        dispatch(getVouchers({...page,...filter}))
    }
    useEffect(() => {
        if(pageSize && pageSize.value) {
          setPage(prev=>({...prev,pageSize:pageSize.value}));
        }
    }, [pageSize])
    const columns = [
        {
            Header: 'Invoice ID',
            accessor: 'InvoceNumber',
            width: 140,
        },
        {
            Header: 'Status',
            accessor: 'Status',
            width: 140,
            Cell: (props) => <TrekkingStatus {...props} />,
        }, 
        //{
        //    Header: 'Registrar Agency',
        //    accessor: 'RegisterAgency',
        //    width: 180,
        //    isSort:true,
        //},
        {
            Header: 'Agency',
            accessor: 'TrekkerName',
            width: 200,
            isSort: true,
        },
        {
            Header: 'Trekking Route',
            accessor: 'TrekRoute',
            Filter:({filter:fil,onchange}) =><TrekkingRouteFilter trekkingArea={filter?.trekkingArea} {...filter} onFilter={onFilter} />,
            width: 280,
            isSort:true,
        },
        //{
        //    Header: 'Payment Medium',
        //    accessor: 'PymentMethod',
        //    Filter:({filter:filt,onchange}) =><PaymentMethodFilter paymethod={filter?.paymethod} {...filter} onFilter={onFilter} />,
        //    width: 220,
        //    Cell:Province,
        //    isSort:true,
        //},
        {
            Header: 'Amount',
            accessor: 'Amount',
            width: 100,
            isSort:true,
        },
        {
            Header: 'Payment Date',
            accessor: 'PaymmentDate',
            width: 200,
            isSort:true,
        },
            
        
        // {
        //     Header: 'Actions',
        //     accessor: 'RegistrationId',
        //     Cell:(props) =><ActionCells {...props} setFlag={setFlag} />,
        //     width: 200,
        //     isSort:true,
        // },
    ];
    // useEffect(() => {
    //     if(search){
    //         dispatch(getVouchers({...page,name:search}))
    //     }
    // }, [search])

    const getSearch = () => {
        dispatch(getVouchers({...page,name:search ?? ""}))
    }
    
    const onClearFilters = () => setFilter(filterState)
    const onCallBack = () =>{
        getVouchersFront()
        onModalClose();
    }
    return (
        <div className="request-table">
            <Container>
                    <div className="space-between search-alt">
                        <Form 
                            formName="reactSelect"
                            control={control}
                            name="pageSize"
                            value={pageSizeValues[0]}
                            defaultValue={pageSizeValues[0]}
                            className="page-nums"
                            width="175px"
                            placeholder="Choose..."
                            options={pageSizeValues}
                        />
                        <div className="flex">
                            
                            <ResetFilter onClearFilters={onClearFilters} filters={filter} />
                            <DownloadAllCsv
                                api={environment.api.getVoucher}
                                headers={csvheaders}
                                extraParams={{ ...filter }}
                                fileName="Invoice"
                                dataField="VoucherData"
                                isData={false}
                            />
                            <Form 
                                register={register}
                                name="search"
                                width="300px"
                                placeholder="Search..."
                                formName="searchInput"
                                onKeyUp={(name,value,e)=>{
                                    if(e?.keyCode === 13){
                                        getSearch()
                                    }
                                }}
                            />
                        </div>
                        
                    </div>
                <div className="routes-table">
                    <Table onRowClick={onRowClick} columns={columns} data={data} />
                    <Pagination onPageChange={onPageChange} {...page} totalRows={page.totalRows} />
                </div>
                <CommonSidebar title="Voucher" open={open} onModalClose={onModalClose}>
                    <VoucherDetails callback={onCallBack} voucher={voucher} onModalClose={onModalClose} />
                </CommonSidebar>  
            </Container>
        </div>
    )
}

export default InvoiceTable
