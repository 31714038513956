import React, { useEffect, useState } from 'react'
import Table from 'components/table';
import Form from 'components/form';
import { useForm, useWatch } from 'react-hook-form';
import { TrekkingStatus } from 'components/table/commonTableComponents';
import { pageSizeValues } from 'constants/contants';
import Pagination from 'components/table/pagination';
import { getAllCards, getCard } from 'store/actions/cardActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import isEmpty from 'helpers/isEmpty';
import { CardStatusFilter, TrekkingRouteFilter } from 'components/common/filters';
import TrekkerSidebar from 'scenes/common/trekkers/trekkerSidebar';
import { getTrekker, getTrekking } from 'store/actions/trekkersAction';


const pageState = {
    pageindex:0,
    pageSize:pageSizeValues[0].value,
    totalRows:0,
}
const filterState = {
    regionalId:"",
    cardStatus:"",
}


function Card({user}) {
    const dispatch = useDispatch();
    const { control, register } = useForm();
    const [filter, setFilter] = useState(filterState)
    const [page, setPage] = useState(pageState)
    const [data,setData] = useState([]);
    const onPageChange = async (page) =>await setPage(prev=>({...prev,pageindex:page}))
    const {cards} =useSelector((state:RootStateTypes)=>state.cardReducer)
    const [timCard,setTimCard] = useState(null);


    const onFilter = async (items) => {
        await setFilter(prev=>({...filter,...items}))
    }  
    
    
    const columns = [
        {
            Header: 'Card Number',
            accessor: 'CardNumber',
            width: 140
        },
        {
            Header: 'Registered Route',
            accessor: 'TrekRoute',
            width: 200,
            Filter:({filter:filter,onchange}) =><TrekkingRouteFilter trekkingArea={filter?.trekkingArea} {...filter} onFilter={onFilter} />,
            // Cell: Card
            // Cell:CardLink
        },
        {
            Header: 'Regd. Date',
            accessor: 'RegisterdDate',
            width: 200,
            // Filter: DateFilter,
        },
        {
            Header: 'Start. Date',
            accessor: 'StartDate',
            width: 200,
            // Filter: DateFilter,
        },
        {
            Header: 'End Date',
            accessor: 'EndDate',
            width: 200,
            // Filter: DateFilter,
        },
        {
            Header: 'Status',
            accessor: 'TrekingStatus',
            width: 200,
            Cell: TrekkingStatus,
            Filter:({filter:filter,onchange}) =><CardStatusFilter trekkingArea={filter?.trekkingArea} {...filter} onFilter={onFilter} />,
            // getProps: () => ({ someFunc: () => alert("clicked")})
        },
    ];
    
    const [open, setOpen] = useState(false);
    useEffect(()=>{
        if(cards){
            setData(cards)
            const totalRows = !isEmpty(cards) ? cards[0]?.TotalRows : 0;
            cards && setPage(prev=>({...prev}))
            setPage(prev=>({...prev,totalRows:totalRows}))
        }
    },[cards]);
    useEffect(()=>{
        getCardsAll()
    },[page.pageSize,page.pageindex])
    useEffect(()=>{
        getCardsAll()
    },[filter])
    const getCardsAll = () =>{
        const userId = user?.Id
        dispatch(getAllCards({...page,...filter,userId}))
    }
    const pageSize:any = useWatch({ control, name: 'pageSize' });
    const search: any = useWatch({ control, name: 'search' });
    useEffect(() => {
        if(pageSize && pageSize.value) {
          setPage(prev=>({...prev,pageSize:pageSize.value}));
        }
    }, [pageSize])
    useEffect(()=>{
        if(timCard){
            // dispatch(getCard(timCard.RegistrationId))
            dispatch(getCard(timCard.RegistrationId,timCard.TrekkerId))
            dispatch(getTrekking(null,timCard.TrekkerId))
            // dispatch(getTrekker(timCard.RegistrationId,timCard.TrekkerId))
            // dispatch(getTrekker(timCard.RegistrationId))
        }
    },[timCard])
    const onClose = () =>{
        setOpen(false)
        setTimCard(null)
    }
    const onRowClick = (data) =>{
        setTimCard(data.original)
        setOpen(true)
    }
    // useEffect(() => {
    //     if (search) {
    //         dispatch(getAllCards({ ...page, ...filter, username: search }))
    //     }
    // }, [search])
    const getSearch = () => {
        dispatch(getAllCards({ ...page, ...filter, username: search ?? ""}))
    }
    return (
        <div>
                <div className="tims">
                    <div className="mb-md tims-search space-between search-alt">
                        <div className="">
                            <Form 
                                formName="reactSelect"
                                control={control}
                                name="pageSize"
                                value={pageSizeValues[0]}
                                defaultValue={pageSizeValues[0]}
                                className="page-nums"
                                width="175px"
                                placeholder="Choose..."
                                options={pageSizeValues}
                            />
                        </div>
                        <Form
                            register={register}
                            name="search"
                            width="300px"
                            placeholder="Search..."
                            formName="searchInput"
                            onKeyUp={(name,value,e)=>{
                                if(e?.keyCode === 13){
                                    getSearch()
                                }
                            }}
                        />
                    </div>
                    <div className="tims-table">
                        <Table onRowClick={onRowClick} columns={columns}  data={data} />
                        <Pagination onPageChange={onPageChange} {...page} totalRows={page.totalRows} />
                    </div>
                </div>
                {/* <CommonSidebar title="Trekker" open={open} onModalClose={onClose}>
                    <TimsCard  />
                </CommonSidebar> */}
                <TrekkerSidebar callback={getCardsAll} open={open} onClose={onClose} />
        </div>
    )
}

export default Card
