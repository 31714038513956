import https from 'services/http';
import Action from 'services/actionUtility';
import environment from 'environment';
import types from 'constants/types';
import getQueryUrl from 'helpers/getQueryUrl';


export const getDashboard = (page) => async dispatch=>{
    const finalUrl = getQueryUrl(environment.api.getDashboard,page)
    
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_DASHBOARD,
        message:'',
        isJustData:true,
        type:'get',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig);
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const getDashboardTotal = () => async dispatch=>{
    const res = https.get(environment.api.getDashboardTotal)
    const actionConfig = {
		dispatch,
		actionType:types.GET_DASHBOARD_TOTAL,
        message:'',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig);
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

const newPage = {
    pageIndex:0,
    pageSize:10
}

export const getDashboardRecentTrekkers = () => async dispatch=>{
    const finalUrl = getQueryUrl(environment.api.getRecentTrekkers,newPage)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_DASHBOARD_RECENT_TREKKERS,
        message:'',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig);
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const getDashboardTopTrekkers = () => async dispatch=>{
    const finalUrl = getQueryUrl(environment.api.getTopTrekkers,newPage)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_DASHBOARD_TOP_TREKKERS,
        message:'',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig);
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const getDashboardTopCountry = () => async dispatch=>{
    const finalUrl = getQueryUrl(environment.api.getTopCountry,newPage)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_DASHBOARD_TOP_COUNTRY,
        message:'',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig);
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const getNotifications = ():any => async dispatch=>{
    const res = https.get(environment.api.notification)
    const actionConfig = {
		dispatch,
		actionType:types.GET_NOTIFICATIONS,
        message:'',
		effect:res,
        isJustData:true,
        // isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig);
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const updateNotifications = (ids=null):any => async dispatch=>{
    const res = https.put(environment.api.notification+'?id='+ids,)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_NOTIFICATIONS,
        message:'',
        type:'put',
		effect:res,
        isJustData:true,
        extraPayload:{id:ids}
        // isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig);
    if(response && response.Status){
        // if(ids){
        //     dispatch({type:types.UPDATE_NOTIFICATIONS,payload:ids})
        // }else{
        //     dispatch({type:types.UPDATE_NOTIFICATIONS,payload:ids,all:true})
        // }
        
        // history.push(RouteEnum.Login)
    }
}
