import React, { useEffect, useState } from 'react'
import Trekkers from 'scenes/common/trekkers'

function RegisteredTrekkers({user:u}) {

    return (
        <div className="">
            <Trekkers userId={u?.Id} />
        </div>
    )
}

export default RegisteredTrekkers
