import React from 'react'
import Button from 'components/button'
import Form from 'components/form';
import FormGroup from 'components/form/formGroup';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { updateEmail } from 'store/actions/auth';
import { useForm } from 'react-hook-form';
// import * as yup from "yup";
import Icons from 'components/icons/svgIcons';
// const SignupSchema = yup.object().shape({
//     firstName: yup.string().required(),
//     age: yup
//       .number()
//       .required()
//       .positive()
//       .integer(),
//     website: yup.string().url()
//   });
  

// const Schema = yup.object().shape({
//     email: yup.string().required('Required field'),
//     emailConfirmation: yup
//       .string()
//       .oneOf([yup.ref('email')], 'Emails must match')
//       .required('Required field'),
// });

function ChangeEmail(props) {
    // const resolver = useValidationResolver(Schema)
    const dispatch = useDispatch()
    const methods = useForm()
    const {watch,errors} = methods;

    const {user} = useSelector((state:RootStateTypes)=>state.auth)
    const {errors:errs} = useSelector((state:RootStateTypes)=>state.validateReducer)
    const onSubmit = async (data) => {
        const nameData = {
            email: data.email,
            userId:user.UserID
        }
        const flag = await dispatch(updateEmail(nameData))
        if(flag){
            props.onModalClose();
        }
        // props.onModalClose();
    }
    return (
        <div className="account-profile">
            <div className="account-settings-form account-profile__body">
                <FormGroup method={methods} onSubmit={onSubmit}>
                    <div className="account-profile__details">
                        <div className="account-profile__row">
                            <Form
                                name="email"
                                label="New E-mail Address"
                                type="email"
                                required={true}
                            ></Form>
                        </div>
                        <div className="account-profile__row">
                            <Form
                                name="confirmEmail"
                                label="Confirm E-mail Address"
                                type="email"
                                validate={(value)=>value === watch("email") || "Email don't match."}
                                error={errors}
                            ></Form>
                        </div>
                        {
                            errs && <div className="error-panel">{errs[0]}</div>
                        }
                    </div>
                    <div className="account-profile__button">
                        
                        <Button
                            icon={<Icons name="Save" size={16} />}
                            bg="primary" 
                            // type="button-outline" 
                            buttonType="icon-button"
                            title="Save Changes"
                            htmlButtonType="submit"
                            
                        />
                        <Button
                            bg="cancel"
                            title="Cancel"
                            // type="button-outline"
                            // type="button-outline"
                            onClick={()=>props.onModalClose()}
                        />
                    </div>
                </FormGroup>
            </div>
        </div>
    )
}

export default ChangeEmail
