import React from 'react'
import classnames from 'classnames';
import { connect } from 'react-redux'
import isEmpty from 'isEmpty'
import {clearMessage,removeMessage} from 'store/actions/validateActions'
import Icons from 'components/icons';


interface ToastProps {
    type?: string,
    position?: string,
    remove?: boolean,
    rounded?: boolean,
    timeout?: number,
    clearMessage?: Function,
    removeMessage?: Function,
    validateReducer?: any,
    id?: string | number,
    children?: React.ReactNode,
}


function Toast(props: ToastProps) {
    const { type, position, rounded, timeout } = props;
    const newType = type.toLocaleLowerCase()
    var toastClasses = classnames({
        'toast': true,
        'success': newType === 'success',
        'warning': newType === 'warning',
        'normal': newType === 'normal',
        'info': newType === 'info',
        'danger': newType === 'danger',
        'primary': newType === 'primary',
        'bottom-left': position === 'bottom-left',
        'bottom-right': position === 'bottom-right',
        'bottom-center': position === 'bottom-center',
        'top-left': position === 'top-left',
        'top-right': position === 'top-right',
        'top-center': position === 'top-center',
        'rounded': rounded === true

    });
    if(timeout && timeout > 0){
        setTimeout(()=>{
            props.clearMessage();
            if(props.remove && !isEmpty(props.id)){
                props.removeMessage(props.id)
            }
        }, timeout)
    }

    const clear = () => {
        props.clearMessage();
        if (props.remove && !isEmpty(props.id)) {
            props.removeMessage(props.id)
        }
    }
    const arrayMessage = (children) => {
        let message = <ol>
            {
                children.map((mes,key) => {
                    return <li key={"Message-"+key} >{mes}</li>
                })
            }
        </ol>

        return message
    }
    const renderMessage = (children) => {
        if (typeof children === 'object' && children.length !== undefined && children.length >= 2) {
            return arrayMessage(children)
        }
        else if (typeof children === 'object' && children.length !== undefined && children.length === 1) {
            return props.children
        } else if (typeof children === 'string' || typeof children === 'number') {
            return props.children
        } else {
            return null
        }

    }

    const getIcon = () =>{
        switch(newType){
            case 'warning':
                return <Icons  type="iconify" dataIcon="mdi:alert" />
            case 'danger':
                return <Icons  type="iconify" dataIcon="ant-design:stop-outlined" />
            case 'success':
                return <Icons  type="iconify" dataIcon="bi:check-circle" />
            case 'info':
                return <Icons  type="iconify" dataIcon="bi:info-circle" />
            case 'primary':
                return <Icons  type="iconify" dataIcon="bx:bx-bell" />
            default :
                return null;
        }
    }
    const closeIcon = <span className="iconify" data-icon="ion:close" data-inline="false"></span>
    if(isEmpty(props.children)){
        return null;
    }
    return (
        <div className={toastClasses}>
            <div className="toast-type-icon">
                {
                    getIcon()
                }
                
            </div>
            <span className="toast__body">
                {
                    isEmpty(props.children) ? null :
                        renderMessage(props.children)
                }
            </span>
            <span className="toast__close" onClick={() => clear()}>
                {closeIcon}
            </span>
        </div>
    )
}

Toast.defaultProps = {
    type: 'primary',
    position: 'bottom-left',
    rounded: false,
    timeout: 5000
}


const mapStateToProps = state => ({
    validateReducer: state.validateReducer,
});

// export default connect(mapStateToProps, {})(Toast);
export default connect(mapStateToProps, { clearMessage,removeMessage })(Toast);
