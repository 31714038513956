import React from 'react'
import img from 'assets/images/fake/a.png'
import CommonImageModal from 'components/common/commonImageModal'

function Documents({ data, data2 }) {
    return (
        <div className="request-documents">
            {
                data2 && data2.map((item,key) => {
                    
                    return <div key={"Docs="+key} className="request-documents__block">
                        <div className="request-documents__block-time">
                            {item?.Date}
                        </div>
                        <div className="request-documents__container">
                            {
                                item && item?.Documents && item?.Documents.map((doc,key) => {
                                    const isPdf = item?.ImagePath && item?.ImagePath?.indexOf(".pdf") != -1;
                                    console.log({isPdf})
                    if(isPdf){
                        //@ts-ignore
                        return  <a href={item.ImagePath} className='view-pdf' without rel='noreferrer' target="_blank">
                        <button >
                          View Pdf File
                        </button>
                     </a>
                    }
                                    return(
                                    <div key={"Document-"+key} className="request-documents__doc">
                                        <div className="request-documents__doc-img">
                                            {/* <img src={'/'+doc.ImagePath} alt="" /> */}
                                            <CommonImageModal src={'/' + doc.ImagePath} alt={doc.UserFileName} />
                                        </div>
                                        <div className="request-documents__doc-details">
                                            <div>{doc.UserFileName}</div>
                                            <span>{doc.CreatedOn}</span>
                                        </div>
                                    </div>
                                )})
                            }
                        </div>
                    </div>
                })
            }

        </div>
    )
}

export default Documents
