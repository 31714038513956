import React,{useRef} from 'react'
import {ButtonProps} from 'ts/interfaces/componentInterface';
import classnames from 'classnames';
import isEmpty from 'isEmpty';

function ButtonDrop(props:ButtonProps) {
    const { 
        children,
        onClick,
        title,
        icon,
        rightIcon,
        leftIcon,
        ...rest 
    } 
    = props;
    return (
        <>
            {
                !isEmpty(icon)? icon: !isEmpty(leftIcon) ? <span className="button-icon button-left-icon">{leftIcon}</span> : null
            }
            <span className="button-text">
                {
                    !isEmpty(title) ? 
                    title :
                    children
                }
            </span>
            {
                !isEmpty(rightIcon)? <span className="button-icon button-right-icon">{rightIcon}</span>:null
            }
        </>
    )
}

export default ButtonDrop
