import https from 'services/http';
import Action from 'services/actionUtility';
import environment from 'environment';
import types from 'constants/types';
import RouteEnum from 'constants/routesEnum';
import getQueryUrl from 'helpers/getQueryUrl';


export const getRoutes = (page):any => async dispatch =>{
    const finalUrl = getQueryUrl(environment.api.getRoutes,page)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROUTES,
        message:'',
        isLoading:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const addRoutes = (data,history):any => async dispatch =>{
    const res = https.post(environment.api.addRoute,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_ROUTES,
        type:'post',
        isLoading:true,
        message:'Route Added',
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        history.push(RouteEnum.Route)
    }
}

export const updateRoutes = (data,history):any => async dispatch =>{
    const res = https.put(environment.api.addRoute,data)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_ROUTE,
        type:'put',
        isLoading:true,
        message:'Route Updated',
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        history.push(RouteEnum.Route)
    }
}
export const updateRouteStatus = (data):any => async dispatch =>{
    const finalUrl = getQueryUrl(environment.api.updateRouteStatus,data)
    const res = https.put(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_ROUTE_STATUS,
        type:'put',
        message:'Route Status Updated',
		effect:res,
        extraPayload:data
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Route)
    }
}



export const getRoute = (id):any => async dispatch =>{
    const res = https.get(environment.api.getRoute+'/'+id)
    const actionConfig = {
		dispatch,
        id,
		actionType:types.GET_ROUTE,
        message:'',
        isLoading:false,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const getRouteProvince = ():any => async dispatch =>{
    const res = https.get(environment.api.getRouteProvince)
    const actionConfig = {
		dispatch,
        type:'get',
		actionType:types.GET_ROUTES_PROVINCE,
        message:'',
        isSelect:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}



export const deleteRoute = (id):any => async dispatch =>{
    const res = https.delete(environment.api.getRoute+'/'+id)
    const actionConfig = {
		dispatch,
        type:'delete',
        id,
		actionType:types.DELETE_ROUTE,
        message:'',
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}