import types from 'constants/types';
import isEmpty from 'isEmpty';
import { Action, Reducer } from 'redux';
import {AuthInitialState} from 'ts/reducer';

const initialState:AuthInitialState ={
    // isAuthenticated:true,
    isAuthenticated:false,
    user:{},
    profile:{},
    loading:false,
    token:null,
    tokenDetails:null
}

export interface ActionType {
    payload:any,
    loadingType:string
    type:string,
    isUser:boolean,
    tokenDetails:any
}

export const reducer:Reducer<AuthInitialState> = (state:AuthInitialState | undefined,incomingAction:Action):AuthInitialState=>{
    if (state === undefined) {
        return initialState;
    }
    const action = incomingAction as ActionType;
    switch(action.type){
        case types.SET_CURRENT_TOKEN:
            return {
              ...state,
              isAuthenticated: !isEmpty(action.payload),
              token: action.payload,
              tokenDetails: action.tokenDetails,
            };
        case types.LOGIN_LOADING:
			return {
				...state,
				loading: action.payload,
        };
        case types.UPDATE_CURRENT_USER:
            return {
                ...state,
                user:{
                    ...state.user,
                    ...action.payload
                }
            }
        case types.SET_CURRENT_USER:
        return{
            ...state,
            isAuthenticated: !isEmpty(action.payload),
            user: action.payload
        }
        case types.GET_USER:
            return{
                ...state,
                user:action.payload
            }
        case types.SET_CURRENT_PROFILE:
            return{
                ...state,
                profile:action.payload
            }
        case types.UPDATE_USER:
            return{
                ...state,
                user:!action.isUser ?{
                    ...state.user,
                    ...action.payload
                } : state.user
            }
        case types.RESET_STATE:{
            return initialState
        }
        default:
        return state;
    }
}




export default reducer;