import React from 'react'
import classnames  from 'classnames';
import PropTypes from 'prop-types'
import isEmpty from 'isEmpty'

interface selectProps {
    name:string,
    placeholder?:string,
    value:string,
    info?:string,
    error?:string,
    options?:any[],
    onChange?:Function,
    disabled?:boolean,
    leftIcon?:any,
    theme?:string,
    className?:string,
}

const SelectInput = ({error,
    info,
    theme,
    leftIcon,
    ...props
}) => {
    const formClasses = classnames({
        "generic-group__form":true,
        ["generic-group__form-" + theme]:true
    })
  return (
      <div className={formClasses}>
            <div className="generic-group__form-group">
                <InputIcon icon={leftIcon} />
                <TextInput 
                    error={error}
                    {...props}
                />    
            </div>
            {info && <small className="form-text text-muted">{info}</small>}
            {/* {error && (<div className="invalid-form">{error}</div>)} */}
      </div>

  );
};

const TextInput = (props) =>{
    const {type,error,placeholder,value,onBlur,options,onKeyUp,name,onChange,disabled, register,required} =props;
    const handleChange = (event) => {
        const { name, value } = event.target;
        onChange && onChange(name, value);
      };
    
      const handleKeyUp = (event) => {
        const { name, value } = event.target;
        onKeyUp && onKeyUp(event);
      };
    
      const handleBlur = (event) => {
        const { name, value } = event.target;
        onBlur && onBlur(name, value);
      };
    return(
        <div className="generic-group__form-group__select">
            <select 
                onChange={handleChange}
                value={value}
                name={name} 
                className={classnames('generic-group__form-select',{ 'is-invalid': error })}
                onBlur={handleBlur}
                disabled={disabled}
                ref={register && register({ required:required?  (props.label ? props.label : '' ) + ' field is required' : false })}
                id={name}>
                <option value="">Choose an option</option>
                {
                    options.map(item=>{
                    return <option key={item.value} value={item.value}>{item.label}</option>
                    })
                }
            </select>
            <div className="custom-arrow"></div>
        </div>

    )
}

const InputIcon = ({icon}) =>{
    if(!isEmpty(icon)){
        return(
            <div className="generic-group__form-group__icon">
                {icon}
            </div>
        )
    }
    return null
}

SelectInput.defaultProps={
    type :'text',
    theme:'tertiary',
}

export default SelectInput;