import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import ReactDOM from 'react-dom';
// import {getBaseUrl} from 'constants/userDetails';
import * as htmlToImage from 'html-to-image';

const fileType = {
    PNG: 'image/png',
    JPEG: 'image/jpeg',
    PDF: 'application/pdf'
};

const DEFAULT_PNG = {
    fileName: 'component.png',
    type: fileType.PNG,
    html2CanvasOptions: {}
};

const DEFAULT_JPEG = {
    fileName: 'component.jpg',
    type: fileType.JPEG,
    html2CanvasOptions: {}
};

const DEFAULT_PDF = {
    fileName: 'component.pdf',
    type: fileType.PDF,
    html2CanvasOptions: {},
    pdfOptions: { usePdfSize: false }
};

/**
 * @param  {string} uri
 * @param  {string} filename
 */
const saveAs = (uri, filename) => {
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
        link.href = uri;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        window.open(uri);
    }
};

/**
 * @param  {React.RefObject} node
 * @param  {string} fileName
 * @param  {string} type
 * @param  {object} html2CanvasOptions={}
 */

const getPDF = async (canvas, obj: any) => {
    const { w, h, orientation, unit = 'mm', pdfFormat, usePdfSize = false } = obj;
    const width = w || canvas.width
    const height = h || canvas.height
    const o = orientation || width > height ? 'l' : 'p'
    const format = pdfFormat || 'a4';
    const imgpdf = await new JsPDF(o, unit, format)
    console.log({ imgpdf })
    return imgpdf
}

const exportComponent = async (node, obj: any) => {
    const {
        fileName,
        type,
        html2CanvasOptions,
        pdfOptions
    } = obj;
    if (!node.current) {
        throw new Error("'node' must be a RefObject")
    }
    const element: any = await ReactDOM.findDOMNode(node.current);
    console.log({ element, html2CanvasOptions, sy: window.scrollY })
    return htmlToImage.toCanvas(element).then(async canvas => {
        if (type === fileType.PDF) {
            const pdf = await getPDF(canvas, pdfOptions)

            let width = pdfOptions.w || canvas.width;
            let height = pdfOptions.h || canvas.height;
            if (pdfOptions.usePdfSize) {
                width = pdf.internal.pageSize.getWidth();
                height = pdf.internal.pageSize.getHeight();
            }
            // pdf.addImage(
            //     canvas.toDataURL(fileType.PNG, 1.0), 
            //     'PNG', 
            //     pdfOptions.x || 0, 
            //     pdfOptions.y || 0,
            //     width,
            //     height
            // );
            const images = await canvas.toDataURL(fileType.PNG, 1.0)
            console.log({ images })
            await pdf.addImage(
                images,
                'PNG',
                0,
                2,
                width,
                height
            );

            await pdf.save(fileName);
            // pdf.html(element,)
            // pdf.save(fileName)
        } else {
            saveAs(canvas.toDataURL(type, 1.0), fileName);
        }
    }).catch(err => console.log({ err }));
    // return html2canvas(element, {
    //     scrollY: -window.scrollY,
    //     useCORS: true,
    //     allowTaint: true,
    //     ...html2CanvasOptions
    // }).then(async canvas => {
    //     const imgData = canvas.toDataURL('image/png');
    //     // saveAs(imgData, "test.png")
    //     // if (false) {
    //     if (type === fileType.PDF) {
    //         const pdf = await getPDF(canvas, pdfOptions)

    //         let width = pdfOptions.w || canvas.width;
    //         let height = pdfOptions.h || canvas.height;
    //         if (pdfOptions.usePdfSize) {
    //             width = pdf.internal.pageSize.getWidth();
    //             height = pdf.internal.pageSize.getHeight();
    //         }
    //         // pdf.addImage(
    //         //     canvas.toDataURL(fileType.PNG, 1.0), 
    //         //     'PNG', 
    //         //     pdfOptions.x || 0, 
    //         //     pdfOptions.y || 0,
    //         //     width,
    //         //     height
    //         // );
    //         const images = await canvas.toDataURL(fileType.PNG, 1.0)
    //         console.log({ images })
    //         await pdf.addImage(
    //             images,
    //             'PNG',
    //             0,
    //             2,
    //             width,
    //             height
    //         );

    //         await pdf.save(fileName);
    //         // pdf.html(element,)
    //         // pdf.save(fileName)
    //     } else {
    //         saveAs(canvas.toDataURL(type, 1.0), fileName);
    //     }
    // }).catch(err => console.log({ err }));
};

/**
 * @param  {React.RefObject} node
 * @param  {string} fileName='component.png'
 * @param  {object} html2CanvasOptions={}
 */
const exportComponentAsPNG = (node, parameters = {}) => exportComponent(node, { ...DEFAULT_PNG, ...parameters });

/**
 * @param  {React.RefObject} node
 * @param  {string} fileName='component.jpeg'
 * @param  {string} type=fileType.JPEG
 * @param  {object} html2CanvasOptions={}
 */
const exportComponentAsJPEG = (node, parameters = {}) => exportComponent(node, { ...DEFAULT_JPEG, ...parameters });

/**
 * @param  {React.RefObject} node
 * @param  {string} fileName='component.pdf'
 * @param  {string} type=fileType.PDF
 * @param  {object} html2CanvasOptions={}
 * @param  {string} pdfOptions={}
 */
const exportComponentAsPDF = (node, parameters = {}) => exportComponent(node, { ...DEFAULT_PDF, ...parameters });

export {
    exportComponentAsJPEG,
    exportComponentAsPDF,
    exportComponentAsPNG
};