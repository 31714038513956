import { combineReducers } from 'redux';
import auth from './authReducer';
import loadingReducer from './loadingReducer';
import validateReducer from './validateReducer';
import trekkersReducer from './trekkersReducer';
import selectReducer from './selectReducer';
import {RootStateTypes} from 'ts/reducer'
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import environment from 'environment';
import cardReducer from './cardReducer';
import routeReducer from './routeReducer';
import userReducer from './userReducer';
import dashboardReducer from './dashboardReducer';
import billingReducer from './billingReducer';
import requestReducer from './requestsReducer';
import guideReducer from './guideReducer';
import selfScanTrekkersReducer from './selfScanTrekkersReducer';

const history = createBrowserHistory<any>({ basename: environment.route.baseRoute });
//const history = createBrowserHistory();
export default combineReducers<RootStateTypes>({
    auth,
    loadingReducer,
    validateReducer,
    selectReducer,
    trekkersReducer,
    selfScanTrekkersReducer,
    cardReducer,
    routeReducer,
    guideReducer,
    billingReducer,
    dashboardReducer,
    userReducer,
    requestReducer,
    router: connectRouter(history)
});