import types from 'constants/types';
import {BillingInitialState} from 'ts/reducer';

const initialState:BillingInitialState ={
    billing: [],
    chalanlog: []
}

export default function BillingReducer(state= initialState,action):BillingInitialState{
    switch(action.type){
        case types.GET_BILLING:
			return {
				...state,
				billing: action.payload,
            };
        case types.GET_CHALANLOG:
            return {
                ...state,
                chalanlog: action.payload,
            };
        case types.RESET_STATE:{
            return initialState
        }
        default:
        return state;
    }
}



