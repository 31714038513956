import classnames from 'classnames';
import dayjs from 'dayjs';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import localizedFormat from 'dayjs/plugin/localizedFormat';
// var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
const getDateFormat = (date) => {

    if (date) {
        return dayjs(date).format('ll')
    }
    return ""
}

const getDateFormat2 = (date) => {

    if (date) {
        return dayjs(date).format('lll')
    }
    return ""
}

function TrekkerDetails({ userData, checkins }) {
    const [active, setActive] = useState(1);
    const { genders, region, routes, countries } = useSelector((state: RootStateTypes) => state.selectReducer)
    if (!userData) {
        return null
    }
    const gender: any = userData?.Gender ? genders ? genders.find(item => item.Value == userData?.Gender) : null : null;
    
    const route: any = userData?.TrekkingAreaId ? region ? routes.find(item => item.Value == userData?.TrekkingAreaId) : null : null;
    const country = countries && countries.find(item=>item.CountryId === userData?.Nationality)
    const nregion = country ? country.RegionID  : 0
    const rg: any = nregion  ? region.find(item => item.Value == nregion) : null;
    // const rg: any = userData?.RegionalId ? routes ? region.find(item => item.Value == userData?.RegionalId) : null : null;
    return (
        <div className="trekker-details">
            <TrekContainer header="Background">
                <TrekRow label="Trek Route" value={userData.TrekRoute} />
                <TrekRow label="Region" value={rg?.label} />
                <TrekRow label="Nationality" value={userData.NationalityName} />
                <TrekRow label="City" value={userData.City} />
                <TrekRow label="Gender" value={gender?.label} />
                <TrekRow label="Date of Birth" value={getDateFormat(userData.Dob)} />
                <TrekRow label="Email" value={userData.Email} />
                <TrekRow label="Passport Number" value={userData.PassportNumber} />
                <TrekRow label="Visa Number" value={userData.VisaNumber} />
                <TrekRow label="Visa issue Date" value={getDateFormat(userData.VisaIssueDate)} />
                <TrekRow label="Visa Expiry Date" value={getDateFormat(userData.VisaExpiryDate)} />
            </TrekContainer>
            <TrekContainer header="Trek Details">
                <TrekRow label="Trekking Area" value={route?.label} />
                <TrekRow label="Trek Entry Date" value={getDateFormat(userData.TrekEntryDate)} />
                <TrekRow label="Trek Exit Date" value={getDateFormat(userData.TrekExitDate)} />
                <TrekRow label="Trek Entry Point" value={userData.TrekEntryPoint} />
                <TrekRow label="Trek Exit Point" value={userData.TrekExitPoint} />
                <TrekRow label="Guide Name" value={userData.PorterGuideInfo} />
                <TrekRow label="Guide Contact" value={userData.PorterGuideContact} />
            </TrekContainer>
            <div className="common-tabs__body">
                <TrekContainer header="Contact">
                    <div className="common-tabs">
                        <div
                            onClick={() => setActive(1)}
                            className={classnames("common-tab", { "active": active === 1 })}
                        >
                            <span>Nepal</span>
                        </div>
                        <div
                            onClick={() => setActive(2)}
                            className={classnames("common-tab", { "active": active === 2 })}
                        >
                            <span>Home Country</span>
                        </div>
                    </div>
                    {active === 1 && (
                        <>
                            <TrekRow label="Contact person Name" value={userData.ContactPerson} />
                            <TrekRow label="Organization Name" value={userData.Organization} />
                            <TrekRow label="Desigantion" value={userData.Designation} />
                            <TrekRow label="Mobile Numer" value={userData.Mobile} />
                            <TrekRow label="Office Number" value={userData.HomeOfficeNo} />
                            <TrekRow label="Address" value={userData.Address} />
                        </>
                    )}
                    {active === 2 && (
                        <>
                            <TrekRow label="Contact person Name" value={userData.NativeContactPerson} />
                            {/* <TrekRow label="Organization Name" value={userData.NativeOrganization} /> */}
                            <TrekRow label="City" value={userData.NativeDesignation} />
                            <TrekRow label="Mobile Numer" value={userData.NativeMobile} />
                            <TrekRow label="Office Number" value={userData.NativeOfficeNo} />
                            <TrekRow label="Address" value={userData.NativeAddress} />
                        </>
                    )}
                </TrekContainer>
            </div>

            <TrekContainer header="Insurance Details">
                <TrekRow label="Insurance company" value={userData.InsuranceCompany} />
                <TrekRow label="Country" value={userData.InsuranceNational} />
                <TrekRow label="Policy number" value={userData.PolicyNumber} />
                <TrekRow label="Policy type" value={userData.PolicyType} />
            </TrekContainer>
            {
                checkins && checkins.length > 0 && (
                    <TrekContainer noPad header="Checkin">
                        {
                            checkins.map((item,key) => {
                                return <TrekContainer light key={"Checkin-"+key} header={(key+1)+". Checkin - " + item?.Location}>
                                    <TrekRow label="Location" value={item?.Location} />
                                    <TrekRow label="Checkin method" value={item?.CheckinMethod} />
                                    <TrekRow label="Date & Time" value={getDateFormat2(item?.ActionDate)} />
                                </TrekContainer>
                            })
                        }

                    </TrekContainer>
                )
            }
        </div>
    )
}

const TrekContainer = (props) => {
    const {light,noPad} = props;
    return (
        <div 
            
            className="trekker-details__container">
            <div 
                style={light?{backgroundColor:'#f5f5f5',fontWeight:600,fontSize:14}:{}}
                className="trekker-details__header">
                <span>
                    {props.header}
                </span>
            </div>
            <div 
                style={noPad?{padding:0}:{}}
                className="trekker-details__body">
                {props.children}
            </div>
        </div>
    )
}


const TrekRow = ({ label, value }) => {
    return (
        <div className="trekker-details__row">
            <div className="trekker-details__col">{label}</div>
            <div className="trekker-details__col">{value}</div>
        </div>
    )
}

export default TrekkerDetails
