import React from 'react'
import CommonImage from 'components/common/commonImageModal';

function Documents({documents}) {
    return (
        <div className="trekking-agency-documents">
            <div className="trekking-agency-documents__container">
                {
                    documents && documents.map((item,key)=>{
                        return(
                            <div key={"Docs"+item?.CreatedOn} className="trekking-agency-documents__doc">
                                <div className="trekking-agency-documents__doc-img">
                                    <CommonImage 
                                        alt={item?.UserFileName}
                                        src={'/'+item?.ImagePath}
                                    />
                                    {/* <img src={'/'+item?.ImagePath} alt="" /> */}
                                </div>
                                <div className="trekking-agency-documents__doc-details">
                                    <div>{item?.UserFileName}</div>
                                    <span>{item?.CreatedOn}</span>
                                </div>
                            </div>
                        )
                    })
                }

                {/* <div className="trekking-agency-documents__doc">
                    <div className="trekking-agency-documents__doc-img">
                        <img src={img} alt="" />
                    </div>
                    <div className="trekking-agency-documents__doc-details">
                        <div>Tax Clearance Document</div>
                        <span>02-Apr-2018</span>
                    </div>
                </div>
                <div className="trekking-agency-documents__doc">
                    <div className="trekking-agency-documents__doc-img">
                        <img src={img} alt="" />
                    </div>
                    <div className="trekking-agency-documents__doc-details">
                        <div>Tax Clearance Document</div>
                        <span>02-Apr-2018</span>
                    </div>
                </div>
                <div className="trekking-agency-documents__doc">
                    <div className="trekking-agency-documents__doc-img">
                        <img src={img} alt="" />
                    </div>
                    <div className="trekking-agency-documents__doc-details">
                        <div>Tax Clearance Document</div>
                        <span>02-Apr-2018</span>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Documents
