import classnames from 'classnames';
import React, { useEffect, useState } from 'react'
import TimsCard from 'scenes/trekkers/timsCard';
import { Tabs, Tab } from 'components/tabs'
import CommonImage from 'components/common/commonImageModal'
import Button from 'components/button';
import Modal from 'components/modal';
import Form from 'components/form';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateVouncherStatus } from 'store/actions/requestActions';
import { approveRejectCard, getCard } from 'store/actions/cardActions';
import { getTrekkerVoucher } from 'store/actions/trekkersAction';
import Icons from 'components/icons/svgIcons';
import Confirm from 'components/common/confirm';
import { RootStateTypes } from 'ts/reducer';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
// var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

function VoucherDetails({ voucher, onModalClose: onMClose, callback }) {
    console.log({voucher})
    const [active, setActive] = useState("Voucher_Details");
    // console.log({voucher})
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [trekData, setTrekData] = useState([]);
    const onModalClose = () => setOpen(false)
    const onModalClose1 = () => setOpen1(false)
    const { control, register } = useForm();
    const note = useWatch({ name: 'Discription', control });
    const voucherRefNo = useWatch({ name: 'VoucherRef', control });
    
    // const {agencyUser:user} = useSelector((state:RootStateTypes)=>state.userReducer)
    const { voucherTrekker } = useSelector((state: RootStateTypes) => state.trekkersReducer)
    const userData = voucherTrekker ? voucherTrekker?.UserRegistrationModel : null;
    // const trekData = Array.isArray(voucherTrekker?.TrekkersShortDetail) ? voucherTrekker?.TrekkersShortDetail : null;
   
    const dispatch = useDispatch()
    const onApprove = () => {
        const data = {
            "userId": voucher?.UserId,
            "registrationId": voucher?.RegistrationId,
            "note": "",
            "createdDate": new Date(),
            "isRejectionNote": false,
            "VoucherRefNo":voucherRefNo
        }
        dispatch(updateVouncherStatus(data, callback))
        setOpen1(false)
    }
    const onReject = () => {
        const data = {
            "userId": voucher?.UserId,
            "registrationId": voucher?.RegistrationId,
            "note": note,
            "createdDate": new Date(),
            "isRejectionNote": true,
            "VoucherRefNo":""
        }
        dispatch(updateVouncherStatus(data, callback))
        setOpen(false)
    }
    // useEffect(()=>{
    //     if(voucher && voucher?.RegistrationId){
    //         dispatch(getCard(voucher?.RegistrationId))
    //     }
    // },[])
    useEffect(() => {
        if (voucherTrekker?.TrekkersShortDetail && Array.isArray(voucherTrekker?.TrekkersShortDetail)) {
            setTrekData(voucherTrekker?.TrekkersShortDetail)
        }
    }, [voucherTrekker?.TrekkersShortDetail])
    useEffect(() => {
        if (voucher?.RegistrationId) {
            setTrekData([])
            dispatch(getTrekkerVoucher(voucher?.RegistrationId))
        }
    }, [voucher?.RegistrationId])
    const onTabClick = (name) => {
        setActive(name)
    }
    const isVerified = voucher?.IsVoucherVerified === null
    const comp = <div className="flex-end">
        {/* {
            active === 'TIMS_Card' ? (
                <Button
                    buttonType="icon-button"
                    onClick={() => setOpen(true)}
                    icon={<Icons name="Times" size={14} />}
                    size="small" bg="danger"
                    // type="button-outline" 
                    title="Reject" />
            ) : (
                <Confirm content="Are you sure you want to reject this voucher?" deleteFunction={() => onReject()}>
                    <Button
                        buttonType="icon-button"
                        icon={<Icons name="Times" size={14} />}
                        size="small" bg="danger"
                        // type="button-outline" 
                        title="Reject" />
                </Confirm>
            )
        } */}
        <Button
            buttonType="icon-button"
            onClick={() => setOpen(true)}
            icon={<Icons name="Times" size={14} />}
            size="small" bg="danger"
            // type="button-outline" 
            title="Reject" />
        <Button onClick={() => setOpen1(true)}
            // type="button-outline" 
            buttonType="icon-button"
            icon={<Icons name="Check" size={14} />}
            bg="primary" size="small" title="Approve" />
    </div>
    return (
        <div className="trekker">
            <Tabs
                onTabClick={onTabClick}
                // component={comp}
                component={isVerified ? comp : null}
                activeTab={active} color="common" >
                <Tab name={'Voucher_Details'} label="Voucher Details" >
                    <div className="">
                        <div className="voucher-details">
                            <div className="voucher-detail">
                                <div className="voucher-detail__header">Trek Details</div>
                                <div className="voucher-detail__body">
                                    <div className="voucher-detail__row">
                                        <strong>Trekking Route</strong>
                                        <span>{userData?.TrekRoute}</span>
                                    </div>
                                    <div className="voucher-detail__row">
                                        <strong>Trek entry date</strong>
                                        <span>{dayjs(userData?.TrekEntryDate).format('ll')}</span>
                                    </div>
                                    <div className="voucher-detail__row">
                                        <strong>Trek exit date</strong>
                                        <span>{dayjs(userData?.TrekExitDate).format('ll')}</span>
                                    </div>
                                    <div className="voucher-detail__row">
                                        <strong>Total Amount</strong>
                                        <span>{voucher?.amount}</span>
                                    </div>
                                </div>
                            </div>
                            {
                                trekData && trekData.map((item, key) => {
                                    return (
                                        <div key={item?.Email} className="voucher-detail">
                                            <div className="voucher-detail__header">User Details - {key + 1}</div>
                                            <div className="voucher-detail__body">
                                                <div className="voucher-detail__row">
                                                    <strong>Full Name</strong>
                                                    <span>{item?.FullName}</span>
                                                </div>
                                                <div className="voucher-detail__row">
                                                    <strong>Trekker Type</strong>
                                                    <span>{item?.TrekkerType}</span>
                                                </div>
                                                {/* <div className="voucher-detail__row">
                                                    <strong>Email</strong>
                                                    <span>{trekData?.Email}</span>
                                                </div>
                                                <div className="voucher-detail__row">
                                                    <strong>PassportNumber</strong>
                                                    <span>{trekData?.PassportNumber}</span>
                                                </div> */}
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <VoucherImageDetail voucher={voucher?.Voucher} />
                    </div>

                </Tab>
                {/* <Tab name={'TIMS_Card'} label="TIMS Card" >
                    <TimsCard />
                </Tab> */}
            </Tabs>
            <Modal
                width="450px"
                open={open}
                onModalClose={onModalClose}
                className="disable-modal"
            // className="request-rejects" 
            >

                <div className="disable">
                    <div className="disable__body">
                        <div className="disable__title">Reject Voucher</div>
                        {/* <div className="disable__content">Do you want to disable user . They will no longer able to login.</div> */}
                        <div className="disable__input form-alt">

                            <Form
                                formName="textarea"
                                // checked
                                register={register}
                                label="Remarks"
                                required
                                name="Discription"

                            />
                        </div>
                    </div>
                    <div className="disable__footer" style={{ gap: 12 }}>
                        <Button
                            bg="cancel"
                            // type="button-outline" 
                            size="small"
                            title="Cancel" onClick={() => setOpen(false)} />
                        <Button
                            size="small"
                            onClick={() => onReject()}
                            htmlButtonType="submit" bg="primary" title="Reject" />
                    </div>
                </div>
            </Modal>

            <Modal
                width="450px"
                open={open1}
                onModalClose={onModalClose}
                className="disable-modal"
            // className="request-rejects" 
            >

                <div className="disable">
                    <div className="disable__body">
                        <div className="disable__title">Approve Voucher</div>
                        {/* <div className="disable__content">Do you want to disable user . They will no longer able to login.</div> */}
                        <div className="disable__input form-alt">

                            <Form
                                formName="textinput"
                                // checked
                                register={register}
                                label="Voucher Ref No"                                
                                name="VoucherRef"

                            />
                        </div>
                    </div>
                    <div className="disable__footer" style={{ gap: 12 }}>
                        <Button
                            bg="cancel"
                            // type="button-outline" 
                            size="small"
                            title="Cancel" onClick={() => setOpen1(false)} />
                        <Button
                            size="small"
                            onClick={() => onApprove()}
                            htmlButtonType="submit" bg="primary" title="Approve" />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

const VoucherImageDetail = ({ voucher }) => {

    return (
        <div className="voucher-images">
            {
                voucher && voucher.map(item => {
                    const isPdf = item?.ImagePath && item?.ImagePath?.indexOf(".pdf") != -1;
                    if(isPdf){
                        //@ts-ignore
                        return  <a href={item.ImagePath} className='view-pdf' without rel='noreferrer' target="_blank">
                        <button >
                          View Pdf File
                        </button>
                     </a>
                    }
                    return <div className="voucher-image">
                        <CommonImage src={'/' + item.ImagePath} />
                        {/* <img src={'/'+item.ImagePath} alt="" /> */}
                    </div>
                })
            }

        </div>
    )
}

export default VoucherDetails
