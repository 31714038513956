import React, { useEffect, useState } from 'react'
import Table from 'components/table';
import Form from 'components/form';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { pageSizeValues } from 'constants/contants';
import { getScannedTrekkersByUser, getTrekking as getTrekker, getTrekkers } from 'store/actions/trekkersAction';
import { useForm, useWatch } from 'react-hook-form';
import Pagination from 'components/table/pagination';
import { DateFilter,CountryFilter,TrekkingRouteFilter, RegTypeFilter } from 'components/common/filters';
import ResetFilter from 'components/common/resetFilter';
import DownloadAllCsv from 'components/common/downloadAllCsv';
import environment from 'environment';
import TrekkerSidebar from 'scenes/common/trekkers/trekkerSidebar';
import { getCard } from 'store/actions/cardActions';
import { TrekkingStatus } from 'components/table/commonTableComponents';

const csvheaders = [
    { label: "Id", key: "RowNum" },
    { label: "Full Name", key: "FullName" },
    { label: "Trekking Region", key: "TrekingArea" },
    { label: "TIMS Card", key: "CardNumber" },
    { label: "Country", key: "Country" },
    { label: "Register Type", key: "registerName" },
    { label: "Registered Date", key: "RegisteredDate" },
    { label: "Card Status", key: "CardStatus" },
]

const pageState = {
    pageindex:0,
    pageSize:pageSizeValues[0].value,
    totalRows:0,
}
const filterState = {
    startDate:null,
    endDate:null,
    status:"",
    country:"",
    region:"",
    trekkingArea:"",
    users:""
}

function RegisteredTrekkers({user}) {
    const dispatch = useDispatch();
    const [page, setPage] = useState(pageState)
    const [filter, setFilter] = useState(filterState)
    const [trek, setTrek] = useState(null)
    const [data,setData] = useState([]);
    const onPageChange = async (page) =>await setPage(prev=>({...prev,pageindex:page}))
    const {scannedTrekkers:trekkers,trekker} = useSelector((state:RootStateTypes)=>state.trekkersReducer)
    useEffect(()=>{
        if(trekkers){
            setData(trekkers)
            const totalRows = trekkers ? trekkers[0]?.TotalRows : 0;
            trekkers && setPage(prev=>({...prev}))
            setPage(prev=>({...prev,totalRows:totalRows}))
        }
    },[trekkers]);
    useEffect(()=>{
        getTrekking()
    },[page.pageSize,page.pageindex])
    const onRowClick = (data) => {
        setTrek(data.original)
        setOpen(true)
    }
    const getTrekking = () =>{
        dispatch(getScannedTrekkersByUser({userId:user?.Id,...page,...filter}))
        // dispatch(getScannedTrekkersByUser({userId:user?.Id,username:user?.UserName,...page,...filter}))
        // dispatch(getTrekkers({username:user?.FullName,...page}))
    }
    const {control,register} = useForm();
    const [open, setOpen] = useState(false);

    const pageSize:any = useWatch({ control, name: 'pageSize' });
    const username:any = useWatch({ control, name: 'username' });
    // useEffect(() => {
    //     if(username){
    //         dispatch(getScannedTrekkersByUser({userId:user?.Id,...page,username}))
    //     }
    // }, [username])
    const getSearch = () =>{
        dispatch(getScannedTrekkersByUser({userId:user?.Id,...page,username}))
    } 
    useEffect(()=>{
        getTrekking()
    },[filter]) 
    const onClose = () => {
        setOpen(false)
        setTrek(null)
    }
    useEffect(() => {
        if (trek) {
            // dispatch(getTrekker(trek.RegistrationId))
            // dispatch(getCard(trek.RegistrationId))
            dispatch(getTrekker(null,trek.TrekkerId))
            dispatch(getCard(trek.RegistrationId,trek.TrekkerId))
        }
    }, [trek])
    useEffect(() => {
        if(pageSize && pageSize.value) {
          setPage(prev=>({...prev,pageSize:pageSize.value}));
        }
    }, [pageSize])
    const onFilter = async (items) => {
        await setFilter(prev=>({...filter,...items}))
        // getUsersDetails()
    }  
    const columns = [
        {
            Header: 'ID',
            accessor: 'UserId',
            width: 140,
        },
        {
            Header: 'Full Name',
            accessor: 'FullName',
            width: 200
        },
        {
            Header: 'Tims Card',
            accessor: 'CardNumber',
            width: 200
        },
        {
            Header: 'Trekking Region',
            accessor: 'TrekingArea',
            width: 200,
            Filter:({filter:filt,onchange}) =><TrekkingRouteFilter trekkingArea={filter?.trekkingArea} {...filt} onFilter={onFilter} />
        },
        {
            Header: 'Country',
            accessor: 'Country',
            width: 180,
            Filter:({ filter:filt, onChange }) =><CountryFilter country={filter?.country} {...filt} onFilter={onFilter} />,
        },
        {
            Header: 'Reg. Type',
            accessor: 'Regional',
            width: 200,
            Filter:({ filter:filt, onChange }) =><RegTypeFilter users={filter?.users} {...filt} onFilter={onFilter} />,
        },
        {
            Header: 'Reg. Date',
            accessor: 'RegisteredDate',
            Filter:({ fit, onChange }) =><DateFilter {...filter} {...fit} onFilter={onFilter} />,
            width: 200
        },
        {
            Header: 'TIMS Status',
            accessor: 'CardStatus',
            width: 170,
            Cell: TrekkingStatus
        },
    ];
    const onClearFilters = () => setFilter(filterState)
    return (
        <div className="">
            {/* <Breadcrumbs isHome linkList={breadList} /> */}
            <div className="routes">
                    {/* <FormGroup onSubmit={onSubmit}> */}
                        <div className="space-between search-alt">
                            <Form 
                                formName="reactSelect"
                                control={control}
                                name="pageSize"
                                value={pageSizeValues[0]}
                                className="page-nums"
                                defaultValue={pageSizeValues[0]}
                                
                                width="175px"
                                placeholder="Choose..."
                                options={pageSizeValues}
                            />
                            <div className="flex">
                            <ResetFilter onClearFilters={onClearFilters} filters={filter} />
                                <DownloadAllCsv 
                                    api={environment.api.getScannedTrekkersByUser}
                                    headers={csvheaders}
                                    fileName="ScannedTrekkersList"
                                    extraParams={{userId:user?.Id,...filter}}
                                    isData={false}
                                />
                                
                                <Form 
                                    register={register}
                                    name="username"
                                    width="300px"
                                    placeholder="Search..."
                                    formName="searchInput"
                                    onKeyUp={(name,value,e)=>{
                                        if(e?.keyCode === 13){
                                            getSearch()
                                        }
                                    }}
                                />
                            </div>
                            
                            {/* <Form
                                name="search_route"
                                width="300px"
                                placeholder="Search..."
                                formName="searchInput"
                            /> */}

                        </div>
                    {/* </FormGroup> */}
                    <div className="routes-table">
                        <Table onRowClick={onRowClick}  columns={columns} data={data} />
                        {/* <Table columns={columns} data={data} isRowSelect /> */}
                        <Pagination onPageChange={onPageChange} {...page} totalRows={page.totalRows} />
                    </div>
            </div>
            <TrekkerSidebar isScanned callback={getTrekking} trek={trek} open={open} onClose={onClose} />
        </div>
    )
}

export default RegisteredTrekkers
