import React, { ReactNode,ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";
import { deepMap } from 'react-children-utilities';
import { useSelector } from "react-redux";
import { RootStateTypes } from "ts/reducer";


function isReactFragment(variableToInspect) {
    if (variableToInspect.type) {
      return variableToInspect.type === React.Fragment;
    }
    return variableToInspect === React.Fragment;
  }

export default function FormGroup(props: any) {
    const { defaultValues, children, onSubmit,method } = props;
    // const methods = useForm({ defaultValues });
    const {erroList} = useSelector((state:RootStateTypes)=>state.validateReducer);
    let methods = useForm({ defaultValues });
    if(method){
        methods = method;
    }
    const { handleSubmit, errors, control,getValues, watch, register,setError,setValue,clearErrors} = methods;
    useEffect(()=>{
        if(erroList && Array.isArray(erroList) && erroList.length > 0){
            erroList.map(item=>{
                Object.keys(item).map(key=>{
                    setError(key, {
                        type: "manual",
                        message:item[key]
                      })
                }
                  )
            })
        }
    },[erroList]);
    const newChildren = deepMap(children, (child: ReactNode) => {
        if(isReactFragment(child)){
            return React.createElement((child as ReactElement).type, {
                ...{
                    ...(child as ReactElement).props,
                    key: (child as ReactElement).props.name
                }
            }) 
        }
        if (child && (child as ReactElement)?.props?.name) {
            return React.createElement((child as ReactElement).type, {
                ...{
                    ...(child as ReactElement).props,
                    register,
                    setError,
                    setValue,
                    clearErrors,
                    watch,
                    control,
                    error: (child as ReactElement).props.error ? (child as ReactElement).props.error :errors,
                    key: (child as ReactElement).props.name
                }
            })
        }
        if(isReactFragment(child)){
            return React.createElement((child as ReactElement).type, {
                ...{
                    ...(child as ReactElement).props,
                    key: (child as ReactElement).props.name
                }
            }) 
        }
        if (child && typeof (child as ReactElement).type !== 'string') {
            return React.createElement((child as ReactElement).type, {
                ...{
                    ...(child as ReactElement).props,
                    register,
                    setError,
                    setValue,
                    control,
                    clearErrors,
                    error: errors,
                    watch,
                    getValues,
                    key: (child as ReactElement)?.props?.name
                }
            })
        }
        return child
    });
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {newChildren}
        </form>
    );
}