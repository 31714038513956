import Button from "components/button";
import Confirm from "components/common/confirm";
import DownloadAllCsv from "components/common/downloadAllCsv";
import {
  CardStatusFilter,
  CountryFilter,
  DateFilter,
  RegTypeFilter,
  RegionFilter,
   TrekkingRouteFilter,
   PaymentDateFilter
} from "components/common/filters";
import ResetFilter from "components/common/resetFilter";
import Form from "components/form";
import Modal from "components/modal";
import Table from "components/table";
import { TrekkingStatus } from "components/table/commonTableComponents";
import Pagination from "components/table/pagination";
import { pageSizeValues, userEnums } from "constants/contants";
import RouteEnum from "constants/routesEnum";
import environment from "environment";
import { useEffect, useState, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  deleteCard,
  deleteTrekkers,
  generateCard,
  getCard,
} from "store/actions/cardActions";
import {
  clearTrekker,
  getTrekkers,
  getTrekking
} from "store/actions/trekkersAction";
import { RootStateTypes } from "ts/reducer";
import "./styles.scss";
import TrekkerSidebar from "./trekkerSidebar";

const RevisitBtn = ({ getTrekkings, value, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
    const user = useSelector((state: RootStateTypes) => state.auth.user);
    const isTannUser =
        user?.UserType === userEnums.taan;
  const isRevisitAllowed =
    user?.UserType === userEnums.trekkingAgency ||
    user?.UserType === userEnums.ntb;
  const status =
    user?.UserType === userEnums.ntb ||
    rest.row?.original?.CardStatus === "Payment Failure" ||
    rest.row?.original?.CardStatus === "Payment Pending" ||
    rest.row?.original?.CardStatus === "Rejected";
  const regId = rest.row?.original?.RegistrationId;
  if (!status || !isRevisitAllowed) {
    return null;
  }
  return (
    <div className="revisit-btn">
      <Button
        bg="primary"
        title={user?.UserType === userEnums.ntb ? "Edit" : "Pay"}
        size="small"
        onClick={() =>
          history.push({
            pathname: RouteEnum.NewRegister,
            search: `?regId=${regId}&isPayment=1`,
            // state: { regId: regId }
          })
        }
      />
    </div>
  );
};

const GenerateCardButton = ({ getTrekkings, value, ...rest }) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootStateTypes) => state.auth.user);

  const isRevisitAllowed = user?.UserName === "prempls23@gmail.com"; //user?.UserType === userEnums.ntb;
  const status =
    rest.row?.original?.CardStatus === "Payment Failure" ||
    rest.row?.original?.CardStatus === "Payment Pending";
  const regId = rest.row?.original?.RegistrationId;
  if (!status || !isRevisitAllowed) {
    return null;
  }
  const onGenerate = () => {
    // e.stopPropagation()
    dispatch(generateCard(regId, cb));
  };
  const cb = () => {
    getTrekkings();
  };
  return (
    <div className="revisit-btn">
      <Confirm
        btnTitle="Generate Card"
        content="Are you sure you want to generate card ?"
        deleteFunction={onGenerate}
        title="Route"
      >
        <Button
          bg="primary"
          title={"Card"}
          size="small"
          // onClick={onGenerate}
        />
      </Confirm>
    </div>
  );
};

const DeleteBtn = ({ getTrekkings, value, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, register, handleSubmit,errors } = useForm();
  const [open, setOpen] = useState(false);
  const user = useSelector((state: RootStateTypes) => state.auth.user);
  const isRevisitAllowed = user?.UserType === userEnums.trekkingAgency;
  const status =
    rest.row?.original?.CardStatus === "Payment Failure" ||
    rest.row?.original?.CardStatus === "Payment Pending" ||
    rest.row?.original?.CardStatus === "Rejected";
  const Id = rest.row?.original?.ID;
  if (!status || !isRevisitAllowed) {
      return null;
  }

  const onDelete = () => {
    // e.stopPropagation()
    dispatch(deleteCard(Id, cb));
  };
  const onReject = (data) => {
    const finalData = {
      Id,
      ...data,
    };
    dispatch(deleteTrekkers(finalData, cb));
  };
  const cb = () => {
    setOpen(false);
    getTrekkings();
  };
  console.log({errors})
  return (
    <div className="revisit-btn">
      <Button
        bg="primary"
        title={"Delete"}
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      />
      {/* <Confirm btnTitle="Yes" content="Are you sure you want to Delete trekker ?" deleteFunction={onDelete} title="Route" >
                <Button
                    bg="primary"
                    title={"Delete"}
                    size="small"
                // onClick={onGenerate}
                />
            </Confirm> */}
      <Modal
        width="600px"
        open={open}
        onModalClose={() => setOpen(false)}
        className="disable-modal"
      >
        <form onSubmit={handleSubmit(onReject)}>
          <div onClick={(e) => e.stopPropagation()} className="disable">
            <div className="disable__body">
              <div className="disable__title">Delete Trekker</div>
              <div className="disable__content">
                Are you sure you want to Delete trekker ?
              </div>
              <div className="disable__input form-alt">
                <Form
                  formName="textarea"
                  // checked
                  register={register}
                  validate={(val:any)=>val?.length < 10 ? "Minimum 10 charactor is required": true}
                  error={errors}
                  label="Delete Reason"
                  required
                  name="Note"
                />
              </div>
            </div>
            <div className="disable__footer" style={{ gap: 12 }}>
              <Button
                bg="cancel"
                // type="button-outline"
                size="small"
                title="Cancel"
                onClick={() => setOpen(false)}
              />
              <Button
                // onClick={() => onReject()}
                size="small"
                htmlButtonType="submit"
                bg="primary"
                title="Delete"
              />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

const pageState = {
  pageindex: 0,
  pageSize: pageSizeValues[0].value,
  totalRows: 0,
};

const csvheaders = [
  { label: "Id", key: "RowNum" },
  { label: "Full Name", key: "FullName" },
  { label: "Trekking Region", key: "TrekingArea" },
  { label: "TIMS Card", key: "CardNumber" },
  { label: "Region", key: "Regional" },
  { label: "Country", key: "Country" },
  { label: "Registered Date", key: "RegisteredDate" },
  { label: "Agency", key: "RegisterName" },

    { label: "Card Status", key: "CardStatus" },
    { label: "Paymment Date", key: "PaymmentDate" },
];

const filterState = {
  startDate: null,
  endDate: null,
  cardStatus: "",
  country: "",
  region: "",
  trekkingArea: "",
    userAgencyType: "",
    paymentStartDate: null,   // Add this for payment start date
    paymentEndDate: null,
};

const beforecolumns:any = ({
  getTrekkings,
  onFilter,
  filter
}) => [
  {
    Header: "ID",
    accessor: "RowNum",
    width: 50,
  },
  {
    Header: "",
    accessor: "RegistrationIds",
    width: 50,
    Cell: (props) => <RevisitBtn {...props} />,
  },
  {
    Header: "",
    accessor: "DeleteCard",
    width: 70,
    Cell: (props) => <DeleteBtn {...props} getTrekkings={getTrekkings} />,
  },
  {
    Header: "",
    accessor: "GenerateCard",
    width: 70,
    Cell: (props) => (
      <GenerateCardButton {...props} getTrekkings={getTrekkings} />
    ),
  },

  {
    Header: "Status",
    accessor: "CardStatus",
    Filter: ({ filter: filt, onchange }) => (
      <CardStatusFilter
        trekkingArea={filter?.trekkingArea}
        {...filter}
        onFilter={onFilter}
      />
    ),
    width: 200,
    Cell: TrekkingStatus,
  },
  {
    Header: "Full Name",
    accessor: "FullName",
    width: 200,
    isSort: true,
  },
  // {
  //     Header: 'Email',
  //     accessor: 'Email',
  //     width: 300
  // },
  {
    Header: "Trekking Region",
    accessor: "TrekingArea",
    width: 200,
    Filter: ({ filter: filt, onchange }) => (
      <TrekkingRouteFilter
        trekkingArea={filter?.trekkingArea}
        {...filter}
        onFilter={onFilter}
      />
    ),
    isSort: true,
  },

  {
    Header: "TIMS Card",
    accessor: "CardNumber",
    width: 200,
    isSort: true,
  },
  {
    Header: "Region",
    accessor: "Regional",
    width: 160,
    isSort: true,
    Filter: ({ filter: filt, onchange }) => (
      <RegionFilter region={filter?.region} {...filter} onFilter={onFilter} />
    ),
  },

  {
    Header: "Country",
    accessor: "Country",
    Filter: ({ filter: filt, onChange }) => (
      <CountryFilter
        country={filter?.country}
        {...filter}
        onFilter={onFilter}
      />
    ),
    width: 200,
    isSort: true,
  },
  {
    Header: "Reg. Date",
    accessor: "RegisteredDate",
    width: 200,
    isSort: true,
    Filter: ({ filter: filt, onChange }) => (
      <DateFilter {...filter} onFilter={onFilter} />
    ),
        },
        {
            Header: "Paymment Date",
            accessor: "PaymmentDate",
            width: 200,
            Filter: ({ filter: filt, onChange }) => (
                <PaymentDateFilter {...filter} onFilter={onFilter} />
            ),
        },
  {
    Header: "RegistrationId",
    accessor: "RegistrationId",
    width: 350,
  },
  // {
  //     Header: 'Register Name',
  //     accessor: 'RegisterName',
  //     width: 200,
  // },

  //{
  //    Header: 'Status',
  //    accessor: 'CardStatus',
  //    Filter: ({ filter: filt, onchange }) => <CardStatusFilter trekkingArea={filter?.trekkingArea} {...filter} onFilter={onFilter} />,
  //    width: 200,
  //    Cell: TrekkingStatus
  //},
  //{
  //    Header: '',
  //    accessor: 'RegistrationIds',
  //    width: 120,
  //    Cell: (props) => <RevisitBtn {...props} isRevisitAllowed={isRevisitAllowed}  />
  //},
];

function TrekkerRoute(props) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(pageState);
  const [trek, setTrek] = useState(null);
  const [filter, setFilter] = useState(filterState);
  const [data, setData] = useState([]);
  const onPageChange = async (page) =>
    await setPage((prev) => ({ ...prev, pageindex: page }));
  const { trekkers, trekker } = useSelector(
    (state: RootStateTypes) => state.trekkersReducer
  );
  const breadList = [
    { title: "Trekkers", to: RouteEnum.TrekkingHistory, isCurrent: true },
  ];
  const user = useSelector((state: RootStateTypes) => state.auth.user);
  const userId = props.userId
    ? props.userId
    : user?.UserType !== userEnums.trekkingAgency
    ? null
    : user.UserID;
  const isRevisitAllowed =
    user?.UserType === userEnums.trekkingAgency ||
    user?.UserType === userEnums.ntb;
  // const isAgencyUser = user.UserType === userEnums.check;
  useEffect(() => {
    if (trekkers) {
      setData(trekkers);
      const totalRows = trekkers ? trekkers[0]?.TotalRows : 0;
      trekkers && setPage((prev) => ({ ...prev }));
      setPage((prev) => ({ ...prev, totalRows: totalRows }));
    }
  }, [trekkers]);
  // useEffect(() => {
  //     getTrekkings()
  // }, [page.pageSize, page.pageindex])

  useEffect(() => {
    getTrekkings();
  }, [filter,page.pageSize, page.pageindex]);
  // useEffect(() => {
  //   getTrekkings();
  // }, [page?.pageSize, page?.pageindex]);
  useEffect(() => {
    if (trek) {
      clearTrekker();
      const status = trek?.CardStatus;
      const isPending =
        status === "Approval Pending" ||
        status === "Payment Pending" ||
        status === "Pending" ||
        status === "Payment Failure" ||
        status === "Rejected";
      dispatch(getTrekking(null, trek.ID));
      // dispatch(getTrekker(trek.RegistrationId))
      if (!isPending) {
        dispatch(getCard(trek.RegistrationId, trek.ID));
      }

      // dispatch(getCard(trek.RegistrationId))
    }
  }, [trek]);
  const onComplete = () => {
    onClose();
    getTrekkings();
  };
  const getTrekkings = () => {
    const data = { ...page, ...filter, userId };
    // const data = isRevisitAllowed ? { ...page, ...filter, userId } : { ...page, ...filter,}
    dispatch(getTrekkers(data));
    // dispatch(getTrekkers({username:user?.UserName,...page,...filter}))
  };
  const { control, register } = useForm();
  const [open, setOpen] = useState(false);
  const pageSize: any = useWatch({ control, name: "pageSize" });
  const search: any = useWatch({ control, name: "search" });
  useEffect(() => {
    if (pageSize && pageSize.value) {
      setPage((prev) => ({ ...prev, pageSize: pageSize.value }));
    }
  }, [pageSize]);
    //const user = useSelector((state: RootStateTypes) => state.auth.user);
    const isTannUser =
        user?.UserType === userEnums.taan;
    const onRowClick = (data) =>
    {
        if (!isTannUser) {
            setTrek(data.original);
            setOpen(true);
        }
  };
  const onClose = () => {
    setOpen(false);
    setTrek(null);
  };
  const onFilter = async (items) => {
      await setFilter((prev) => ({ ...filter, ...items }));
  };

  // useEffect(() => {
  //     if (search) {
  //         dispatch(getTrekkers({ ...page, ...filter, userId, username: search }))
  //     }
  // }, [search])


  const getTrekkersSearch = () => {
    dispatch(getTrekkers({ ...page, ...filter, userId, username: search }));
  };
  const isTrekkingAgency = user.UserType === userEnums.trekkingAgency;
  
  let columns: any = useMemo(()=>{
    const cols = beforecolumns({getTrekkings,filter,onFilter});
    if (!isTrekkingAgency) {
      const col = {
        Header: "Agency",
        accessor: "RegisterName",
        width: 200,
        Filter: ({ filter: filt, onChange }) => (
          <RegTypeFilter
            users={filter?.userAgencyType}
            field="userAgencyType"
            {...filter}
            onFilter={onFilter}
          />
        ),
      };
      cols.splice(7, 0, col);
    }
    return cols
  },[getTrekkings,filter,onFilter,isTrekkingAgency])
  
  const onClearFilters = () => setFilter(filterState);
  return (
    <>
      {/* <Breadcrumbs isHome linkList={breadList} /> */}

      <>
        <div className="space-between search-alt">
          <Form
            formName="reactSelect"
            control={control}
            name="pageSize"
            value={pageSizeValues[0]}
            defaultValue={pageSizeValues[0]}
            width="175px"
            className="page-nums"
            placeholder="Choose..."
            options={pageSizeValues}
          />
          <div className="flex">
            <ResetFilter onClearFilters={onClearFilters} filters={filter} />
            <DownloadAllCsv
              api={environment.api.getTrekkers}
              headers={csvheaders}
              extraParams={{ ...filter, userId }}
              fileName="TrekkersList"
              // extraParams={{username:user?.FullName,}}
              isData={false}
            />
            <Form
              register={register}
              name="search"
              width="300px"
              placeholder="Search..."
              formName="searchInput"
              onKeyUp={(name, value, e) => {
                if (e?.keyCode === 13) {
                  getTrekkersSearch();
                }
              }}
            />
          </div>
        </div>
        {/* </FormGroup> */}
        <div className="routes-table">
          <Table
            isFixedWidth
            onRowClick={onRowClick}
            columns={columns}
            data={data}
          />
          {/* <Table columns={columns} data={data} isRowSelect /> */}
          <Pagination
            onPageChange={onPageChange}
            {...page}
            totalRows={page.totalRows}
          />
        </div>
      </>
      <TrekkerSidebar
        callback={onComplete}
        trek={trek}
        rowData={trek}
        onClose={onClose}
        open={open}
      />
    </>
  );
}

export default TrekkerRoute;
