export default  (url, param) => {
    const entries = param ? Object.entries(param) : null;
    let fullUrl = url;
    entries &&
      entries.map((entry, ind) => {
        if (ind == 0) {
          fullUrl = `${fullUrl}?${`${entry[0]}=${entry[1]}`}`;
        } else {
          fullUrl = `${fullUrl}&${`${entry[0]}=${entry[1]}`}`;
        }
      });
    return fullUrl;
  };