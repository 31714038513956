import React, { useState } from 'react'
import classnames from 'classnames';
import RequestTable from './requestTable';
import InvoiceTable from './invoiceTable';
import CardReturnTable from './cardReturnTable';
import { Tabs, Tab } from 'components/tabs'
import './styles.scss';
import { useSelector } from 'react-redux';
import { RootStateTypes } from '../../ts/reducer';

function Request(props) {
    const [active, setActive] = useState(1);
    const { user } = useSelector((state: RootStateTypes) => state.auth)
    return (
        <div className="requests users">
             <Tabs activeTab={"Trekking Agency"}>
                <Tab name={"Trekking Agency"} label={"Trekking Agency"} >
                    <RequestTable {...props} />
                </Tab>
                { user.UserType == 4 && 
                <Tab name={"Voucher"} label={"Voucher"} >
                    <InvoiceTable {...props} />
                    </Tab>
                }
                {user.UserType == 4 &&
                    <Tab name={"TIMS Card Return"} label={"TIMS Card Return"} >
                        <CardReturnTable {...props} />
                    </Tab>
                }
          
             </Tabs>
            {/* <div className="requests-tabs">
                <div 
                    onClick={()=>setActive(1)}
                    className={classnames("requests-tab",{"active":active ===1})}
                >
                    <span>Trekking Agency</span>
                </div>    
                <div 
                    onClick={()=>setActive(2)}
                    className={classnames("requests-tab",{"active":active ===2})}
                >
                    <span>Voucher</span>
                </div>
            </div>
            <div className="requests-body common-container">
                {active === 1 && <RequestTable {...props} />}
                {active ===2 && <InvoiceTable {...props} />}
            </div> */}
        </div>
    )
}

export default Request
