import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/breadcrumb';
import './style.scss';
import RouteEnum from 'constants/routesEnum';
import classnames from 'classnames';
import RegisteredTrekkers from './registeredTrekkers';
import Documents from './documents';
import Profile from './profile'
import { getTrekkingAgencyUser } from 'store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';

function TrekkingAgency(props) {
    
    const [active, setActive] = useState(1);
    const {agencyUser:user} = useSelector((state:RootStateTypes)=>state.userReducer);
    const dispatch = useDispatch()
    const {id} = props.match.params;
    useEffect(()=>{
        const params = {
            userId:id
        }
        dispatch(getTrekkingAgencyUser(params))
        // dispatch(getRegisterType(id))
    },[dispatch,id])
    const breadList = [{ title: 'Users', to: RouteEnum.Users }, { title: user?.AgencyUser?.OrganizationName, to: RouteEnum.TrekkingAgencyUser, isCurrent: true }];
    return (
        <div className="trekking-agency">
            <Breadcrumbs linkList={breadList} />
            <div className="trekking-agency__body">
                <div className="common-tabs">
                    <div
                        onClick={() => setActive(1)}
                        className={classnames("common-tab", { "active": active === 1 })}
                    >
                        <span>Profile</span>
                    </div>
                    <div
                        onClick={() => setActive(2)}
                        className={classnames("common-tab", { "active": active === 2 })}
                    >
                        <span>Documents</span>
                    </div>
                    <div
                        onClick={() => setActive(3)}
                        className={classnames("common-tab", { "active": active === 3 })}
                    >
                        <span>Registered Trekker</span>
                    </div>
                </div>
                <div className="common-tabs__body">
                    {active === 1 && <Profile user={user?.AgencyUser} />}
                    {active === 2 && <Documents documents={user?.Documents} />}
                    {active === 3 && <RegisteredTrekkers user={user?.AgencyUser} />}
                </div>
            </div>
        </div >
    )
}

export default TrekkingAgency
