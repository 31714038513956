import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import TrekkerDetails from "./trekkerDetails";
import TimsCard from "./timsCard";
import { findSelectById } from "helpers/methods";
import { useDispatch, useSelector } from "react-redux";
import { RootStateTypes } from "ts/reducer";
import Icons from "components/icons/svgIcons";
import Button from "components/button";
import Pdf from "react-to-pdf";
import ReactToPrint from "react-to-print";
import { userEnums } from "constants/contants";
import { approveRejectCard, retrunCard } from "store/actions/cardActions";
import dayjs from "dayjs";
import govt from "assets/images/govt.png";
import mount from "assets/images/mount.png";
import ntb from "assets/images/ntb.png";
import taan from "assets/images/taan.png";
import nepalLife from "assets/images/nepal-life.png";
import { getTImsChargs } from "helpers/calculateAmount";
import localizedFormat from "dayjs/plugin/localizedFormat";
import {
  exportComponentAsPNG,
  exportComponentAsPDF,
} from "helpers/exportComponents";
import sign from "./sign.png";
import stamp from "./stamp.png";
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';

// var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat);

const pageStyle = `
  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

function Trekker({
  trekker,
  status,
  callback,
  isReturn,
  rowData,
  isScanned = false,
}) {
  const userData = trekker && trekker?.UserRegistrationModel;
  const checkins = trekker && trekker?.Checkin;
  const imageData = trekker && trekker?.Attachment && trekker?.Attachment[0];
  const regId = userData?.RegistrationId;
  const trekId = userData?.ID;
  const [active, setActive] = useState(1);
  const { card: cd }: any = useSelector(
    (state: RootStateTypes) => state.cardReducer
  );
  const ref = useRef(null);
  const cardRef = useRef(null);
  const dispatch = useDispatch();
  const { countries } = useSelector(
    (state: RootStateTypes) => state.selectReducer
  );
  const { user } = useSelector((state: RootStateTypes) => state.auth);
  const isAdmin =
    user?.UserType === userEnums.ntb || user?.UserType === userEnums.taan;
  const isChekpost = user?.UserType === userEnums.check;
  const isPending =
    status === "Approval Pending" ||
    status === "Payment Pending" ||
    status === "Pending" ||
    status === "Payment Failure" ||
    status === "Rejected";
  const isNotReturned =
    status === "Approval Pending" ||
    status === "Payment Pending" ||
    status === "Rejected" ||
    status === "Cancelled" ||
    status === "Returned" ||
    status === "Payment Completed" ||
    status === "Payment Failure";
  const doAllowCard = !isPending;
  const country = findSelectById(countries, userData?.Nationality);
  const onReturnCard = async () => {
    await dispatch(retrunCard(regId, trekId));
    (await callback) && callback();
    };

    const activetab = doAllowCard ? 2 : 1;
  useEffect(() => {
      setActive(activetab);
  }, [trekker]);
  const onApprove = async () => {
    const data = {
      registrationId: regId,
      trekkerid: trekId,
      isApprove: true,
    };
    await dispatch(approveRejectCard(data));
    (await callback) && callback();
  };
  const onReject = async () => {
    const data = {
      registrationId: regId,
      trekkerid: trekId,
      isApprove: false,
    };
    await dispatch(approveRejectCard(data));
    (await callback) && callback();
  };
  const isCardReturned = rowData && rowData?.IsCardReturned;
  const comp = (
    <div className="flex-end" style={{ flexGrow: 1 }}>
      <Button
        onClick={() => onReject()}
        size="small"
        bg="danger"
        // type="button-outline"
        title="Reject"
      />
      <Button
        onClick={() => onApprove()}
        bg="primary"
        size="small"
        title="Approve"
      />
    </div>
  );
  const isFree = userData && userData?.UserType === 1;
  const amount =
    userData?.UserType && userData?.RegionalId
      ? getTImsChargs(userData?.UserType, userData?.RegionalId)
      : null;
  const options = {
    // orientation: 'landscape',
    orientation: "p",
    unit: "px",
    format: "a4",
    putOnlyUsedFonts: true,
  };

  return (
    <>
      <div className="trekker">
        <div className="trekker-user">
          <div className="trekker-user__image">
            <img src={imageData && imageData.FileLocation} alt="" />
          </div>
          <div className="trekker-user__details">
            <div className="trekker-user__name">{userData?.FullName}</div>
            <div className="trekker-user__add flex">
              <div className="trekker-user__add-text flex" style={{ gap: 8 }}>
                <Icons name="Mail" />
                <span>{userData?.Email}</span>
              </div>
              <div className="trekker-user__add-text flex" style={{ gap: 8 }}>
                <Icons size={17} name="Location" />
                <span>{country && country.label}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="trekker-tabs">
          <div className="common-tabs">
            <div
              onClick={() => setActive(1)}
              className={classnames("common-tab", { active: active === 1 })}
            >
              <span>Trekker Details</span>
            </div>
            {doAllowCard && (
              <div
                onClick={() => setActive(2)}
                className={classnames("common-tab", { active: active === 2 })}
              >
                <span>TIMS Card</span>
              </div>
            )}
            {isAdmin && isReturn && isCardReturned && !isScanned && comp}
          </div>
          <div className="common-tabs__body">
            {active === 1 && (
              <TrekkerDetails checkins={checkins} userData={userData} />
            )}
            {/* {active === 2 && <TimsCard />} */}
            {doAllowCard && active === 2 && (
              <div ref={ref} id="trekker">
                <div className="trekker-header">
                  <div className="trekker-header__logo">
                    <img src={govt} alt="" />
                  </div>
                  <div className="trekker-header__logo">
                    <img src={ntb} alt="" />
                    <div className="trekker-header__logo-title">
                      Nepal Tourism Board
                    </div>
                    <div className="trekker-header__logo-sub">
                      Trekkers Information Management System (TIMS)
                      <span style={{ display: "block", fontSize: 10 }}>
                        https://tims.ntb.gov.np
                      </span>
                    </div>
                  </div>
                  <div className="trekker-header__logo">
                    <img src={taan} alt="" />
                  </div>
                </div>
                <div className="trekker-table">
                  <div className="trekker-table__header">
                    <div className="trekker-table__header-head">
                      Trekker Details
                    </div>
                    <div className="trekker-table__header-heading">
                      <div className="trekker-table__header-heading-label">
                        TIMS Card No:{" "}
                      </div>
                      <div
                        style={{ fontWeight: 600 }}
                        className="trekker-table__header-heading-value"
                      >
                        {cd?.CardNumber}
                      </div>
                    </div>
                    <div className="trekker-table__header-heading">
                      <div className="trekker-table__header-heading-label">
                        Issue Date:
                      </div>
                      <div className="trekker-table__header-heading-value">
                        {dayjs(userData?.IssueDate).format("ll")}
                      </div>
                    </div>
                  </div>
                  <div className="trekker-table__row">
                    <div className="trekker-table__data">
                      <TrekRow label="Full Name" value={userData?.FullName} />
                      <TrekRow
                        label="Nationality"
                        value={userData?.NationalityName}
                      />
                      <div className="trekker-table__flex2">
                        <TrekRow label="Visa No." value={cd?.VisaNumber} />
                        <TrekRow
                          label="Passport No."
                          value={cd?.PassportNumber}
                        />
                      </div>
                      <div className="trekker-table__flex2">
                        <TrekRow label="Gender" value={cd?.Gender} />
                        <TrekRow
                          isDate
                          label="Date of Birth"
                          value={userData?.Dob}
                        />
                      </div>
                    </div>
                    <div className="trekker-table__img">
                      <img src={imageData && imageData.FileLocation} alt="" />
                    </div>
                  </div>
                  <div className="trekker-table__header">
                    <div className="trekker-table__header-head">
                      Trek Details
                    </div>
                  </div>
                  <div className="trekker-table__row trekker-table__row2">
                    <div className="trekker-table__data">
                      <TrekRow label="Trekker Area" value={cd?.TrekkingArea} />
                      <TrekRow label="Route" value={cd?.TrekRoute} />
                      <div className="trekker-table__flex">
                        <TrekRow label="Entry Date" value={cd?.TrekEntryDate} />
                        <TrekRow label="Exit Date" value={cd?.TrekExitDate} />
                      </div>
                    </div>
                    <div className="trekker-table__img">
                      {cd?.QrCodes && (
                        <img
                          src={`data:image/jpeg;base64,${cd?.QrCodes}`}
                          alt="Tim Card Qr"
                        />
                      )}
                    </div>
                  </div>
                  {/* <div className="trekker-table__header">
                    <div className="trekker-table__header-head">Contact</div>
                </div> */}
                  <div className="trekker-table__contact">
                    <div className="trekker-table__contact-col">
                      <div className="trekker-table__contact-header">
                        Nepal Contact
                      </div>
                      <div className="trekker-table__contact-body">
                        <div className="trekker-table__contact-row">
                          <strong>Contact Person Name</strong>
                          <span>{userData?.ContactPerson}</span>
                        </div>
                        <div className="trekker-table__contact-row">
                          <strong>Organization Name</strong>
                          <span>{userData?.Organization}</span>
                        </div>
                        <div className="trekker-table__contact-row">
                          <strong>Designation</strong>
                          <span>{userData?.Designation}</span>
                        </div>
                        <div className="trekker-table__contact-row">
                          <strong>Mobile Number</strong>
                          <span>{userData?.Mobile}</span>
                        </div>
                        <div className="trekker-table__contact-row">
                          <strong>Office Number</strong>
                          <span>{userData?.HomeOfficeNo}</span>
                        </div>
                        <div className="trekker-table__contact-row">
                          <strong>Address</strong>
                          <span>{userData?.Address}</span>
                        </div>
                      </div>
                    </div>
                    <div className="trekker-table__contact-col">
                      <div className="trekker-table__contact-header">
                        Home Country Contact
                      </div>
                      <div className="trekker-table__contact-body">
                        <div className="trekker-table__contact-row">
                          <strong>Contact Person Name</strong>
                          <span>{userData?.NativeContactPerson}</span>
                        </div>
                        <div className="trekker-table__contact-row">
                          <strong>City</strong>
                          <span>{userData?.NativeDesignation}</span>
                        </div>
                        <div className="trekker-table__contact-row">
                          <strong>Mobile Number</strong>
                          <span>{userData?.NativeMobile}</span>
                        </div>
                        <div className="trekker-table__contact-row">
                          <strong>Office Number</strong>
                          <span>{userData?.NativeOfficeNo}</span>
                        </div>
                        <div className="trekker-table__contact-row">
                          <strong>Address</strong>
                          <span>{userData?.NativeAddress}</span>
                        </div>
                      </div>
                    </div>
                    <div className="trekker-table__contact-col">
                      <div className="trekker-table__contact-header">
                        Emergency Contact
                      </div>
                      <div className="trekker-table__contact-body">
                        <div className="trekker-table__contact-row">
                          <strong>1144 - Tourist Police</strong>
                          <span></span>
                        </div>
                        {userData?.AgencyContact && (
                          <div className="trekker-table__contact-row">
                            <strong>{userData?.AgencyContact}(Agency)</strong>
                            <span></span>
                          </div>
                        )}
                        <div className="trekker-table__contact-row">
                          <strong>01-45 40 920(TAAN, Kathmandu)</strong>
                          <span></span>
                        </div>
                        <div className="trekker-table__contact-row">
                          <strong>01-42 25 709(Crisis Hotline NTB)</strong>
                          <span></span>
                        </div>
                        <div className="trekker-table__contact-row">
                          <strong>01-42 56 909 (NTB)</strong>
                          <span></span>
                        </div>
                        <div className="trekker-table__contact-row">
                          <strong>061-46 30 33 (TAAN, Pokhara)</strong>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="trekker-terms__container">
                    <div className="trekker-terms">
                      <div className="trekker-terms__header">
                        TIMS Regulatory Provision
                      </div>
                      <ul className="trekker-terms__list">
                        <li>Card cost NRs. {amount} including VAT.</li>
                        <li>
                          Please keep this card with you during the trekking
                          period.
                        </li>
                        <li>
                          Please present this card to NTB/TAAN Officials,
                          National Parks, Conservation Areas and Police Check
                          Posts on request.
                        </li>
                        <li>
                          Please keep a copy of passport along with you during
                          your trek for verification.
                        </li>
                        <li>
                          This card is non-transferable, non-endorssable and
                          valid only for prescribed area and duration.
                          </li>
                           <li>
                            This card is  non-refundable.
                            </li>
                      </ul>
                    </div>
                    {isFree ? (
                      <div className="trekker-terms">
                        <div className="trekker-terms__header">
                          Free Individual Trekker (FIT)
                        </div>
                        <div className="trekker-terms__sub">
                          Trekker carrying own luggage and bearing all the
                          liabilites and reponsibilites individually.
                        </div>
                      </div>
                    ) : (
                      <div className="trekker-terms">
                        <div className="trekker-terms__header">
                          Group Trekker/s (GT)
                        </div>
                        <div className="trekker-terms__sub">
                          Trekker/s using local facilites/expertise such as
                          Trekking Guide/Support Staff and all pre-booked
                          facilites.
                        </div>
                      </div>
                    )}
                    <div className="trekker-terms">
                      <div className="trekker-terms__header">
                        Thank you for :
                      </div>
                      <ul className="trekker-terms__list">
                        <li>Respecting local people</li>
                        <li>Respecting local culture and traditions</li>
                        <li>Respecting nature and environment</li>
                        <li>Using local products and facilities</li>
                      </ul>
                    </div>

                    <div className="trekker-terms">
                      <div className="trekker-terms__curs">
                        <span>*This card is valid for single entry only.</span>
                        <span>
                          For reliable services, please make reservation through
                          government registered trekking agencies.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="trekker-table-footer">
                    <div className="trekker-table-img">
                      <img src={nepalLife} alt="" />
                    </div>
                    <div className="trekker-sign">
                      <div className="trekker-sign__sign">
                        {/*<img src={sign} alt="" />*/}
                      </div>
                      <div className="trekker-sign__stamp">
                        <img src={stamp} alt="" />
                      </div>
                      <div className="trekker-sign__line"></div>
                      <div className="trekker-sign__text">
                        <span>Authorized Signature</span>
                      </div>
                    </div>
                    <div className="trekker-table-img">
                      <img src={mount} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="trekker-footer">
          {doAllowCard && active === 2 && (
            <div className="trekker-buttons flex">
              <ReactToPrint
                // pageStyle={pageStyle}
                bodyClass="print-class"
                trigger={() => (
                  <Button
                    bg="primary"
                    // type="button-outline"
                    size="small"
                    title="Print"
                    buttonType="icon-button"
                    icon={<Icons name="Print" size={14} />}
                  />
                )}
                content={() => ref.current}
              />

              <Button
                bg="primary"
                // type="button-outline"
                size="small"
                onClick={() => {
                  // exportComponentAsPNG(ref,{fileName:userData?.FullName ? `${userData?.FullName}.png` : "tims-card.png"})
                  // appendCanvas(ref.current)
  //                 htmlToImage.toPng(ref.current)
  // .then(function (dataUrl) {
  //   var img = new Image();
  //   img.src = dataUrl;
  //   document.body.appendChild(img);
  // })
  // .catch(function (error) {
  //   console.error('oops, something went wrong!', error);
  // });
              // htmlToImage.toCanvas(document.getElementById('trekker'))
              // .then(function (canvas) {
              //   const fn = userData?.FullName
              //   ? `${userData?.FullName}.pdf`
              //   : "tims-card.pdf"
              //   // downloadImage(dataUrl, fn);
              // });
              
                  exportComponentAsPDF(ref, {
                    pdfOptions: {
                      // orientation: "p",
                      unit: "mm",
                      // w:785,
                      // h:1100,
                      format: "a4",

                      // format: [4, 2]
                      usePdfSize: true,
                    },
                    fileName: userData?.FullName
                      ? `${userData?.FullName}.pdf`
                      : "tims-card.pdf",
                  });
                }}
                title="Download"
                buttonType="icon-button"
                icon={<Icons name="Down" size={14} />}
              />
              {/* <Pdf 
                            // scale={1} 
                            targetRef={ref} filename={userData?.FullName ? `${userData?.FullName}.pdf` : "tims-card.pdf"}>
                            {({ toPdf }) => <Button bg="primary" 
                            // type="button-outline" 
                            size="small" onClick={toPdf} title="Download" buttonType="icon-button" icon={<Icons name="Down" size={14} />} />}
                        </Pdf> */}
              {/*{*/}
              {/*    !isChekpost && !isAdmin && !isCardReturned && !isNotReturned &&   <Button onClick={()=>onReturnCard()} bg="primary" */}
              {/*    // type="button-outline" */}
              {/*    size="small" title="Return Card"  />*/}
              {/*}*/}
              {isAdmin && isReturn && isCardReturned && !isScanned && comp}
            </div>
          )}
        </div>
        {/*<div className="trekker__watermark">*/}
        {/*    <p>Nepal Tourism Board</p>*/}
        {/*</div>*/}
      </div>
    </>
  );
}

const TrekRow = ({ label, value, isDate = false }) => {
  return (
    <div className="trekker-table__data-row">
      <div className="trekker-table__data-label">{label}</div>
      <div className="trekker-table__data-value">
        {isDate ? dayjs(value).format("ll") : value}
      </div>
    </div>
  );
};

export default Trekker;


// const downloadImage = (blob, fileName) => {
//   const fakeLink:any = window.document.createElement("a");
//   fakeLink.style = "display:none;";
//   fakeLink.download = fileName;
  
//   fakeLink.href = blob;
  
//   document.body.appendChild(fakeLink);
//   fakeLink.click();
//   document.body.removeChild(fakeLink);
  
//   fakeLink.remove();
//   };