import React from 'react';
import isEmpty from 'isEmpty'
import CheckInput from './checkInput'
import classnames from 'classnames'
import { Controller } from 'react-hook-form';


interface checkGroupProps {
    name:string,
    value:any,
    error?:string,
    labelPosition?:string,
    id:any,
    disabled?:boolean,
    className?:string,
    checked?:string,
    checkPosition:'horizontal' | 'vertical',
    onChange:Function,
    labels:labelsInter[]
    control?:any,
}
interface labelsInter{
    label:string,
    value:any,
    color?:any
}

function CheckGroup(props:checkGroupProps) {
    const {
        labels,
        checkPosition,
        checked,
        // value,
        // onChange,
        name,
        control,
        ...rest
    } = props;
    const radioGroupClasses = classnames({
        "custom-group__container":true,
        "custom-group__horizontal":checkPosition==='horizontal',
        "custom-group__vertical":checkPosition === 'vertical'
    })
    // const handleChange = (na,val) =>{
    //     let newValue;
    //     if(value.includes(val)){
    //         newValue = value.filter(item=>item!==val)
    //     }else{
    //         newValue = [val,...value]
    //     }
    //     onChange(na,newValue)
    // }
    return (
        <div className={radioGroupClasses}>
            {/* {
                labels.map((item:labelsInter)=>{
                   
                    return  */}
                    <Controller 
                            name={name}
                            control={control && control}
                            defaultValue={[]}
                            render={({ onChange, onBlur, value, ref }) => {
                                const handleChange = (na,val) =>{
                                    let newValue;
                                    if(value){
                                        if(value.includes(val)){
                                            newValue = value.filter(item=>item!==val)
                                        }else{
                                            newValue = [val,...value]
                                        }
                                    }else{
                                        newValue = [val]
                                    }
                                    onChange(newValue)
                                }
                                return <>
                                {labels.map((item:labelsInter)=>{
                                    let check = Array.isArray(value) && value.includes( item.value + "" )
                                    return(
                                        (
                                            <CheckInput 
                                                // key={item.value} 
                                                key={Math.random()}
                                                value={item.value}
                                                onChange ={handleChange} 
                                                checked={check} 
                                                checkLabel={item.label} 
                                                color={item?.color}
                                                isCheckGroup
                                                name={name}
                                                {...rest} 
                                            />
                                        )
                                    )
                                })
                            }</>
                            }}
                    />
                {/* })
            } */}
        </div>
    )
}

export default CheckGroup
