import React from 'react'
import ModalImage from "react-modal-image";

interface ModalProps{
    src:string,
    isUrl?:boolean,
    alt?:string
}

function CommonImageModal({src,isUrl,alt}:ModalProps) {
    return (
        <ModalImage
            small={src}
            medium={src}
            alt={alt}
        />
    )
}

CommonImageModal.defaultProps={
    alt:'Image View'
}

export default CommonImageModal
