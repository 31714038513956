import Button from 'components/button';
import isEmpty from 'helpers/isEmpty';
import React from 'react'




const checkIfObjectEmpty = (obj) =>{
    const reducer = (accumulator, currentValue) => {
        if(!isEmpty(obj[currentValue])){
            return 1 + accumulator
        }
        return 0 + accumulator
    };
    if(obj && typeof obj === "object"){
        const sum = Object.keys(obj).reduce(reducer,0);
        const isNull = Object.keys(obj).every(item=>isEmpty(obj[item]))
       return [sum,isNull]
    }
    return [0,true]
}

function ResetFilter(props) {
    const {filters,onClearFilters} = props;
    const [sum,isNull] = checkIfObjectEmpty(filters)
    if(isNull){
        return null;
    }
    return (
        <div className="clear-filter-btn">
            <Button style={{height:36}} bg="danger" onClick={()=>onClearFilters()} title={
                <div className="clear-filter-content">
                    <span>Clear Filter</span>
                    <span className="clear-filter-tag">{sum}</span>
                </div>
             } />
        </div>
    )
}

export default ResetFilter
