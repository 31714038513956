import React from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { RootStateTypes } from 'ts/reducer'
import Icons from 'components/icons/svgIcons';
import { getDashboardTopTrekkers } from 'store/actions/dashboardActions';

function TopTrekkers() {
    const {topTrekkers} = useSelector((state:RootStateTypes)=>state.dashboardReducer);
    const dispatch = useDispatch();
    const onIconClick = () => {
        dispatch(getDashboardTopTrekkers())
    }
    return (
        <div className="dashboard-container">
            <div className="dashboard-container__header">
                <span>Top Trekking Agency</span>
                <span onClick={()=>onIconClick()} >
                    <Icons name="Re" />
                </span>
            </div>
            <div className="dashboard-container__body">
            {
                    Boolean(topTrekkers) ? (
                        <ul className="dashboard-container__list">
                            {
                                topTrekkers && topTrekkers.map(item=>{
                                    return(
                                        <li className="dashboard-container__item dashboard-container__trekker">
                                            <div className="dashboard-container__name">
                                                <div>{item?.RegisterName}</div>
                                                <p>{item?.Address}</p>
                                            </div>
                                            <div className="dashboard-container__num">
                                                <span>{item?.TrekkerCount}</span>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    ):(
                        <div className="dashboard_nth">No Data Found</div>
                    )
                }
            </div>
        </div>
    )
}

export default TopTrekkers
