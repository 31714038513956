import React from "react";
import classnames from "classnames";

interface Loading {
  loading: boolean;
}

function LogoLoading({ loading }: Loading) {
  const classes = classnames({
    "overlay-spinner": true,
    "show-loading": loading,
  });
  return (
    <div className={classes}>
      <div className="spinner-logo">
        logo here
      </div>
      <div className="status">
        <h1 data-text="Loading…">Loading…</h1>
      </div>
    </div>
  );
}

export default LogoLoading;
