import React from 'react'
import { useForm, Controller, useWatch } from 'react-hook-form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDispatch, useSelector } from 'react-redux';
import { addRequestNote } from 'store/actions/requestActions';
import { RootStateTypes } from 'ts/reducer';
import FormGroup from 'components/form/formGroup';
import Form from 'components/form';
import Button from 'components/button';
import classnames from 'classnames';


function Notes({data}) {
    const {control}= useForm();
    const dispatch = useDispatch();
    const {agencyUser:user} = useSelector((state:RootStateTypes)=>state.userReducer);
    const note = useWatch({name:'Discription',control})
    const onNote = (datas) =>{
        const data = {
            "userId": user?.AgencyUser?.UserId,
            "registrationId": "",
            // "note": note,
            "createdDate": new Date(),
            ...datas
            // "isRejectionNote": false
          }
        dispatch(addRequestNote(data))
    }
    const remarks = user?.UserNotes || [];
    
    return (
        <div className="request-notes">
            <div className="request-notes__container">
                <ul className="request-notes__list">
                    {
                        remarks && remarks.map((item,key)=>{
                            const isApproved = !item.Note;
                            const isRemarks = item.Type === "Remarks"
                            return(<li key={key + "note"} className={classnames("request-notes__item",{"accepted":isRemarks || isApproved,"rejected":!isRemarks && !isApproved})}>
                            <div className="request-notes__remarks">
                                <div>{isApproved ? "Approved" :item.Note}</div>
                                <span>{item.CreatedDate}</span>
                            </div>
                            <div className="request-notes__tag">
                                <span>{item.Type}</span>
                            </div>
                        </li>)
                        })
                    }

                </ul>
                <div className="request-notes__form ck-form">
                    <FormGroup onSubmit={onNote}>
                        {/* <Controller
                                // control={control}
                                name="note"
                                required
                                render={({ onChange, value }) => {
                                    return <CKEditor
                                        editor={ClassicEditor}
                                        data={value}
                                        config={ {
                                            // plugins: [ Paragraph, Bold, Italic, Essentials ],
                                            // toolbar: [ 'bold', 'italic','underline','strikethrough' ]
                                            toolbar: {
                                                items: [ 'heading', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ]
                                            },
                                        } }
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            // console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            onChange(data)
                                            // console.log({ event, editor, data });
                                        }}
                                    />
                                }}
                            /> */}
                        <Form 
                            formName="textarea"
                            // checked
                            label="Remarks"
                            required
                            name="note"
                        
                        />
                        {/* <Form 
                            formName="checkinput"
                            checkLabel="Is Rejected ?"
                            // checked
                            // required
                            name="isRejectionNote"
                        
                        /> */}
                        <Button title="Add Remark" htmlButtonType="submit" bg="primary"  />
                    </FormGroup>

                </div>
            </div>
        </div>
    )
}

export default Notes
