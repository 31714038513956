import React, { useState } from 'react'
import { Tabs, Tab } from 'components/tabs'
import Button from 'components/button';
import UserFormModal from './userFormModal';
import Icons from 'components/icons/svgIcons';
import CommonView from './commonView';
import './styles.scss';
import { RootStateTypes } from 'ts/reducer';
import { useDispatch, useSelector } from 'react-redux';
import CommonSidebar from 'components/common/commonSidebar';
import { addUsers } from 'store/actions/userActions';
import { useForm } from 'react-hook-form';

function Users(props) {
    // const breadList = [{ title: 'TAAN', to: RouteEnum.Users, isCurrent: true }];
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [flag, setFlag] = useState(false);
    const [editData,setEditData] = useState(null);
    const onModalClose = () => onEditCancel();
    const { wholeUserType } = useSelector((state: RootStateTypes) => state.selectReducer)
    let comp = <Button onClick={() => setOpen(true)} bg="primary" style={{height:36}} title="Add New User" buttonType="icon-button" icon={<Icons name="UserAlt" size={16} />} />
    const onEdit = (data) => {
        setIsEdit(true)
        setOpen(true)
        setEditData(data)
    }
    const onEditCancel = () =>{
        setIsEdit(false)
        setOpen(false)
        setEditData(null)
    }
    const dispatch = useDispatch()
    const { user } = useSelector((state: RootStateTypes) => state.auth)
    const method = useForm();
    const { handleSubmit } = method;
    const callbackFunc = () =>{
        setFlag(true)
        onModalClose()
    }
    const onSubmit = (data) => {
        data['userType'] = data['userType'].value;
        data['isAdmin'] = data['userType'].value === '4';
        data['userId'] = user.UserID
        if(data['routeID']){
            data['routeID'] = data['routeID'].value;
        }
        if(data['provinceId']){
            data['provinceId'] = data['provinceId'].value;
        }
        if(data['checkPointID']){
            data['checkPointID'] = data['checkPointID'].value;
        }
        dispatch(addUsers(data,callbackFunc))
    }
    
    const onSave = () =>{
        handleSubmit((data)=>{
            onSubmit(data)
        })()
    }
    console.log(user);
    return (
        <div className="common-container" style={{padding:0}}>
            {/* <Breadcrumbs isHome linkList={breadList} component={comp} /> */}
            <div className="users">
                {
                    wholeUserType &&
                    <Tabs>
                        {/* {wholeUserType.map(item => (
                            <Tab key={item.Value} name={item.Text} label={item.Text} >
                                <CommonView addButton={comp} history={props.history} setOpen={setOpen} onEditClick={onEdit} userType={item.Value} />
                            </Tab>
                        ))} */}
                            {user.UserType ==4 && <Tab name={"NTB"} label={"NTB"} >
                            <CommonView setFlag={setFlag} flag={flag} addButton={comp} history={props.history} setOpen={setOpen} onEditClick={onEdit} userType={"4"} />
                        </Tab>}
                        <Tab name={"TAAN"} label={"TAAN"} >
                            <CommonView setFlag={setFlag} flag={flag} addButton={comp} history={props.history} setOpen={setOpen} onEditClick={onEdit} userType={"6"} />
                        </Tab>
                        <Tab name={"Trek Agency"} label={"Trek Agency"} >
                            <CommonView setFlag={setFlag} flag={flag} addButton={comp} history={props.history} setOpen={setOpen} onEditClick={onEdit} userType={"2"} />
                        </Tab>
                        <Tab name={"Checkpost"} label={"Checkpost"} >
                            <CommonView setFlag={setFlag} flag={flag} addButton={comp} history={props.history} setOpen={setOpen} onEditClick={onEdit} userType={"5"} />
                        </Tab>
                        {/*<Tab name={"FIT"} label={"FIT"} >*/}
                        {/*    <CommonView setFlag={setFlag} flag={flag} addButton={comp} history={props.history} setOpen={setOpen} onEditClick={onEdit} userType={"1"} />*/}
                        {/*</Tab>*/}
                    </Tabs>
                }
            </div>
            <CommonSidebar onSave={onSave} title="Add a user" open={open} onModalClose={onModalClose}>
                <UserFormModal method={method} isEdit={isEdit} editData={editData} onEditCancel={onEditCancel} />
            </CommonSidebar>
            {/* <UserFormModal isEdit={isEdit} editData={editData} onEditCancel={onEditCancel} open={open} onModalClose={onModalClose} /> */}
        </div>
    )
}

export default Users
