import types from "constants/types";
import { RouteInitialState } from "ts/reducer";

const initialState: RouteInitialState = {
  routes: null,
  route: null,
  routesProvince: [],
};

export default function RouteReducer(state = initialState, action): RouteInitialState {
  switch (action.type) {
    case types.GET_ROUTES:
      return {
        ...state,
        routes: action.payload,
      };
    case types.DELETE_ROUTE:
      return {
        ...state,
        routes: {
          ...state.routes,
          RoutesRecord:
            state.routes &&
            state.routes.RoutesRecord &&
            state.routes.RoutesRecord.filter(
              (item) => item.RouteId !== action.payload
            ),
        },
      };
    case types.UPDATE_ROUTE:
      return {
        ...state,
        routes: {
          ...state.routes,
          RoutesRecord:
            state.routes &&
            state.routes.RoutesRecord &&
            state.routes.RoutesRecord.map((item) =>
              item.RouteId === action.id ? action.payload : item
            ),
        },
      };
    case types.UPDATE_ROUTE_STATUS:
      return {
        ...state,
        routes: {
          ...state.routes,
          RoutesRecord:
            state.routes &&
            state.routes.RoutesRecord &&
            state.routes.RoutesRecord.map((item) =>
              item.RouteId === action.routeId
                ? { ...item, Status: action.status ? "Active" : "Disable" }
                : item
            ),
        },
      };
    case types.GET_ROUTES_PROVINCE:
      return {
        ...state,
        routesProvince: action.payload,
      };
    case types.ADD_ROUTES:
      return {
        ...state,
        // routes:[...state.routes, action.payload],
      };
    case types.GET_ROUTE:
      return {
        ...state,
        route: action.payload,
      };
    case types.RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
