import types from 'constants/types';
import {RequestInitialState} from 'ts/reducer';

const initialState:RequestInitialState ={
    requests:null,
    vouchers:null,
    timsChalan:null,
    userChalan:null,

}

export default function RequestReducer(state= initialState,action):RequestInitialState{
    switch(action.type){
        case types.GET_REQUESTS:
			return {
				...state,
				requests: action.payload,
        };
        case types.GET_TIMS_CHALAN:
			return {
				...state,
				timsChalan: action.payload,
        };
        case types.GET_USER_CHALAN:
			return {
				...state,
				userChalan: action.payload,
        };
        case types.CLEAR_CHALAN:
			return {
				...state,
				userChalan: null,
				timsChalan: null,
        };
        case types.GET_VOUCHERS:
			return {
				...state,
				vouchers: action.payload,
        };
        case types.RESET_STATE:{
            return initialState
        }
        default:
        return state;
    }
}


