import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Button from 'components/button';
import Modal from 'components/modal';

class CropUpload extends PureComponent<any,any> {
    static imageRef=null
    static fileUrl = null;
  state = {
    src: null,
    crop: {
      // unit: '%',
      unit: 'px',
      // width: 30,
    //   aspect: 16 / 9,
      aspect: 4 / 4,
      x: 70,
      y: 40,
      width: 200,
      height: 200
    },
    finalImage:null,
    croppedImageUrl:null
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  componentDidMount(){
    if (this.props.file) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          this.setState({ src: reader.result })
        );
        reader.readAsDataURL(this.props.file);
      }
  }

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    CropUpload.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (CropUpload.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        CropUpload.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob:any) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        this.setState({finalImage:blob})
        window.URL.revokeObjectURL(CropUpload.fileUrl);
        CropUpload.fileUrl = window.URL.createObjectURL(blob);
        resolve(CropUpload.fileUrl);
      }, 'image/jpeg');
    });
  }
  onImageCrop(){
    const { crop, croppedImageUrl, finalImage } = this.state;
    const {file,onChange} = this.props;
    onChange(finalImage);
    this.props.onModalClose();
  }

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    const {file,open,onModalClose} = this.props;
    return (
        <div className="s" key="crop-modal" >
        <Modal open={open} className="crop-image__modal" onModalClose={onModalClose} width={`${325+32}px`} title="Crop Image" >
            <>
            <div style={{padding:'16px 16px 0'}} className="">
              {file && (
                  <ReactCrop
                  src={src}
                  //@ts-ignore
                  crop={crop}
                  // locked={true}
                  ruleOfThirds
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                  />
              )}
            </div>

            {/* {croppedImageUrl && (
                <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
            )}
            {this.state.finalImage && (
                <img alt="Crop" style={{ maxWidth: '100%' }} src={URL.createObjectURL(this.state.finalImage)} />
            )} */}
                <div className="crop-image__button">
                    <Button title={"Done"} bg="primary" onClick={()=>this.onImageCrop()}  />
                </div>
            </>
        </Modal>
      </div>
    );
  }
}

export default CropUpload;
