import React, { useContext } from 'react'
import Context from '../context'
import { PaymentFailure, PaymentSuccess } from './paymentStatusMessage';
function PaymentStatus() {
    const paymentLoad= false;
    const paymentError= false;
    const vals = useContext(Context)
    const payNotSuccess = vals.isFailure || vals.isDecline;
    if(paymentLoad){
        return (
            <div className="payment-validate">
                <div className="lds-dual-ring"></div>
                <div className="payment-validate__title">
                    <span>Please be patient and do not leave this page yet.</span>
                </div>
                <div className="payment-validate__sub">Your Payment is being validated.</div>
            </div>
        )
    }
    if(paymentError){
        return(
            <div className="payment-validate">
                <div className="payment-validate__title">
                    <span>There seems to be some problem processing your payment right now.</span>
                </div>
                <div className="payment-validate__sub">Please contact us for more info or try again later.</div>
            </div>
        )
    }
    return (
        <div className="cards-info">
            {(payNotSuccess) && <PaymentFailure isDecline={vals.isDecline} amount="" />}
            {vals.isSuccess && <PaymentSuccess />}
        </div>
    )
}

export default PaymentStatus