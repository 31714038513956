import https from 'services/http';
import Action from 'services/actionUtility';
import environment from 'environment';
import types from 'constants/types';
import getQueryUrl from 'helpers/getQueryUrl';


export const getGuides = (page):any => async dispatch =>{
    const finalUrl = getQueryUrl(environment.api.getGuides,page)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_GUIDES,
        message:'',
        isLoading:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const getGuide = (id):any => async dispatch =>{
    const res = https.get(environment.api.getGuide+'/'+id)
    const actionConfig = {
		dispatch,
        id,
		actionType:types.GET_GUIDE,
        message:'',
        isLoading:true,
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}
