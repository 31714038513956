import React from 'react'
import { useSelector } from 'react-redux'
import { RootStateTypes } from 'ts/reducer'
import Avatr from 'react-avatar';
import classNames from 'classnames';

function RecentTrekekrs(props) {
    const {recentTrekkers} = useSelector((state:RootStateTypes)=>state.dashboardReducer)
    return (
        <div className="dashboard-container">
            <div className="dashboard-container__header">
                <span>Trekkers</span>
            </div>
            <div className="dashboard-container__body">
                {
                    Boolean(recentTrekkers) ? (
                        <div className="dashboard-container__table">
                            <div className="dashboard-container__table-header">
                                <div className="dashboard-container__row">
                                    <div className="dashboard-container__col">TREKKER</div>
                                    <div className="dashboard-container__col">TREKKING REGISTRAR</div>
                                    <div className="dashboard-container__col">STATUS</div>
                                </div>
                            </div>
                            <div className="dashboard-container__table-body">
                                {
                                    recentTrekkers && recentTrekkers.map(item=>{
                                        const value = item?.CardStatus
                                        const isActive = value === "Active";
                                        const isDisable = value === "Disable";
                                        const isApproved = value === "Approved";
                                        const isPaymentPending = value === "Payment Pending"
                                        const isPending = value === "Pending"
                                        const isCancelled = value === "Cancelled";
                                        const isMissed = value === "Missed";
                                        const isReturned = value === "Returned";
                                        const isRejected = value === "Rejected";
                                        const isUpcoming = value === "Upcoming";
                                        const isCompleted = value === "Completed";
                                        const classs = classNames("badges badges-rounded", {
                                            "badges-active": isActive,
                                            "badges-canceled": isCancelled,
                                            "badges-missed": isMissed,
                                            "badges-blue": isReturned,
                                            "badges-payment-pending": isPaymentPending,
                                            "badges-pending": isPending,
                                            "badges-success": isApproved,
                                            "badges-rejected": isRejected,
                                            "badges-upcoming": isUpcoming,
                                            "badges-completed": isCompleted,
                                        })
                                        return(
                                            <div className="dashboard-container__row">
                                               <div className="dashboard-container__col">
                                                   <div className="dashboard-container__user">
                                                       <div className="dashboard-container__user-image">
                                                            {
                                                                //@ts-ignore
                                                                <Avatr src={item?.ImageUrl} size="36px" round="4px" name={item?.FullName + ' Image'} />
                                                            }
                                                       </div>
                                                       <div className="dashboard-container__user-details">
                                                           <div>{item?.FullName}</div>
                                                           <p>{item?.Country}, {item?.City}</p>
                                                       </div>
                                                   </div>
                                               </div>
                                                <div className="dashboard-container__col">
                                                    <div className="dashboard-container__regis">
                                                        <div>{item?.RegisterName}</div>
                                                        <p>{item?.AgencyAddress}</p>
                                                    </div>
                                                </div>
                                                <div className="dashboard-container__col dashboard-container__badge">
                                                    <div className={classs}>
                                                        <div>{item?.CardStatus}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    ) : (
                        <div className="dashboard_nth">No Data Found</div>
                    )
                }
                
            </div>
        </div>
    )
}

export default RecentTrekekrs
