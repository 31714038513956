import React, { useEffect, useRef, useState } from 'react'
import './styles.scss';
import Button from 'components/button';
import FormGroup from 'components/form/formGroup';
import { useForm } from 'react-hook-form';
import Form from 'components/form';
import dayjs from 'dayjs';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { getTImsChalan, getUserChalan } from 'store/actions/requestActions';
import { CSVLink } from 'react-csv'
import { RootStateTypes } from 'ts/reducer';
import types from 'constants/types';
import isEmpty from 'helpers/isEmpty';

function Chalan() {
    const { timsChalan, userChalan } = useSelector((state: RootStateTypes) => state.requestReducer)
    return (
        <div className='chalan'>
            <div className="chalan-container">
                <div className="chalan-blocks">
                    <ChalanBlock title="TIMS Chalan" action={getTImsChalan} dt={timsChalan} fileName="tims-chalan" />
                    <ChalanBlock title="Customer" action={getUserChalan} dt={userChalan} fileName="customer" />
                </div>
            </div>
        </div>
    )
}

const csvheaders = [
    { label: "Customer Code", key: "Customer_Code" },
    { label: "Customer Name", key: "Customer_Name" },
    { label: "PAN", key: "PAN" },
    { label: "Contact Number", key: "Contact_Number" },
    { label: "Email", key: "Email" },
    { label: "Address Date", key: "Address" },

]

const csvTImsheaders = [
    { label: "Sales Chalan Date", key: "sales_chalan_date" },
    { label: "Customer Code", key: "customer_code" },
    { label: "Item Code", key: "item_code" },
    { label: "Card Number From", key: "card_nofrom" },
    { label: "Card No To", key: "card_noto" },
    { label: "Quantity", key: "qty" },      
]

const ChalanBlock = (props) => {
    const { title = "", action, dt = [], fileName = "chalan" } = props;
    const isCustomer = title === "Customer";
    const dts = dt ?? []
    const [csvData, setCsvData] = useState([]);
    const date = new Date()
    const ref = useRef(null)
    const today = isCustomer ? "" : dayjs(date).format("MM/DD/YYYY");
    const dispatch = useDispatch()
    const method = useForm({ defaultValues: { StartDate: today, EndDate: today } });
    const { watch } = method;
    const start = watch("StartDate")
    const end = watch("EndDate")
    useEffect(() => {
        if (!isEmpty(dts)) {
            setCsvData(dts ?? [])
            setTimeout(() => {
                if (ref.current) {
                    ref.current.link.click();
                    dispatch({ type: types.CLEAR_CHALAN })
                    setCsvData([])
                }

            }, 1000);
        }
    }, [dts])
    const onImport = async (data) => {
        await dispatch(action({ startDate: data.StartDate, endDate: data.EndDate }))
    }
    return <><FormGroup method={method} onSubmit={onImport} >
        <div className="chalan-block">
            <div className="chalan-block__title">
                {title}
            </div>
            <div className="chalan-block__inputs">
                <div className="chalan-block__input">
                    <Form
                        name="StartDate"
                        formName="dateinput"
                        // type="date"
                        width="280px"
                        placeholder="Start date"
                        label="Start date"
                        className="required"
                        isClearable={isCustomer}
                        // required={!isCustomer}
                        // validate={(value)=>}!isCustomer
                        validate={(value) => {
                            if(!Boolean(value) && !isCustomer){
                                return "Start Date is required"
                            }
                            if(isCustomer && end && !Boolean(value)){
                                return "Start Date is required or clear end date to continue"
                            }
                            return true;
                        }}
                    />
                </div>
                <div className="chalan-block__input">
                    <Form
                        name="EndDate"
                        formName="dateinput"
                        // type="date"
                        width="280px"
                        placeholder="End date"
                        label="End date"
                        isClearable={isCustomer}
                        className="required"
                        // required={!isCustomer}
                        validate={(value) => {
                            if(!Boolean(value) && !isCustomer){
                                return "End Date is required"
                            }
                            if(isCustomer && start && !Boolean(value)){
                                return "End Date is required or clear start date to continue"
                            }
                            return true;
                        }}
                    />
                </div>

            </div>
            <div className="chalan-block__btn">
                <Button
                    bg='primary'
                    title={"Download"}
                    htmlButtonType="submit"
                // onClick={()=>onImport()}
                />
            </div>

        </div>
    </FormGroup>
        <CSVLink
            headers={!isCustomer ? csvTImsheaders : csvheaders}
            filename={`${fileName}.csv`}
            data={csvData}
            ref={ref}
        />
    </>
}

export default Chalan