import types from 'constants/types'
import {CommonAction} from 'ts/interfaces/reduxInterfaces';
import { Action, Reducer } from 'redux';
import {ValidateInitialState} from 'ts/reducer';

const initialState:ValidateInitialState = {
    messages: {},
    otherMessage:{},
    arrayMessages: [],
    errors: null,
    erroList:null,
};

export const reducer:Reducer<ValidateInitialState> = (state:ValidateInitialState | undefined,incomingAction:Action):ValidateInitialState =>{
    if (state === undefined) {
        return initialState;
    }
    const action = incomingAction as CommonAction;
    switch (action.type) {
        case types.ADD_MESSAGE:
        return {
            ...state,
            messages: action.payload
        };
        case types.ADD_MESSAGES:
        return {
            ...state,
            arrayMessages: [action.payload, ...state.arrayMessages]
        };
        case types.GET_ERRORS:
            return{
                ...state,
                errors:action.payload,
                erroList:action.erroList ? action.erroList : []
            }
        case types.CLEAR_ERRORS:
            return{
                ...state,
                errors:null,
                erroList:null,
            }
        case types.ADD_OTHER_MESSAGES:
        return {
            ...state,
            otherMessage: action.payload
        };
        case types.REMOVE_MESSAGE:
        return {
            ...state,
            arrayMessages: state.arrayMessages.filter(message => message.id !== action.payload)
        };
        case types.CLEAR_MESSAGE:
        return {
            ...state,
            messages: {}
        };
        case types.RESET_STATE:{
            return initialState
        }
        default:
        return state;
    }

}


export default reducer;