import React from 'react'
import { useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';

function Profile({user}) {

    const countryId = user?.CountryID;
    const {countries} = useSelector((state:RootStateTypes)=>state.selectReducer)
    const country = countries && countries.find(item=>parseInt(item.value) === parseInt(countryId))
    return (
        <div className="fit-user-background">
            <div className="fit-user-background__row">
                <div className="fit-user-background__col">Full Name</div>
                <div className="fit-user-background__col">{user?.FullName}</div>
            </div>
            <div className="fit-user-background__row">
                <div className="fit-user-background__col">Email</div>
                <div className="fit-user-background__col">{user?.Email}</div>
            </div>
            <div className="fit-user-background__row">
                <div className="fit-user-background__col">Country</div>
                <div className="fit-user-background__col">{country?.label}</div>
            </div>
        </div>
    )
}

export default Profile
