import Button from 'components/button';
import React, { useEffect, useState } from 'react'
import Common from './common';
import Form from 'components/form';
import RoutesEnum from 'constants/routesEnum';
import FormGroup from 'components/form/formGroup';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'helpers/isEmpty';
import { clearOtherMessage } from 'store/actions/validateActions';
import { loginUser, resendEmail, verifyEmail } from 'store/actions/auth';
import {  RootStateTypes } from 'ts/reducer';
import { Link } from 'react-router-dom';
import Icons from 'components/icons/svgIcons';
import QS from 'query-string';
import { useForm } from 'react-hook-form';
import FloatingNotice from './FloatingNotice';  // Adjust the import path based on your folder structure
function Login(props) {
    const dispatch = useDispatch();
    const [notVerified,setNotVerified] = useState(false);
    const [email,setEmail] = useState(null);
    const method = useForm()
    const {handleSubmit} = method
    const onSubmit = async (data) => {
        const res:any = await dispatch(loginUser(data));
        if(res && res?.Data?.IsEmailVerified == false){
            setEmail(data?.username)
            setNotVerified(true);
        }
    }
    const auth = useSelector((state:RootStateTypes)=>state.auth)
    const search = props.location.search;
    React.useEffect(()=>{
        if(auth.isAuthenticated){
            props.history.push(RoutesEnum.Dashboard)
        }
    },[auth,props.history])
    useEffect(()=>{
        if(search){
            let tk:any = QS.parse(search);
            if(tk){
                const {token,verify} = tk;
                
                if(token){
                    const data = {
                        token:token.replace(/\s/g, '+'),
                        userid:verify.replace(/\s/g, '+')
                    }
                    dispatch(verifyEmail(data,props.history))
                }
            }
        }
    },[search,dispatch,props.history])
    // const loading = true
    const loading = auth.loading;
    const onResend = () =>{
        if(email){
            dispatch(resendEmail({email}))
        }
    }
    return (
        <Common
            headerText='Trekkers Information Management System'
            headerTextSub="TIMS"
            isLogin title="Sign In"
        sub={<span>Don't have an account? <Link to={RoutesEnum.Register}>Register Now</Link></span>}>
            <FormGroup method={method} onSubmit={onSubmit}>
                <div className="login-form__group">
                    <LogoutMessage />
                    <Form
                        placeholder="Email"
                        name="username"
                        leftIcon={<Icons name="User" />}
                        required={true}
                    />
                    <Form
                        placeholder="Password"
                        name="password"
                        type="password"
                        leftIcon={<Icons name="Lock" />}
                        required={true}
                    />
                    <div className="login-remember space-between">
                        <Form
                            name="remember"
                            formName="checkinput"
                            checkLabel="Remember me"
                            className="login-check"
                        />
                        <Link className="login-forgot" to={RoutesEnum.forgotPassword} >
                            <span>Forgot Password ?</span>
                        </Link>
                    </div>
                    <div className="button-group button-group-bold">
                        <Button
                            isDisabled={loading}
                            bg="primary"
                            buttonType="icon-button"
                            // type="button-outline"
                            icon={loading ? null :<Icons name="Login" />}
                            // buttonClass={!loading ? "" :"login-loading"}
                            // htmlButtonType="submit"
                            // onClick={()=>handleSubmit(onSubmit)}
                            onClick={handleSubmit((d) => onSubmit(d))}
                            >
                            {loading ? (
                                <>
                                    <span className="button-spin"></span>
                                    {/* <span>Logging In...</span> */}
                                </>
                                ) : (<span>Sign In</span>)
                            }
                        </Button>
                        {/* <Button
                            onClick={()=>props.history.push(RoutesEnum.Register)}
                            style={{border:'1px solid #e0e0e0'}}
                            buttonType="icon-button"
                            // type="button-outline"
                            icon={<Icons name="UserAdd" />}
                            bg="grey"  title="Sign up"
                            />  */}
                    </div>
                 {/*   <div className="newyear">HAPPY NEW YEAR 2080 </div>*/}
                </div>
            </FormGroup>
            {
                notVerified ? <div className="footer-verify">
                    <div className="footer-verify-text">We are sorry to inform you that your email has not been verified yet. We have sent a verification email to the address you provided during registration. If you are unable to locate the verification email, please click the button below to resend it.</div>
                    <div className="center-text">
                    <Button
                        isDisabled={loading}
                        bg="primary"
                        type="button-outline"
                        onClick={()=>onResend()}
                    >
                        Resend Email
                    </Button>
                    </div>
                </div>
                :null
            }
            <div className="footer-link">
                <Link to={RoutesEnum.LocationPath}>Self Check in</Link>
            </div>
            <div className="footer-text">
                <span>*This site is more suitable with Google Chrome and Microsoft Edge</span>
            </div>
          {/*  <FloatingNotice />*/}
        </Common>
        //<div style={{
        //    maxWidth: '600px',
        //    margin: '20px auto',
        //    padding: '20px',
        //    border: '2px solid #f44336',
        //    borderRadius: '10px',
        //    backgroundColor: '#fff3f3',
        //    fontFamily: 'Arial, sans-serif',
        //    color: '#333'
        //}}>
        //    <h2 style={{
        //        color: '#d32f2f',
        //        textAlign: 'center'
        //    }}>
        //        Maintenance Notice
        //    </h2>
        //    <p style={{
        //        fontSize: '16px',
        //        lineHeight: '1.5'
        //    }}>
        //        The system will be undergoing maintenance today, <strong>July 15, 2024</strong>, for the purpose of updating to the new financial year. The maintenance window is scheduled from <strong>5 PM to 12 AM midnight</strong>. We apologize for any inconvenience this may cause.
        //    </p>
        //    <p style={{
        //        textAlign: 'center',
        //        marginTop: '20px'
        //    }}>
        //        <em>Thank you for your understanding.</em>
        //    </p>
        //</div>
    )
}

const LogoutMessage = () => {
    const dispatch = useDispatch();
    const otherMessage = useSelector(
      (state: any) => state.validateReducer.otherMessage
    );
    if (!isEmpty(otherMessage)) {
      return (
        <div className="login-message">
          <div className="login-message__text">{otherMessage.message}</div>
          <div
            onClick={() => dispatch(clearOtherMessage())}
            className="login-message__close"
          >
            {/* <MdClose /> */}
            X
          </div>
        </div>
      );
    }
    return null;
  };

export default Login
