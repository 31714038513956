import https from 'services/http';
import Action from 'services/actionUtility';
import environment from 'environment';
import types from 'constants/types';
import RouteEnum from 'constants/routesEnum';
import getQueryUrl from 'helpers/getQueryUrl';
import { userEnums } from 'constants/contants';


export const registerNewTrekker = (data, callBack) => async dispatch => {
    dispatch({type:types.REGISTER_NEW_TREKKERS_LOADING,payload:true});
    const res = https.post(environment.api.registerTrekker,data)
    const actionConfig = {
		dispatch,
        isLoading:false,
		actionType:types.REGISTER_NEW_TREKKERS,
        message:'',
        type:'post',
        // message:'New Trekker Successfully Added',
		effect:res,
	}
    const response = await Action.createThunkEffect(actionConfig);
    dispatch({type:types.REGISTER_NEW_TREKKERS_LOADING,payload:false});
    if (response && response.Status) {
        // history.push(RouteEnum.Login)
        callBack(response?.Data?.UserRegistrationModel)
        dispatch({
            type: 'ADD_MESSAGE',
            payload: { type: 'success', message: 'Trekk detail added successfully.' }
        });
        return response;
    }else{
        if(response?.Message){
            dispatch({
                type: 'ADD_MESSAGE',
                payload: { type: 'danger', message: response?.Message ?? '500: Something went wrong' }
            });
        }
        
        // dispatch({
        //     type: 'ADD_MESSAGE',
        //     payload: { type: 'danger', message: '500: Something went wrong' }
        //   });
    }
    return false;
}

export const getTrekkingHistory = (page) => async dispatch =>{
    const finalUrl = getQueryUrl(environment.api.getTrekkingHistory,page)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_TREKKING_HISTORY,
        message:'',
        type:'get',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const updateTrekkingDetails = (data) => async dispatch => {
    const res = https.put(environment.api.updateTrekkingDetails,data)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_NEW_TREKKERS_DETAILS,
        message:'Trek Details Successfully Updated',
		effect:res,
        type:'put',
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const updateInsuranceDetails = (data) => async dispatch =>{
    const res = https.post(environment.api.updateInsuranceDetails,data)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_NEW_TREKKERS,
        message:'Insurance Details Successfully Updated',
		effect:res,
        type:'post',
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
        return true
    }
    return false;
}

export const updateUserDetails = (data,index=0) => async dispatch =>{
    const res = https.post(environment.api.updateUserDetails,data)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_NEW_TREKKERS,
        message:'User Details Successfully Updated',
		effect:res,
        type:'post',
        isLoading:true,
        extraPayload:{index}
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
        return true;
    }
    return false;
}


export const uploadVoucherDocuments = (data) => async dispatch => {
    const res = https.post(environment.api.uploadVoucher,data)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_VOUCHER_STATUS,
        type:'post',
        message:'Documents uploaded',
		effect:res,
	}
	await Action.createThunkEffect(actionConfig); 
    // if(response && response.Status){
    //     history.push(RouteEnum.UploadDocumentsStatus + "/" + uploadDocumentStatus.sent)
    // }
}


export const addTrekkerUserDetails = (data) => async dispatch => {
    dispatch({type:types.ADD_NEW_TREKKERS_LOADING,payload:true});
    const res = https.post(environment.api.adduserDetails,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_USER_DETAILS,
        type:'post',
        message:'Trkker added successfully.',
		effect:res,
	}
	const response = await Action.createThunkEffect(actionConfig);
    dispatch({type:types.ADD_NEW_TREKKERS_LOADING,payload:false});
    if(response && response.Status){
        return true;
    }
    return false; 
    // if(response && response.Status){
    //     history.push(RouteEnum.UploadDocumentsStatus + "/" + uploadDocumentStatus.sent)
    // }
}


export const postBills = (data,history,userId) => async dispatch =>{
    // debugger
    const res = https.post(environment.api.postBills,data)
    const actionConfig = {
		dispatch,
		actionType:'types.UPDATE_NEW_TREKKERS',
        message:'Bills updated ',
		effect:res,
        type:'post',
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        dispatch({type:types.GET_TREKKER,payload:null})
        if(userId === userEnums.free){
            history.push(RouteEnum.TrekkingHistory)
        }else{
            history.push(RouteEnum.Trekkers)
        }
        return true
    }
    return false
}
