import types from "constants/types";
import { GuideInitialState } from "ts/reducer";

const initialState: GuideInitialState = {
  guides: null,
  guide: null,
};

export default function GuideReducer(state = initialState, action): GuideInitialState {
  switch (action.type) {
    case types.GET_GUIDES:
      return {
        ...state,
        guides: action.payload,
      };
    case types.GET_GUIDE:
      return {
        ...state,
        guide: action.payload,
      };
    default:
      return state;
  }
}
