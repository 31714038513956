import axios from "axios";
import dayjs from "dayjs";
// import { getBaseAxios } from "./helpers/Constants";
const axiosInstance = axios.create({
  // baseURL:window.location.origin,
  // headers:{
  //   "Content-Type": "application/x-www-form-urlencoded", Accept: "application/json"
  // }
});

export const setAuthToken = (token) => {
  if (token) {
    // var accessToken = localStorage.getItem("AuthToken");
    //Apply to every request
    axiosInstance.defaults.headers.common["Authorization"] =  token;
  } else {
    //Delete Auth Header
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
};

// axiosInstance.defaults.headers.common = '';
// axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

// axiosInstance.defaults.headers.loading=true;

export default axiosInstance;
