import https from 'services/http';
import Action from 'services/actionUtility';
import environment from 'environment';
import types from 'constants/types';
import getQueryUrl from 'helpers/getQueryUrl';

export const getCard = (regId=null,trekId=null) => async dispatch=>{
    const res = https.get(`${environment.api.getSingleCard}?registrationId=${regId}&trekkerId=${trekId}`)
    const actionConfig = {
		dispatch,
		actionType:types.GET_SINGLE_USER_CARD,
        message:'',
        // type:'get',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const getCards = (page) => async dispatch=>{
    const finalUrl = getQueryUrl(environment.api.getCards,page)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_CARDS,
        message:'',
        type:'get',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const getAllCards = (page) => async dispatch=>{
    const finalUrl = getQueryUrl(environment.api.getCardsAll,page)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_CARDS,
        message:'',
        type:'get',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const getReturnCards = (page) => async dispatch=>{
    const finalUrl = getQueryUrl(environment.api.getReturnCards,page)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_RETURN_CARDS,
        message:'',
        type:'get',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const retrunCard = (regId,trekId) => async dispatch=>{
    const finalUrl = getQueryUrl(environment.api.returnCard,{registrationid:regId,trekkerid:trekId})
    const res = https.put(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.RETURN_CARD,
        message:'Card returned updated successfully',
        type:'put',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const approveRejectCard = (data) => async dispatch=>{
    const finalUrl = getQueryUrl(environment.api.approveRejectCard,data)
    const res = https.put(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.APPROVE_REJECT_CARD,
        message:'Card status updated successfully',
        type:'put',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}

export const generateCard = (regId,cb) => async dispatch=>{
    const res = https.get(environment.api.generateCard + regId)
    const actionConfig = {
		dispatch,
		actionType:types.GENERATE_CARD,
        message:'Card generated successfully',
        type:'get',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    console.log({response})
    if(response ){
        // history.push(RouteEnum.Login)
        cb && cb()
    }
}

export const deleteCard = (Id, cb) => async dispatch => {
    const finalUrl = getQueryUrl(environment.api.deleteCard, { Id: Id})
    //const res = https.get(environment.api.deleteCard + Id)
    const res = https.put(finalUrl)
    const actionConfig = {
        dispatch,
        actionType: types.DELETE_TREKKER,
        message: 'Trekker deleted successfully',
        type: 'put',
        effect: res,
        isLoading: true
    }
    const response = await Action.createThunkEffect(actionConfig);
    console.log({ response })
    if (response) {
        // history.push(RouteEnum.Login)
        cb && cb()
    }
}

export const deleteTrekkers = (data, cb) => async dispatch => {
    const finalUrl = getQueryUrl(environment.api.deleteCard,data)
    const res = https.put(finalUrl)
    const actionConfig = {
        dispatch,
        actionType: types.DELETE_TREKKER,
        message: 'Trekker deleted successfully',
        type: 'put',
        effect: res,
        isLoading: true
    }
    const response = await Action.createThunkEffect(actionConfig);
    if (response) {
        // history.push(RouteEnum.Login)
        cb && cb()
    }
}

