import React from "react";
import PropTypes from "prop-types";
import classnames from 'classnames';
interface Props {
  name: string,
  type: string,
  value: any,
  autoFocus: boolean,
  placeholder: string,
  disabled:boolean,
  onChange:Function,
  onKeyUp:Function,
  onBlur:Function,
  theme:string,
  label?:string,
  required?:any
  register?:any
};

const SearchInput = (props:Props) => {
  const { name, type, value, autoFocus,register,required, disabled, placeholder,theme } = props;

  const handleChange = (event) => {
    const { name, value } = event.target;
    props.onChange && props.onChange(name, value);
  };

  const handleKeyUp = (event) => {
    const { name, value } = event.target;
    props.onKeyUp && props.onKeyUp(name, value,event);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    props.onBlur && props.onBlur(name, value);
  };
  const formClasses = classnames({
    "generic-group__form":true,
    "generic-group__form-search":true,
    ["generic-group__form-" + theme]:true
})
  return (
    <div className={formClasses}>
        
        <div className="generic-group__form-group generic-group__form-group-search">
            <div className="generic-group__icon">
                <span className="iconify" data-inline="false" data-icon="bytesize:search"></span>
            </div>
            <input
            className="generic-group__form-input"
            placeholder={placeholder}
            value={value}
            name={name}
            disabled={disabled}
            ref={register && register({ required:required?  (props.label ? props.label :placeholder? placeholder:  '' ) + ' field is required' : false })}
            autoFocus={autoFocus}
            onKeyUp={handleKeyUp}
            onBlur={handleBlur}
            onChange={handleChange}
            />
        </div>
    </div>

  );
};



SearchInput.defaultProps = {
  name: "",
  type: "search",
  autoFocus: false,
};

export default SearchInput;
