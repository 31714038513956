import React,{useEffect} from 'react'
import { useDispatch } from 'react-redux';
import {logoutUser} from 'store/actions/auth';

function Logout() {
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(logoutUser(''))
    },[])
    return (
        <div>
            
        </div>
    )
}

export default Logout
