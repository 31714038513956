import React, { useEffect, useState } from 'react'
import {RouteNest} from './nestedRoutes';
import {Switch, useHistory} from 'react-router-dom'
import isEmpty from 'isEmpty';
import Header from 'components/layout/header';
import Sidebar from 'components/layout/sidebar';
import Dashboard from 'scenes/dashboard';
import Login from 'scenes/login';
import SignUp from 'scenes/login/signin';
import RegisterSuccess from 'scenes/login/registerSuccess';
import Register from 'scenes/register/new';
// import Register from 'scenes/register';
import Inbox from 'scenes/inbox';
import TrekkingHistory from 'scenes/trekkingHistory';
import Trekkers from 'scenes/trekkers';
import DeletedTrekkerRoute from 'scenes/deleted-trekkers';

import SelfScanTrekkers from 'scenes/selfScanTrekkers';
import RoutesPage from 'scenes/routes';
import TimsCards from 'scenes/timsCards';
import Users from 'scenes/users';
import SuccessPasswordReset from 'scenes/forgotPassword/successPasswordReset';
import ForgotPassword from 'scenes/forgotPassword';
import CheckMail from 'scenes/forgotPassword/checkMail';
import AccountSettings from 'scenes/accountSettings'
import Route from 'scenes/routes/route';
import CreatePassword from 'scenes/forgotPassword/createPassword';
import UserPassword from 'scenes/forgotPassword/userPassword';

import Card from 'scenes/timsCards/card';
import Billing from 'scenes/billing';
import NotFound from 'components/common/notFound';
import RoutesEnum from 'constants/routesEnum';
import { useDispatch, useSelector } from 'react-redux';
import Components from './components';
import Logout from 'scenes/account/logout';
import useCommonAPIs from 'hooks/useCommonAPIs';
import RouteAdd from 'scenes/routes/routeForm';
import Requests from 'scenes/requests';
import Reports from 'scenes/reports'
import Request from 'scenes/requests/request';
import User from 'scenes/users/user';
import TrekkingAgencyUser from 'scenes/users/trekkingAgency';
import FIT from 'scenes/users/fit';
import CheckPost from 'scenes/users/checkpoint';
import UploadDocuments from 'scenes/documents'
import UploadDocumentsStatus from 'scenes/documents/status'
import Scan from 'scenes/scanTrekker'
import ScannedTrekker from 'scenes/scanTrekker/trekker';
import {modulesList} from 'constants/contants';
import Location from 'scenes/location';
import VerifyEmail from 'scenes/forgotPassword/verifyEmail';
import { clearErrors, clearRegistrationState } from 'store/actions/auth';
import Chalan from 'scenes/chalan';
import ChalanLog from '../scenes/chalanlog';

function Routes() {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(()=>{
      history.listen((location,action)=>{
        dispatch(clearErrors())
        dispatch(clearRegistrationState())
      })
    },[])
    return (
      <Switch>
        <RouteNest name={modulesList.all} isNotPrivate path="/" component ={Layout} >
            <RouteNest name={modulesList.dashboad} title="Dashboard" exact path={["/",RoutesEnum.Dashboard]} component={Dashboard} />
            <RouteNest name={modulesList.all} isNotPrivate exact path={RoutesEnum.VerifyEmail} component={VerifyEmail} />
            <RouteNest name={modulesList.login} isNotPrivate exact path={RoutesEnum.Login} component={Login} />
            <RouteNest name={modulesList.signup} isNotPrivate exact path={RoutesEnum.Register} component={SignUp} />
            <RouteNest name={modulesList.login} isNotPrivate title="Register Success" exact path={RoutesEnum.registerSuccess} component={RegisterSuccess} />
            {/* <RouteNest isNotPrivate exact path={"/components"} component={Components} /> */}
            
            <RouteNest name={modulesList.forgotPassword} isNotPrivate title="Forgot Password" exact path={RoutesEnum.forgotPassword} component={ForgotPassword} />
            <RouteNest name={modulesList.passwordReset} isNotPrivate title="Successfully Password Reset" exact path={RoutesEnum.successPasswordChange} component={SuccessPasswordReset} />
            <RouteNest name={modulesList.checkMail} isNotPrivate title="Check Mail" exact path={RoutesEnum.checkMail} component={CheckMail} />
            <RouteNest name={modulesList.createPassword} isNotPrivate title="Create New Password" exact path={RoutesEnum.createNewPassword} component={CreatePassword} />

            <RouteNest  name={modulesList.trekkingHistory} roles={[1]} title="Trekking History" exact path={RoutesEnum.TrekkingHistory} component={TrekkingHistory} />
            <RouteNest name={modulesList.timsCard} roles={[1]} title="TIMS Cards" exact path={RoutesEnum.TimsCards} component={TimsCards} />
            <RouteNest name={modulesList.timsCard} title="TIMS Card" exact path={RoutesEnum.TimsCards + '/:id'} component={Card} />

                <RouteNest name={modulesList.trekkers} roles={[2, 4, 5, 6]} title="Trekkers" exact path={RoutesEnum.Trekkers} component={Trekkers} />
                <RouteNest name={modulesList.trekkers} roles={[4]} title="Deleted Trekkers" exact path={RoutesEnum.DeletedTrekkers} component={DeletedTrekkerRoute} />
                <RouteNest name={modulesList.userPassword} roles={[4]} title="User Password" exact path={RoutesEnum.UserPassword} component={UserPassword} />
                <RouteNest name={modulesList.selfScanTrekkers} roles={[4, 6]} title="Self Scan Trekkers" exact path={RoutesEnum.SelfScanTrekkers} component={SelfScanTrekkers} />
            <RouteNest name={modulesList.routes} roles={[2,4,5,6]} title="Routes" exact path={RoutesEnum.Route} component={RoutesPage} />
            <RouteNest name={modulesList.routesAdd} roles={[4]} title="Add a new Route" exact path={[RoutesEnum.RouteAdd,RoutesEnum.RouteAdd+'/:id']} component={RouteAdd} />
            <RouteNest name={modulesList.routes} title="Route" exact path={RoutesEnum.Route + '/:id'} component={Route} />
            
            <RouteNest name={modulesList.inbox} title="Inbox" exact path={RoutesEnum.Inbox} component={Inbox} />
            <RouteNest name={modulesList.registerTrekkers} title="Register New Trekker" exact path={[
                RoutesEnum.NewRegister,
                RoutesEnum.NewRegister+'/:regId',
                // RoutesEnum.NewRegister +'/:regId/:actionType',
                RoutesEnum.NewRegister +'/:regId/:payType/:actionType'
                
              ]} 
              // onLeave = {()=>{
              //   console.log("asasas")
              //   dispatch(clearRegistrationState())
              // }}
              component={Register} 
            />
            <RouteNest name={modulesList.users} title="Users" exact path={RoutesEnum.Users} component={Users} />
            <RouteNest name={modulesList.users} title="User" exact path={RoutesEnum.Users + '/:id'} component={User} />
            <RouteNest name={modulesList.users} title="Trekking Agency User" exact path={RoutesEnum.TrekkingAgencyUser + '/:id'} component={TrekkingAgencyUser} />
          <RouteNest name={modulesList.users} title="FIT User" exact path={RoutesEnum.FitAgencyUser + '/:id'} component={FIT} />
            <RouteNest name={modulesList.users} title="Checkpost User" exact path={RoutesEnum.CheckPostUser + '/:id'} component={CheckPost} />
            <RouteNest name={modulesList.logout} title="Logout" exact path={RoutesEnum.Logout} component={Logout} />
            <RouteNest name={modulesList.settings} title="Account Settings" exact path={RoutesEnum.accountSettings} component={AccountSettings} />
            <RouteNest name={modulesList.billing} title="Billing" exact path={RoutesEnum.Billing} component={Billing} />
            <RouteNest name={modulesList.requests} title="Requests" exact path={RoutesEnum.Request} component={Requests} />
            <RouteNest name={modulesList.reports} title="Reports" exact path={RoutesEnum.Reports} component={Reports} />
            <RouteNest name={modulesList.requests} title="Request" exact path={RoutesEnum.Request +'/:id'} component={Request} />
            <RouteNest name={modulesList.uploadDocuments} title="Upload Documents" exact path={RoutesEnum.UploadDocuments} component={UploadDocuments} />
            <RouteNest name={modulesList.uploadDocumentsStatus} title="Upload Documents Status" exact path={RoutesEnum.UploadDocumentsStatus+'/:type'} component={UploadDocumentsStatus} />
            <RouteNest name={modulesList.reports} title="Reports" exact path={RoutesEnum.Reports} component={Reports} />
            <RouteNest name={modulesList.scan} title="Scan" exact path={RoutesEnum.Scan} component={Scan} />
            <RouteNest name={modulesList.scannedTrekker} title="Scanned Trekker" exact path={RoutesEnum.ScannedTrekker +'/:regid/:id'} component={ScannedTrekker} />
            <RouteNest name={modulesList.chalan} title="Chalan" exact path={RoutesEnum.chalan} component={Chalan} />
            {/* <RouteNest name={modulesList.scannedTrekker} title="Scanned Trekker" exact path={RoutesEnum.ScannedTrekker +'/:id'} component={ScannedTrekker} /> */}
            <RouteNest name={modulesList.all} isNotPrivate title="Checkin" exact path={RoutesEnum.LocationPath} component={Location} />
                <RouteNest name={modulesList.notFound} exact path={RoutesEnum.NotFound} component={NotFound} />
                <RouteNest name={modulesList.chalanlog} title="Chalan Log" exact path={RoutesEnum.chalanlog} component={ChalanLog} />
        </RouteNest>
      </Switch>
    )
}

const Layout = (props) =>{
    const { isAuthenticated,profile } = useSelector((state:any)=>state.auth);
    const [title,setTitle] = useState('NTB');
    useEffect(()=>{
      if(props?.location?.title){
        setTitle(props?.location?.title)
      }else{
        setTitle('NTB')
      }
    },[props.location]);
    // useEffect(()=>{
    //   props.history.push(RoutesEnum.Register)
    // },[])
    // var isAuthenticated=props.location.pathname !== RoutesEnum.Register && props.location.pathname !== RoutesEnum.Login;
  //  var isAuthenticated=true;
  useCommonAPIs()
    return(
      <div className="layout">
        {isAuthenticated && <Sidebar />}
        <div className="layout-body">
          {isAuthenticated && (<Header title={title} history={props.history} />)}
          <div className="layout-data">
            {props.children}
          </div>
        </div>
      </div>
    )
  }

export default Routes
