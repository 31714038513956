import React, { useEffect, useState } from 'react'
import './styles.scss'
import { useDispatch, useSelector } from 'react-redux';
import { 
    getDashboard, 
    getDashboardTotal,
    getDashboardRecentTrekkers,
    getDashboardTopTrekkers,
    getDashboardTopCountry
} from 'store/actions/dashboardActions';
import { RootStateTypes } from 'ts/reducer';
import dayjs from 'dayjs';
import BarChartNew from './barChartTrekkers';
import DataBlocks from './dataBlocks';
import NewDataBlock from './newDataBlock'
import BarChartRevenue from './barChartRevenue';
import RecentTrekekrs from './recentTrekekrs';
import TopTrekkers from './topTrekkers';
import TopCountry from './topCountry';

var lastday = new Date(new Date().setDate(new Date().getDate() + 1));
var firstday = new Date(new Date().setDate(lastday.getDate() - 7));

const initialState = {
    startDate:firstday,
    endate:lastday,
    fromDate:'',
    toDate:'',
    tekkinginDate:'',
    tekkingOutDate:''
}

const getTextDate = (data) =>{
    if(data){
        let newData:any = {}
        Object.keys(data).map(item=>{
            if(data[item]){
                newData[item] = dayjs(data[item]).format('MM/DD/YYYY')
            }else{
                newData[item] = "";
            }
            return null;
        })
        return newData
    }
    return data;
}

function Dashboard(props) {
    const [state,setState] = useState(initialState)
    const dispatch = useDispatch();
    
    const {dashboard,dashboardTotal}:any = useSelector((state:RootStateTypes)=>state.dashboardReducer);
    const onDateRangeChange = async (date) =>{
        await setState(prev=>({...prev,...date}))
        // await getDashboardData()
    }
    useEffect(()=>{
        const getDashboardData = async () =>{
            const textState = getTextDate(state)
            await dispatch(getDashboard(textState))
        }
        getDashboardData()
    },[state])
    useEffect(()=>{
        const getData = async () => {
            dispatch(getDashboardTotal())
            dispatch(getDashboardRecentTrekkers())
            dispatch(getDashboardTopTrekkers())
            dispatch(getDashboardTopCountry())
        }
        getData();
    },[])

    return (
        <div className="common-container" style={{backgroundColor:'#F4F5F6'}} >
            {/* <DataBlocks data={dashboardTotal} /> */}
            <NewDataBlock data={dashboardTotal} />
            <div className="dashboard-reports">
                <RecentTrekekrs />
                <TopTrekkers />
                <TopCountry />
            </div>
            {/* <BarChartNew onDateRangeChange={onDateRangeChange} dashboardData={dashboard} /> */}
            {/* <BarChartRevenue onDateRangeChange={onDateRangeChange} dashboardData={dashboard} />  */}
        </div>
    )
}

export default Dashboard
