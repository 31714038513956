import React, { useEffect, useState } from 'react'
import { Tabs, Tab } from 'components/tabs'
import Profile from './profile'
import Card from './card'
import './styles.scss';
import RouteEnum from 'constants/routesEnum';
import { getUser } from 'store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import Breadcrumbs from 'components/breadcrumb';


function FIT(props) {

    // const [active, setActive] = useState(1);
    const { user } = useSelector((state: RootStateTypes) => state.userReducer);
    const dispatch = useDispatch()
    const { id } = props.match.params;
    const breadList = [{ title: 'Users', to: RouteEnum.Users }, { title: user?.FullName, to: RouteEnum.TrekkingAgencyUser, isCurrent: true }];
    useEffect(() => {
        dispatch(getUser(id))
    }, [dispatch,id])
    return (
        <>
            <Breadcrumbs linkList={breadList} />
            <div className="fit-user">
                <Tabs activeTab={'profile'} color="common" >
                    <Tab name={'profile'} label="Profile" >
                        <Profile user={user} />
                    </Tab>
                    <Tab name={'card'} label="TIMS Card" >
                        <Card user={user} />
                    </Tab>
                </Tabs>
            </div>

        </>

    )
}

export default FIT
