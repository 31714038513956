import React from 'react'

function PaymentMessage() {
  return (
    <div>
      <p className="text-light-red text-center">Please wait for 10-15 minutes before your next payment The system will update your payment</p>
    </div>
  )
}

export default PaymentMessage