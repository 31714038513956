import Icons from 'components/icons'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootStateTypes } from 'ts/reducer'

function Overview({routeDetails,routes}) {
    const { routesProvince } = useSelector((state: RootStateTypes) => state.routeReducer)
    const provinceName = routesProvince && routeDetails?.ProvinceId && routesProvince.find(item=>item.value == routeDetails?.ProvinceId)
    return (
        <div className="trek-preview">
            <div className="trek-preview__table">
                <div className="trek-preview__header">
                    <div className="trek-preview__title"><span>Trek Details</span></div>
                </div>
                <div className="trek-preview__body">
                    <div className="trek-preview__row">
                        <div className="trek-preview__col">
                            <div className="trek-preview__label">Trek route name:</div>
                            <div className="trek-preview__value">{routeDetails?.RouteName}</div>
                        </div>
                        <div className="trek-preview__col">
                            <div className="trek-preview__label">Province:</div>
                            <div className="trek-preview__value">{provinceName && provinceName.label}</div>
                        </div>
                    </div>
                    <div className="trek-preview__row">
                        <div className="trek-preview__col">
                            <div className="trek-preview__label">Start point:</div>
                            <div className="trek-preview__value">{routeDetails?.StartPoint}</div>
                        </div>
                        <div className="trek-preview__col">
                            <div className="trek-preview__label">End Point:</div>
                            <div className="trek-preview__value">{routeDetails?.EndPoint}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="trek-preview__content">
                <div className="trek-preview__content-title"><span>Routes</span></div>
                <div className="trek-preview__content-p">
                    <div className="trek-preview__content-routes">
                        {routes && routes.filter(item=>item.IsEndPoint !== true).map(item=><div key={item.Route} ><span>{item.Route}</span><Icons type="iconify" dataIcon="bi:arrow-right"></Icons></div>)}
                        {/* {routes && <span className="iconify" data-icon="bi:arrow-right" data-inline="false"></span>} */}
                        {routes && routes.filter(item=>item.IsEndPoint === true).map(item=><div key={item.Route} ><span>{item.Route}</span></div>)}
                    </div>
                </div>
            </div>
            <div className="trek-preview__content">
                <div className="trek-preview__content-title"><span>Introduction</span></div>
                <div className="trek-preview__content-p">
                    {routeDetails?.Discription && <div dangerouslySetInnerHTML={{__html:routeDetails?.Discription}}></div>}
                </div>
            </div>
        </div>
    )
}

export default Overview
