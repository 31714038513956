import TrekkerModel from "models/TrekkerModel";


export enum StepperEnums{
    userInfo=1,
    review=2,
    payment=3,
}  

export type TStep = {
    title:string,
    step:StepperEnums
}

export interface IUserRegisterationModel {
    TrekkingAreaId: any,
    TrekExitDate: string,
    TrekEntryPoint: string,
    TrekExitPoint: string,
    TrekEntryDate: string,
    TrekRoute: string,
    RegistrationId: any,
    PorterGuideInfo: string,
    PorterGuideContact: string,
    PorterGuideLicesenceNo: string,
    OtherSupportStaffs: string,
}

export interface IUserDetailsModel {
    Files: any,
    FullName: string,
    RegionalId: any,
    City: any,
    Gender: any,
    Dob: any,
    Email: any,
    PassportNumber: any,
    VisaNumber: any,
    VisaIssueDate: any,
    VisaExpiryDate: any,
    ContactPerson: any,
    Organization: any,
    Mobile: any,
    HomeOfficeNo: any,
    Address: any,
    NativeContactPerson: any,
    NativeDesignation: any,
    NativeMobile: any,
    NativeOfficeNo: any,
    NativeAddress: any,
    Nationality: any,
    InsuranceCompany: any,
    InsuranceCountry: any,
    PolicyNumber: any,
    PolicyType: any,
}

export interface IWholeFormModel extends IUserDetailsModel,IUserRegisterationModel {}

export interface IObjectRetur{
    isPaymnet?:boolean,
    isFailure?:boolean,
    isSuccess?:boolean,
    isDecline?:boolean,
    isNotReq?:boolean,
    regId?:string,
    TXNID?:Array<any>,
}

export interface IRegisterTrekkerContext extends IObjectRetur{
    activeStep:StepperEnums;
    setActiveStepper?:Function;
    steps:Array<TStep>;
    userRegisterationModel:IUserRegisterationModel,
    registered?:TrekkerModel,
    isDto?:boolean,
    paymentError?:boolean,
    paymentLoad?:boolean,
    amount?:any
}


export const steps:Array<TStep> = [
    {title:'User information',step:StepperEnums.userInfo},
    {title:'Review',step:StepperEnums.review},
    {title:'Payment',step:StepperEnums.payment},
]


export const getParsedQueryObject = (params,parsedReq):IObjectRetur => {

    const isPaymnet = (parsedReq?.isPayment && parsedReq?.isPayment === '1') ?? false;
    const isFailure = (params.actionType && params.actionType === 'failure') ?? false;
    const isSuccess = (params.actionType && params.actionType === 'success') ?? false;
    const isDecline = (params.actionType && params.actionType === 'decline') ?? false;
    const isNotReq = (params?.regId && params?.regId === 'noreg') ?? false;
    const TXNID = parsedReq?.TXNID ? parsedReq.TXNID.split("@-@") : null;
    const regId = (params?.regId) ?? (parsedReq?.regId) ?? null
    return {
        isPaymnet,
        isFailure,
        isSuccess,
        isDecline,
        isNotReq,
        regId:regId,
        TXNID
    }
}