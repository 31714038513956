import React, { useContext, useEffect, useState } from 'react'
import Icons from 'components/icons/svgIcons';
import Button from 'components/button';
import { useDispatch, useSelector } from 'react-redux';
import { postBills, uploadVoucherDocuments } from 'store/actions/registerTrekker';
import { RootStateTypes } from 'ts/reducer';
import { useHistory } from 'react-router-dom';
import Context from '../context';

function PaymentUpload(props) {
    const vals = useContext(Context)
    const [error, setError] = useState([]);
    const [file, setFile] = useState(null);
    // const regId = "30b13eae-c5b1-42bf-8b2e-87ed2fe1e19f";
    const { user } = useSelector((state: RootStateTypes) => state.auth);
    const userId = 17;
    const history = useHistory()
    const allowedMinSize = .001;
    const allowedMaxSize = 2;
    const allowedExt = ".jpg,.jpeg,.png,.pdf";
    const isError = Boolean(error.length > 0);
    const dispatch = useDispatch();
    console.log({vals})
    useEffect(()=>{
        onBillSend()
    },[])
    const onChange = async (e) => {
        const fl = e.target.files[0]

        if (fl) {
            var FileSize = fl.size / 1024 / 1024;
            const isfilestype = (/\.(jpe?g|pdf?|png)$/i).test(fl.name)
            const isimagetype = (/\.(jpe?g|png)$/i).test(fl.name)
            setError([])
            if (FileSize > allowedMaxSize) {
                await setError(prev => ([...prev, "The file size should not be more than 2MB."]))
                return;
            }
            if (FileSize < allowedMinSize && isimagetype) {
                await setError(prev => ([...prev, "The image file size should be atleast 1Kb."]));
                return;
            }
            if (!isfilestype) {
                await setError(prev => ([...prev, "Only .jpg, .png and pdf extensions allowed"]));
                return;
            }
            setFile(fl)
        }
    }
    const onUpload = () => {
        if (file) {
            const fd = new FormData();
            fd.append('RegistationId', vals.regId)
            fd.append('Files', file)
            dispatch(uploadVoucherDocuments(fd))
        }
    }
    const onBillSend = async () => {
        if(file){        
            const data = {
                "RegistationId": vals.registered?.UserRegistrationModel?.RegistrationId,
                "Amount": vals.amount,
                "PaymentMethod": 5,
                "UserID": user.UserID,
                "Files": file
            }
            const fd = new FormData();
            Object.keys(data).map(item => {
                fd.append(item, data[item])
            })
            const res = await dispatch(postBills(fd, history, user.UserType));
        }

    }
    return (
        <div className="payment-upload">
            <div className="payment-upload__container">
                <div className="payment-upload__header">
                    <div className="payment-upload__title">Upload Payment Voucher</div>
                </div>
                <div className="payment-upload__body">
                    <div className="payment-upload__label">
                        <div className="payment-upload__icon"><Icons name="Remarks" size={25} /></div>
                        <div className="payment-upload__text">Verification through Voucher takes 1 or 2 business days. </div>
                    </div>
                    <div className="payment-upload__upload">
                        <div className="payment-upload__upload-icon">
                            <Icons name="UploadFiles" size={25} />
                        </div>
                        <div className="payment-upload__upload-input">
                            <label>
                                File
                                <input
                                    type="file" name={"upload_file"} onChange={onChange} />
                                {
                                    file && <span className="payment-upload__upload-filename">{file.name}</span>
                                }
                            </label>
                        </div>
                        <div className="payment-upload__text">Supported file format - png/jpeg/jpg/pdf </div>
                    </div>
                    {
                        isError && (
                            <div className="pay-errors">
                                {
                                    error && error.map(item => <span key={item} >{item}</span>)
                                }
                            </div>
                        )
                    }

                    {
                        file && (
                            <div className="payment-upload__btn">
                                <Button onClick={() => onBillSend()} title={"Submit"} bg="primary" />
                            </div>
                        )
                    }

                </div>
            </div>
        </div>
    )
}

export default PaymentUpload
