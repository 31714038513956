import React, { useState } from 'react'
import Common from './common';
import Form from 'components/form';
import Button from 'components/button';
import RoutesEnum from 'constants/routesEnum';
import FormGroup from 'components/form/formGroup';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { registerUser } from 'store/actions/auth';
import { useForm, useWatch } from 'react-hook-form';
import Modal from 'components/modal';
import Icons from 'components/icons/svgIcons';
import { Link } from 'react-router-dom';
import CommonPasswordValidation from 'components/common/commonPasswordValidation'
import usePasswordValidationResolver from 'hooks/usePasswordValidation'
import classNames  from 'classnames';
import {onlyText,emailValid,onlyNum} from 'constants/regex';


function Signin(props) {
    const dispatch = useDispatch()
    const methods = useForm();
    const { control, watch, reset } = methods
    const [open, setOpen] = useState(false);
    const errors = useSelector((state: RootStateTypes) => state.validateReducer.errors);
    const userTypes = useSelector((state: RootStateTypes) => state.selectReducer.userType)
    const countries = useSelector((state: RootStateTypes) => state.selectReducer.countries)
    const { userLoading } = useSelector((state: RootStateTypes) => state.loadingReducer)
    const userType: any = useWatch({ control, name: 'UserType' });
    const password: any = useWatch({ control, name: 'Password' });
    const email: any = useWatch({ control, name: 'Email' });
    const onModalClose = () => setOpen(false);
    const onSubmit = (data) => {
        data['UserType'] = data['UserType'].value;
        data['CountryID'] = userType?.value === '2' ? 524 : data['CountryID'].value;
        dispatch(registerUser(data, props.history, callback))
    }
    const callback = () => {
        // setOpen(true)
        props.history.push(RoutesEnum.registerSuccess+`?email=${email}`);
        reset()
    }
    const isAgent = userType?.value === '2'
    const isUser = Boolean(userType);

    const {
        pass,
        isNotEight,
        isNotNum,
        isNotLowUp,
        isNotNonAlph
    } = usePasswordValidationResolver(password)
    return (
        <div className={classNames("signin",{ "login-before": !isUser })}>
            <Common 
                headerText='Trekkers Information Management System' 
                headerTextSub="TIMS" 
                isFooter title="Register" sub={<span>Already have an account? <Link to={RoutesEnum.Login}>Login Here</Link></span>}>
                <FormGroup method={methods} onSubmit={onSubmit}>
                    <div className="login-form__group">
                        <div className="login-form__text">

                            <div className="login-form__row">
                                <Form
                                    formName="reactSelect"
                                    placeholder="Select Users.."
                                    name="UserType"
                                    required={true}
                                    options={userTypes}
                                />
                                {
                                    isUser && (
                                        
                                        <Form
                                        placeholder="Email"
                                        name="Email"
                                        type="email"
                                        required={true}
                                        validate={(value) => emailValid(value) || "Please enter a correct email address"}

                                    />
                                )}
                            </div>
                            {
                                isUser && (
                                    <>

                                        {
                                            isAgent && (
                                                <div className="login-form__row">
                                                    <Form
                                                        placeholder="Organization Name"
                                                        name="OrganizationName"
                                                        required={true}
                                                        validate={(val) => !onlyText(val) || 'Only text allowed'}
                                                    />
                                                    <Form
                                                        placeholder="Organization PAN Number"
                                                        name="OrganizationPanNumber"
                                                        required={true}
                                                        // isNoAlp
                                                        validate={(val) => onlyNum(val, 9) || 'Please enter 9 digit number'}
                                                    />

                                                </div>
                                            )
                                        }
                                        <div className="login-form__row">
                                            <Form
                                                placeholder={isAgent ? "Contact Person Name" : "Full Name"}
                                                name="FullName"
                                                // regex={/^[a-zA-Z ]*$/}
                                                required={true}
                                                validate={(val) => !onlyText(val) || 'Only text allowed'}
                                            />
                                            {
                                                isAgent ? (
                                                    <Form
                                                        placeholder="Contact Number"
                                                        name="ContactNo"
                                                        // isNoAlp
                                                        required={true}
                                                        validate={(val) => onlyNum(val, 10) || 'Please enter 10 digit number'}
                                                    />
                                                ) : (
                                                    <Form
                                                        formName="reactSelect"
                                                        placeholder="Choose a country"
                                                        name="CountryID"
                                                        required={true}
                                                        options={countries}
                                                        isSearchable={true}
                                                    />
                                                )
                                            }
                                        </div>

                                        {
                                            isAgent && (
                                                <div>
                                                    <Form
                                                        placeholder="Contact Address"
                                                        name="ContactAddress"
                                                        required={true}
                                                    />
                                                </div>
                                            )
                                        }
                                        <div className="login-form__row">
                                            <Form
                                                placeholder="Password"
                                                name="Password"
                                                type="password"
                                                validate={(value) => isNotEight && isNotLowUp && isNotNum && isNotNonAlph || ""}
                                                required={true}
                                            />
                                            <div className="show-phone">
                                                <CommonPasswordValidation
                                                    pass={pass}
                                                    isNotEight={isNotEight}
                                                    isNotLowUp={isNotLowUp}
                                                    isNotNum={isNotNum}
                                                    isNotNonAlph={isNotNonAlph}
                                                />
                                            </div>
                                            <Form
                                                placeholder="Confirm Password"
                                                name="ConfirmPassword"
                                                type="password"
                                                validate={(value) => value === watch("Password") || "Password don't match."}
                                                required={true}
                                            />
                                        </div>
                                        {
                                            errors && Array.isArray(errors) && (
                                                <ul className="login-errors">
                                                    {
                                                        errors.map(item => {
                                                            return (
                                                                <li key={item}>{item}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            )
                                        }
                                        <div className="login-form__row">
                                            <div className="hide-phone">
                                                <CommonPasswordValidation
                                                    pass={pass}
                                                    isNotEight={isNotEight}
                                                    isNotLowUp={isNotLowUp}
                                                    isNotNum={isNotNum}
                                                    isNotNonAlph={isNotNonAlph}
                                                />
                                            </div>
                                            <div className="">
                                                <div className="button-group button-group-bold">
                                                    <Button
                                                        isDisabled={userLoading}
                                                        buttonType="icon-button"
                                                        icon={<Icons name="UserAdd" />}
                                                        bg="primary" htmlButtonType="submit" title="Register" 
                                                    />
                                                    
                                                </div>
                                                <div className="login-form__container-footer">
                                                    <span>
                                                        By clicking the button above, you agree to our
                                                        
                                                    </span>   
                                                    <div>
                                                        <span className="login-form__container-footer-link"> Terms of use</span>
                                                        <span className="login-form__container-footer-link">  Privacy Policy</span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </FormGroup>
                <Modal
                    open={open}
                    width={"400px"}
                    onModalClose={onModalClose}
                    className="email-modal"
                >
                    <div className="email-modal__body">
                        <div className="email-modal__content">
                            <div className="email-modal__content-title">Account Verification</div>
                            <div className="email-modal__content-subtitle">Please check your email for account verification.</div>
                        </div>
                        <div className="email-modal__btns flex">
                            <Button
                                type="button-outline"
                                buttonType="icon-button"
                                title="Open Gmail"
                                // bg="primary"
                                onClick={() => window.open("https://mail.google.com/")}
                                icon={<Icons name="Gmail" />}
                            />
                            <Button
                                type="button-outline"
                                buttonType="icon-button"
                                title="Open Webmail"
                                onClick={() => window.open("https://mail.google.com/")}
                                // bg="grey"
                                icon={<Icons name="Webmail" />}
                            />
                        </div>
                    </div>
                </Modal>
            </Common>
        </div>
    )
}

export default Signin
