import React, { useEffect } from 'react'
import Form from 'components/form';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { uploadImage, updateName } from 'store/actions/auth';
import './styles.scss'
import { useForm, useWatch } from 'react-hook-form';
import Avatar from 'react-avatar';

function ChangeAccount(props) {
    const dispatch = useDispatch()
    const { user } = useSelector((state: RootStateTypes) => state.auth)
    const { setValue, register, control } = props.method;
    const image = useWatch({ control: control, name: "profile_image" })
    // const onSubmit = (data) => {
    //     const nameData = {
    //         fullName: data.fullName,
    //         userId: user.UserID
    //     }
    //     dispatch(updateName(nameData))
    // }
    useEffect(() => {
        if (user.FullName) {
            setValue("fullName", user.FullName)
        }
    }, [user])
    // const onUpload = (data) => {
    //     const fd = new FormData();
    //     fd.append('Files', data.profile_image);
    //     fd.append('UserId', user.UserID);
    //     dispatch(uploadImage(fd))
    //     props.onModalClose()
    //     // uploadImage
    // }

    return (
        <div className="account-profile">
            <div className="account-settings-form account-profile__body">
                {/* <FormGroup method={methods} onSubmit={onUpload}> */}
                {/* <div className="account-profile__upload-container">
                    <div className="account-profile__upload-avatar">
                        <Avatar
                            name={user.FullName}
                            size={"80px"}
                            round
                            src={image ? URL.createObjectURL(image) : user?.UserImage}
                        />
                    </div>
                    <div className="account-profile__upload-right">
                        {image && <span
                            title="Remove"
                            className="account-profile__upload-remove"
                            onClick={() => setValue("profile_image", null)}
                        >
                            Remove
                        </span>
                        }
                        <div className="account-profile__upload">
                            <Form
                                name="profile_image"
                                formName="uploadInput"
                                btnText={image ? "Change Picture" : "Add Picture"}
                                control={control}
                                noImageShow
                            />
                        </div>
                    </div>

                </div> */}

                {/* </FormGroup> */}
                {/* <FormGroup onSubmit={onSubmit}> */}
                <div className="account-profile__details">
                    <div className="account-profile__row">
                        <Form
                            name="fullName"
                            label="Full Name"
                            register={register}
                            control={control}
                            required={true}
                        ></Form>
                    </div>
                </div>
                {/* <div className="account-profile__button">
                        <Button
                            icon={<Icons name="Save" size={16} />}
                            bg="primary" 
                            // type="button-outline" 
                            buttonType="icon-button"
                            title="Save Changes"
                            htmlButtonType="submit"

                        />
                        <Button
                            bg="cancel"
                            title="Cancel"
                            // type="button-outline"
                            // type="button-outline"
                            onClick={()=>props.onModalClose()}
                        />
                    </div> */}
                {/* </FormGroup> */}
            </div>
        </div>
    )
}

export default ChangeAccount
