import React,{useRef,useEffect} from 'react'
import { getRouteProvince } from 'store/actions/routesAction';
import { 
    getGender, 
    getRegions,
    getCountries,
    getTrekkingArea,
    getUserType,
    getUserAgentType, 
    getPaymentMethods,
    getRouteDrop, 
    getCheckpoint, 
    getRegisterType,
    getFilterAgencyUsers,
    getTrekkerPersionality,
    getGuides
} from 'store/actions/selectAction';
import {getNotifications} from 'store/actions/dashboardActions';
import store from 'store/store';
import { getUser } from 'store/actions/auth';

function useCommonAPIs(){
    const dispatch = store.dispatch;
    useEffect(()=>{
      (async ()=>{
        const promiseArray = [
          dispatch(getUserType()),
          dispatch(getCountries()),
        ]
        await Promise.all(promiseArray);
      })()  
    },[])
    useEffect(() => {
        if(store?.getState()?.auth?.isAuthenticated){
          (async ()=>{
            const promiseArray = [
              dispatch(getUser()),
              dispatch(getGender()),
              dispatch(getTrekkingArea()),
              dispatch(getUserAgentType()),
              dispatch(getRouteProvince()),
              dispatch(getRouteDrop()),
              dispatch(getCheckpoint()),
              dispatch(getRegions()),
              dispatch(getFilterAgencyUsers()),
              dispatch(getRegisterType()),
              dispatch(getPaymentMethods()),
              dispatch(getTrekkerPersionality()),
              dispatch(getCountries()),
              dispatch(getNotifications()),
              dispatch(getGuides()),
            ]
            await Promise.all(promiseArray);
          })()        
        }else{
 
        }
      }, [store?.getState()?.auth?.isAuthenticated])
}


export default useCommonAPIs