import React,{useEffect,useRef} from 'react'
import { Link } from 'react-router-dom';
import './sideblock.styles.scss';
import classnames from 'classnames'
import {CommonSidebar} from './sidebar';
import useEventListener from 'src/hooks/useEventListener';
import logo from 'assets/images/logo.png';


function Sidebar({open,onClose}) {
    const sideRef = useRef(null);
    const sidebarRef = useRef(null);
    const sidebarClass = classnames({
        "sideblock":true,
        "sideblock-close":!open,
        "sideblock-open":open
    });
    // useEventListener('click',(e)=>{
    //     if(sidebarRef.current && !sidebarRef.current.contains(e.target)){

    //     }
    // })
    // useEffect(()=>{
    //     let setTime;
    //     if(sideRef && sideRef.current){
    //         if(open){
    //             sideRef.current.classList.add('sideblock-open')
    //         }
    //     }


    //     return(
    //         clearTimeout(setTime)
    //     )
    // },[open]);
    const onClick=()=>{
        onClose()
    }
    // if(!open) return null
    return (
        <div ref={sideRef} className={sidebarClass}>
            <div onClick={()=>onClose()} className="sideblock-overlay">
                
            </div>
            <div ref={sidebarRef} className="sideblock-bg">
                {/* <div className="sideblock-top">
                    <button className="button button-white" onClick={()=>onClick()}>
                        <span>Close</span>
                    </button>
                    <i className="icon-delivery-logo"></i>
                </div> */}
                <CommonSidebar isPhone onClose={onClose} />
                {/* <div className="sideblock__footer-image">
                    <img src={ logo} alt="Nepal Logo" />
                </div> */}
            </div>
        </div>
    )
}

export default Sidebar
