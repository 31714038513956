import React from 'react'

function Profile({user}) {
    return (
        <div className="trekking-agency-background">
            <div className="trekking-agency-background__row">
                <div className="trekking-agency-background__col">Organization Name</div>
                <div className="trekking-agency-background__col">{user?.OrganizationName}</div>
            </div>
            <div className="trekking-agency-background__row">
                <div className="trekking-agency-background__col">Organization PAN Number</div>
                <div className="trekking-agency-background__col">{user?.OrganizationContact}</div>
            </div>
            {/* <div className="trekking-agency-background__row">
                <div className="trekking-agency-background__col">Phone Number</div>
                <div className="trekking-agency-background__col">{user?.PhoneNumber}</div>
            </div> */}
            <div className="trekking-agency-background__row">
                <div className="trekking-agency-background__col">Email Address</div>
                <div className="trekking-agency-background__col">{user?.Email}</div>
            </div>
            <div className="trekking-agency-background__row">
                <div className="trekking-agency-background__col">Contact Address</div>
                <div className="trekking-agency-background__col">{user?.ContactAddress}</div>
            </div>
            <div className="trekking-agency-background__row">
                <div className="trekking-agency-background__col">Registration Date</div>
                <div className="trekking-agency-background__col">{user?.RegisterdDate}</div>
            </div>
            <div className="trekking-agency-background__row">
                <div className="trekking-agency-background__col">Contact Number</div>
                <div className="trekking-agency-background__col">{user?.ContactNo}</div>
            </div>
            <div className="trekking-agency-background__row">
                <div className="trekking-agency-background__col">Contact Person Name</div>
                <div className="trekking-agency-background__col">{user?.FullName}</div>
            </div>
        </div>
    )
}

export default Profile
