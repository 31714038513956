import Container from 'components/layout/container';
import RouteEnum from 'constants/routesEnum';
import React, { useEffect, useState } from 'react';
import Trekkers from 'scenes/common/trekkers'
import Breadcrumbs from 'components/breadcrumb';
import { useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { userEnums } from 'constants/contants';
import { Tabs, Tab } from 'components/tabs'
import classnames from 'classnames';
import ScannedTrekkers from 'scenes/users/checkpoint/scannedTrekkers';
import  './styles.scss';
import {Profiler} from 'react'

function TrekkerRoute() {
    const breadList = [{ title: 'Trekkers', to:RouteEnum.Trekkers, isCurrent: true }]
    const {user} = useSelector((state:RootStateTypes)=>state.auth);
    const isCheckpost = user.UserType === userEnums.check;
    function onRender(id, phase, actualDuration, baseDuration, startTime, commitTime) {
        // Aggregate or log render timings...
        console.log({id,phase, actualDuration, baseDuration, startTime, commitTime})
      }
    return (
        <>
            <Breadcrumbs isHome linkList={breadList} />
            <div className="trekkers  common-container">
                <div className={classnames("routes",{"trekkers-tabs":isCheckpost})}>
                    <Container>
                        {
                            isCheckpost ? (
                                <Tabs isAlternative activeTab={"Scanned Trekkers"}>
                                    <Tab name={"Scanned Trekkers"} label={"Scanned Trekkers"} >
                                        <ScannedTrekkers user={{...user,Id:user.UserID}} />
                                    </Tab>
                                    <Tab name={"Trekkers"} label={"Trekkers"} >
                                        <Trekkers />
                                    </Tab>
                                </Tabs>
                            ) : (
                                // <Profiler id="Trekkers" onRender={onRender}>
                                <Trekkers />
                                // </Profiler>
                            )
                        }
                    </Container>
                </div>
            </div>
        </>

    )
}

export default TrekkerRoute
