import Button from 'components/button';
import Icons from 'components/icons/svgIcons';
import React, { useRef } from 'react'
import Pdf from "react-to-pdf";
import ReactToPrint from "react-to-print";

var a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

function inWords(num) {
    if (!num || isNaN(num)) {
        return ""
    }
    if ((num = num.toString()).length > 9) return 'overflow';
    var n: any = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'Only ' : '';
    return str;
}


function InvoiceNew(props) {
    const { invoice } = props;
    const ref = useRef(null);
    const isFree = invoice?.RegisterAgency === 'FIT';
    const isCheck = invoice?.RegisterAgency === 'Checkpost';
    const iAgency = !isFree && !isCheck;
    return (
        <div className="">
            <div className="common-sidebar__headers" style={{ gap: 12 }}>
                <Pdf targetRef={ref} filename={`Receipt-${invoice.InvoceNumber}-${new Date().toISOString()}.pdf`}>
                    {({ toPdf }) => <Button title="Export as PDF" size="small"
                        // type="button-outline" 
                        onClick={toPdf} buttonType="icon-button" icon={<Icons name="Down" size={14} />} bg="primary" />}

                </Pdf>
                <ReactToPrint
                    trigger={() => <Button bg="primary"
                        // type="button-outline" 
                        size="small" title="Print" buttonType="icon-button" icon={<Icons name="Print" size={14} />} />}
                    content={() => ref.current}
                />
            </div>
            <div ref={ref} id={`invoice-${invoice.InvoceNumber}`} className="">
                <div className="invoiceNew">
                    <div className="invoiceNew-header">
                        <div className="invoiceNew-title"><span>Nepal Tourism Board</span></div>
                        <div className="invoiceNew-sub">Bhrikundimandap, Kathmandu Nepal</div>
                        <div className="invoiceNew-sub">Phone: 00977-014256909</div>
                        <div className="invoiceNew-sub">E-mail: tims@ntb.org.np</div>
                        {/* <div className="invoiceNew-copy">Customer Copy</div>*/}
                    </div>
                    <div className="invoiceNew-body">
                        <div className="invoiceNew-container">
                            <div className="invoiceNew-row">
                                {/*<div className="invoiceNew-col">*/}
                                {/*    <div className="invoiceNew-label">VAT No.</div>*/}
                                {/*    <div className="invoiceNew-vat">*/}
                                {/*        <div className="invoiceNew-vat-box">5</div>*/}
                                {/*        <div className="invoiceNew-vat-box">0</div>*/}
                                {/*        <div className="invoiceNew-vat-box">0</div>*/}
                                {/*        <div className="invoiceNew-vat-box">0</div>*/}
                                {/*        <div className="invoiceNew-vat-box">5</div>*/}
                                {/*        <div className="invoiceNew-vat-box">2</div>*/}
                                {/*        <div className="invoiceNew-vat-box">5</div>*/}
                                {/*        <div className="invoiceNew-vat-box">6</div>*/}
                                {/*        <div className="invoiceNew-vat-box">9</div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="invoiceNew-col">*/}
                                {/*    <div className="invoiceNew-label">VAT No.</div>*/}
                                {/*    <div className="invoiceNew-value">{invoice.UserVatNo}</div>*/}
                                {/*</div>*/}
                                <div className="receiptheader">
                                  RECEIPT
                                </div>
                            </div>
                            <div className="invoiceNew-row">
                                <div className="invoiceNew-col">
                                    <div className="invoiceNew-label">Name</div>
                                    <div className="invoiceNew-value">{invoice.FullName}</div>
                                </div>
                                <div className="invoiceNew-col">
                                    <div className="invoiceNew-label">Receipt No.</div>
                                    <div className="invoiceNew-value">{invoice.InvoceNumber}</div>
                                </div>
                            </div>
                            <div className="invoiceNew-row">
                                <div className="invoiceNew-col">
                                    <div className="invoiceNew-label">Address: </div>
                                    <div className="invoiceNew-value">{invoice.Address}</div>
                                </div>
                                <div className="invoiceNew-col">
                                    <div className="invoiceNew-label">Receipt Date:</div>
                                    <div className="invoiceNew-value">{invoice.PaymmentDate}</div>
                                </div>
                            </div>
                            <div className="invoiceNew-row">
                                <div className="invoiceNew-col">
                                    <div className="invoiceNew-label">Contact No:</div>
                                    <div className="invoiceNew-value">{invoice.ContactNo}</div>
                                </div>
                                <div className="invoiceNew-col">
                                    <div className="invoiceNew-label">Email</div>
                                    <div className="invoiceNew-value">{invoice.Email}</div>
                                </div>
                            </div>
                        </div>
                        <div className="invoiceNew-table">
                            <div className="invoiceNew-table__header">
                                <div className="invoiceNew-table__row">
                                    <div className="invoiceNew-table__col">S.No</div>
                                    <div className="invoiceNew-table__col">Particular</div>
                                    <div className="invoiceNew-table__col">Quantity</div>
                                    <div className="invoiceNew-table__col">Rate</div>
                                    <div className="invoiceNew-table__col">Value</div>
                                </div>
                            </div>
                            <div className="invoiceNew-table__body">
                                <div className="invoiceNew-table__row">
                                    <div className="invoiceNew-table__col">1</div>
                                    <div className="invoiceNew-table__col">Tims Card - {isFree ? "Free individual Trekker" : isCheck ? "Checkpost" : "Trekking Agency"}</div>
                                    <div className="invoiceNew-table__col">{invoice.UserVatNo}</div>
                                    <div className="invoiceNew-table__col"></div>
                                    <div className="invoiceNew-table__col">{invoice.Amount}</div>
                                </div>
                            </div>
                            <div className="invoiceNew-table__footer">
                                <div className="invoiceNew-table__row">
                                    <div className="invoiceNew-table__col"></div>
                                    <div className="invoiceNew-table__col"></div>
                                    <div className="invoiceNew-table__col"></div>
                                    <div className="invoiceNew-table__col">Total</div>
                                    <div className="invoiceNew-table__col">{invoice.Amount}</div>
                                </div>
                            </div>
                        </div>
                        <div className="invoiceNew-footer">
                            <strong>Amount in words:</strong>
                            <span> Nrs {inWords(invoice.Amount)} Only.</span>
                        </div>
                    </div>
                    <div className="note"> Note: Please obtain the tax
                        invoice from NTB</div>
                    <div className="bolltomText"> For Nepal Tourism Board </div>
                    <div className="invoiceNew-bottomnote">
                        Note:  This is computer generated receipt.
                    </div>
                </div>
            </div>

        </div>

    )
}

export default InvoiceNew
