import React, { useEffect } from 'react'
import Form from 'components/form';
import { RootStateTypes } from 'ts/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { addUsers, updateUsers } from 'store/actions/userActions';
import { useForm, useWatch } from 'react-hook-form';
import { getCheckpoint } from 'store/actions/selectAction';
import CommonPasswordValidation from 'components/common/commonPasswordValidation'
import usePasswordValidationResolver from 'hooks/usePasswordValidation'

function lowercaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }

function UserFormModal(props) {
    const {
        open,
        onModalClose,
        method
    } = props;
    const dispatch = useDispatch()
    const { routesProvince } = useSelector((state: RootStateTypes) => state.routeReducer)
    // const method = useForm();
    const { setError,setValue, control,register, errors:errs,watch } = method;
    const currentUserType: any = useWatch({ control, name: 'userType' });
    const currentRoute: any = useWatch({ control, name: 'routeID' });
    const errors = useSelector((state: RootStateTypes) => state.validateReducer.errors);
    const { userType, wholeUserType, agentUserType, routes, checkpoints } = useSelector((state: RootStateTypes) => state.selectReducer)
    const { user } = useSelector((state: RootStateTypes) => state.auth)
    const onSubmit = (data) => {
        data['userType'] = data['userType'].value;

        if (props.isEdit) {
            data['userId'] = props.editData.UserID
            dispatch(updateUsers(data))
        } else {
            data['userId'] = user.UserID
            dispatch(addUsers(data))
        }

    }
    useEffect(() => {

        if (props.editData) {

            setValue("email", props.editData.Email)
            setValue("fullName", props.editData.FullName)
            setValue("isAdmin", props.editData.IsAdmin)
            const userTypes = wholeUserType.find(item => item.value == props.editData.UserType)
            if (userTypes) {
                setValue("userType", userTypes)
            }

            // console.log(getValues("userType"))
        }
    }, [props.isEdit, props.editData]);
    useEffect(()=>{
        if(currentRoute && currentRoute.value){
            dispatch(getCheckpoint(currentRoute.value))
        }
    },[currentRoute])
    const {erroList} = useSelector((state:RootStateTypes)=>state.validateReducer);
    useEffect(()=>{
        if(erroList && Array.isArray(erroList) && erroList.length > 0){
            erroList.map(item=>{
                
                Object.keys(item).map(key=>{
                    setError(lowercaseFirstLetter(key), {
                        type: "manual",
                        message:item[key]
                      })
                }
                  )
            })
        }
    },[erroList])
    const password = watch("password")
    const {
        pass,
        isNotEight,
        isNotNum,
        isNotLowUp,
        isNotNonAlph
    } = usePasswordValidationResolver(password)
    return (
        <div className="users-register">
            <div className="users-register__container">
                {/* <div className="users-register__title">Registration</div>
                <div className="users-register__caption">Fill the below form to create a new account.</div> */}
                {/* <FormGroup method={method} onSubmit={onSubmit}> */}
                    <div className="users-register__form">
                        <Form
                            formName="reactSelect"
                            name="userType"
                            label="User Type"
                            options={agentUserType}
                            required={true}
                            control={control}
                            register={register}
                            error={errs}
                        />
                        {
                            currentUserType && currentUserType.Value === '5' && (
                                <>
                                    <Form
                                        name="provinceId"
                                        label="Province"
                                        placeholder="Select Province"
                                        control={control}
                                        required
                                        formName="reactSelect"
                                        options={routesProvince}
                                        error={errors}
                                    />
                                    <Form
                                        name="localGovermentInfo"
                                        label="Local Goverment Name"
                                        required={true}
                                        control={control}
                                        register={register}
                                        error={errs}
                                    />
                                    <Form
                                        name="wardNo"
                                        label="Ward No"
                                        required={true}
                                        control={control}
                                        register={register}
                                        error={errs}
                                    />

                                    <Form
                                        formName="reactSelect"
                                        name="routeID"
                                        label="Routes"
                                        options={routes}
                                        required={true}
                                        control={control}
                                        register={register}
                                        error={errs}
                                    />
                                    {
                                        currentRoute && <Form
                                            formName="reactSelect"
                                            name="checkPointID"
                                            label="Checkpost"
                                            options={checkpoints}
                                            required={true}
                                            control={control}
                                            register={register}
                                            error={errs}
                                        />
                                    }
                                </>
                            )
                        }
                        <Form
                            name="fullName"
                            label="Full Name"
                            required={true}
                            control={control}
                            register={register}
                            error={errs}
                        />
                        <Form
                            name="email"
                            type="email"
                            label="Email"
                            required={true}
                            control={control}
                            register={register}
                            error={errs}
                        />
                        {
                            !props.isEdit && (
                                <Form
                                    name="password"
                                    label="Password"
                                    type="password"
                                    validate={(value) => isNotEight && isNotLowUp && isNotNum && isNotNonAlph  || ""}
                                    control={control}
                                    register={register}
                                    error={errs}
                                    required={true}
                                />
                            )
                        }
                        {
                            !props.isEdit && (
                                <CommonPasswordValidation 
                                    pass={pass}
                                    isNotEight={isNotEight}
                                    isNotLowUp={isNotLowUp}
                                    isNotNum={isNotNum}
                                    isNotNonAlph={isNotNonAlph}
                                />
                            )
                        }
                        {
                            !props.isEdit && (
                                <Form
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type="password"
                                    required={true}
                                    validate={(value)=>value === password || "Password don't match."}
                                    control={control}
                                    register={register}
                                    error={errs}
                                />
                            )
                        }

                        {/* <Form
                            formName="checkinput"
                            name="isAdmin"
                            required={true}
                            checkLabel="Is Admin"
                            control={control}
                            register={register}
                            error={errs}
                        /> */}

                        {
                            errors && Array.isArray(errors) && (
                                <ul className="login-errors">
                                    {
                                        errors.map(item => {
                                            return (
                                                <li key={item}>{item}</li>
                                            )
                                        })
                                    }
                                </ul>
                            )
                        }
                        {/* <Button size="large" title={props.isEdit ? "Update user" : "Register"} htmlButtonType="submit" bg="primary-light" />
                        {props.isEdit && <Button bg="grey" title="Cancel" onClick={() => props.onEditCancel()} />} */}
                    </div>
                {/* </FormGroup> */}
            </div>
        </div>
    )
}

export default UserFormModal
