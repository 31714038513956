import React, { useEffect } from 'react'
import Dropdown from '../dropdown'
import Icons from 'components/icons/svgIcons';
import './notif.scss';
import { useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { getNotifications,updateNotifications } from 'store/actions/dashboardActions';
import classnames from 'classnames';
import {Link} from 'react-router-dom'
import localizedFormat from 'dayjs/plugin/localizedFormat';
// var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

function Notification(props) {
    const {notifications} = useSelector((state:RootStateTypes)=>state.dashboardReducer);
    const dispatch = useDispatch()
    const getNotifi = () =>{
        dispatch(getNotifications())
    }
    const markAllRead = () =>{
        dispatch(updateNotifications(null))
    }
    const isRead = notifications && notifications.length > 0? notifications.some(item=>item.IsRead) : true
    let sum = 0 
    const notifNum = notifications ? notifications.map((item)=>!item.IsRead ? sum +=1 : null) : sum;
    return (
        <Dropdown
            callback={getNotifi}
            isFilter
            dropClass="notification-drop"
            title={
                (
                    <div className="header-notification__icon">
                        <Icons name="Bell" size={22} color="#333" />
                        {!isRead && <span className="header-notification__is"></span>}
                    </div>
                )
            }
            isIcon={false}>
            <div className="header-notification__dropdown">
                <div className="header-notification__header">
                    <div className="flex">
                        <div className="header-notification__title">Notifications</div>
                        <div className="header-notification__tag">{sum || 0}</div>
                    </div>
                    <div className="header-notification__mark">
                        <span onClick={()=>markAllRead()}>Mark all read</span>
                    </div>
                </div>
                <div className="header-notification__body">
                    
                    <ul className="header-notification__list">
                        {
                            notifications && notifications.length > 0 ? notifications.map(item=>{
                                return(
                                    <li 
                                        onClick={()=>dispatch(updateNotifications(item.Id))}
                                        key={item.Id} className={classnames("header-notification__item",{"header-notification__item-read":item.IsRead})}>
                                        <div className="header-notification__item-title">
                                            <span>{item?.Body} {item.RedirectUrl && <Link to={item?.RedirectUrl}>View</Link>} </span>
                                        </div>
                                        <div className="header-notification__item-sub">
                                            <div className="flex">
                                                <div className={
                                                    classnames("header-notification__item-box", {
                                                        "green":item?.NoticeType === 'Payment',
                                                        "purple":item?.NoticeType === 'Route',
                                                        "pink":item?.NoticeType !== 'Route' && item?.NoticeType !== 'Payment',
                                                    })
                                                } style={{backgroundColor:item?.boxColor}} ></div>
                                                <div className="header-notification__item-text">{item?.NoticeType}</div>
                                            </div>
                                            <div className="header-notification__item-time">
                                                <span>{ item?.CreatedDate }</span>
                                                {/* <span>{dayjs(item?.createdOn).format('ll') }</span> */}
                                            </div>
                                        </div>
                                    </li>
                                )
                            }) : (
                                <div className="notification-empty">
                                    No notifications found
                                </div>
                            )
                        }
                    </ul>
                </div>
            </div>
        </Dropdown>
    )
}

export default Notification
