import React from 'react'
import Common from '../login/common';
import checkImg from 'assets/images/check_email.png'
import Button from 'components/button';
import RoutesEnum from 'constants/routesEnum';

function CheckMail(props) {
    return (
        <Common isFooter={false}>
            <div className="forgot">
                {/* <div className="forgot-image">
                    <img src={checkImg} alt="Forgot Password Image"/>
                </div> */}
                <div className="forgot-title login-form__container-title">
                    <span>Check in your mail!</span>
                </div>
                <div className="forgot-sub login-form__container-sub">
                    <span>we just emailed you the password reset link with instructions.</span>
                </div>
                <div className="login-btn-block">
                    <Button
                            title="Login"
                            bg="primary"
                            // type="button-outline"
                            htmlButtonType="button"
                            onClick={()=>props.history.push(RoutesEnum.Login)}
                        />
                </div>
            </div>
        </Common>
    )
}

export default CheckMail
