import types from 'constants/types';
import HttpErrorResponseModel from './httpErrorResponseModel';
import DataFormator from 'helpers/dataFormator';
import convertToKeyValue from 'helpers/convertToKeyValue';
import isEmpty from 'helpers/isEmpty';
interface actionProps{
  dispatch:any,
  actionType:string,
  effect:any,
  type?:string
  message?:string
  id?:string|number,
  isSelect?:boolean,
  showError?:boolean,
  isLoading?:boolean,
  isUserLoading?:boolean,
  extraPayload?:{},
  isJustData?:boolean,
  model?:any,
}

export default class ActionUtility {
  static async createThunkEffect({
                                  dispatch, 
                                  isUserLoading, 
                                  extraPayload, 
                                  showError = true,
                                  actionType,
                                  isSelect, 
                                  isJustData, 
                                  effect, 
                                  id, 
                                  model:Models,
                                  message,
                                  type,
                                  isLoading,
                              }:actionProps) {
    // if(type === 'get') dispatch(ActionUtility.createAction(actionType));
  
    if(isLoading) dispatch({type: types.ADD_LOADING,payload:true});;
    if(isUserLoading) dispatch({type: types.ADD_USER_LOADING,payload:true});;
    const model = await effect;
    if(isLoading) dispatch({type: types.ADD_LOADING,payload:false});;
    if(isUserLoading) dispatch({type: types.ADD_USER_LOADING,payload:false});;
    const httpError = model instanceof HttpErrorResponseModel;
    if(showError && model?.data && !model?.data?.Status){
      dispatch(toastMessage({message:model?.data?.Message,type:'danger'}))
    }
    let isError = httpError || model?.data?.Status === false;
    if(isError){
      let erroList = null
      if(model &&  model.hasOwnProperty('data')){
        if( model?.data &&  model?.data.hasOwnProperty('Data')){
          erroList = convertToKeyValue(model.data.Data);
        }
        dispatch({type:`GET_ERRORS`,payload:model,erroList});
        return model?.data;
      }

      if(model && model.status === 500){
        dispatch(toastMessage({message:"Something went wrong, Please try again later",type:'danger'}))
      }
      return model;
    }
    if(isSelect){
      dispatch(ActionUtility.createAction(actionType,DataFormator(model.data), isError,extraPayload));
      return model.data;
    }
    if(isJustData){
      dispatch(ActionUtility.createAction(actionType,model.data, isError,extraPayload));
      return model.data;
    }
    if(type === 'post' || type === 'put'){
      dispatch({type:types.CLEAR_ERRORS})
    }
    if(type === 'get') {
      const final = Models ? new Models(model.data) : model.data; 
      dispatch(ActionUtility.createAction(actionType, final, isError, extraPayload));
      return final
    }
    if(model.data &&  model.data.hasOwnProperty('Status') && !model.data.Status){
      let erroList = null
      if(model.data &&  model.data.hasOwnProperty('Data')){
        erroList = convertToKeyValue(model.data.Data);
      }
      dispatch({type:types.GET_ERRORS,payload:model.data.Message,erroList});
      return model.data;
    }

    if(type === 'delete'){
      dispatch(ActionUtility.createAction(actionType, id, isError,extraPayload));
    }else{
      const final = Models ? new Models(model.data.Data) : model.data.Data; 
      dispatch(ActionUtility.createAction(actionType, final, isError,extraPayload));
    }
    if(message){
      dispatch(toastMessage({message,type:'success'}))
    }
    dispatch({type:types.CLEAR_ERRORS});
    return model.data;
  }
  static createAction(type, payload=null, error = false,  extraPayload=null) {
    return extraPayload ? { type, payload, error, ...extraPayload } : { type, payload, error };
  }
}

export const toastMessage = (data) =>dispatch=>{
    let MessageType =  data.type ? data.type : null;
    let Message = data.message ? data.message : [];
    if(!isEmpty(Message)){
      dispatch({
        type: 'ADD_MESSAGE',
        payload: { type: MessageType, message: Message }
      });
    }

  }