import React, { useContext, useState } from 'react'
import TrekFrom from './trekForm'
import UserDetailsForm from './userDetailsForm';
import Context from '../context'
import isEmpty from 'helpers/isEmpty';
import { v4 as uuid } from 'uuid';
import Button from 'components/button'
import { StepperEnums } from '../helpers';
import IconsN from 'components/icons';
import { RootStateTypes } from 'ts/reducer';
import { useSelector } from 'react-redux';
import { userEnums } from 'constants/contants';

function EditForm() {
    const [forms,setForms] = useState([])
    const vals = useContext(Context);
    const TrekkersDetailsDtos  = vals.registered?.TrekkersDetailsDtos;
    const isTrekkers = !isEmpty(TrekkersDetailsDtos);
    const user = useSelector((state: RootStateTypes) => state.auth.user)
    const isNtb =  user?.UserType === userEnums.ntb;
    const isNewTrekkers = forms.length && forms.length > 0;
    const onRemove = (id)=>{
        setForms(prev=>{
            return prev.filter(item=>item.id !== id?.id)
        })
    }
    return (
        <>
            <TrekFrom  />
            {
                !isTrekkers ? 
                    <UserDetailsForm /> :
                TrekkersDetailsDtos?.map(item=>{
                    return <UserDetailsForm key={"Trekker" + (item.TrekkersDetails.ID ?? "2")} isEdit currentUser={item} /> 
                })
            }
            {
                forms && forms.map(item=><UserDetailsForm key={"Trkker-"+item.id} onRemove={onRemove} trekItem={item} isRemovable={true} /> )
            }
            <div className="trekkers-footer space-between mt-sm">
                <div className=""></div>
                {!isNewTrekkers && isTrekkers && <Button  
                    title={"Add Trekker"}
                    bg='secondary'
                    size="large"
                    buttonType="icon-button" 
                    leftIcon={<IconsN type="iconify" size={20} dataIcon="bi:plus" />} 
                    onClick={()=>setForms(fr=>{
                        return [
                            ...fr,
                            {
                                id:uuid(),
                            },
                        ]
                    })}
                />}
                {
                    isTrekkers && !isNtb ? <Button  
                    bg='primary'
                    title={"Next"}
                    size="large"
                    buttonType="icon-button" 
                    rightIcon={<IconsN type="iconify" dataIcon="bi:arrow-right" />} 
                    onClick={()=>{
                        if(vals.activeStep === StepperEnums.review){
                            vals.setActiveStepper(StepperEnums.payment);
                        }
                    }}
                /> : <span></span>
                }
            </div>

        </>
    )
}

export default EditForm