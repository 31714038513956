import React, { useState } from 'react'
import Dropdown from 'components/dropdown';
import Icons from 'components/icons';
import './styles.scss';

const filters = [
    {label:'All',value:'All'},
    {label:'Registration',value:'Registration'},
    {label:'Users',value:'Users'},
    {label:'TIMS Cards',value:'TIMS Cards'},
]

function Inbox() {
    const [active,setActive] = useState('All')
    return (
        <div className="inbox">
            <div className="inbox-header">
                <Dropdown dropClass="inbox-dropdown" customDropIcon={<Icons type="iconify" dataIcon={"fluent:filter-24-regular"} />} title="Filters">
                        <div className="inbox-filters">
                            <div className="inbox-filtes__list">
                                {
                                    filters.map(item=>(
                                        <div onClick={()=>setActive(item.value)} key={item.label} className="inbox-filters__item">
                                            {item.value === active && <Icons type="iconify" dataIcon="akar-icons:check" />}
                                            <span>{item.label}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                </Dropdown>
            </div>
            <div className="inbox-body">
                <div className="inbox-container">
                    <ul className="inbox-list">
                        <li className="inbox-item">
                            <div className="inbox-item__caption">
                                <div className="inbox-item__caption-status"></div>
                                <div className="inbox-item__caption-text">Registration</div>
                            </div>
                            <div className="inbox-item__body">
                                <div className="inbox-item__avatar">SD</div>
                                <div className="inbox-item__content">
                                    Shree Krishna Travels registered in TIMS as Trekking agency. 
                                    {/* <a href="#">Verify</a> */}
                                </div>
                            </div>
                        </li>
                        <li className="inbox-item">
                            <div className="inbox-item__caption">
                                <div className="inbox-item__caption-status"></div>
                                <div className="inbox-item__caption-text">Registration</div>
                            </div>
                            <div className="inbox-item__body">
                                <div className="inbox-item__avatar">SD</div>
                                <div className="inbox-item__content">
                                    Shree Krishna Travels registered in TIMS as Trekking agency. 
                                    {/* <a href="#">Verify</a> */}
                                </div>
                            </div>
                        </li>
                        <li className="inbox-item">
                            <div className="inbox-item__caption">
                                <div className="inbox-item__caption-status"></div>
                                <div className="inbox-item__caption-text">Registration</div>
                            </div>
                            <div className="inbox-item__body">
                                <div className="inbox-item__avatar">SD</div>
                                <div className="inbox-item__content">
                                    Shree Krishna Travels registered in TIMS as Trekking agency. 
                                    {/* <a href="#">Verify</a> */}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Inbox
