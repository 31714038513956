import * as React from "react";
import classnames from "classnames";
import isEmpty from "isEmpty";
import { Controller } from "react-hook-form";

interface IAppProps {
  name?: string;
  value: any;
  error?: string;
  labelPosition?: string;
  checkLabel?: string;
  className?: string;
  checked?: boolean;
  onChange: Function;
  disabled?: boolean;
  register?:any
  required?:any;
  color?:any,
  isCheckGroup?:boolean,
  label?:any;
}

const CheckGroup: React.FunctionComponent<IAppProps> = (props) => {
  const {
    name,
    value,
    error,
    checkLabel,
    checked,
    className,
    onChange,
    label,
    labelPosition,
    isCheckGroup,
    disabled,
    register, 
    required,
    color
  } = props;
  var inputclass = classnames({
    "custom-check": true,
    error: !isEmpty(error) && error,
    [className]: className,
    // [size]:size,
    "label-right": labelPosition === "right",
  });
  const [isChecked,setChecked] =React.useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setChecked(!isChecked)
    onChange && onChange(name, value);
  };
  React.useEffect(()=>{
    if(checked){
      setChecked(checked)
    }
  },[checked])
  const isColor = Boolean(color);
  const style = isColor?{color :color}:{};
  if(isCheckGroup){
    
    return <label style={style} className={inputclass}>
      <span >{checkLabel}</span>
      <input
        type="checkbox"
        name={name}
        checked={isChecked}
        onChange={handleChange}
        value={value}
        disabled={disabled}
      />
      <div className="checkmark"></div>
    </label>
  }
  return (
    <label style={style} className={inputclass}>
      <span >{checkLabel}</span>
      <input
        type="checkbox"
        name={name}
        checked={isChecked}
        onChange={handleChange}
        value={value}
        disabled={disabled}
        ref={register && register({ required:required?  (props.label ? props.label : '' ) + ' field is required' : false })}
      />
      <div className="checkmark"></div>
    </label>
  );
};
CheckGroup.defaultProps = {
  disabled: false,
  labelPosition:'right'
};

export default CheckGroup;
