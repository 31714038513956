import React,{useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateTypes } from 'ts/reducer';
import Modal from 'components/modal';
import ChangePassword from './changePassword';
import ChangeAccount from './changeAccount';
import ChangeEmail from './changeEmail'
import './styles.scss';
import { getUser } from 'store/actions/userActions';
import { deleteUsers } from 'store/actions/userActions';
import Confirm from 'components/common/confirm'
import RouteEnum from 'constants/routesEnum';
import Breadcrumbs from 'components/breadcrumb';
import Button from 'components/button';
import Icons from 'components/icons/svgIcons';
import CommonSidebar from 'components/common/commonSidebar';
import { updateCheckDetails, updateName, uploadImage } from 'store/actions/auth';
import { useForm } from 'react-hook-form';
import CheckForm from './checkForm';
import { clearErrors } from 'store/actions/auth';
const items = [
    {
        id:1,
        title:'Account Details',
        field:'FullName',
    },
    {
        id:2,
        title:'Email',
        field:'Email',
    },
    {
        id:3,
        title:'Password',
        field:null,
    }
]

function AccountSettings(props) {
    const [open,setOpen] = useState(false);
    const [active,setActive] = useState(null);
    const [accOpen, setAccOpen] = useState(false);
    const dispatch = useDispatch()
    const onModalClose = () =>{
        setOpen(false);
        setActive(null);
        dispatch(clearErrors())
    }
    const onModalOpen = (num) =>{
        setActive(num);
        setOpen(true);
        dispatch(clearErrors())
    }
    const {user} = useSelector((state:RootStateTypes)=>state.userReducer);
    const {user:us} = useSelector((state:RootStateTypes)=>state.auth);
    const [checkOpen, setCheckOpen] = useState(false);
    // const [check, setCheck] = useState(null);
    const {id} = props.match.params;
    const method = useForm();
    const { handleSubmit,setValue,setError } = method;
    const onCheckSideModalClose = () => {
        setCheckOpen(false);
        // setCheck(null);
    }
    // const onChecknModalOpen = (num) => {
    //     setCheck(num);
    //     setCheckOpen(true)
    // }
    useEffect(()=>{
        dispatch(getUser(id))
    },[dispatch,id])
    const getComponents = () =>{
        switch(active){
            // case 1:
            //     return <ChangeAccount onModalClose={onModalClose} />
            case 2:
                return <ChangeEmail onModalClose={onModalClose} />
            case 3:
                return <ChangePassword id={id} onModalClose={onModalClose} />
            default:
                return null
        }
    }
    const onDelete = (id) =>{
        dispatch(deleteUsers(id + "",props.history))
    }
    const onAccSave = () => {
        handleSubmit((data) => {
            
            if(data.fullName){
                const nameData = {
                    fullName: data.fullName,
                    userId:id
                }
                dispatch(updateName(nameData))
            }
            if(data.profile_image){
                const fd = new FormData();
                fd.append('Files', data.profile_image);
                fd.append('UserId',id);
                dispatch(uploadImage(fd))
                setValue("profile_image", null)
                
            }
            setAccOpen(false)
        },(e)=>console.log({e}))()
    }
    const onCheckSubmit = async (data) => {
        // data['organizationPanNumber'] = data['organizationPanNumber'];
        // data['organizationName'] = data['organizationName'];
        data['CheckPointID'] = data?.CheckPointID?.value
        data['ProvinceId'] = data?.ProvinceId?.value
        data['RouteID'] = data?.RouteID?.value
        data['UserId'] = id
        const flag = dispatch(updateCheckDetails(data))
        if(flag){
            onCheckSideModalClose()
        }
    }
    const onCheckSave = () => {
        handleSubmit((data) => {
            onCheckSubmit(data)
        },(e)=>{
            if(e){
                Object.keys(e).map(item=>{
                    setError && setError(item, {
                        type: "manual",
                        message:e[item]?.message,
                      });
                })
            }
        })()
    }
    const sameUser =  parseInt(us?.UserID) === parseInt(id);
    const isComponents = Boolean(getComponents())
    const breadList = [{ title: 'Users', to: RouteEnum.Users }, { title: user?.FullName, to: RouteEnum.TrekkingAgencyUser, isCurrent: true }];
    return (
        <>
        {!props.isCheckPost && <Breadcrumbs linkList={breadList} />}
        <div className="account-settings">
            
            <div className="account-settings__container">
                <ul className="account-settings__list">
                    {
                        items.map(item=>{
                            return <li key={item.id} className="account-settings__item">
                                <div className="account-settings__label">{item.title}</div>
                                <div className="account-settings__value">
                                    {item.field && user && user[item.field]}
                                </div>
                                <div className="account-settings__btn">
                                    {/* <button
                                        onClick={()=>onModalOpen(item.id)}
                                        >Change</button> */}
                                    {us?.UserType==4 &&
                                        <Button
                                            title="Change"
                                            icon={<Icons name="Cog" size={16} />}
                                            buttonType="icon-button"
                                            onClick={() => item.id === 1 ? props.isCheckPost ? setCheckOpen(true) : setAccOpen(true) : onModalOpen(item.id)}
                                            bg="primary"
                                        // type="button-outline"
                                        />}
                                </div>
                            </li>
                        })
                    }
                    {
                        !props.isCheckPost && !sameUser && (
                            <li className="account-settings__item">
                                <div className="account-settings__label">Delete User</div>
                                <div className="account-settings__value">
                                </div>
                                <div className="account-settings__btn">
                                    <Confirm title="User" deleteFunction={()=>user && onDelete(user.Id)}>
                                        {/* <button
                                            className="delete-btn"
                                            // onClick={()=>user && onDelete(user.Id)}
                                            >Delete</button> */}
                                        <Button 
                                            title="Delete"
                                            icon={<Icons name="Times" size={16} />}
                                            buttonType="icon-button"
                                            // onClick={()=>onModalOpen(item.id)}
                                            bg="danger"
                                            // type="button-outline"
                                        />
                                    </Confirm>
        
                                </div>
                            </li>
                        )
                    }
                </ul>
                {!props.isCheckPost && <CommonSidebar onSave={onAccSave} title="Account Name" open={accOpen} onModalClose={()=>setAccOpen(false)}>
                    <ChangeAccount method={method} onModalClose={()=>setAccOpen(false)} />
                </CommonSidebar>}
                {props.isCheckPost && <CommonSidebar onSave={onCheckSave} title="Update Checkpost Information" open={checkOpen} onModalClose={onCheckSideModalClose}>
                    <CheckForm method={method} />
                </CommonSidebar>}
                {
                    isComponents && 
                        <Modal
                            open={open}
                            onModalClose={onModalClose}
                            width="500px"
                            className="account-settings-modal"
                            title={active === 1 ?"Change Account Name": active ===2 ?"Change E-mail Address":active===3 ?"Change Password":null}
                        >
                            {getComponents()}
                        </Modal>  
                }
            </div>
        </div>
        </>
        
    )
}

export default AccountSettings