import React from 'react'
import Trekkers from 'scenes/common/trekkers'

function RegisteredTrekkers({user:u}) {
    return (
        <div className="">
            <Trekkers userId={u?.UserId}  />
        </div>
    )
}

export default RegisteredTrekkers
