import React, { useEffect, useState }  from 'react'
import Container from 'components/layout/container';
import Table from 'components/table';
import Form from 'components/form';
import { useForm, useWatch } from 'react-hook-form';
import { Status, Reason } from 'components/table/commonTableComponents';
import Icons from 'components/icons/svgIcons'
import { pageSizeValues } from 'constants/contants';
import Pagination from 'components/table/pagination';
import { getAllCards, getCard } from 'store/actions/cardActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import isEmpty from 'helpers/isEmpty';
import FilterDrop from 'components/common/filterDrop';
import FormGroup from 'components/form/formGroup';
import Button from 'components/button';
import TimsCard from 'scenes/trekkers/timsCard';
import CommonSidebar from 'components/common/commonSidebar';
import { CardStatusFilter, TrekkingRouteFilter } from 'components/common/filters';

const pageState = {
    pageindex:0,
    pageSize:pageSizeValues[0].value,
    totalRows:0,
}
const filterState = {
    regionalId:"",
    cardStatus:"",
}


function TimsCards(props) {
    const dispatch = useDispatch();
    const { control, register } = useForm();
    const [filter, setFilter] = useState(filterState)
    const [page, setPage] = useState(pageState)
    const [data,setData] = useState([]);
    const onPageChange = async (page) =>await setPage(prev=>({...prev,pageindex:page}))
    const {cards,card}:any =useSelector((state:RootStateTypes)=>state.cardReducer)
    const [timCard,setTimCard] = useState(null);


    const onFilter = async (items) => {
        await setFilter(prev=>({...filter,...items}))
    }  
    
    
    const columns = [
        {
            Header: 'TIMS Cards Number',
            accessor: 'CardNumber',
            width: 170
        },
        {
            Header: 'Trekking Route',
            accessor: 'TrekRoute',
            width: 220,
            Filter:({filter:filter,onchange}) =><TrekkingRouteFilter trekkingArea={filter?.trekkingArea} {...filter} onFilter={onFilter} />,
            // Cell: Card
            // Cell:CardLink
        },

        {
            Header: 'Trekking Date',
            accessor: 'StartDate',
            width: 180,
            // Filter: DateFilter,
        },
        {
            Header: 'Regd. Date',
            accessor: 'RegisterdDate',
            width: 180,
            // Filter: DateFilter,
        },
        {
            Header: 'Status',
            accessor: 'TrekingStatus',
            width: 140,
            Cell: Status,
            Filter:({filter:filter,onchange}) =><CardStatusFilter trekkingArea={filter?.trekkingArea} {...filter} onFilter={onFilter} />,
            // getProps: () => ({ someFunc: () => alert("clicked")})
        },
    ];
    
    const [open, setOpen] = useState(false);
    useEffect(()=>{
        if(cards){
            setData(cards)
            const totalRows = !isEmpty(cards) ? cards[0]?.TotalRows : 0;
            cards && setPage(prev=>({...prev}))
            setPage(prev=>({...prev,totalRows:totalRows}))
        }
    },[cards]);
    useEffect(()=>{
        getCardsAll()
    },[page.pageSize,page.pageindex])
    useEffect(()=>{
        getCardsAll()
    },[filter]) 
    const getCardsAll = () =>{
        dispatch(getAllCards({username:card.Email,...page}))
    }
    const pageSize:any = useWatch({ control, name: 'pageSize' });
    useEffect(() => {
        if(pageSize && pageSize.value) {
          setPage(prev=>({...prev,pageSize:pageSize.value}));
        }
    }, [pageSize])
    useEffect(()=>{
        if(timCard){
            dispatch(getCard(timCard.RegistrationId,timCard.ID))
        }
    },[timCard])
    const onClose = () =>{
        setOpen(false)
        setTimCard(null)
    }
    const onRowClick = (data) =>{
        setTimCard(data.original)
        setOpen(true)
    }
    return (
        <Container>
            <div className="tims">
                <div className="tims-title">TIMS Cards</div>
                <div className="mb-md tims-search space-between">
                        <div className="">
                            <Form 
                                formName="reactSelect"
                                control={control}
                                name="pageSize"
                                value={pageSizeValues[0]}
                                defaultValue={pageSizeValues[0]}
                                className="page-nums"
                                width="175px"
                                placeholder="Choose..."
                                options={pageSizeValues}
                            />
                        </div>
                    </div>
                <div className="tims-table">
                    <Table isFixedWidth onRowClick={onRowClick} columns={columns}  data={data} />
                    <Pagination onPageChange={onPageChange} {...page} totalRows={page.totalRows} />
                    {/* <Pagination onPageChange={onPageChange} {...page} totalRows={page.totalRows} /> */}
                </div>
            </div>
        </Container>
    )
}

export default TimsCards
