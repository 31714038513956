import https from 'services/http';
import Action from 'services/actionUtility';
import environment from 'environment';
import types from 'constants/types';
import getQueryUrl from 'helpers/getQueryUrl';


export const getBilling = (page) => async dispatch=>{
    const finalUrl = getQueryUrl(environment.api.getBilling,page)
    const res = https.get(finalUrl)
    const actionConfig = {
		dispatch,
		actionType:types.GET_BILLING,
        message:'',
        type:'get',
		effect:res,
        isLoading:true
	}
	const response = await Action.createThunkEffect(actionConfig); 
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}
export const getChalanlog = (page): any => async dispatch => {
    const finalUrl = getQueryUrl(environment.api.getChalanlog, page)
    const res = https.get(finalUrl)
    const actionConfig = {
        dispatch,
        actionType: types.GET_CHALANLOG,
        message: '',
        type: 'get',
        isLoading: true,
        effect: res,
    }
    const response = await Action.createThunkEffect(actionConfig);
    if (response && response.Status) {
        // history.push(RouteEnum.Login)
    }
}

