import RouteEnum from 'constants/routesEnum'
import React, { useEffect, useState } from 'react'
import Breadcrumbs from 'components/breadcrumb';
import Container from 'components/layout/container';
import FormGroup from 'components/form/formGroup';
import Button from 'components/button';
import {Tabs, Tab} from 'components/tabs'
import '../route/styles.scss';
import Overview from './overview';
import Gallery from './gallery';
import Map from './map';
import {getRouteProvince,addRoutes, getRoute, updateRoutes} from 'store/actions/routesAction';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { RootStateTypes } from 'ts/reducer';
import isEmpty from 'helpers/isEmpty';
import { Prompt } from 'react-router'
import SvgIcons from 'components/icons/svgIcons';
const routeTabs = [
    {id:1,name:'overview',label:'Overview'},
    {id:2,name:'gallery',label:'Gallery'},
    {id:3,name:'map',label:'Map'},
];

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
  
      formData.append(parentKey, value);
    }
  }
  
  function jsonToFormData(data) {
    const formData = new FormData();
  
    buildFormData(formData, data, null);
  
    return formData;
  }


function RouteAdd(props) {
    const breadList = [{ title: 'Routes', to:RouteEnum.Route },{ title: 'Add New Routes', to:RouteEnum.RouteAdd, isCurrent: true }]
    const dispatch = useDispatch()
    const [active, setActive] = useState(1);
    const [attachments, setAttachments] = useState(null);
    const [mapAttachments, setMapAttachments] = useState(null);
    
    const methods = useForm();
    const {getValues,watch} = methods;
    // const allValuses2 = watch(["RouteName", "Discription","EndPoint", "StartPoint" , "ProvinceId", "RouteDetail", "Files", "MapFiles"])
    const {handleSubmit,errors,setValue} = methods
    const {params} = props.match;
    const [isEdit,setIsEdit] = useState(false)
    const {route} = useSelector((state:RootStateTypes)=>state.routeReducer);
    useEffect(()=>{
        if(params && params.id){
            setIsEdit(true)
            dispatch(getRoute(params.id))
        }
    },[])
    const { routesProvince } = useSelector((state: RootStateTypes) => state.routeReducer);
    useEffect(()=>{
        if(route && isEdit){
            if(route.RouteDto){
                const provinceName = routesProvince && route.RouteDto?.ProvinceId && routesProvince.find(item=>item.value == route.RouteDto?.ProvinceId)
                setValue("RouteName",route.RouteDto.RouteName)
                setValue("Discription",route.RouteDto.Discription)
                setValue("EndPoint",route.RouteDto.EndPoint)
                setValue("StartPoint",route.RouteDto.StartPoint)
                setValue("ProvinceId",provinceName)
                setValue("RouteDetail",route.RouteDetail)
                setAttachments(route.Attachment)
                setMapAttachments(route.MapAttachment)
            }
        }
    },[route])
    const onSubmit = (data) =>{
        const formData = new FormData()
        const newData:any = {}
        newData['RouteDetail'] = []
        Object.keys(data).map(item=>{
            if(item === 'Files' && data[item]){
                const imagesArray = data[item]? Array.from(data[item]):[]
                if(imagesArray){
                    imagesArray.map((file:any)=>{
                        // formData.append('Files',file)
                        newData['Files'] = file
                    })
                }
            }
            if(item === 'MapFiles' && data[item]){
                const imagesArray = data[item]? Array.from(data[item]):[]
                if(imagesArray){
                    imagesArray.map((file:any)=>{
                        // formData.append('MapFiles',file)
                        newData['MapFiles'] = file
                    })
                    
                }
            }
            
            if(item === 'ProvinceId'){
                newData['RouteDto.'+item] = data[item].value
                // formData.append('RouteDto.'+item,data[item].value)
                return
            }
            if(item === "StartPoint"){
                newData['RouteDto.'+item] = data[item];
                if(isEdit && route){
                    const r = route?.RouteDetail[0];
                    const routeDetailed = {
                        "routeId": r?.RouteId,
                        "routeRefId": r?.RouteRefId,
                        "route": data["StartPoint"]
                    }
                    newData['RouteDetail'] = [...newData['RouteDetail'],routeDetailed];
                    return 
                }
                const routeDetailed = {
                    "routeId": 0,
                    "routeRefId": 0,
                    "route": data[item]
                }
                
                newData['RouteDetail'] = [...newData['RouteDetail'],routeDetailed];
            }
            
            if(item.includes('RouteDetail')){
                if(isEdit && route){
                    if(item.split("_").length === 2){
                        const routeDetailed = {
                            "routeId": 0,
                            "routeRefId": 0,
                            "route": data[item]
                        }
                        
                        newData['RouteDetail'] = [...newData['RouteDetail'],routeDetailed];
                        return
                    }
                    const [name,routeId,routeRefId] = item.split("_");
                    
                    const routeDetailed = {
                        "routeId":routeId,
                        "routeRefId": routeRefId,
                        "route": data[item]
                    }
                    
                    newData['RouteDetail'] = [...newData['RouteDetail'],routeDetailed];
                    return
                }else{
                    const routeDetailed = {
                        "routeId": 0,
                        "routeRefId": 0,
                        "route": data[item]
                    }
                    
                    newData['RouteDetail'] = [...newData['RouteDetail'],routeDetailed];
                    return
                }
            }
            newData['RouteDto.'+item] = data[item];
            // formData.append('RouteDto.'+item,data[item])
        })
        if(isEdit && route){
            const r = route?.RouteDetail?.find(item=>item.IsEndPoint === true);
            const routeDetailed = {
                "routeId": r?.RouteId,
                "routeRefId": r?.RouteRefId,
                IsEndPoint:true,
                "route": data["EndPoint"]
            }
            
            newData['RouteDetail'] = [...newData['RouteDetail'],routeDetailed];
        }else{
            const routeDetailed = {
                "routeId": 0,
                "routeRefId": 0,
                IsEndPoint:true,
                "route": data["EndPoint"]
            }
            
            newData['RouteDetail'] = [...newData['RouteDetail'],routeDetailed];
        }

        // if(newData['RouteDto.StartPoint']){
        //     const routeDetailed = {
        //         "routeId": 0,
        //         "routeRefId": 0,
        //         "route": newData['RouteDto.StartPoint']
        //     }
        //     newData['RouteDetail'] = [routeDetailed,...newData['RouteDetail']];
        // }
        // if(newData['RouteDto.EndPoint']){
        //     const routeDetailed = {
        //         "routeId": 0,
        //         "routeRefId": 0,
        //         "route": newData['RouteDto.EndPoint']
        //     }
        //     newData['RouteDetail'] = [...newData['RouteDetail'],routeDetailed];
        // }
        // debugger
        // return
        if(isEdit && route){
            newData['RouteDto.RouteId'] = route.RouteDto.RouteId;
            newData['Attachment'] = attachments ? attachments : []
            newData['MapAttachment'] = mapAttachments ? mapAttachments : []
            // attachments && attachments.map(item=>{
                
            //     // a.append('Attachment',JSON.stringify(item))
            // })
            const a = jsonToFormData(newData);
            dispatch(updateRoutes(a,props.history))
            return;
        }
        const a = jsonToFormData(newData);
        dispatch(addRoutes(a,props.history))
    }
    useEffect(()=>{
        if(errors){
            const errorsItems = errors?Object.keys(errors) : []
            if(errorsItems.length ===1 && errors.Files){
                setActive(2)
            }
        }
    },[errors])
    return (
        // <FormGroup onSubmit={onSubmit} >
            <>
            {/* <Prompt
                when={isChanged}
                message='You have unsaved changes, are you sure you want to leave?'
                /> */}
                <Prompt
                    message={(location, action) => {
                        const allValues = getValues(["RouteName", "Discription","EndPoint", "StartPoint" , "ProvinceId", "RouteDetail", "Files", "MapFiles"])
                        const isChanged = Object.keys(allValues).some(item=>!isEmpty(allValues[item]));
                        if(isChanged && !location.pathname.includes("/route")){
                            return "Are you sure you want to leave this page with changes"
                        }
                        return true;
                    }}
                />
                <Breadcrumbs 
                    component={<Button 
                        onClick={handleSubmit(onSubmit)}   
                        buttonType="icon-button" 
                        // type="button-outline"
                        icon={<SvgIcons name="Copy" size={16} />} 
                        title={isEdit? "Update" : "Save"} bg="primary" />}
                    linkList={breadList} />
                <div className="route common-container">
                    <div className="route-tabs">
                        <div className="route-tabs__header">
                            {
                                routeTabs.map(item=>(
                                        <div 
                                            onClick={()=>setActive(item.id)}
                                            key={item?.id}
                                            className={classnames("route-tabs__tab",{"active":item.id === active})}>
                                            {item.label}
                                        </div>
                                    )
                                )
                            }
                        </div>
                        <div className="route-tabs__body">
                            <div className={classnames("route-tabs__body-item",{"active-tab":1 === active})}>
                                <Overview isEdit={isEdit} route={route} methods={methods} />
                            </div>
                            <div className={classnames("route-tabs__body-item",{"active-tab":2 === active})}>
                                <Gallery 
                                    attachments={attachments}
                                    setAttachments={setAttachments}
                                    isEdit={isEdit} route={route} methods={methods}  />
                            </div>
                            <div className={classnames("route-tabs__body-item",{"active-tab":3 === active})}>
                                <Map attachments={mapAttachments}
                                    setAttachments={setMapAttachments}
                                    isEdit={isEdit} route={route} methods={methods} />
                            </div>
                        </div>
                    </div>
                    {/* <Tabs activeTab="overview">
                            {
                                routeTabs.map(item=>(
                                    <Tab key={item.id} name={item.name} label={item.label} >
                                        {
                                            item.id === 1 ? <Overview methods={methods} /> :
                                            item.id === 2 ? <Gallery methods={methods}  /> :
                                            item.id === 3 ? <Map /> : null
                                        }
                                    </Tab>
                                )
                            )
                        }
                    </Tabs> */}
                </div>    
            </>

      //</FormGroup>
    )
}

export default RouteAdd
