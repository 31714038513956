import React from 'react'
import classnames from 'classnames';
import Icons from 'components/icons/svgIcons';
import Confirm from 'components/common/confirm';
import Tooltip from 'components/tooltip';
import DropDown from 'components/dropdown';
import useAuth from 'src/hooks/useAuth';
import { modulesList } from 'constants/contants';
import classNames from 'classnames';
import { RootStateTypes } from 'ts/reducer';
import { useSelector } from 'react-redux';

export const Reason = ({ value }) => {
    return (
        <div className="table-reason">

            {/* <div className="table-reason__tool">
                {value}
            </div> */}
            <Tooltip text={value} position="top">
                {/* <span>
                    {value}
                </span> */}
                <span>
                    <Icons name="Reason" size={24} />
                </span>
            </Tooltip>
        </div>
    )
}

export const Status = ({ value, onClick, row }) => {
    const isActive = value === 'Active';
    const allowed = useAuth()
    const user = useSelector((state:RootStateTypes)=>state.auth.user);
    const isNotCurrentUser = user?.UserID !== row?.original?.UserId
    const isAllowed = allowed[modulesList.routesAdd];
    const reason = row?.original?.Remarks
    const classs = classnames("badges badges-rounded", {
        "badges-active": isActive,
        // "badges-danger": !isActive 
    });
    return (
        <div className={classnames("table-status", { "is-cancelled": !isActive })}>
            {
                reason ? (
                    <Tooltip text={reason} position="top">
                        <span className={classs}>{isActive ? "Active" : "Disabled"}</span>
                    </Tooltip>
                ) : (
                    <span className={classs}>{isActive ? "Active" : "Disabled"}</span>
                )
            }
            {/* {
                isActive ? 'Active' : 'Cancelled'
            } */}
            {
                isAllowed && isNotCurrentUser ? <DropDown
                    isIcon={false}
                    isFixed
                    title={<div className="dashboard-pie__drop flex">
                        <Icons name="ChevronDown" size={14} />
                    </div>
                    }
                    lists={[
                        !isActive ? { label: 'Active', onClick: () => onClick && onClick('Active', row.original) } :
                            { label: 'Disable', onClick: () => onClick && onClick('Disable', row.original) },
                    ]}
                /> : (
                    null
                )
            }

            {/* {value}
            <span className="table-status__circle"></span> */}
        </div>
    )
}


export const TrekkingStatus = ({ value, row }) => {
    const isActive = value === "Active";
    const isDisable = value === "Disable";
    const isApproved = value === "Approved";
    const isPaymentPending = value === "Payment Pending"
    const isPending = value === "Pending"
    const isCancelled = value === "Cancelled";
    const isMissed = value === "Missed";
    const isReturned = value === "Returned";
    const isRejected = value === "Rejected";
    const isUpcoming = value === "Upcoming";
    const isCompleted = value === "Completed";
    const reason = row?.original?.Reason;
    const classs = classNames("badges badges-rounded", {
        "badges-active": isActive,
        "badges-canceled": isCancelled,
        "badges-missed": isMissed,
        "badges-blue": isReturned,
        "badges-payment-pending": isPaymentPending,
        "badges-pending": isPending,
        "badges-success": isApproved,
        "badges-rejected": isRejected,
        "badges-upcoming": isUpcoming,
        "badges-completed": isCompleted,
    })
    if (!value) {
        return null;
    }
    return (
        <div className="trekkers-status">
            {
                reason ? (
                    <Tooltip text={reason} position="top">
                        <span className={classs}>{value}</span>
                    </Tooltip>
                ) : (
                    <span className={classs}>{value}</span>
                )
            }
        </div>
    )
}


export const Actions = ({ value, module, row, ...props }) => {
    // onDeleteFunc
    const onDelete = () => props.onDeleteFunc && props.onDeleteFunc(row.original[props.field])
    const allowed = useAuth();
    if (module && allowed[module]) {
        return (
            <div className="flex action-icons">
                <span
                    onClick={() => props.onEditFunc && props.onEditFunc(row.original)}
                    className="action-icon action-icon__edit">
                    <Icons name="EditOutline" color="#343434" size={20} className="cursor-pointer" />
                </span>
                <Confirm deleteFunction={onDelete} title="Route" >
                    <span className="action-icon">
                        <Icons name="Trash" className="cursor-pointer" />
                    </span>
                </Confirm>
            </div>
        )
    }
    return null
}