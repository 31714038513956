import React from 'react'
import Button from 'components/button';
import FormGroup from 'components/form/formGroup';
import Form from 'components/form';
import { autoCheckin } from 'store/actions/checkinAction';
import { useDispatch } from 'react-redux';
import './styles.scss';

function Checkin({ data: d, clearData }) {
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
       const res = await dispatch(autoCheckin({...data,...d}))
       clearData(res ? 2 : 1)
    }
    return (
        <div className="location">
            <FormGroup onSubmit={onSubmit} >
                <div className="location-form__row">
                    <Form
                        name="place"
                        placeholder="Location"
                        // width="400px"
                        required
                    />
                </div>
                {/* <div className="location-form__row">
                    <Form
                        name="cardno"
                        label="TIMS Card number"
                        width="400px"
                    />
                </div>
                <div className="location-form__row">
                    <Form
                        name="passport"
                        label="Passport number"
                        width="400px"
                    />
                </div> */}
                <div className="location-form__btn">
                    <Button
                        title="Self Checkin"
                        bg="primary"
                        // type="button-outline"
                        htmlButtonType="submit"
                    />
                </div>
            </FormGroup>
        </div>
    )
}

export default Checkin
