import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Form from 'components/form';
import { RootStateTypes } from 'ts/reducer'
import { useWatch } from 'react-hook-form';
import { getCheckpoint } from 'store/actions/selectAction';
import {onlyText,emailValid,onlyNum} from 'constants/regex';

function lowercaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

function CheckForm(props) {
    const { method,open } = props;
    const dispatch = useDispatch()
    const { setValue, control, register, setError, errors: errs } = method;
    const { user } = useSelector((state: RootStateTypes) => state.auth)
    // const { agencyUser } = useSelector((state: RootStateTypes) => state.userReducer)
    const { routes, checkpoints } = useSelector((state: RootStateTypes) => state.selectReducer)
    const currentRoute: any = useWatch({ control, name: 'RouteID' });
    const errors = useSelector((state: RootStateTypes) => state.validateReducer.errors);
    const { routesProvince } = useSelector((state: RootStateTypes) => state.routeReducer)
    useEffect(() => {
        if (user) {
            const { ProvinceID, LocalGovermentInfo, WardNo, FullName, RouteID, CheckPointID } = user;
            
            if (LocalGovermentInfo)
                setValue("LocalGovermentInfo", LocalGovermentInfo)
            if (WardNo)
                setValue("WardNo", WardNo)
            if (FullName)
                setValue("FullName", FullName)
            if (ProvinceID){
                const province = routesProvince && routesProvince.find(item=>parseInt(item.value) === ProvinceID)
                setValue("ProvinceId", province)
            }
            if(CheckPointID){
                const check = checkpoints && checkpoints.find(item=>parseInt(item.value) === CheckPointID)
                setValue("CheckPointID", check)
            }
            if(RouteID){
                const route = routes && routes.find(item=>parseInt(item.value) === RouteID)
                setValue("RouteID", route)
            }
        }
    }, [user,currentRoute,open,routesProvince,routes,checkpoints]);
    useEffect(() => {
        if (currentRoute && currentRoute.value) {
            (async ()=>{
                await dispatch(getCheckpoint(currentRoute.value))
                const {  CheckPointID } = user;
                if(CheckPointID && checkpoints){
                    const check = checkpoints && checkpoints.find(item=>item.value == CheckPointID)
                    setValue("CheckPointID", check)
                }
            })()
        }
        if(user?.RouteID){
            dispatch(getCheckpoint(user?.RouteID))
        }
    }, [currentRoute,user?.RouteID])
    const { erroList } = useSelector((state: RootStateTypes) => state.validateReducer);
    useEffect(() => {
        if (erroList && Array.isArray(erroList) && erroList.length > 0) {
            erroList.map(item => {

                Object.keys(item).map(key => {
                    setError(lowercaseFirstLetter(key), {
                        type: "manual",
                        message: item[key]
                    })
                }
                )
            })
        }
    }, [erroList])
    return (
        <div className="agency-form">
            <div className="agency-form__row">
                <Form
                    name="FullName"
                    label="Full Name"
                    control={control}
                    validate={(val)=> !onlyText(val) || 'Only text allowed'}
                    register={register}
                    required={true}
                ></Form>
            </div>
            <div className="agency-form__row">
                <Form
                    name="ProvinceId"
                    label="Province"
                    placeholder="Select Province"
                    control={control}
                    required
                    formName="reactSelect"
                    options={routesProvince}
                    error={errors}
                />
            </div>
            <div className="agency-form__row">
                <Form
                    name="LocalGovermentInfo"
                    label="Local Goverment Name"
                    required={true}
                    control={control}
                    register={register}
                    error={errs}
                />
            </div>
            <div className="agency-form__row">
                <Form
                    name="WardNo"
                    label="Ward No"
                    required={true}
                    control={control}
                    register={register}
                    error={errs}
                />
            </div>
            <div className="agency-form__row">
                <Form
                    formName="reactSelect"
                    name="RouteID"
                    label="Routes"
                    options={routes}
                    required={true}
                    control={control}
                    register={register}
                    error={errs}
                />
            </div>
            <div className="agency-form__row">
                {
                    currentRoute && <Form
                        formName="reactSelect"
                        name="CheckPointID"
                        label="Checkpost"
                        options={checkpoints}
                        required={true}
                        control={control}
                        register={register}
                        error={errs}
                    />
                }
            </div>
        </div>
    )
}

export default CheckForm
