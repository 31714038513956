import React, {
  Component,
} from "react";
import { createPortal } from 'react-dom';
import PropTypes from "prop-types";
import isEmpty from "isEmpty";
import classnames from 'classnames';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import './style.scss';

interface ModalInter {
  open?: boolean,
  onModalClose: Function,
  type: 'confirm' | 'success' | 'danger' | 'dangerContent' | 'normal',
  title?: string,
  width?: string,
  isPrimary?: boolean,
  className?: string,
  func?: Function,
  // content?:React.ReactNode,
  // children?:React.ReactNode
  content?: any,
  children?: any,
  btnTitle?:any
};

class Mode extends Component<ModalInter> {
  public modalTarget: any;
  state = {
    open: false,
    noCloseButton: false,
  };
  componentDidMount = () => {
    this.setState({ open: this.props.open });
    const modal = document.getElementsByClassName('modal');
    if (modal &&modal.length >0) {
      modal[0].addEventListener('click', (e: any) => {
        if (e.target.classList === modal[0].classList) {
          this.onClose()
        }

      })
      modal[0].addEventListener('keyDown', (e: any) => console.log(e.key))
    }
    document.addEventListener('keydown', this.onModalEscape)
  };
  componentWillUnmount() {
    
    const modal = document.getElementsByClassName('modal');
    if (modal && modal.length >0) {
      modal[0].removeEventListener('click', () => console.log('cl'))
    }
    document.removeEventListener('keydown', this.onModalEscape);
    // ReactDOM.unmountComponentAtNode(this.modalTarget);
    // document.body.removeChild(this.modalTarget);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.open !== prevProps.open) {
      this.setState({ open: this.props.open });
    }
  }
  onModalEscape = (e) => {
    if (e.key === 'Escape') {
      this.onClose()
    }
  }
  onClose = () => {
    this.setState({ open: false });
    this.props.onModalClose()
  };
  switchComponent() {
    const { func, onModalClose, type, title, content ,btnTitle} = this.props;
    switch (type) {
      case "confirm":
        return <Confirm title={title} btnTitle={btnTitle} onModalClose={onModalClose} func={func} />
      case "success":
        return <Success onModalClose={onModalClose} />
      case "danger":
        return <Danger onModalClose={onModalClose} />
      case "dangerContent":
        return <DangerWithContent onModalClose={onModalClose} title={title} content={content} />
      case "normal":
        return <DefaultModal {...this.props} />
      default:
        return null
    }
  }
  render() {
    const modalClasses = classnames({
      "modal": true,
      [this.props.className]: !isEmpty(this.props.className),
      "primary-modal": this.props.isPrimary
    })
    return createPortal(
      <CSSTransition
        in={this.state.open}
        // key={item.module_name}
        timeout={300}
        classNames="modalOpen"
        unmountOnExit
      >
        <div className={modalClasses}>
          {this.switchComponent()}
        </div>
      </CSSTransition>
      ,
      document.body
    )
  }
}

const DefaultModal = ({ width, title, onModalClose, content, children }: ModalInter) => {
  const closeIcon = <span className="iconify" data-icon="ion:close" data-inline="false"></span>
  return (
    <div className="modal__content" style={!isEmpty(width) ? { width } : {}} >
      {
        !isEmpty(title) ? (
          <div className="modal__header">
            <div className="modal__heading">
              <h2>{title}</h2>
            </div>
            <div className="modal__header-close" onClick={(e) => onModalClose(e)}>
              {closeIcon}
            </div>
          </div>
        ) :
          <div className="modal__header-close modal__header-close-noheader" onClick={(e) => onModalClose(e)}>
            {closeIcon}
          </div>
      }
      <div className="modal__content-body">
        {
          React.cloneElement(content ? content : children)
        }
        {/* {content ? content : children} */}
      </div>
    </div>
  )
}

const Confirm = ({ func, title,btnTitle="Delete", onModalClose }) => {
  return (
    <div className="modal__confirm confirm">
      <h2 className="confirm-title">{title ? title : 'Are you sure ?'}</h2>
      {title ? null : <p> You wont be able to revert your action</p>}
      <div className="confirm-buttons" style={{gap:8}}>
        <button
          className="button button-outline-danger button-outline"
          onClick={(e) => {
            e.stopPropagation()
            func();
            onModalClose(e);
          }}
        >
          <span>{btnTitle ? btnTitle :'Delete'}</span>
        </button>
        <button
          className="button button-outline-cancel button-outline"
          onClick={(e) => {
            e.stopPropagation()
            onModalClose(e)}}
        >
          <span>Cancel</span>
        </button>
      </div>
    </div>
  );
};

const Success = ({ onModalClose }) => {
  return (
    <div className="modal__confrim confirm success">
      <div className="confirm-icon">
        <i className="material-icons">check_circle</i>
      </div>
      <h2 className="confirm-title">Success</h2>
      <p>Your Current Action was a success</p>
      <div className="confirm-buttons">
        <button
          className="btn btn--success"
          style={{ borderRadius: "12rem" }}
          onClick={(e) => onModalClose(e)}
        >
          Done
          </button>
      </div>
    </div>
  );
};

const Danger = ({ onModalClose }) => {
  return (
    <div className="modal__confrim confirm danger">
      <div className="confirm-icon">
        <span className="iconify" data-icon="fa-regular:times-circle" data-inline="false"></span>
      </div>
      <h2 className="confirm-title">Error</h2>
      <p>
        Something Bad Occured
          <span>Please Try again Later</span>
      </p>
      <div className="confirm-buttons">
        <button
          className="btn btn--danger"
          style={{ borderRadius: "12rem" }}
          onClick={(e) => onModalClose(e)}
        >
          Done
          </button>
      </div>
    </div>
  );
};

const DangerWithContent = ({ onModalClose, title, content }) => {
  return (
    <div className="modal__confrim confirm danger">
      <div className="confirm-icon">
        <span className="iconify" data-icon="fa-regular:times-circle" data-inline="false"></span>
      </div>
      <h2 className="confirm-title">{title}</h2>
      <p>
        {/* Something Bad Occured */}
        <span>{content}</span>
      </p>
      <div className="confirm-buttons">
        <button
          className="btn btn-red"
          style={{ borderRadius: "12rem" }}
          onClick={(e) => onModalClose(e)}
        >
          Close
          </button>
      </div>
    </div>
  );
};


export class Modal extends Component<ModalInter> {
  public static defaultProps = {
    open: false,
    type: "normal",
  };
  render() {
    const { open, ...props } = this.props;
    if (open) {
        return (
          //@ts-ignore
        <Mode {...this.props} />
      )
    }
    return null
  }
}



export default Modal;