import Modal from 'components/modal';
import Button from 'components/button';

interface IPDiplomatModal {
    isOpen: boolean,
    onClose: () => void;
}


export const DiplomatModal = (props:IPDiplomatModal) => {
    const { isOpen, onClose } = props;
  return (
    <Modal
        onModalClose={onClose}
        open={isOpen}
        // type=''
        title='Diplomat Trekker'
        width="500px"
    >
        <div className="diplomat-container">
            <div className="diplomat-error">
                For Diplomat please visit Nepal Turisom Board
            </div>
            <div className="diplomat-btns">
                <Button
                    onClick={onClose}
                    type='button-outline'
                    bg="cancel"
                >
                    Close
                </Button>
            </div>
        </div>
    </Modal>
  );
};

export default DiplomatModal;