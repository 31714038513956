import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames';
import { createPortal } from 'react-dom';

const initial_state = {
    visible: false,
    x: 0,
    y: 0,
    type: "none"
}

interface ToolTipInterface {
    children: any,
    text: any,
    position?: 'top' | 'bottom' | 'right' | 'left';
}

function Tooltip({ children, text, position }: ToolTipInterface) {
    const toolRef = useRef(null)
    const [state, setState] = useState(initial_state);

    // useEffect(() => {
    //     if(typeof children !== 'string'){
    //         children[0].props.onMouseOver = handleOnMouseOver;
    //     }
    // }, [])
    const handleOnMouseOver = async (e) => {
        let el = e.currentTarget;
        if (el != null) {
            let rect = el.getBoundingClientRect();
            await setState(prev => ({ ...prev, visible: true }))
            handleHover(rect);
        }
    }
    const handleOnMouseOut = async (e) => {
        await setState(prev => ({ ...prev, visible: false }))
    }
    const handleHover = (hoverRect) => {
        let ttNode = toolRef.current;
        if (ttNode) {
            let x = 0, y = 0;

            const docWidth = document.documentElement.clientWidth,
                docHeight = document.documentElement.clientHeight;
            let rx = hoverRect.x + hoverRect.width, // most right x
                lx = hoverRect.x, // most left x
                ty = hoverRect.y, // most top y
                by = hoverRect.y + hoverRect.height;

            let ttRect = ttNode.getBoundingClientRect();

            let bRight = (rx + ttRect.width) <= (window.scrollX + docWidth);
            let bLeft = (lx - ttRect.width) >= 0;

            let bAbove = (ty - ttRect.height) >= 0;
            let bBellow = (by + ttRect.height) <= (window.scrollY + docHeight);

            let newState: any = {};
            if (position) {
                // the tooltip doesn't fit to the right
                if (position === 'right') {
                    x = rx;

                    y = ty + (hoverRect.height - ttRect.height);

                    if (y < 0) {
                        y = ty;
                    }

                    newState.type = "right";
                }
                else if (position === 'bottom') {
                    y = by;

                    x = lx + (hoverRect.width - ttRect.width);

                    if (x < 0) {
                        x = lx;
                    }

                    newState.type = "bottom";
                }
                else if (position === 'left') {
                    x = lx - ttRect.width;

                    y = ty + (hoverRect.height - ttRect.height);

                    if (y < 0) {
                        y = ty;
                    }

                    newState.type = "left";
                }
                else if (position === 'top') {
                    y = ty - ttRect.height;

                    x = lx + (hoverRect.width - ttRect.width);

                    if (x < 0) {
                        x = lx;
                    }

                    newState.type = "top";
                }
            } else {
                // the tooltip doesn't fit to the right
                if (bBellow) {
                    x = rx;

                    y = ty + (hoverRect.height - ttRect.height);

                    if (y < 0) {
                        y = ty;
                    }

                    newState.type = "right";
                }
                else if (bRight) {
                    y = by;

                    x = lx + (hoverRect.width - ttRect.width);

                    if (x < 0) {
                        x = lx;
                    }

                    newState.type = "bottom";
                }
                else if (bLeft) {
                    x = lx - ttRect.width;

                    y = ty + (hoverRect.height - ttRect.height);

                    if (y < 0) {
                        y = ty;
                    }

                    newState.type = "left";
                }
                else if (bAbove) {
                    y = ty - ttRect.height;

                    x = lx + (hoverRect.width - ttRect.width);

                    if (x < 0) {
                        x = lx;
                    }

                    newState.type = "top";
                }
            }


            newState = { ...newState, x: x, y: y };
            setState(prev => ({ ...prev, ...newState }));
        }
    }
    let style = {
        left: ((state.x + window.scrollX) + 'px'),
        top: ((state.y + window.scrollY) + 'px')
    };
    const classes = classnames("tooltip", {
        on: state.visible,
        off: !state.visible,
        [state.type]: state.type && state.type
    })
    if (typeof children === 'string') {
        <span
            onMouseOver={(e) => handleOnMouseOver(e)}
            className="responsive-class">
            {children}
        </span>
    }
    const childrens = React.Children.map(children, child => React.cloneElement(child, { onMouseOver: handleOnMouseOver, onMouseOut: handleOnMouseOut }));
    return <>
        {childrens}
        {createPortal(<div ref={toolRef} className={classes} style={style} >
            <div className="tooltip-arrow"></div><div className="tooltip-inner">
                {text}
            </div>
        </div>,document.body)}
    </>
}

export default Tooltip
