import React, { useEffect, useState } from 'react'
import { Tabs, Tab } from 'components/tabs'
import Profile from '../user';
import RegisteredTrekkers from './registeredTrekkers'
import ScannedTrekkers from './scannedTrekkers'
import './styles.scss';
import RouteEnum from 'constants/routesEnum';
import classnames from 'classnames';
import { getUser } from 'store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import Breadcrumbs from 'components/breadcrumb';
function Checkpost(props) {
    const {user} = useSelector((state:RootStateTypes)=>state.userReducer);
    const breadList = [{ title: 'Users', to: RouteEnum.Users }, { title: user?.FullName, to: RouteEnum.TrekkingAgencyUser, isCurrent: true }];
    const dispatch = useDispatch()
    const {id} = props.match.params;
    useEffect(()=>{
        dispatch(getUser(id))
    },[])
    return (
        <div>
            <Breadcrumbs linkList={breadList} />
            <div className="common-container">
                <Tabs activeTab={'profile'} color="common" >
                    <Tab name={'profile'} label="Profile" >
                        <Profile {...props} isCheckPost />
                    </Tab>
                    <Tab name={'scanned_trekkers'} label="Scanned Trekkers" >
                        <ScannedTrekkers user={user}  />
                    </Tab>
                    <Tab name={'registered_trekkers'} label="Registered Trekkers" >
                        <RegisteredTrekkers user={user}  />
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

export default Checkpost
