import {
    EXPIRES_TIME,
    REFRESH_TOKEN,IDLE_TIME_OUT,AUTH_TOKEN,EXPIRES_MINUTES} from 'constants/contants';

export const clearLogoutLocalStorage = () =>{
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(IDLE_TIME_OUT);
    localStorage.removeItem(EXPIRES_MINUTES);
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(EXPIRES_TIME);
}


export const findSelectById = (selects,id) =>{
    if(selects && Array.isArray(selects) && id){
        return selects.find(item=>item.value == id)
    }
    return null;
}