import React from 'react'
import { useTable, useSortBy, useRowSelect, useResizeColumns, useFlexLayout, useBlockLayout } from 'react-table';
import Icons from 'components/icons/svgIcons';
import './styles.scss';
import isEmpty from 'helpers/isEmpty';
import { useSticky } from "react-table-sticky";

const IndeterminateCheckbox = React.forwardRef<any>(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef: any = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input className="table-check" type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

function Table(props) {
  const { columns, data, isRowSelect, onRowClick, isFixedWidth,extra} = props;
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 60,
      width: 150,
      maxWidth: 400,
      extra:extra||{},
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    setHiddenColumns,
    rows,
    columns: cls,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds},
  } = useTable({ columns, data, defaultColumn}, useSortBy,
    // useBlockLayout,
    useFlexLayout,
    useResizeColumns,
    useRowSelect,
    useSticky,
    
    // ...extra,
    hooks => {
      if (isRowSelect) {
        hooks.visibleColumns.push((columns: any) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }: any) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ])
      }

    }
  );
  // const firstPageRows = rows.slice(0, 20);
  React.useEffect(() => {
    const hiddenColumns = cls.filter((column: any) => {
      if ('show' in column) {
        return !column.show
      }
    }).map((column: any) => column.id);
    setHiddenColumns(hiddenColumns);
  }, []);
  return (
    <div className="table-contain">
      <div className="table-container">
        <div style={{ width: '100%' }} className="table-table sticky ">
        {/* <div style={{ width: '100%' }} className="table-table sticky " {...getTableProps()}> */}
          <div className="table-header">
            {headerGroups.map((headerGroup,key) => {
              // console.log(headerGroup.getHeaderGroupProps())
              return (
                <div key={"Table-Header-"+key} {...headerGroup.getHeaderGroupProps()} className="tr">
                  {headerGroup.headers.map((column: any,key) => {
                    // console.log({column,as:column.getHeaderProps()})
                    // const isShow = 'show' in column ? !column.show :true;
                    const {style,...rest} = column.getHeaderProps();
                    return (
                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                      
                      <div
                        // style={isFixedWidth ? { minWidth: column.width } : {}}
                        {...rest}
                        key={"Table-Row-"+key}
                        style={{...style}}
                        // style={{...style,width:'auto',minWidth:column.minWidth,flex:1}}
                        className={column.show && column.show === false ? "hide-th th" : "th"}
                      // onClick={column?.isSort ?column.getSortByToggleProps().onClick:null }
                      // {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}
                        {/* {column?.id !== 'selection'  &&(<span className="table-sort"> */}

                        {column?.id !== 'selection' && column?.isSort && (<span
                          style={{ padding: '0 8px', marginLeft: 0 }}
                          onClick={column?.isSort ? column.getSortByToggleProps().onClick : null}
                          className="table-sort">
                          {
                            column.isSorted && column.isSortedDesc ? <Icons size={12} color={column.isSorted && column.isSortedDesc ? "#333" : "#DBDDE0"} name="ArrowDown" /> :
                              column.isSorted && !column.isSortedDesc ? <Icons size={12} styles={{ transform: 'rotate(180deg)' }} color={column.isSorted && !column.isSortedDesc ? "#333" : "#DBDDE0"} name="ArrowDown" />
                                : <Icons size={12} color={column.isSorted && column.isSortedDesc ? "#333" : "#DBDDE0"} name="ArrowDown" />
                          }
                        </span>)}
                        {
                          column?.Filter && <column.Filter />
                        }
                        <div
                          {...column.getResizerProps()}
                          className={`resizer ${column.isResizing ? 'isResizing' : ''
                            }`}
                        />
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
          <div {...getTableBodyProps()} className="table-body">
            {rows.map(
              (row, key) => {

                prepareRow(row);
                return (
                  <div key={"Table-Row-"+key} {...row.getRowProps()} onClick={() => onRowClick && onRowClick(row)} className="tr" >
                    {row.cells.map((cell:any,any) => {
                      const { style, ...otherProps } = cell.getCellProps()
                      const extraStyle = cell.column.Header === 'Status' ? { overflow: 'unset' } : {}
                      // const widthStyle = {width:'auto',minWidth:cell.column.minWidth,flex:1}
                      return (
                        <div key={"Table-Cell-"+key} className="td" style={{ ...style, ...extraStyle }} {...otherProps}>{cell.render('Cell')}</div>
                      )
                    })}
                  </div>
                )
              }
            )}
            {isEmpty(rows) && <div className="table-no-data"><span>No Data Found For This Table</span></div>}
          </div>
        </div>
      </div>
    </div>

  )
}

export default Table
