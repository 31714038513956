import React, { useEffect, useState } from 'react'
import Form from 'components/form';
import { useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { useForm, Controller, useWatch } from 'react-hook-form';
import classnames from 'classnames';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { v4 as uuid } from 'uuid';
import Button from 'components/button';
import Icons from 'components/icons/svgIcons';

function Overview(props) {
    const { routesProvince } = useSelector((state: RootStateTypes) => state.routeReducer)
    const { register, control, errors, setValue } = props.methods;
    const { route } = props;
    const [active, setActive] = useState(0);
    const [checkpoints, setCheckpoints] = useState([])
    const [checks, setChecks] = useState([])
    useEffect(() => {
        if (route && route.RouteDetail) {
            // const [a,b,...c] = route.RouteDetail;
            // setValue("RouteDetail_"+a.RouteId,a.Route)
            // setValue("RouteDetail_"+b.RouteId,b.Route)
            // route.RouteDetail && route.RouteDetail.map(item => {
            //     setValue("RouteDetail_" + item.RouteId + '_' + item.RouteRefId , item.Route)
            // })
            // let newChecks = route.RouteDetail ? route.RouteDetail.slice(1, -1) : [];
            let assignRoute = Object.assign(route)
            let routes = assignRoute.RouteDetail ? assignRoute.RouteDetail : [];
            routes && setCheckpoints(routes.slice(1).filter((item)=>(!item.IsEndPoint)).map(item => ({ id: item.RouteId, isUsed: true, ...item })))
        }
    }, [route])
    useEffect(()=>{
        setCheckpoints([])
    },[])
    
    useEffect(()=>{
        if(checkpoints){
            checkpoints.map(item => {
                setValue("RouteDetail_" + item.RouteId + '_' + item.RouteRefId , item.Route)
            })
        }
    },[checkpoints])

    const addCheckPoint = () => setCheckpoints((prev) => {
        if (prev) {
            return [...prev, { id: uuid(), isUsed: false }]
        }
        return [{ id: uuid() }]
    })
    const removeCheckPoint = (check) => setCheckpoints((prev) => {
        if (prev) {
            return prev.filter(item => item.id !== check.id)
        }
        return []
    })
    useEffect(() => {
        if (errors) {
            const isCheckError = Object.keys(errors).some(item => item.includes("RouteDetail"))
            if (!errors.description && (errors.starting_point || isCheckError)) {
                setActive(1)
            }
        }
    }, [errors])
    const startPoint = useWatch({ name: "StartPoint", control })
    const endPoint = useWatch({ name: "EndPoint", control });
    return (
        <div className="trekking-form">
            <div className="trekking-form__container">
                <Form
                    name="RouteName"
                    label="Trek Route Name"
                    placeholder="Trekking route name"
                    register={register}
                    required
                    error={errors}
                />
                <Form
                    name="ProvinceId"
                    label="Province"
                    placeholder="Select Trekkinng Province"
                    control={control}
                    required
                    formName="reactSelect"
                    options={routesProvince}
                    error={errors}
                />
                <Form
                    name="StartPoint"
                    label="Start Point"
                    placeholder="Trek Start Poinnt"
                    register={register}
                    required
                    error={errors}
                />
                <Form
                    name="EndPoint"
                    label="End Point"
                    placeholder="Trek End Poinnt"
                    register={register}
                    required
                    error={errors}
                />
            </div>
                
            <div className="trekking-form__tabs">
                <div className="trekking-form__tab-list">
                    <div
                        onClick={() => setActive(0)}
                        className={classnames("trekking-form__tab-item", { "active": active === 0 })} >
                        <span>Description</span>
                    </div>
                    <div
                        onClick={() => setActive(1)}
                        className={classnames("trekking-form__tab-item", { "active": active === 1 })}>
                        <span>Route</span>
                    </div>
                </div>
                <div className="trekking-form__tab-body">
                    <div style={{ display: active === 0 ? 'block' : 'none' }}>
                        {/* <Controller
                            control={control}
                            name="Discription"
                            render={({ onChange, value }) => {
                                return <CKEditor
                                    editor={ClassicEditor}
                                    data={value}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        // console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        onChange(data)
                                        // console.log({ event, editor, data });
                                    }}
                                // onBlur={(event, editor) => {
                                //     console.log('Blur.', editor);
                                // }}
                                // onFocus={(event, editor) => {
                                //     console.log('Focus.', editor);
                                // }}
                                />
                            }}
                        /> */}
                        <Form
                            name="Discription"
                            formName="react-quill"
                            control={control}
                            required
                            error={errors}
                            className="quil-text"
                        />
                    </div>
                    <div className="trekking-form__routes" style={{ display: active === 1 ? 'block' : 'none' }} >

                        <Form
                            name="Startpoint_0"
                            placeholder="Starting Point"
                            register={register}
                            disabled={true}
                            width="300px"
                            // required
                            value={startPoint}
                            error={errors}
                        />
                        {
                            checkpoints && checkpoints.map((item, key) => {
                                return (
                                    <div key={item.RouteId} className="flex checkpoint" style={{ gap: 12 }} >
                                        <Form
                                            key={item.id}
                                            name={item.isUsed ?"RouteDetail_" + item.RouteId + '_' + item.RouteRefId :"RouteDetail_" + item.id}
                                            placeholder="Checkpoint name"
                                            register={register}
                                            width="300px"
                                            error={errors}
                                            required
                                        />
                                        <div onClick={() => removeCheckPoint(item)} className="checkpoint-remove">
                                            <Icons name="MinusCircle"  />
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <Form
                            name="Startpoint_1"
                            placeholder="End Point"
                            register={register}
                            width="300px"
                            value={endPoint}
                            disabled={true}
                            // required
                            error={errors}
                        />

                        <div className="mt-md">
                            <div 
                                onClick={() => addCheckPoint()}
                                className="checkpoint-add">
                                <span>Add Checkpoint</span>
                                <Icons name="AddCircle" />
                            </div>
                            {/* <Button bg="primary" title="Add" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Overview
