
const convertToKeyValue = (data) =>{
    let errs = [];
    if(data && Array.isArray(data)){
      data.map(item=>{
          if(item.hasOwnProperty('Key') &&  item.hasOwnProperty('Message')){
            const key = item.Key.includes(".") ? item.Key.split(".")[1] : item.Key;
            errs = [...errs,{[key]:item.Message}]
          }
      })
    }
    return errs;
  }

  export default convertToKeyValue;