import React, { useContext, useEffect } from 'react'
import connectIpsImg from 'assets/images/connectIps.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { clearPayment, getPaymentConnectIps, getPaymentEsewa, getPaymentKhalti, savePaymentKhalti } from 'store/actions/trekkersAction';
import { createPortal } from 'react-dom';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import Context from '../context'

function GatewayForm(props) {
  return (
    <div className="payment-cards">
      <div className="payment-cards__container">
        <div className="payment-cards__header">
          <span>Payment through</span>
        </div>
        <div className="payment-cards__body payment-cards__gateway">
          <div className="payment-cards__gateway-images">
            {/* <PayComp
              type="esewa"
              img={esewaImg}
            />
            <PayComp
              type="fonePay"
              img={foneImg}
            /> */}
            {/* <PayComp 
                            type="esewa"
                            img={esewa}
                        /> */}
            <PayComp
              type="connectIps"
              img={connectIpsImg}
              isSuccess={props.isSuccess}
            />

          </div>
        </div>
      </div>
    </div>
  )
}


export const PayComp = (props) => {
  const { type, img, isDiabled, isSuccess } = props;
  
  const { esewa, ips, fone, khalti } = useSelector((state: RootStateTypes) => state.trekkersReducer);
  const dispatch = useDispatch();
  const vals = useContext(Context);
  const { search } = useLocation();
  const parsedReq: any = queryString.parse(search);
  const onClick = async () => {
    // const param = regId || state?.regId;
    const param = vals.regId ;
    if (!isDiabled) {
      // if (type === "fone") {
      //   await dispatch(getPaymentfone(param));
      // }
      if (type === "esewa") {
        await dispatch(getPaymentEsewa(param));
      }
      if (type === "connectIps") {
        dispatch(getPaymentConnectIps(param));
      }
      if (type === "khalti") {
        await dispatch(getPaymentKhalti(param));
      }
    }
  };

  useEffect(() => {
    // if (fone) {
    //   window.location = fone;
    //   dispatch(ClearPayment("fone"));
    // }
    if (esewa) {
      const form: any = document.getElementById("frmPP");
      // form.setAttribute("target", "_blank")

      if(form){
        form.submit();
        dispatch(clearPayment("esewa"));
      }
    }
    if (ips) {
      const form: any = document.getElementById("frmPPs");
      // form.setAttribute("target", "_blank")
      if(form){
        form.submit();
        dispatch(clearPayment("ips"));
      }

    }
  }, [fone, esewa, ips,dispatch]);

  useEffect(() => {
    if (khalti && type == "khalti") {
      const khaltiObj = JSON.parse(khalti);
      dispatch(clearPayment("khalti"));
      let config = {
        // replace this key with yours
        publicKey: khaltiObj.publicKey,
        productIdentity: khaltiObj.productIdentity,
        productName: khaltiObj.productName,
        productUrl: khaltiObj.productUrl,
        eventHandler: {
          onSuccess(payload) {
            // hit merchant api for initiating verfication
            dispatch(savePaymentKhalti(payload));
          },
          // onError handler is optional
          onError(error) {
            // handle errors
          },
          onClose() {
          },
        },
        paymentPreference: [
          "KHALTI",
          "EBANKING",
          "MOBILE_BANKING",
          "CONNECT_IPS",
          "SCT",
        ],
      };
      // let checkout = new KhaltiCheckout(config);
      // checkout.show({ amount: khaltiObj.amount });
    }
  }, [khalti,dispatch]);
  const isDisabled = isSuccess
  return (
    <div
      onClick={() => !isDisabled &&  onClick()}
      style={isDisabled ? {backgroundColor:'#dedede'} : {}}
      className="payment-cards__pay">
      <div
        
        className="payment-cards__pay-image">
        <img src={img} alt="Connect IPS Logo" />
      </div>
      {type === "esewa" ? (
        <div dangerouslySetInnerHTML={{ __html: esewa }}></div>
      ) : type === "connectIps" ? (
        <div>
          {
            createPortal(
              <div dangerouslySetInnerHTML={{ __html: ips }}>

              </div>,
              document.body
            )
          }
        </div>
      ) : null}
    </div>
  )
}

export default GatewayForm
