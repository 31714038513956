import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Controller } from 'react-hook-form';

interface Props {
  name: string;
  label: string;
  btnText: string;
  className: string;
  noImageShow?:boolean,
  onChange: Function;
  value: any;
  isBlob: any;
  register: any;
  required: any;
  control: any;
  setError?: any;
}

const fileToDataUri = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    resolve(event.target.result)
  };
  reader.readAsDataURL(file);
  })

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  
  // const byteCharacters = atob(decodeURIComponent(b64Data.split(';')[1].split(',')[1]));
  const byteCharacters = atob(b64Data.split(';')[1].split(',')[1]);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
  }
  
  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

function UploadInput(props: Props) {
  const { name, value, isBlob, setError, noImageShow, btnText,label, className,control, required } = props;

  const fileClasses = (value) => {
    return classnames({
      'upload-btn__file': value,
      "left-icon": value,
    });
  };
  const file = value;

  // const fileSelectedHandler = async (event) => {
  //   const newFile = event.target.files[0];
    
  //   if(isBlob){
  //     const image = await  fileToDataUri(newFile);
  //     const blob = b64toBlob(image, newFile.type);
  //     const blobUrl = URL.createObjectURL(blob);
  //     onChange && onChange(name, blobUrl);
  //   }else{
  //     onChange && onChange(name, newFile);
  //   }
  // };

  // const fileRemoveHandler = () => {
  //   onChange(name, null);
  // };

  return (
    <div className="upload-btn-wrapper" style={{ marginRight: "20px" }}>
      <Controller 
        rules={{ required:required?   'Upload field is required' : false  }}
        name={name}
        control={control && control}
        defaultValue=''
        render={({ onChange,value }) =>{
          const allowedMinSize = .001;
          const allowedMaxSize = 3;
          const allowedExt = ".jpg,.jpeg,.png"; 
          const onImageChange = (e) => {
            const fl = e.target.files[0];
            
            if(fl){
              console.log({fl})
              var FileSize = fl.size / 1024 / 1024; 
              const isfilestype = (/\.(jpe?g|pdf?|png)$/i).test(fl.name);
              if(FileSize > allowedMaxSize){
                  setError(name, {
                      type: "manual",
                      message: "The file size should not be more than 3MB.",
                  })
                  return;
              }
              if(FileSize < allowedMinSize){
                  setError(name, {
                      type: "manual",
                      message: "The file size should be atleast 1Kb.",
                  })
                  return;
              }
              if(!isfilestype){
                  setError(name, {
                      type: "manual",
                      message: "Only .jpg, .png and pdf extensions allowed",
                  });
                  return;
              }
              onChange(fl)
          }
            
          }
          return  !noImageShow && value && value.name ? (
          <div
            className={fileClasses(true)}
            // onClick={()=>this.onModalOpen(file,true)}
          >
            {/* <span className="document-icon">
              <i className="icon-trophy"></i>
            </span> */}
            <div className="document-image">
              <img src={URL.createObjectURL(value)} alt=""/>
            </div>
            {/* <label className="document-name">
              {value.name}
            </label> */}
            <span
              className="document-icon cancel-icon"
              // onClick={fileRemoveHandler}
              onClick={()=>onChange(null)}
            >
              <span className="iconify" data-icon="eva:close-fill" data-inline="false"></span>
            </span>
          </div>
        ) : (
          <button className={"upload-btn " + className}>
            {btnText}
            <input 
              type="file" name={name} onChange={(e) =>onImageChange(e)} />
          </button>
        )}}
      />
    </div>
  );
}

UploadInput.defaultProps = {
  name: "",
  btnText: "Add document",
  className: "",
};

export default UploadInput;
