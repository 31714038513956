import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import Heading from 'components/typography';
import Icons from 'components/icons/svgIcons';
import SMIcons from 'components/icons';

interface ContainerProps{
    children:any,
    width?:number | string,
    maxWidth?:number | string,
    isCollapse?:boolean,
    isInitialCollapse?:boolean,
    isIcon?:boolean,
    title?:string
}

function Container({children,width,maxWidth, isIcon=false,isCollapse=false,title='',isInitialCollapse=false}:ContainerProps) {
    const style = width && maxWidth ? {width,maxWidth} : width ? {width} : maxWidth ? {maxWidth} : {};
    const ref = useRef(null)
    const [collapsed,setCollapsed] = useState(true);
    useEffect(()=>{
        setCollapsed(!isInitialCollapse)
    },[isInitialCollapse])
    if(isCollapse){
        const containerClass = classnames("container-collapsable container",{"container-collapsed":collapsed})
        const maxHeight = !collapsed ? ref && ref?.current && ref?.current?.scrollHeight + 24 : 68;
        return <div  style={style} className={containerClass} >
                <div 
                    ref={ref} 
                    style={{maxHeight}}
                    className="container-body">
                    <div 
                        onClick={()=>setCollapsed(!collapsed)}
                        className="container-header"
                    >
                        <div className="space-between">
                            <Heading>
                                <span className="flex">
                                    {title}
                                    <Icons size={22} styles={{display:isIcon ? 'block' : 'none'}} name="Error" />
                                </span>
                                
                            </Heading>
                            <div className="container-icon">
                                <Icons size={18} name="ChevronDown" />
                            </div>
                        </div>
                        
                    </div>
                    <div className="container-contain">
                        {children}
                    </div>
                </div>
            </div>
    }
    return (
        <div style={style} className="container">
            <div className="container-body">
                {children}
            </div>
        </div>
    )
}

export default Container
