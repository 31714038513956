import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getCard } from 'store/actions/cardActions';
import { getTrekking } from 'store/actions/trekkersAction';
import { RootStateTypes } from 'ts/reducer';
import dayjs from 'dayjs';
import TimsCards from './timsCards'
import Icons from 'components/icons/svgIcons'
import './styles.scss'

function Trekker(props) {
    const [active, setActive] = useState(0);
    const {regid,id} = props.match.params;
    const dispatch = useDispatch();
    const {trekker} = useSelector((state:RootStateTypes)=>state.trekkersReducer)
    const {card} = useSelector((state:RootStateTypes)=>state.cardReducer)
    useEffect(()=>{
        if(id){
            dispatch(getTrekking(regid,id));
            dispatch(getCard(regid,id));
        }
    },[])
    return (
        <div className="scanned">
            <div className="scanned__container">
                <div className="scanned__block">
                    <div className="scanned__block__tabs">
                        <div className="scanned__block__tabs-image">
                            <img src={trekker?.Attachment && '/'+trekker?.Attachment[0]?.FileLocation} alt="" />
                        </div>
                        <div className="scanned__block__tabs-name">{trekker?.UserRegistrationModel?.FullName}</div>
                        <div className="scanned__block__tabs-list">
                            <div
                                onClick={() => setActive(0)}
                                className={classNames("scanned__block__tab", { "active": active === 0 })}>
                                <span>Trekker Information</span>
                            </div>
                            {/* <div
                                onClick={() => setActive(1)}
                                className={classNames("scanned__block__tab", { "active": active === 1 })}>
                                <span>TIMS Card Information</span>
                            </div> */}
                        </div>
                    </div>
                    <div className="scanned__block__details">
                        {
                            active === 0 && <ProfileDetails card={card} cd={trekker?.UserRegistrationModel} />
                        }
                        {
                            active === 1 && <TimsCards />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const ProfileDetails = ({cd,card}) => {
    const { genders, region, routes } = useSelector((state: RootStateTypes) => state.selectReducer)
    const gender: any = cd?.Gender ? genders ? genders.find(item => item.Value == cd?.Gender) : null : null;
    const rg: any = cd?.RegionalId ? routes ? region.find(item => item.Value == cd?.RegionalId) : null : null;
    const route: any = cd?.TrekkingAreaId ? region ? routes.find(item => item.Value == cd?.TrekkingAreaId) : null : null;
    const isCancelled = cd?.Status === 2
    return (
        <div className="cards-preview__details">
            {
                isCancelled && <div className="cards-cancelled flex">
                    <Icons name="Error" />
                    <span>This TIMS Card is Cancelled</span>
                </div>
            }
            <ProfileContainer title="User Details">
                <ProfileItem label="Full Name" value={cd?.FullName}  />
                <ProfileItem label="Regional" value={rg?.label}  />
                <ProfileItem label="Gender" value={gender?.label}  />
                <ProfileItem label="Date of Birth:" value={dayjs(cd?.Dob).format("YYYY-MM-DD")}  />
                <ProfileItem label="Email" value={cd?.Email}  />
                <ProfileItem label="Passport number" value={cd?.PassportNumber}  />
                <ProfileItem label="Visa number" value={cd?.VisaNumber}  />
                <ProfileItem label="Visa issue date:" value={dayjs(cd?.VisaIssueDate).format("YYYY-MM-DD")} />
                <ProfileItem label="Visa expiry date:" value={dayjs(cd?.VisaExpiryDate).format("YYYY-MM-DD")}  />
                
            </ProfileContainer>
            <ProfileContainer title="Trek Details">
                <ProfileItem label="Trekking Area" value={route?.label}  />
                <ProfileItem label="Trek entry date" value={dayjs(cd?.TrekEntryDate).format("YYYY-MM-DD")}  />
                <ProfileItem label="Trek Exit date" value={dayjs(cd?.TrekExitDate).format("YYYY-MM-DD")}  />
                <ProfileItem label="TIMS card number" value={card?.CardNumber}  />
                <ProfileItem label="Trek entry point" value={cd?.TrekEntryPoint}  />
                <ProfileItem label="Trek Exit Point" value={cd?.TrekExitPoint}  />
                <div className="cards-preview__detail-row-2">
                    <ProfileItem label="Route of trekking" value={cd?.TrekRoute}  />
                </div>
            </ProfileContainer>
            <ProfileContainer title="Emergency contact details (Nepal Contact)">
                <ProfileItem label="Contact person name" value={cd?.ContactPerson}  />
                <ProfileItem label="Org name" value={cd?.Organization}  />
                <ProfileItem label="Designation" value={cd?.Designation}  />
                <ProfileItem label="Address" value={cd?.Address}  />
                <ProfileItem label="Mob Num" value={cd?.Mobile}  />
                <ProfileItem label="Tel Num" value={cd?.HomeOfficeNo}  />
            </ProfileContainer>
        </div>
    )
}

const ProfileContainer = ({title,children}) =>{
    return(
        <div className="cards-preview__detail">
        <div className="cards-preview__detail-header">
            <div className="cards-preview__detail-title"><span>{title}</span></div>
        </div>
        <div className="cards-preview__detail-body">
            <div className="cards-preview__detail-row">
                {children}
            </div>
        </div>
    </div>
    )
}


const ProfileItem = ({label,value}) => {
    return(
        <div className="cards-preview__detail-col">
            <div className="cards-preview__detail-label">{label}: </div>
            <div className="cards-preview__detail-value">{value}</div>
        </div>
    )
}

export default Trekker
