import classnames from 'classnames';
import React, { useEffect, useState } from 'react'


const blocks = [
    { id: 1, title: 'Trekker', color: 'green' },
    { id: 2, title: 'Revenue', color: 'purple' },
    { id: 3, title: 'Users', color: 'pink' },
    { id: 4, title: 'Route', color: 'blue' },
]

function NewDataBlock(props) {
    const { data } = props;
    const [total, setTotal] = useState(null);
    useEffect(() => {
        if (data) {
            const routesTotal = data?.TrekkerRevenueTotal?.TotalRoute;
            const trekkersTotal = data?.TrekkerRevenueTotal?.TotalTrekker;
            const usersTotal = data?.TrekkerRevenueTotal?.TotalUser;
            const revenueTotal = data?.TrekkerRevenueTotal?.TotalRevenue;

            const routes = data?.TrekkerByRoute?.map(item => ({ label: item.Route, value: item.Total }));
            const trekkers = [
                { label: 'This Month', value: data?.TrekkerRevenueTotal?.TrekkerThisMonth },
                { label: 'Last Month', value: data?.TrekkerRevenueTotal?.TrekkerLastMonth },
               // { label: 'This Year', value: data?.TrekkerRevenueTotal?.TrekkerThisYear },
                // { label: 'Last Year', value: data?.TrekkerRevenueTotal?.TrekkerLastYear },
            ]
            const revenues = [
                { label: 'Connect IPS', value: "रु " +data?.TrekkerRevenueTotal?.RevenueConnecIPs?.toLocaleString() },
                { label: 'Voucher', value: "रु " +data?.TrekkerRevenueTotal?.RevenueVoucher?.toLocaleString() },
                { label: 'Qr Scan', value: "रु " +data?.TrekkerRevenueTotal?.RevenueQrScan?.toLocaleString() },
                { label: 'This Month', value: "रु " + data?.TrekkerRevenueTotal?.RevenueThisMonth?.toLocaleString() },
                { label: 'Last Month', value: "रु " + data?.TrekkerRevenueTotal?.RevenueLastMonth?.toLocaleString() },
                { label: 'This Year', value: data?.TrekkerRevenueTotal?.RevenueThisYear },
               // { label: 'Last Year', value: data?.TrekkerRevenueTotal?.RevenueLastYear },
            ]
            const users = [
                // { label: 'NTB', value: data?.TrekkerRevenueTotal?.NtbUser },
                // { label: 'TAAN', value: data?.TrekkerRevenueTotal?.TaanUser },
                // { label: 'Checkpost User', value: data?.TrekkerRevenueTotal?.CheckpostUSer },
                { label: 'Trekking Agency', value: data?.TrekkerRevenueTotal?.TrekingAgencyUser },
                //{ label: 'Fit', value: data?.TrekkerRevenueTotal?.FitUser },
            ]
            const newData = {
                Trekker: { id: 1, title: 'Trekker', total: trekkersTotal, data: trekkers, color: 'blue' },
                Revenue: { id: 2, title: 'Revenue', total: "रु " + revenueTotal.toLocaleString(), data: revenues, color: 'purple' },
                Users: { id: 3, title: 'Users', total: usersTotal, data: users, color: 'yellow' },
                Route: { id: 4, title: 'Route', total: null, data: routes, color: 'red' },
                // Route: { id: 4, title: 'Route', total: routesTotal, data: routes, color: 'red' },
            }
            setTotal(newData)
        }
    }, [data])
    return (
        <div className="dashboard-blocks">
            <div className="dashboard-blocks__list">
                {
                    blocks && blocks.map(item => <DataBlock
                        key={`blocks-${item.id}`}
                        title={item.title}
                        item={item}
                        data={total && total[item.title]}
                        color={item.color} />)
                }
            </div>
        </div>
    )
}

function DataBlock(props) {
    const { color, title, onClick, item, data } = props;
    const blockClass = classnames("dashboard__newblock", { [`dashboard__newblock-${color}`]: color })
    return (
        <>
            <div className={blockClass} 
                onClick={() => data && onClick && onClick(data)}
                 >
                <div className="dashboard__newblock-body">
                    <div className="dashboard__newblock-title">
                        {title}
                    </div>
                    {
                        data && data?.total && <div className="dashboard__newblock-num">
                            {data?.total}
                        </div>
                    }
                    {
                        Boolean(data?.data) && (
                            <div className="dashboard__newblock-list">
                                {
                                    data?.data && data?.data.map(item=>{
                                        return <div className="dashboard__newblock-item">
                                            <div className="dashboard__newblock-item-label">
                                                {item?.label} :
                                            </div>
                                            <div className="dashboard__newblock-item-value">
                                                {item?.value}
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}


export default NewDataBlock
