/*
 * Base is the default environment for production.
 * Add everything here and override value in other files if needed.
 * https://blog.usejournal.com/my-awesome-custom-react-environment-variables-setup-8ebb0797d8ac
 */
export default function baseEnv(baseApi:string) {
  return {
    route: {
      baseRoute: '/', // Fixes issue with Github Pages
    },
    api: {
      registerUser: `${baseApi}/Account/user/`,
      getUser:`${baseApi}/Account/users/`,
      getCurrentUser:`${baseApi}/Account/users/details`,
      forgotPassword:`${baseApi}/Account/users/forgot-password`,
      resetPassword:`${baseApi}/Account/users/reset-password`,
      verifyEmail:`${baseApi}/Account/user/confirm-email`,
      resendEmail:`${baseApi}/Account/users/verify-email`,
      verifyChangeEmail:`${baseApi}/Account/user/change-email`,
      uploadUserImage:`${baseApi}/Account/user/upload-photo`,
      updateName:`${baseApi}/Account/user/fullname`,
      updateEmail:`${baseApi}/Account/user/email`,
      registerTrekker:`${baseApi}/v1/register/register-user`,
      notification: `${baseApi}/v1/notification/notification`,
      changeUserPassword: `${baseApi}/Account/users/change-users-password`,
      
      //Dashboard
      getDashboard:`${baseApi}/v1/register/dasboard`,
      getDashboardTotal:`${baseApi}/v1/register/users/trekkertotal`,
      getRecentTrekkers:`${baseApi}/v1/register/dashbaord/recentTrekkers`,
      getTopTrekkers:`${baseApi}/v1/register/dashbaord/topTrekkers`,
      getTopCountry:`${baseApi}/v1/register/dashbaord/topCountry`,

      //Billing
      getBilling:`${baseApi}/v1/register/billing`,
      getChalanlog: `${baseApi}/v1/register/chalanlog`,
      
      updateTrekkingDetails:`${baseApi}/v1/register/trekking`,
      updateInsuranceDetails:`${baseApi}/v1/register/trekking-insurance`,
      updateUserDetails:`${baseApi}/v1/register/trekking-userDetail`,

      getTrekkingHistory:`${baseApi}/v1/register/trekking-history`,
      getTrekkerVoucher:`${baseApi}/v1/register/get-trkkers-voucher`,
      signin:`${baseApi}/v1/auth/signin`,
      refreshToken:`${baseApi}/v1/auth/refresh-token`,
      logout:`${baseApi}/v1/auth/signout`,
      changePassword:`${baseApi}/Account/users/change-password`,

      getSingleCard:`${baseApi}/v1/register/user/cards`,
      getCards:`${baseApi}/v1/register/user-cards`,
      getCardsAll:`${baseApi}/v1/register/all/cards`,
      getReturnCards:`${baseApi}/v1/register/returnedcards`,
      returnCard:`${baseApi}/v1/register/retrun/card`,
      approveRejectCard:`${baseApi}/v1/register/approve-reject/card`,
      generateCard:`${baseApi}/v1/register/users/generatecard/`,
      
      //Checkin
      verifyTrekker:`/v1/register/verify-trekker`,
      autoCheckin:`/v1/register/autocheckin`,

      //users
      getUsers:`${baseApi}/v1/register/users`,
      getTableUser:`${baseApi}/Account/user/`,
      getAgencyUser:`${baseApi}/v1/register/agency-userDetails`,
      addUser:`${baseApi}/Account/user/admin`,
      updateUser:`${baseApi}/Account/user`,
      deleteUser:`${baseApi}/Account/users/`,
      disableUser:`${baseApi}/Account/disable`,
      approveUser:`${baseApi}/Account/approve`,
      uploadVoucher:`${baseApi}/v1/register/upload/voucher`,
      adduserDetails:`${baseApi}/v1/register/add-trekkers`,
      // adduserDetails:`${baseApi}/v1/register/register-userDetail`,
      //routes
      getRoutes:`${baseApi}/v1/route/route/detail`,
      getRoute:`${baseApi}/v1/route/route`,
      timsChalan:`${baseApi}/v1/register/timschalan`,
      userChalan:`${baseApi}/v1/register/customer`,
      getRouteProvince:`${baseApi}/v1/route/province`,
      addRoute:`${baseApi}/v1/route/routes`,
      updateRouteStatus:`${baseApi}/v1/route/route/enable-disable`,

      //select
      userType:`${baseApi}/Account/usertype`,
      userAgentType:`${baseApi}/Account/agency/usertype`,
      gender:`${baseApi}/v1/register/gender`,
      trekkingArea:`${baseApi}/v1/register/trekkingArea`,
      getRouteDrop:`${baseApi}/v1/route/route-dropdown`,
      getCheckpoint:`${baseApi}/v1/route/checkpoints`,
      getRegion:`${baseApi}/v1/register/region`,
      getTrekkerPersionality:`${baseApi}/v1/register/trekker-personality`,
      getCountries:`${baseApi}/v1/register/countries`,
      getCountriesByRegion:`${baseApi}/v1/register/countries`,
      getPaymentMethods:`${baseApi}/v1/register/paymentMethod`,
      getRegisterType:`${baseApi}/v1/register/filter/registerType`,
      getFilterAgencyUsers:`${baseApi}/v1/register/filter/agencyUser`,
      getFilterScannedTrekkers:`${baseApi}/v1/register/filter/scannedTrakker`,
      
      //Requests
      getRequest:`${baseApi}/v1/register/agency/request`,
      requestNotes:`${baseApi}/v1/register/agency/notes`,
      getVoucher:`${baseApi}/v1/register/voucher`,
      updateVoucherStatus:`${baseApi}/v1/register/reject-approve/voucher`,
      approveDocument:`${baseApi}/Account/reject-approve/documents`,
      postNotes:`${baseApi}/v1/register/agency/notes`,

      //Trekkers
      getTrekkers:`${baseApi}/v1/register/trekkers`,
      getDeletedTrekkers:`${baseApi}/v1/register/deleted-trekkers`,
      // getTrekkers:`${baseApi}/v1/register/trekkers/byUser`,
      getTrekker:`${baseApi}/v1/register/get-trekkers`,
      getTrekking:`${baseApi}/v1/register/get-trekking`,
      getScannedTrekkers:`${baseApi}/v1/register/filter/scannedTrakker`,
      getScannedTrekkersByUser:`${baseApi}/v1/register/scanned-trekkers/byUser`,
      verifyScanTrekker: `${baseApi}/v1/register/verify/scan/trekker`,
      getSelfScannedTrekkers: `${baseApi}/v1/register/self-scan-trekkers`,
      deleteCard: `${baseApi}/v1/register/delete-trekker`,
      
      //Payments
      getEsewaPayment:`${baseApi}/v1/payments/esewa`,
      getCIPSPayment:`${baseApi}/v1/payments/connectIPS`,
      saveCIPSPayment:`${baseApi}/v1/payments/connectIPS`,
      saveCIPSPaymentFailure:`${baseApi}/v1/payments/connectIPS-failure`,
      getKhaltiPayment:`${baseApi}/v1/payments/khalti`,
      getfonePayPayment:`${baseApi}/v1/payments/fonePay`,
      getCardPayment:`${baseApi}/v1/payments/payment`,

      //Documents 
      uploadAgencyDocuments:`${baseApi}/Account/user-agency/documents`,
      rejectAgencyDocuments:`${baseApi}/Account/reject-approve/documents`,
      updateAgencyDetails:`${baseApi}/Account/user/update-agency-details`,
      updateCheckDetails:`${baseApi}/Account/user/update-checkpost-details`,

      //Bills
        postBills: `${baseApi}/v1/register/bills`,

        //Guides
        getGuides: `${baseApi}/v1/route/guide-dropdown`,
        getGuide: `${baseApi}/v1/route/guide`
    },
    isProduction: true,
    isDevelopment: false,
    isTesting: false,
  };
}
