import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import { pageSizeValues } from 'constants/contants';
import { useForm, useWatch } from 'react-hook-form';
import Pagination from 'components/table/pagination';
import Table from 'components/table';
import Form from 'components/form';
import FormGroup from 'components/form/formGroup';
import Container from 'components/layout/container';
import RouteEnum from 'constants/routesEnum';
import {getRequests} from 'store/actions/requestActions';
import DownloadAllCsv from 'components/common/downloadAllCsv';
import environment from 'environment';
import { Status, TrekkingStatus } from 'components/table/commonTableComponents';
import { RequestStatusFilter } from 'components/common/filters';


const csvheaders = [
    { label: "Company Name", key: "OrganizationName" },
    { label: "Email", key: "Email" },
    { label: "Address", key: "ContactAddress" },
    { label: "Regd. Date", key: "RegisterDate" },
]

const data = [
    {Id:1,CompanyName:'Buddha International Travel and Tours',Email:'alma.lawson@example.com',Address:'Kathmandu Naya Bazar 17 Kal Dhara chock,...',RegisteredDate:'8 Sep, 2020'}
]

const pageState = {
    pageindex:0,
    pageSize:pageSizeValues[0].value,
    totalRows:0,
}

const filterState = {
    status: null,
}

const statusValues = {
    "approved":[1,1],
    "pending":[1,0],
    "rejected":[1,null],
    "document_pending":[null,null],
}

function RequestTable(props) {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState(filterState)
    const [page, setPage] = useState(pageState)
    // const [data,setData] = useState([]);
    const {control,register} = useForm();
    const onPageChange = async (page) =>await setPage(prev=>({...prev,pageindex:page}))
    const pageSize:any = useWatch({ control, name: 'pageSize' });
    const search:any = useWatch({ control, name: 'search' });
    
    const [data,setData] = useState([]);
    const onRowClick = (row)=>{
        // props.history.push(RouteEnum.Request + '/' +row.original.Id,);
        // props.history.push({
        //     pathname: RouteEnum.Request + '/' +row.original.UserId,
        //     state: { detail: row.original }
        // });
        window.open(RouteEnum.Request + '/' +row.original.UserId, "_blank");
    }
    const onFilter = async (items) => {
        const {status} = items;
        await setFilter(prev => ({ ...filter, ...items }))
        if(status){
            const statusItems = status.split(",");
            const isDocumentUploaded =[];
            const isVerified =[];
            statusItems.map(st=>{
                const stats = statusValues[st];
                if(stats){
                    isDocumentUploaded.push(stats[0])
                    isVerified.push(stats[1])
                }
            })
            const IsDocumentUploaded = isDocumentUploaded.join(",")
            const IsVerified = isVerified.join(",")
            dispatch(getRequests({...page,name:search,IsDocumentUploaded,IsVerified}))
            return;
        }
        else{
            dispatch(getRequests({...page,name:search}))
        }
        
    }
    // useEffect(()=>{ 
    //     getRequestsFront()
    // },[]);
    const {requests} =useSelector((state:RootStateTypes)=>state.requestReducer)
    useEffect(()=>{
        if(requests && requests){
            setData(requests.AgencyUser)
            const totalRows = requests ? requests.AgencyUser[0]?.TotalRows : 0;
            requests && setPage(prev=>({...prev}))
            requests && setPage(prev=>({...prev,totalRows:totalRows}))
        }
    },[requests]);
    
    useEffect(()=>{
        getRequestsFront()
    },[page.pageSize,page.pageindex]);

    const getRequestsFront = () =>{
        dispatch(getRequests({...page}))
    }
    useEffect(() => {
        if(pageSize && pageSize.value) {
          setPage(prev=>({...prev,pageSize:pageSize.value}));
        }
    }, [pageSize])
    // useEffect(() => {
    //     if(search){
    //         dispatch(getRequests({...page,name:search}))
    //     }
    // }, [search]);
    const getSearch = () => {
        dispatch(getRequests({...page,name:search ?? ""}))
    }

    
const columns = [
        {
            Header: 'Status',
            accessor: 'Status',
            width: 200,
            isSort:true,
            Cell: (props) => <TrekkingStatus {...props} />,
            Filter: ({ filter: filt, onchange }) => <RequestStatusFilter {...filter} onFilter={onFilter} />,
        },
        {
            Header: 'Company Name',
            accessor: 'OrganizationName',
            isSort:true,
            width: 260,
        },
        {
            Header: 'Email',
            accessor: 'Email',
            width: 140
        },
        {
            Header: 'Address',
            accessor: 'ContactAddress',
            width: 350,
            isSort:true,
        },
        {
            Header: 'Regd. Date',
            accessor: 'RegisterDate',
            width: 140,
            isSort:true,
        },
        
    ];

    return (
        <div className="request-table">
            <Container>
                    <div className="space-between search-alt">
                        <Form 
                            formName="reactSelect"
                            control={control}
                            name="pageSize"
                            value={pageSizeValues[0]}
                            defaultValue={pageSizeValues[0]}
                            className="page-nums"
                            width="175px"
                            placeholder="Choose..."
                            options={pageSizeValues}
                        />
                        <div className="flex">
                            
                            <DownloadAllCsv 
                                isData={false}
                                dataField="AgencyUser"
                                headers={csvheaders} fileName="Requests" api={environment.api.getRequest}  />
                            <Form 
                                register={register}
                                name="search"
                                width="300px"
                                placeholder="Search..."
                                formName="searchInput"
                                onKeyUp={(name,value,e)=>{
                                    if(e?.keyCode === 13){
                                        getSearch()
                                    }
                                }}
                            />
                        </div>
                    </div>
                <div className="routes-table">
                    <Table onRowClick={onRowClick} columns={columns} data={data} />
                    <Pagination onPageChange={onPageChange} {...page} totalRows={page.totalRows} />
                </div>
            </Container>
        </div>
    )
}

export default RequestTable
