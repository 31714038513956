import React, { useEffect, useState } from 'react'
import RegisterContext from './context';
import {StepperEnums,steps} from './helpers';
import Breadcrumbs from 'components/breadcrumb';
import RouteEnum from 'constants/routesEnum';
import TrekForm from './form/trekForm';
import RegisterStepper from './registerStepper';
import '../styles.scss';
import { getTrekker,getRegistrationTrekker, SaveCIPSFailure, SaveCIPS } from 'store/actions/trekkersAction';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateTypes } from 'ts/reducer';
import queryString from 'query-string';
import {getParsedQueryObject} from './helpers'
import AddForm from './form/addForm';
import EditForm from './form/editForm';
import isEmpty from 'helpers/isEmpty';
import Payment from './payment'
import TrekkerModel from 'models/TrekkerModel';
import { getTotalFromRegions } from 'helpers/calculateAmount';

const {Provider} = RegisterContext;
const userRegisterationModel = {
    TrekkingAreaId:null,
    TrekExitDate: "",
    TrekEntryPoint: "",
    TrekExitPoint: "",
    TrekEntryDate: "",
    TrekRoute: "",
    RegistrationId: null,
    PorterGuideInfo: "",
    PorterGuideContact: "",
    PorterGuideLicesenceNo: "",
    OtherSupportStaffs: "",
}

const getFinalAmount = (trekkers:TrekkerModel,user) =>{
    const regionIds = trekkers?.TrekkersDetailsDtos && 
                        trekkers?.TrekkersDetailsDtos?.map((item:any)=>item?.TrekkersDetails.RegionalId);
    if(regionIds && regionIds.length > 0){
        const total = getTotalFromRegions(user.UserType,regionIds);
        return total;
    }
    return null;
}

function RegisterTrekker(props) {
    const breadList = [
        { title: 'Trekking History', to: RouteEnum.TrekkingHistory }, 
        { title: 'Register new trek', to: '/', isCurrent: true },
    ]
    const [activeStep,setActiveStep] = useState(StepperEnums.userInfo);
    const { params } = props.match;
    const { search, state } = props.location;
    const dispatch = useDispatch();
    const {user} = useSelector((state:RootStateTypes)=>state.auth);
    const { registeredTrekker } = useSelector((state: RootStateTypes) => state.trekkersReducer);
    const parsedReq: any = queryString.parse(search);
    const [paymentLoad, setPaymentLoad] = useState<boolean>(false);
    const [paymentError, setPaymentError] = useState<boolean>(false);
    const [paymentCalled, setPaymentCalled] = useState<boolean>(false);
    const vals = getParsedQueryObject(params,parsedReq);

    const onPaymentStatusValidate = async () =>{
        setPaymentLoad(true);
        if (vals.isFailure) {
            const response = await dispatch(SaveCIPSFailure(parsedReq?.TXNID));
            const isError:boolean = response ? false : true;
            setPaymentError(isError)
        }
        if (vals.isSuccess) {
            const response = await dispatch(SaveCIPS(parsedReq?.TXNID));
            const isError:boolean = response ? false : true;
            setPaymentError(isError)
        }
        setPaymentLoad(false);
        await setPaymentCalled(true)
    };

    useEffect(() => {
        if (vals.regId && !registeredTrekker) {
            if (!vals.isNotReq) {
                setActiveStep(vals.isPaymnet ? StepperEnums.review : params.actionType ? StepperEnums.payment : StepperEnums.review);
                // setActiveStep(vals.isPaymnet ? StepperEnums.payment : params.actionType ? StepperEnums.payment : StepperEnums.review);
                dispatch(getRegistrationTrekker(vals.regId, null))
            } else {
                if(vals?.TXNID){
                    const [id, some] =vals.TXNID;
                    setActiveStep(StepperEnums.payment);
                    dispatch(getRegistrationTrekker(null, id));
                    // !paymentCalled && onPaymentStatusValidate()
                }
            }
        }
    // }, []);
    }, [parsedReq]);
    useEffect(() => {
        if(vals?.TXNID){
            !paymentCalled && onPaymentStatusValidate()
        }
    }, []);

    const renderView = () =>{
        switch(activeStep){
            case StepperEnums.userInfo:
                return <TrekForm  />
            case StepperEnums.review:
                return <EditForm />
            case StepperEnums.payment:
                return <Payment />
            default:
                return <EditForm  />
        }
    }
    // console.log({vals,registeredTrekker})
    return (
        <Provider value={{
            activeStep:activeStep,
            setActiveStepper:setActiveStep,
            steps,userRegisterationModel,
            registered:registeredTrekker,
            isDto:!isEmpty(registeredTrekker),
            amount:isEmpty(registeredTrekker) ? null : getFinalAmount(registeredTrekker,user),
            paymentError,
            paymentLoad,
            // regId: vals.regId === 'noreg' ? registeredTrekker?.UserRegistrationModel?.RegistrationId : vals.regId,
            ...vals,
        }}>
            <Breadcrumbs linkList={breadList} />
            <div className="trek-register">
                <RegisterStepper  />
                {renderView()}
            </div>
        </Provider>
    )
}

export default RegisterTrekker