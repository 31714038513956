import React from 'react'
import CommonImageModal from 'components/common/commonImageModal';

function Gallery({images}) {
    return (
        <div className="gallery">
            <ul className="gallery-list">
                {
                    images.map(item=>{
                        return(
                            <li key={item.UserFileName} className="gallery-list__item">
                                <div className="gallery-list__item-image">
                                    {/* <img src={'/'+item.FileLocation} alt="Route Image"/> */}
                                    <CommonImageModal 
                                        src={'/'+item.FileLocation}
                                    />
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default Gallery
