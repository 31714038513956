import React from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { RootStateTypes } from 'ts/reducer'
import Avatar from 'react-avatar';
import Icons from 'components/icons/svgIcons';
import { getDashboardTopCountry } from 'store/actions/dashboardActions';

function TopCountry() {
    const {topCountry} = useSelector((state:RootStateTypes)=>state.dashboardReducer);
    const dispatch = useDispatch();
    const onIconClick = () => {
        dispatch(getDashboardTopCountry())
    }
    return (
        <div className="dashboard-container">
            <div className="dashboard-container__header">
                <span>Top Countries</span>
                <span onClick={()=>onIconClick()} >
                    <Icons name="Re" />
                </span>
            </div>
            <div className="dashboard-container__body">
            {
                    Boolean(topCountry) ? (
                        <ul className="dashboard-container__list">
                            {
                                topCountry && topCountry.map(item=>{
                                    return(
                                        <li className="dashboard-container__item dashboard-container__country">
                                            <div className="dashboard-container__flag">
                                                {
                                                    //@ts-ignore
                                                    <Avatar
                                                        src={item?.Flag}
                                                        size="40px"
                                                        round="3px"
                                                        name={item?.Country}
                                                    />
                                                }
                                            </div>
                                            <div className="dashboard-container__name">
                                                <div>{item?.Country}</div>
                                            </div>
                                            <div className="dashboard-container__num">
                                                <span>{item?.CountryCount}</span>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    ):(
                        <div className="dashboard_nth">No Data Found</div>
                    )
                }
            </div>
        </div>
    )
}

export default TopCountry
