import Button from 'components/button'
import React, { useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import https from 'services/http';
import getQueryUrl from 'helpers/getQueryUrl';
import HttpErrorResponseModel from "src/services/httpErrorResponseModel";
import { useDispatch } from 'react-redux';
import Icons from 'components/icons/svgIcons';


interface CsvProps{
    api:string,
    extraParams?:any,
    isData?:boolean,
    headers?:Array<any>,
    dataField?:string,
    fileName?:string,
}

function DownloadAllCsv(props:CsvProps) {
    const {
        api,
        extraParams,
        isData,
        fileName,
        headers,
        dataField,
    } = props;
    const [data,setData] = useState([]);
    const [load,setLoad] = useState(false);
    const ref = useRef(null)
    const dispatch = useDispatch()
    const getData = async () =>{
        const finalUrl = getQueryUrl(api,{
            pageindex:0,
            pageSize:1000000000,
            ...extraParams
        })
        setLoad(true);
        const res:any = await https.get(finalUrl);
        setLoad(false);
        const isError = res instanceof HttpErrorResponseModel;
        if(isError){
            console.log("Some Error Occured");
            return
        }
        console.log({res,props})
        if(res.status === 200){
            if(isData){
                if(res.data.Status){
                    
                    if(dataField){
                        setData(res.data.Data[dataField])
                    }else{
                        setData(res.data.Data)
                    }
                }else{
                    console.log("Some Error Occured")
                }
            }else{
                if(dataField){
                    setData(res.data[dataField])
                }else{
                    setData(res.data)
                }
            }
            setTimeout(() => {
                ref.current.link.click();
            });
        }

    }
    return (
        // <CSVLink data={data}>
        //     <Button isDisabled={load}  title="Export as CSV" bg="grey" type="button-outline" />
        // </CSVLink>
        <div>
        
            <Button 
                style={{padding:'0 6px'}} isDisabled={load} bg={load ? "cancel" :"primary" }
                // buttonType="icon-button"
                buttonClass="download-btn" 
                onClick={()=>getData()}  
                title={load ? "Fetching" :<Icons color="#111" size={17} name="Down" />}  />
            <CSVLink
                headers={headers}
                filename={`${fileName}.csv`}
                data={data}
                ref={ref}
            />
        </div>
    )
}

DownloadAllCsv.defaultProps = {
    extraParams:{},
    isData:true,
    dataField:null,
    fileName:"data",
    headers:null
}

export default DownloadAllCsv
