import classNames from 'classnames'
import Icons from 'components/icons/svgIcons'
import React from 'react'

function CommonPasswordValidation({pass,isNotEight,isNotLowUp,isNotNum,isNotNonAlph}) {
    const getPasswordError = (log) =>classNames("password-error",
    {
        "password-error-active":log,
        "password-error-inactive":pass && !log,
    })
    return (
        <ul className="password-errors">
            <li className="password-errors-title">Your Password must have :</li>
            <li className={getPasswordError(isNotEight)}>
                <Icons  name="CheckCircle" size={18} />
                <span>8 or more characters</span>
            </li>
            <li className={getPasswordError(isNotLowUp)}>
                <Icons  name="CheckCircle" size={18} />
                <span>Upper and lowercase characters</span>
            </li>
            <li className={getPasswordError(isNotNum)}>
                <Icons  name="CheckCircle" size={18} />
                <span>At least one number</span>
            </li>
            <li className={getPasswordError(isNotNonAlph)}>
                <Icons  name="CheckCircle" size={18} />
                <span>At least one non AlphaNumeric</span>
            </li>
        </ul>
    )
}

export default CommonPasswordValidation
