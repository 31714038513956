import React, { useState } from 'react'
import Button from 'components/button';
import FormGroup from 'components/form/formGroup';
import Form from 'components/form';
import { verifyTrekker } from 'store/actions/checkinAction';
import { useDispatch } from 'react-redux';
import Checkin from './checkin';
import './styles.scss';
import Common from '../login/common';
import Icons from 'components/icons/svgIcons';
import { Link } from 'react-router-dom';
import RoutesEnum from 'constants/routesEnum';

function Location(props) {
    const dispatch = useDispatch();
    const [active, setActive] = useState(0);
    const [data, setData] = useState(null);
    // const [error, setError] = useState("");
    const onSubmit = async (data) => {
        const res: any = await dispatch(verifyTrekker(data));
        if(!res){
            // setError("Please enter the correct card and passport number")
            // setTimeout(()=>{
            //     setError("")
            // },8000)
        }
        if (res) setData(data)
        setActive(res?1:0)
    }
    const clearData = (res) => setActive(res);
    return (
        <Common 
                isLogin 
                title={active === 0 ? "Self check-in" : active === 1 ? "Hello" : "Thank You !"}
                sub={ active === 0 ? "Check in with TIMS Card No. and Passport No." : active === 1 ? "Plese enter your current Location. This will help us to track." : "You are successfully checked in." }
            >
            <div className="location ">
                {
                    active === 0 ? (
                        <div className="location-container">
                            <FormGroup onSubmit={onSubmit} >
                                <div className="location-form__row">
                                    <Form
                                        name="cardno"
                                        placeholder="TIMS Card No."
                                        // width="400px"
                                        required
                                        // leftIcon={<Icons name="CardAlt" />}
                                    />
                                </div>
                                <div className="location-form__row">
                                    <Form
                                        name="passport"
                                        placeholder="Passport No."
                                        // width="400px"
                                        required
                                        // leftIcon={<Icons name="CardAlt" />}
                                    />
                                </div>
                                <div className="location-form__row">
                                    <Form
                                        name="isCheckOut"
                                        formName="checkinput"
                                        checkLabel="Is Check Out"
                                        className="login-check"
                                    />
                                </div>
                                
                                {/* {
                                    error && <div className="error-panel">{error}</div>
                                } */}
                                <div className="button-group button-group-bold">
                                {/* <div className="location-form__btn"> */}
                                    <Button
                                        title="Check"
                                        bg="primary"
                                        // type="button-outline"
                                        htmlButtonType="submit"
                                    />
                                </div>
                            </FormGroup>
                        </div>
                    ) : active === 1 ? (
                        <Checkin data={data}  clearData={clearData} />
                    ) : (
                        <Button
                            title="Login"
                            bg="primary"
                            // type="button-outline"
                            htmlButtonType="button"
                            onClick={()=>props.history.push(RoutesEnum.Login)}
                        />
                    )
                }
            </div>
            {
                active !== 2 && (
                    <div className="footer-link">
                        <Link to={RoutesEnum.Login}>Login</Link>
                    </div>
                )
            }
            
        </Common>

    )
}

export default Location
