import React,{useState,useEffect} from 'react'
import Common from '../login/common';
import Form from 'components/form';
import FormGroup from 'components/form/formGroup';
import { useDispatch } from 'react-redux';
import { changeUserPassword ,resetPassword} from 'store/actions/auth';
import forgotImage from 'assets/images/lock.png'
import Button from 'components/button';
import QS from 'query-string';
import { useForm } from 'react-hook-form';
import usePasswordValidationResolver from 'src/hooks/usePasswordValidation';
import CommonPasswordValidation from 'components/common/commonPasswordValidation';
// import jwt_decode from 'jwt-decode';
import RoutesEnum from 'constants/routesEnum';

function UserPassword(props) {
    const dispatch = useDispatch();
    const [code,setCode]= useState('')
    const [userId,setUserId]= useState('')
    const [error,setError]= useState('')
    const methods = useForm();
    const { control, watch, reset } = methods
    const onSubmit = (data) => {
        setError('')
        //if(!code){
        //    setError('Invalid token! please refresh and try again');
        //    return;
        //}
        //if(!userId){
        //    setError('Invalid User Id! please refresh and try again');
        //    return;
        //}
        const newData ={
            ...data,
            userId,
            code
        }
        dispatch(changeUserPassword(newData,props.history))
    }
    const {search} = props.location; 
    useEffect(()=>{
        // if(true){
        //     props.history.push(RoutesEnum.Login,{state: { isTokenExpired: true }});
            
        // }
        if(search){
            let tk:any =QS.parse(search);
            console.log({tk})
            setError('')
            if(tk?.token){
                const tkn = tk?.token.replace(/ /g,"+");
                // console.log({tkn})
                // const decode = true;
                // if(decode){
                //     props.history.push(RoutesEnum.Login);

                // }
                // // const decode  = jwt_decode(tkn);
                // console.log({decode})
                setCode(tkn);
            }
            if(tk?.verify){
                const vrf = tk?.verify.replace(/ /g,"+");
                setUserId(vrf);
            }
        }
    },[search])
    const password = watch("password")
    const {
        pass,
        isNotEight,
        isNotNum,
        isNotLowUp,
        isNotNonAlph
    } = usePasswordValidationResolver(password)
    return (
        <Common isFooter={false} title="Create New Password" >
            <FormGroup method={methods} onSubmit={onSubmit}>
                <div className="forgot" style={{paddingTop:0}} >
                    {/* <div className="forgot-image">
                        <img src={forgotImage} alt="Forgot Password Image"/>
                    </div> */}
                    {/* <div className="forgot-title login-form__container-title">
                        <span>Create new password</span>
                    </div> */}
                    {/* <div className="forgot-sub login-form__container-sub">
                        <span>Please, enter new password of at least 8 digit character containing number and letters.</span>
                    </div> */}
                    <div className="login-form__group">
                        <Form
                            placeholder="User Name"
                            name="username"
                            type="username"                        
                            required={true}
                        />
                        <Form
                            placeholder="Password"
                            name="password"
                            type="password"
                            validate={(value) => isNotEight && isNotLowUp && isNotNum && isNotNonAlph  || ""}
                            required={true}
                        />
                        <CommonPasswordValidation 
                            pass={pass}
                            isNotEight={isNotEight}
                            isNotLowUp={isNotLowUp}
                            isNotNum={isNotNum}
                            isNotNonAlph={isNotNonAlph}
                        />
                        <Form
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            validate={(value) => value === password || "Password don't match."}
                            type="password"
                            required={true}
                        />
                        
                        {
                            error && <div className="error-messgae">{error}</div>
                        }
                        <div className="forgot-button">
                            <Button title="Reset my password" htmlButtonType="submit" bg="primary" />
                        </div>
                    </div>
                </div>
            </FormGroup>
        </Common>
    )
}

export default UserPassword
