// import isEmpty from "~/helpers/isEmpty";
import types from "constants/types";
import { v4 as uuidv4 } from 'uuid';
import { validateAction } from "ts/interfaces/reduxInterfaces";

interface dispatchAction {
  type: string;
  payload: object | string | [];
}

export const addMessage = (data: validateAction) => (
  dispatch: (action: dispatchAction) => dispatchAction
) => {
  dispatch({
    type: types.ADD_MESSAGE,
    payload: {
      type: data.type,
      message: data.message,
      position: data.position,
    },
  });
};

export const addMessages = (data: validateAction) => (
  dispatch: (action: dispatchAction) => dispatchAction
) => {
  dispatch({
    type: types.ADD_MESSAGES,
    payload: {
      type: data.type,
      message: data.message,
      position: data.position,
      id: uuidv4(),
    },
  });
};

export const checkResponseData = (err) => {
  if (err.response) {
    if (err.response.data) {
      return true;
    }
  }
  return false;
};

export const formErrorsAction = (err) => (dispatch) => {
  const bool = checkResponseData(err);
  if (bool) {
    if (err.response.data.errors) {
      dispatch({
        type: types.GET_ERRORS,
        payload: err.response.data.errors,
      });
    }
  }
};

export const toastMessage = (data) => (dispatch) => {
  let MessageType = data.type ? data.type : null;
  let Message = data.message ? data.message : [];

  dispatch({
    type: types.ADD_MESSAGE,
    payload: { type: MessageType, message: Message },
  });
};

export const newtoastMessage = (err, type = "") => (dispatch) => {
  const bool = checkResponseData(err);
  if (bool) {
    if (err.response.data.message) {
      dispatch({
        type: types.ADD_MESSAGE,
        payload: {
          type: err.response.data.messageType
            ? err.response.data.messageType
            : "normal",
          message: err.response.data.message,
        },
      });
    }
  }
  if (type) {
    dispatch({
      type: type,
      payload: [],
    });
  }
};

export const clearOtherMessage = () => (
  dispatch: (action: dispatchAction) => dispatchAction
) => {
  dispatch({
    type: types.ADD_OTHER_MESSAGES,
    payload: {},
  });
};

export const removeMessage = (id: string) => (
  dispatch: (action: dispatchAction) => dispatchAction
) => {
  dispatch({
    type: types.REMOVE_MESSAGE,
    payload: id,
  });
};

export const clearMessage = () => (
  dispatch: (action: dispatchAction) => dispatchAction
) => {
  dispatch({
    type: types.CLEAR_MESSAGE,
    payload: {},
  });
};



export const clearAllErrors = () => {
  return {
    type: types.CLEAR_ERRORS,
  };
};

export const clearKEYErrors = () => {
  return {
    type: types.CLEAR_ERRORS,
  };
};
