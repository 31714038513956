import React from 'react';
import classnames from 'classnames';
import './styles.scss';

interface TypoProps{
    children:any,
    className?:string,
    style?:any,
}

function Typo(props:TypoProps) {
    const {children,className,style} = props;
    return (
        <h3 
            style={style?style:{}}
            className={classnames("heading-3",{className})}>
            {children}
        </h3>
    )
}

export default Typo
