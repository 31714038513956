import {userEnums} from 'constants/contants';

export const getTImsChargs = (userType, region) => {
    
    if( parseInt(region) === 4){
        return 500
    }
    if(parseInt(region) === 1 && userType == userEnums.check){
        return 1000
    }
    if(parseInt(region) === 2 && userType == userEnums.check){
        return 2000
    }

    if(parseInt(userType) === userEnums.trekkingAgency && region == 1){
        return 1000
    }
    if(parseInt(userType) === userEnums.free && region == 1){
        return 1000
    }
    if(parseInt(userType) === userEnums.trekkingAgency && region == 2){
        return 2000
    }
    if(parseInt(userType) === userEnums.free && region == 2){
        return 2000
    }
    if(parseInt(userType) === userEnums.trekkingAgency && region == 5){
        return 0
    }
    if(parseInt(userType) === userEnums.free && region == 5){
        return 0
    }
}

export const getTotalFromRegions = (userType,regions) => {
    var sum = 0;
    if(regions && regions.length > 0){
        regions.forEach(item=>{
            sum += getTImsChargs(userType,item);
        })
    }
    return sum;
}
